<mat-card *ngIf="form_error.detail">
  <div class="detail-not-exist">Record Does Not Exist.</div>
</mat-card>
<div fxLayout="row" fxLayoutAlign="center" *ngIf="!form_error.detail">
  <mat-card class="mission-form">
    <div fxLayout="column">
      <h2 fxFlex>{{ title }}</h2>
      <mat-card-header>
        <div fxLayout="row">
          <mat-card-title *ngIf="id">
            <h3>{{ mission.label }}</h3>
          </mat-card-title>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="column">
          <mat-form-field *ngIf="!id">
            <input
              matInput
              placeholder="Mission Identifier"
              [(ngModel)]="mission.mission_id"
              maxlength="12"
            />
          </mat-form-field>
          <mat-error *ngIf="form_error.mission_id">
            {{ form_error.mission_id }}
          </mat-error>
          <mat-form-field>
            <mat-select placeholder="Type" [(ngModel)]="mission.mission_type">
              <mat-option *ngFor="let t of labels.type" [value]="t">
                {{ t }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select
              placeholder="Status"
              [(ngModel)]="mission.mission_status"
            >
              <mat-option *ngFor="let s of labels.status" [value]="s">
                {{ s }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              [matDatetimepicker]="picker1"
              [(ngModel)]="mission.start_date"
              placeholder="Start Date"
            />
            <mat-datetimepicker-toggle matSuffix [for]="picker1">
            </mat-datetimepicker-toggle>
            <mat-datetimepicker touchUi #picker1></mat-datetimepicker>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              [matDatetimepicker]="picker2"
              [(ngModel)]="mission.end_date"
              placeholder="End Date"
            />
            <mat-datetimepicker-toggle matSuffix [for]="picker2">
            </mat-datetimepicker-toggle>
            <mat-datetimepicker touchUi #picker2></mat-datetimepicker>
          </mat-form-field>
          <mat-error *ngIf="dateError">{{ errorMessage }}</mat-error>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="row">
          <span fxFlex fxLayoutAlign="end">
            <button mat-raised-button #save_btn (click)="save(save_btn)">
              Save
            </button>
          </span>
        </div>
      </mat-card-actions>
    </div>
  </mat-card>
</div>
