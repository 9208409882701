import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Title } from "@angular/platform-browser";

import { ApiService } from "@app/api/api";
import { CG_CommonModule } from "@app/common/module";
import { NoteModule } from "@app/note/module";
import { FlightComponent } from "./component";
import { MaterialModule } from "@app/material.module";
import { FlightDetailComponent } from "./detail/detail.component";
import { FlightListComponent } from "./list/list.component";
import { FlightFormComponent } from "./form/form.component";
import { FlightReportComponent } from "./report/component";
import { FlightLegModule } from "@app/flight-leg/flight-leg.module";
import { PipeModule } from "@app/common/pipes";
import { TrackMapComponent } from "./detail/trackmap.component";
import { GenericListModule } from "@app/generic-list/module";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMomentDatetimeModule } from "@mat-datetimepicker/moment";
import {
  MatDatetimepickerModule,
  MAT_DATETIME_FORMATS,
} from "@mat-datetimepicker/core";
@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    CG_CommonModule,
    NoteModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FlightLegModule,
    PipeModule,
    GenericListModule,
    MatDatetimepickerModule,
    MatDatepickerModule,
    MatMomentDatetimeModule,
  ],
  exports: [
    FlightComponent,
    FlightDetailComponent,
    FlightListComponent,
    FlightFormComponent,
    FlightReportComponent,
    TrackMapComponent,
  ],
  providers: [ApiService, Title],
  declarations: [
    FlightComponent,
    FlightDetailComponent,
    FlightListComponent,
    FlightFormComponent,
    FlightReportComponent,
    TrackMapComponent,
  ],
})
export class FlightModule {}
