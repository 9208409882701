import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import * as turf from "@turf/turf";

@Injectable()
export class ContextmenuService {
  component: string;
  private DataSource = new Subject<any>();
  data$ = this.DataSource.asObservable();

  private MeterSource = new Subject<any>();
  meterdata$ = this.MeterSource.asObservable();

  private MeterMsg = new Subject<any>();
  metermsg$ = this.MeterMsg.asObservable();

  meterlist = [];
  airportlist = [];
  copyresponse = [];

  airport_result(res, params, select = false) {
    if (res["features"].length > 0) {
      this.filter_airport_result(res, params, select);
    } else {
      this.airportlist = [{ name: "Airport not found !" }];
      this.DataSource.next(this.airportlist);
    }
  }
  meter_result(res, params, select = false) {
    if (res["features"].length > 0) {
      this.filter_meter_result(res, params, select);
    } else {
      this.meterlist = [{ name: "Meter not found !" }];
      this.DataSource.next(this.meterlist);
    }
  }
  locationcopy() {
    navigator["clipboard"]
      .readText()
      .then((text) => {
        this.copyresponse = [{ name: "Copying to clipboard was successful!" }];
      })
      .catch((err) => {
        console.error("Something went wrong", err);
      });
    this.DataSource.next(this.copyresponse);
  }
  filter_meter_result(res, params, selected_area = false) {
    this.meterlist = [];
    for (let i = 0; i < res["features"].length; i++) {
      const lat = res["features"][i].geometry.coordinates[1];
      const lng = res["features"][i].geometry.coordinates[0];
      const from = turf.point([params["lat"], params["lng"]]);
      const to = turf.point([lat, lng]);
      let distance = turf.distance(from, to);

      const name = "serial : " + res["features"][i].properties.serial;
      const serial = res["features"][i].properties.serial;
      if (selected_area) {
        this.meterlist.push({ name: name, serial: serial });
      } else {
        this.meterlist.push({
          name: name,
          latlng: distance.toFixed(1) + " Km",
          serial: serial,
        });
      }
    }
    this.MeterSource.next(this.meterlist);
  }
  filter_airport_result(res, params, selected_area = false) {
    this.airportlist = [];
    for (let i = 0; i < res["features"].length; i++) {
      const lat = res["features"][i].geometry.coordinates[1];
      const lng = res["features"][i].geometry.coordinates[0];
      const name = res["features"][i].properties.name;
      const from = turf.point([params["lat"], params["lng"]]);
      const to = turf.point([lat, lng]);
      let distance = turf.distance(from, to);
      if (selected_area) {
        this.airportlist.push({ name: name + " airport in this area" });
      } else {
        this.airportlist.push({
          name: name,
          latlng: distance.toFixed(1) + " Km",
        });
      }
    }
    this.DataSource.next(this.airportlist);
  }
  display_meter_message() {
    this.MeterMsg.next("Meter not found !");
  }
}
