import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ApiService } from '@app/api/api';
import { FormsModule } from '@angular/forms';

import { GenericListComponent } from './generic-list.component';
import { MaterialModule } from '@app/material.module';
import { NoteModule } from '@app/note/module'
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule, NoteModule, FormsModule, FlexLayoutModule],
  exports: [ GenericListComponent ],
  providers: [ApiService ],
  declarations: [ GenericListComponent ]
})
export class GenericListModule {
}
