<mat-icon mat-list-avatar>location_city</mat-icon>
<h4 mat-line>
  {{group.group_name}}
  <span *ngIf="group.group_id">[{{group.group_id}}]</span>
</h4>
<span class="spacer"></span>
<mat-icon class="clickable-mat-icon" matTooltip="View Meters">explore</mat-icon>
<a
  class="anchor"
  *ngIf="group.routes.length>0"
  [href]="'#/customer_route/'+ group.routes[0]"
>
  <mat-icon
    class="clickable-mat-icon"
    matTooltip="View Route"
    [routerLink]="['/customer_route',group.routes[0]]"
  >
    pin_drop
  </mat-icon>
</a>
<mat-icon
  class="clickable-mat-icon"
  matTooltip="Create Route"
  *ngIf="group.routes.length<1"
  style="color: grey"
  #create_route_btn
  (click)="create_route(create_route_btn, group)"
>
  pin_drop
</mat-icon>
<mat-icon
  class="clickable-mat-icon"
  matTooltip="Remove Meter Group"
  *ngIf="list.delete_fn"
  #delete_fn_btn
  (click)="list.delete_fn(delete_fn_btn)"
>
  delete
</mat-icon>
<p mat-line>{{group.last_updated | date}}</p>
