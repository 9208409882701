import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { BehaviorSubject, Subscription, Subject } from "rxjs";
import { mergeMap, first, takeUntil } from "rxjs/operators";
import { TaskService } from "@app/task/task.service";
import { Location } from "@angular/common";
import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { FileUploadSpinnerService } from "@app/services/file-upload-spinner.service";
import { SubSink } from "subsink";
import { ImageryProject } from "@app/api/types";

@Component({
  selector: "imageryproject-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["../component.css"],
})
export class ImageryProjectDetailComponent implements OnInit, OnDestroy {
  imagery_project = new BehaviorSubject<ImageryProject>({
    id: null,
    analysis: null,
    results: [],
  });
  get project(): ImageryProject {
    return this.imagery_project.value;
  }
  set project(data: ImageryProject) {
    this.imagery_project.next(data);
  }

  error = null;
  id: string;
  valid_tags = ["meter locations", "data logging request", "real time request"];
  validated = false;
  selected_tab: number = 0;
  uploading = false;
  analyze_button = true;
  warnings_acknowledged: boolean = false;
  spinnerColor = "primary";
  spinnerMode = "indeterminate";
  @ViewChild("files") files;
  get link() {
    return this.project.link;
  }
  subsink = new SubSink();

  constructor(
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private tasks: TaskService,
    private location: Location,
    private errorHandleService: ErrorHandleService,
    private titleService: Title,
    private spinner: FileUploadSpinnerService
  ) {}

  ngOnInit() {
    this.subsink.sink = this.route.params
      .pipe(
        mergeMap((params) => {
          this.id = params["id"];
          return this.api.imagery.project.detail(this.id);
        })
      )
      .subscribe({
        next: (project: any) => {
          const title = "Tarnished Lamp Imagery Project " + project.name;
          this.titleService.setTitle(title);
          this.project = project;
        },
        error: (error: any) => {
          this.id = null;
          this.error = error;
        },
      });
  }

  disable_button() {
    this.analyze_button = false;
  }

  add_file(button: HTMLButtonElement) {
    this.dialogService
      .file_upload()
      .pipe(first())
      .subscribe((res: any) => {
        if (res) {
          button.disabled = true;
          this.spinner.start_spinner().subscribe(() => (this.uploading = true));
          this.api.imagery.project.upload_file(this.id, res).subscribe({
            next: (res: any) => {
              this.files.reload();
              if ("task_id" in res) {
                this.watch_analysis(res.task_id);
              }
            },
            complete: () => {
              this.uploading = false;
              button.disabled = false;
            },
          });
        }
      });
  }

  watch_analysis(task_id) {
    this.tasks.add_task(task_id, {
      message: "Analysis Scheduled",
      started: {
        message: "Analysis Running",
        always: true,
      },
      finished: {
        message: "Analysis Complete",
        callback: (job) => {
          this.files.reload();
          this.load_project();
        },
      },
    });
  }

  delete_project(button: HTMLButtonElement, id) {
    button.disabled = true;
    this.api.imagery.project.delete(id).subscribe({
      next: (res: any) => {
        this.location.back();
      },
      error: (error) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  load_project(tab: number = 0) {
    this.subsink.sink = this.api.imagery.project
      .detail(this.id)
      .subscribe((data) => {
        this.project = data;
        this.selected_tab = tab;
      });
  }

  render_link(id) {
    let link = this.router
      .createUrlTree(["../" + id], { relativeTo: this.route })
      .toString();
    return link;
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
