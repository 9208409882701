import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { ApiService } from "@app/api/api";
import { Tag } from "@app/api/types";

@Component({
  selector: "tag",
  template: ` <span>
    {{ tag.label }}
    <button
      mat-icon-button
      #delete_tag_btn
      (click)="delete_tag(delete_tag_btn, tag)"
      matTooltip="Remove Tag"
      class="tag-button"
    >
      <mat-icon>remove_circle_outline</mat-icon>
    </button>
  </span>`,
  styleUrls: ["commoncss/taglist.css"],
})
export class TagItem {
  @Input("tag") tag: Tag = null;
  constructor(
    private api: ApiService,
    private errorService: ErrorHandleService
  ) {}
  delete_tag(button: HTMLButtonElement, tag: Tag) {
    button.disabled = true;
    this.api.tag
      .delete(tag.id)
      .pipe(first())
      .subscribe({
        next: (res: Tag) => {},
        error: (error) => {
          console.error("delete error: ", error);
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }
}

@Component({
  selector: "tag-list",
  templateUrl: "./commonhtml/taglist.html",
  styleUrls: ["commoncss/taglist.css"],
})
export class TagList implements OnInit, OnDestroy {
  @Input("content_type") content_type: string = "";
  @Input("object_id") object_id: number = 0;
  @Input("tags") tags = [];
  @Input("tagset") tagset = [];
  __create_tag = false;
  new_tag: string = "";
  $tag: Subscription;

  constructor(
    private api: ApiService,
    private errorService: ErrorHandleService
  ) {}

  ngOnInit() {
    if (!this.tags.length) {
      this.load_tags();
    }
  }

  load_tags() {
    this.$tag = this.api.tag
      .get(this.content_type, this.object_id)
      .subscribe((data: any) => {
        this.tags = data.results;
      });
  }

  create_tag(button: HTMLButtonElement) {
    button.disabled = true;
    const tag = {
      content_type: this.content_type,
      object_id: this.object_id,
      label: this.new_tag,
    };
    this.api.tag.create(tag).subscribe({
      next: (res: any) => {
        this.load_tags();
      },
      error: (error) => {
        this.errorService.sendError("Please Select a Tag from Given Option.");
      },
      complete: () => {
        button.disabled = false;
      },
    });
    this.new_tag = "";
    this.__create_tag = false;
  }

  ngOnDestroy() {
    if (this.$tag) {
      this.$tag.unsubscribe();
    }
  }
}
