import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  Subscription,
  throwError,
} from "rxjs";
import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { TaskService } from "@app/task/task.service";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { FlightListComponent } from "@app/flight/list/list.component";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import { ResultAnalysisComponent } from "@app/customerjob/results/analysis.component";
import { SubSink } from "subsink";
import { ImageryProjectListComponent } from "@app/imageryproject/list/list.component";
import { APIListResponse, CustomerRoute, Mission } from "@app/api/types";

export enum MissionTypes {
  meter = "meter",
  imagery = "imagery",
  unknown = "unknown",
}

@Component({
  selector: "mission-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["../component.css"],
})
export class MissionDetailComponent implements OnInit, OnDestroy {
  subsink = new SubSink();
  mission_data = new BehaviorSubject<Mission>({
    meter_groups: [],
    analysis: {},
    mission_area: null,
    mission_id: null,
    start_date: null,
    end_date: null,
    mission_type: null,
    mission_status: null,
  });
  get mission() {
    return this.mission_data.value;
  }
  set mission(data) {
    this.mission_data.next(data);
  }
  mapRequiresResize = false;
  meter_groups = [];
  routes: CustomerRoute[] = [];
  loading: boolean = true;
  id: number;
  deleted = null;
  mission_types = MissionTypes;
  mission_type: string = MissionTypes.meter;
  @ViewChild("files", { static: false }) files;
  @ViewChild("map", { static: false }) map;
  @ViewChild("flights", { static: false }) flights: FlightListComponent;
  @ViewChild("projects", { static: false })
  projects: ImageryProjectListComponent;
  constructor(
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private tasks: TaskService,
    private errorHandleService: ErrorHandleService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params["id"];
      this.load_data();
    });
  }

  get_mission_type(): Observable<MissionTypes> {
    return forkJoin([
      this.api.imagery.project.list({ mission: this.id }).pipe(
        catchError((_err) => of({ results: [] })),
        map((x: any) => x.results.length > 0)
      ),
      this.api.customer_job.list({ mission: this.id }).pipe(
        catchError((_err) => of({ results: [] })),
        map((x: any) => x.results.length > 0)
      ),
    ]).pipe(
      map(([imagery, meters]: [boolean, boolean]) => {
        if (imagery && meters) {
          throw new Error(
            "A Single mission can not have both meter and imagery projects set"
          );
        }
        if (imagery) return MissionTypes.imagery;
        if (meters) return MissionTypes.meter;
        return MissionTypes.unknown;
      })
    );
  }

  load_data() {
    this.subsink.sink = forkJoin([
      this.api.mission.detail(this.id),
      this.get_mission_type(),
    ]).subscribe({
      next: ([mission, mission_type]: [Mission, MissionTypes]) => {
        this.mission_type = mission_type;
        const title = "Tarnished Lamp Mission " + mission.mission_id;
        this.titleService.setTitle(title);
        this.deleted = mission.is_delete;
        this.mission = mission;
        this.loading = false;
        this.customer_route();
        if (this.flights) {
          this.flights.get_flights();
        }
        if (this.projects) {
          this.projects.get_projects();
        }
      },
      error: (errors) => {
        console.log(errors);
        this.deleted = errors.error;
        this.loading = false;
      },
    });
  }

  customer_route() {
    this.subsink.sink = this.api.customer_route
      .list({ mission: this.id })
      .subscribe((data: APIListResponse<CustomerRoute>) => {
        data.results.forEach((route) => {
          const old_route = this.routes.find(
            (r) => r.name === route.name && r.customer === route.customer
          );
          route._selected =
            old_route === undefined ? true : old_route._selected;
          route.analysis = this.mission.analysis
            ? this.mission.analysis[route.name]
            : { idm: [0, 0], scm: [0, 0] };
        });
        this.routes = data.results;
      });
  }

  edit_mission() {
    this.router.navigate(["mission", this.id, "edit"]);
  }

  onTabSelect(event) {
    if (event.tab.textLabel === "Mission Area") {
      this.map.invalidateSize();
      this.map.fit_bounds();
    }
  }

  process_jobs(button: HTMLButtonElement) {
    button.disabled = true;
    this.api.mission.process_job_data(this.id).subscribe({
      next: (tasks: any) => {
        for (let task of tasks) {
          this.tasks.add_task(task.task_id, {
            message: "Data Processing Scheduled",
            started: {
              message: "Data Processing Running",
            },
            finished: {
              message: "Data Processing Complete",
              callback: (job) => {
                this.load_data();
              },
            },
          });
        }
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  meter_request() {
    this.api.mission.generate_location_request(this.id).subscribe(
      (task: any) => {
        this.tasks.add_task(task.task_id, {
          message: "Scheduled Meter Location Request Generation",
          started: {
            message: "Generating Meter Location Requests",
          },
          finished: {
            message: "Meter Location Requests Complete",
            callback: (job) => {
              this.files.reload();
              this.api.file.download(job.result.id);
            },
          },
        });
      },
      (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      }
    );
  }

  meter_result() {
    this.dialogService.file_upload().subscribe((res: any) => {
      this.api.file
        .create({
          content_type: "mission",
          object_id: this.id,
          datafile: res,
        })
        .subscribe(
          (res: any) => {
            this.files.reload();
            this.api.mission
              .process_location_result(this.id, res.id)
              .subscribe((task: any) => {
                this.tasks.add_task(task.task_id, {
                  message: "Scheduled Meter Location Update",
                  started: {
                    message: "Meter Location Update Running",
                  },
                  finished: {
                    message: "Meter Location Update Complete",
                    callback: (job) => {
                      console.debug(job);
                    },
                  },
                });
              });
          },
          (errors) => {
            this.errorHandleService.sendError(
              "An error occurred while processing your request. If this keeps happening please contact support."
            );
          }
        );
    });
  }

  schedule_flight(button: HTMLButtonElement) {
    button.disabled = true;
    this.dialogService
      .add_flight()
      .pipe(
        filter((res: any) => res !== undefined && res.id),
        switchMap((res: any) => this.api.mission.add_flight(this.id, res.id))
      )
      .subscribe({
        next: (res: any) => {
          this.load_data();
        },
        error: (errors) => {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. If this keeps happening please contact support."
          );
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  complete_mission(button: HTMLButtonElement) {
    button.disabled = true;
    this.api.mission.complete(this.id).subscribe({
      next: (res: any) => {
        this.router.navigate(["/mission"]);
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  delete_mission(button: HTMLButtonElement, id) {
    button.disabled = true;
    this.api.mission.delete(id).subscribe({
      next: (data: any) => {
        this.router.navigate(["mission"]);
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  add_customer_job(button: HTMLButtonElement) {
    if (!this.mission) {
      return;
    }
    button.disabled = true;
    this.dialogService
      .customerjob_detail()
      .pipe(
        switchMap((res) => {
          if (!res) {
            return this.api.customer_job.null();
          }
          res["mission"] = this.mission["id"];
          return this.api.customer_job.create(res);
        })
      )
      .subscribe({
        next: (data: any) => {
          this.load_data();
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  add_imagery_project(button: HTMLButtonElement) {
    if (!this.mission) {
      return;
    }
    button.disabled = true;
    this.dialogService
      .imagery_project_creation()
      .pipe(
        filter((res) => res !== undefined && res.id !== undefined),
        switchMap((project) =>
          this.api.imagery.project.assign(project["id"], this.mission["id"])
        )
      )
      .subscribe({
        next: (data: any) => {
          this.load_data();
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  update_mission_area() {
    this.mission.mission_area = this.map.service_area;
    this.api.mission.update(this.id, this.mission).subscribe((data: any) => {
      this.load_data();
    });
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
