import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AirportComponent } from './component';
import { MaterialModule } from '@app/material.module';
import { AirportDetailComponent } from './detail/detail.component';
import { AirportListComponent } from './list/list.component';
import { AirportFormComponent } from './form/form.component';
import { NoteModule } from '@app/note/module'
import { ApiService } from '@app/api/api';
import { CG_CommonModule } from '@app/common/module';
import { GenericListModule } from '@app/generic-list/module';
import { GenericFilterModule } from '@app/generic-filter/module';


@NgModule({
  imports: [
      CommonModule, RouterModule, MaterialModule, FormsModule, NoteModule,
      CG_CommonModule, GenericListModule, GenericFilterModule, FlexLayoutModule
  ],
  exports: [
    AirportComponent, AirportDetailComponent, AirportListComponent, AirportFormComponent
  ],
  providers: [ ApiService, Title ],
  declarations: [
    AirportComponent, AirportDetailComponent, AirportListComponent, AirportFormComponent
  ]
})
export class AirportModule {
}
