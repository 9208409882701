import { Component, OnDestroy, OnInit, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Subscription, Observable } from "rxjs";

export interface optionConfig {
  source: Observable<any>;
  object_type?: string;
  filter?: (e: any, i: number, _a: any[]) => boolean;
  list_text?: (obj: any) => string;
}

@Component({
  selector: "genericselect-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class GenericSelectDialogComponent
  implements OnInit, OnDestroy, optionConfig
{
  source: Observable<any>;
  object_type: string = "Object";
  filter: (e: any, i: number, _a: any[]) => boolean = (_e, _i, _a) => {
    return true;
  };
  list_text: (obj: any) => string = (obj) => JSON.stringify(obj);

  object_list: any[] = [];
  selected_object: any;
  $source: Subscription;
  error_message: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: optionConfig,
    public dialogRef: MatDialogRef<GenericSelectDialogComponent>
  ) {
    Object.assign(this, data);
    if (!this.source) {
      throw new Error("No source defined");
    }
  }

  ngOnInit() {
    this.$source = this.source.subscribe(
      (data: any) => {
        this.object_list = data.results.filter(this.filter);
      },
      (error) => {
        this.error_message = error;
      }
    );
  }

  submit(button: HTMLButtonElement) {
    this.dialogRef.close(this.selected_object);
  }

  ngOnDestroy() {
    if (this.$source) {
      this.$source.unsubscribe();
    }
  }
}
