import { Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import * as moment from "moment";

import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

@Component({
  selector: "flightleg-start-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class FlightLegStartDialogComponent implements OnInit {
  flight_id: any;
  flight_leg_landing_list = [];
  takeoff_airport: any;
  form_errors: any = {};
  last_landing: any = null;
  leg_id = null;
  show_note: boolean = false;

  takeoff = new UntypedFormGroup({
    flight: new UntypedFormControl(),
    airport: new UntypedFormControl(),
    engine_start_time: new UntypedFormControl(),
    wheel_up_time: new UntypedFormControl(),
    amount_of_fuel: new UntypedFormControl(),
    fuel_unit: new UntypedFormControl(),
    note: new UntypedFormControl(),
  });

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<FlightLegStartDialogComponent>,
    private errorHandleService: ErrorHandleService
  ) {}

  ngOnInit() {
    this.takeoff.patchValue({ fuel_unit: "%" });
    this.get_flight_leg_landings(this.flight_id);
  }

  load_leg_data(leg_id: number) {
    this.leg_id = leg_id;
    this.api.flightleg_takeoff.detail(leg_id).subscribe((result) => {
      this.takeoff.patchValue({
        amount_of_fuel: result.amount_of_fuel,
        engine_start_time: result.engine_start_time,
        wheel_up_time: result.wheel_up_time,
        note: result.note,
        fuel_unit: result.fuel_unit,
      });
      if (result.note) {
        this.show_note = true;
      }
      this.get_airport(result.airport);
    });
  }

  flight_leg_take_off() {
    this.form_errors = {};

    let engine_start_time: moment.Moment = moment(
      this.takeoff.get("engine_start_time").value
    );
    let wheel_up_time: moment.Moment = moment(
      this.takeoff.get("wheel_up_time").value
    );
    let engine_off_time: moment.Moment = this.last_landing
      ? moment(this.last_landing.engine_off_time)
      : null;
    let now: moment.Moment = moment();

    if (wheel_up_time.isSameOrAfter(now)) {
      this.form_errors.wheel_up_time = "Wheel up time cannot be in the future";
    } else if (engine_start_time.isSameOrAfter(wheel_up_time)) {
      this.form_errors.wheel_up_time =
        "Engine Start time cannot be after Wheel Up Time.";
    }

    if (engine_start_time.isSameOrAfter(now)) {
      this.form_errors.engine_start_time =
        "Engine start time cannot be in the future";
    } else if (
      !this.leg_id &&
      this.last_landing &&
      engine_off_time.isSameOrAfter(engine_start_time)
    ) {
      this.form_errors.engine_start_time =
        "Engine start time must be after last Flight Leg Engine-off Time. [" +
        engine_off_time.format() +
        "]";
    }

    if (Object.keys(this.form_errors).length) {
      return;
    }

    this.takeoff.patchValue({
      flight: this.flight_id,
      engine_start_time: engine_start_time,
      wheel_up_time: wheel_up_time,
    });

    let data: any = {};
    Object.assign(data, this.takeoff.value);
    data.airport = data.airport.id;
    if (!this.show_note) {
      delete data["note"];
    }

    let endpoint = this.leg_id
      ? this.api.flightleg_takeoff.update(this.leg_id, data)
      : this.api.flightleg_takeoff.create(data);

    endpoint.subscribe(
      (_takeoff: any) => {
        this.dialogRef.close();
      },
      (_error) => {
        console.error(_error);
        if (_error.error) {
          this.form_errors = { ..._error.error };
        } else {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. " +
              "If this keeps happening please contact support."
          );
        }
      }
    );
  }

  get_airport(id: number) {
    this.api.airport.detail(id).subscribe((result) => {
      this.takeoff_airport = result;
      this.takeoff.patchValue({
        airport: {
          id: result.id,
          name: result.properties.name,
          icao_code: result.properties.icao_code,
        },
      });
    });
  }

  get_flight_leg_landings(flight: number) {
    this.api.flightleg_landing
      .search({ flight: flight })
      .subscribe((leg_landing: any) => {
        if (leg_landing.count) {
          this.flight_leg_landing_list = leg_landing.results;
          this.last_landing = leg_landing.results[leg_landing.count - 1];
          this.get_airport(this.last_landing.airport);
        }
      });
  }
}
