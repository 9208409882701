import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { contentHeaders } from "@app/common/headers";
import { BaseApiService } from "@app/api/base_api";
import { AircraftStatus } from "../types";

interface HistoricalRequestParameters {
  date?: string;
  hour?: number;
  span?: number;
  aircraft?: number;
}

@Injectable()
export class AircraftStatusService extends BaseApiService<AircraftStatus> {
  component: string = "aircraftstatus";

  latest(aircraft = null) {
    let params = new HttpParams();
    if (aircraft) {
      for (let a in aircraft) {
        if (aircraft[a] != null) {
          params = params.append("aircraft", aircraft[a]);
        }
      }
    }
    return this.get_with_retry(this.function_url("latest"), {
      params: params,
      headers: contentHeaders(),
    });
  }

  track(track_params: HistoricalRequestParameters = {}) {
    let params = new HttpParams();
    for (let k in track_params) {
      if (track_params[k] != null) {
        params = params.set(k, track_params[k]);
      }
    }
    return this.get_with_retry(this.function_url("track"), {
      params: params,
      headers: contentHeaders(),
    });
  }

  statistic(statistic_params: HistoricalRequestParameters = {}) {
    let params = new HttpParams();
    for (let k in statistic_params) {
      if (statistic_params[k] != null) {
        params = params.set(k, statistic_params[k]);
      }
    }
    return this.get_with_retry(this.function_url("statistic"), {
      params: params,
      headers: contentHeaders(),
    });
  }
}
