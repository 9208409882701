import { Injectable } from "@angular/core";
import { GridsterConfig, GridsterItem } from "angular-gridster2";
import { UUID } from "angular2-uuid";

export interface DashboardItem {
  widget?: string;
  layout: GridsterItem;
  id?: string;
  config?: { [id: string]: any };
}

@Injectable()
export class LayoutService {
  options: GridsterConfig = {
    draggable: {
      enabled: true,
    },
    pushItems: true,
    resizable: {
      enabled: true,
    },
    displayGrid: "none",
    minCols: 4,
    minRows: 4,
    maxCols: 8,
    maxRows: 8,
    itemChangeCallback: LayoutService.itemChange,
    itemResizeCallback: LayoutService.itemResize,
  };

  static itemChange(item, itemComponent) {
    // console.info("itemChanged", item, itemComponent);
  }

  static itemResize(item, itemComponent) {
    // console.info("itemResized", item, itemComponent);
  }

  public widgets: DashboardItem[] = [];

  constructor() {
    // Interim, in future retrieve form and store to a saved user profile
    this.addItem({
      layout: { cols: 2, rows: 2, y: 0, x: 0 },
      widget: "dailyflights",
    });
    this.addItem({
      layout: { cols: 2, rows: 2, y: 0, x: 2 },
      widget: "dailymissions",
    });
  }

  addItem({
    layout,
    id = null,
    widget = null,
    config = null,
  }: DashboardItem): void {
    if (!id) {
      if (layout.id) {
        id = layout.id;
      } else {
        id = UUID.UUID();
        layout.id = id;
      }
    }
    this.widgets.push({
      layout: layout,
      id: id,
      widget: widget,
      config: config,
    });
    // console.table(this.widgets);
  }

  deleteItem(id: string): void {
    const item = this.widgets.find((d) => d.id === id);
    this.widgets.splice(this.widgets.indexOf(item), 1);
  }
}
