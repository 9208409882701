import { Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CG_CommonModule } from '@app/common/module';
import { ApiService } from '@app/api/api';
import { ContactComponent } from './component';
import { MaterialModule } from '@app/material.module';
import { ContactDetailComponent } from './detail/detail.component';
import { ContactListComponent } from './list/list.component';
import { ContactFormComponent } from './form/form.component';
import { DialogModule } from '@app/dialog/dialog.module';
import { DialogService } from '@app/services/dialog.service';

@NgModule({
  imports: [ CommonModule, RouterModule, MaterialModule, DialogModule, CG_CommonModule, FormsModule, FlexLayoutModule ],
  exports: [ ContactComponent, ContactDetailComponent, ContactFormComponent, ContactListComponent ],
  providers: [ ApiService, DialogService, Title ],
  declarations: [ ContactComponent, ContactDetailComponent, ContactFormComponent, ContactListComponent ]
})
export class ContactModule {
}
