import { Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: "messagebox-dialog",
  templateUrl: "./dialog.component.html",
})
export class NoteDialog implements OnInit {
  title: string = "Add Note";
  ok: boolean = true;
  cancel: boolean = true;
  note: string = "";
  constructor(public dialogRef: MatDialogRef<NoteDialog>) {}
  ngOnInit() {}

  submit(button: HTMLButtonElement) {
    this.dialogRef.close(this.note);
  }
}
