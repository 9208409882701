import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";

import * as missionFlightsActionTypes from "@app/store/map/sidebar/missions/flights/actions";
import MissionFlightsActionWithPayload, {
  MissionFlights,
} from "@app/store/map/sidebar/missions/flights/reducer";

@Injectable()
export class MapSidebarMissionFlightsStoreService {
  mission$: Subscription;
  missionFlightState$: Observable<MissionFlights>;

  constructor(private store: Store<MissionFlights>) {}

  public get_mission_flights() {
    let flightState = {};
    this.missionFlightState$ = this.store.pipe(
      map((state: any) => state.map_sidebar_mission_flights)
    );
    this.missionFlightState$
      .pipe(
        map((flightStateData) => {
          if (
            flightStateData &&
            flightStateData.flights &&
            Object.entries(flightStateData.flights).length > 0
          ) {
            flightState = flightStateData.flights;
          }
        })
      )
      .subscribe();
    return flightState;
  }

  public assign_mission_flights(flights) {
    const AssignMissionFlights: MissionFlightsActionWithPayload<MissionFlights> = {
      type: missionFlightsActionTypes.ActionTypes.AssignMissionFlights,
      flights: flights,
    };
    this.store.dispatch(AssignMissionFlights);
  }
}
