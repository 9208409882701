import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Subscription, BehaviorSubject, Observable } from "rxjs";

import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

@Component({
  selector: "employee-qualification-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class EmployeeQualificationDialogComponent implements OnInit, OnDestroy {
  _id: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  $id: Subscription;
  set id(value: number) {
    this._id.next(value);
  }
  get id(): number {
    return this._id.getValue();
  }

  set employee(value: number) {
    this.qualification.employee = value;
  }

  qualification: any = {
    employee: null,
    qualification: null,
    effective: null,
    expires: null,
  };
  form_errors: any = {
    employee: null,
    qualification: null,
    effective: null,
    expires: null,
  };
  title: string = "Add Qualifiation";
  qualifications = [];
  constructor(
    public dialogRef: MatDialogRef<EmployeeQualificationDialogComponent>,
    private api: ApiService,
    private errorHandleService: ErrorHandleService
  ) {}

  ngOnInit() {
    this.api.qualification.list({ page_size: 100 }).subscribe((data: any) => {
      this.qualifications = data.results;
    });
    this.$id = this._id.subscribe((id) => {
      if (id) {
        this.form_errors.detail = null;
        this.title = "Update Qualification";
        this.api.employee_qualification.detail(id).subscribe(
          (qual: any) => {
            const title = this.title + " " + qual.name;
            this.qualification = qual;
          },
          (errors) => {
            this.form_errors = errors.error;
          }
        );
      }
    });
  }

  submit(button: HTMLButtonElement) {
    let call = this.api.employee_qualification.create(this.qualification);
    if (this.id) {
      call = this.api.employee_qualification.update(
        this.id,
        this.qualification
      );
    }
    button.disabled = true;
    call.subscribe({
      next: (res: any) => {
        this.dialogRef.close(this.qualification);
      },
      error: (errors) => {
        if (!errors.status) {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. If this keeps happening please contact support."
          );
        }
        this.form_errors = errors.error;
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    this.$id.unsubscribe();
  }
}
