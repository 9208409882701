import { Component, OnInit, OnDestroy } from "@angular/core";
import { ApiService } from "@app/api/api";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ElementRef, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocomplete as MatAutocomplete,
} from "@angular/material/legacy-autocomplete";
import { MatLegacyChipInputEvent as MatChipInputEvent } from "@angular/material/legacy-chips";
import { Observable } from "rxjs";
import { map, startWith, tap } from "rxjs/operators";

export class TreeNode {
  constructor(public item: string, public children: TreeNode[] = []) {}

  get length(): number {
    return this.children.length == 0
      ? 1
      : this.children.reduce(
          (accumulator, child) => accumulator + child.length,
          0
        );
  }

  insert_record(record: MeterReport, fields: string[]) {
    const key = record[fields.shift()];
    let child = this.children.find((element: TreeNode) => element.item === key);
    if (!child) {
      child = new TreeNode(key);
      this.children.push(child);
    }
    if (fields.length > 0) {
      child.insert_record(record, fields);
    }
  }
}

interface PaginatedResponse<t = any> {
  prev: string;
  next: string;
  count: number;
  results: t[];
}

interface MeterReport {
  id: number;
  meter: string;
  market: string;
  vendor: string;
  exception: string;
  route: string;
}

@Component({
  selector: "meter-report",
  templateUrl: "./meter.report.html",
  styleUrls: ["./meter.report.css"],
})
export class MeterReportDetailComponent implements OnInit, OnDestroy {
  id: number;
  loading: boolean = false;
  job: any;
  error: any;
  data: BehaviorSubject<MeterReport[]> = new BehaviorSubject<MeterReport[]>([]);
  field_order$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([
    "route",
    "exception",
    "meter_id",
  ]);
  get field_order(): string[] {
    return this.field_order$.getValue();
  }
  set field_order(value: string[]) {
    this.field_order$.next(value);
  }
  field_list: string[] = ["route", "exception", "meter_id", "market", "vendor"];
  builder: Subscription;
  treeControl = new NestedTreeControl<TreeNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  field_form = new UntypedFormControl();
  filtered_fields: Observable<string[]>;

  @ViewChild("field_input") field_input: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  ngOnDestroy() {
    this.builder.unsubscribe();
  }

  ngOnInit() {
    this.route.params
      .pipe(
        map((params) => {
          this.id = params["id"];
          return this.api.customer_job.detail(this.id);
        })
      )
      .subscribe(
        (job: any) => {
          const title = "Tarnished Lamp CustomerJob " + job.name;
          this.titleService.setTitle(title);
          this.job = job;
          this.load_report();
        },
        (error: any) => {
          this.id = null;
          this.error = error;
        }
      );

    this.builder = combineLatest([
      this.data.asObservable(),
      this.field_order$.asObservable(),
    ]).subscribe(([data, order]) => {
      this.dataSource.data = this.build_tree(data, [...order]);
    });
  }

  load_report(page: number = 1) {
    this.api.meter_report
      .list({ job: this.id, page: page })
      .pipe(
        tap(() => {
          this.loading = true;
        })
      )
      .subscribe(({ next, results }: PaginatedResponse<MeterReport>) => {
        this.data.next([...this.data.getValue()].concat(results));

        if (next) {
          this.load_report(page + 1);
        } else {
          console.log("Data Load complete");
          this.loading = false;
        }
      });
  }

  build_tree(data: MeterReport[], field_order: string[]): TreeNode[] {
    const field = field_order.shift();
    return data.reduce<TreeNode[]>((accumulator, item) => {
      const key = item[field];
      let child = accumulator.find((element: TreeNode) => element.item === key);
      if (!child) {
        child = new TreeNode(key);
        accumulator.push(child);
      }
      if (field_order.length > 0) {
        child.insert_record(item, [...field_order]);
      }
      return accumulator;
    }, []);
  }

  hasChild = (_: number, node: TreeNode) =>
    !!node.children && node.children.length > 0;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private titleService: Title
  ) {
    this.filtered_fields = this.field_form.valueChanges.pipe(
      startWith([null]),
      map((field: string | null) =>
        field ? this._filter(field) : this.field_list.slice()
      )
    );
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    let state = [...this.field_order];
    // Add our fruit
    if ((value || "").trim()) {
      state.push(value.trim());
      this.field_order = state;
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.field_form.setValue(null);
  }

  remove(field: string): void {
    const state = [...this.field_order];
    const index = state.indexOf(field);

    if (index >= 0) {
      state.splice(index, 1);
    }
    this.field_order = state;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let state = [...this.field_order];
    state.push(event.option.viewValue);
    this.field_order = state;
    this.field_input.nativeElement.value = "";
    this.field_form.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toString().toLowerCase();

    return this.field_list.filter(
      (field: string) =>
        field.toString().toLowerCase().indexOf(filterValue) === 0
    );
  }
}
