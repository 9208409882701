import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { CustomerJob } from "../types";

@Injectable()
export class CustomerJobService extends BaseApiService<CustomerJob> {
  component: string = "customerjob";

  upload_file(object_id: number | string, datafile: any) {
    let formData = new FormData();
    formData.append("content_type", "customerjob");
    formData.append("datafile", datafile);
    // the api ignores this value though the serializer requires it, feed a known invalid number
    formData.append("object_id", "-1");

    let headers = contentHeaders();
    headers = headers.delete("Content-Type");

    return this.http_client.post(
      this.function_url("upload_file", object_id),
      formData,
      { headers: headers }
    );
  }

  analyze_job(object_id: number | string) {
    return this.http_client.post(
      this.function_url("analyze_job", object_id),
      {},
      { headers: contentHeaders() }
    );
  }

  submit_job(object_id: number | string) {
    return this.http_client.post(
      this.function_url("commit", object_id),
      {},
      { headers: contentHeaders() }
    );
  }

  unsubmit_job(object_id: number | string) {
    return this.http_client.post(
      this.function_url("uncommit", object_id),
      {},
      { headers: contentHeaders() }
    );
  }

  ertlist(object_id: number | string, route: string, key: string) {
    return this.get_with_retry(this.function_url("ert_list", object_id), {
      params: { route: route, key: key },
      headers: contentHeaders(),
    });
  }

  make_link(object_id: number | string) {
    return this.http_client.post(
      this.function_url("make_link", object_id),
      {},
      { headers: contentHeaders() }
    );
  }

  search(params) {
    return this.list(params);
  }
}
