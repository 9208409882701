import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  ViewChild,
  SimpleChanges,
  AfterViewInit,
} from "@angular/core";
import { Subject } from "rxjs";
import * as L from "leaflet";
import { ApiService } from "@app/api/api";
import { BaseMap } from "@app/base_map";
import { copyTextToClipboard } from "@app/common/clipboard";

interface FlightTrackData {
  flight_plan: L.GeoJSON | null;
  flight_track: L.GeoJSON | null;
  flight_area: L.GeoJSON | null;
}

@Component({
  selector: "track-map",
  templateUrl: "./trackmap.component.html",
  styleUrls: ["./trackmap.css"],
  host: {
    class: "main-map",
    style: "display:flex;flex-flow:column;flex:1 1 auto",
  },
})
export class TrackMapComponent
  extends BaseMap
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  ngUnsubscribe = new Subject();
  @ViewChild("map", { static: true }) mapContainer;

  @Input("plan") plan: number;
  @Input("track") track: number;
  @Input("area") area: any;

  map_layers: FlightTrackData = {
    flight_plan: null,
    flight_track: null,
    flight_area: null,
  };
  tracks: FlightTrackData = {
    flight_plan: null,
    flight_track: null,
    flight_area: null,
  };

  constructor(private api: ApiService) {
    super();
  }

  ngOnInit() {
    this.map_build(this.mapContainer);
    this.load();
  }

  get_link(button: HTMLButtonElement, target) {
    target = this.tracks["flight_" + target];
    if (!target) {
      return;
    }
    console.log(target);
    if (target.properties.link) {
      console.log("Using existing link");
      copyTextToClipboard(target.properties.link);
      return;
    }
    console.log("Requesting new link");
    button.disabled = true;
    this.api.flightpath.make_link(target.id).subscribe({
      next: (res: any) => {
        copyTextToClipboard(res);
        this.load();
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  update(flight: { flight_plan: number; flight_path: number }) {
    this.plan = flight.flight_plan;
    this.track = flight.flight_path;
    this.load();
  }

  load() {
    this.get_flightpath(this.plan, "flight_plan", {
      color: "blue",
      opacity: this.map_layers.flight_track ? 0.2 : 1.0,
    });
    this.get_flightpath(this.track, "flight_track", { color: "green" });
    if (this.area) {
      this.swap_layer(
        this.create_feature(this.area, { color: "aqua" }),
        "flight_area"
      );
    }
  }

  swap_layer(layer, name) {
    layer.addTo(this.map);
    if (this.map_layers[name]) {
      this.map.removeLayer(this.map_layers[name]);
    }
    this.map_layers[name] = layer;
  }
  fit_bounds() {
    let bounds: L.LatLngBounds;
    if (this.map_layers.flight_track) {
      bounds = this.map_layers.flight_track.getBounds();
    } else if (this.map_layers.flight_plan) {
      bounds = this.map_layers.flight_plan.getBounds();
    }
    if (bounds && bounds.isValid()) {
      this.map.fitBounds(bounds);
    }
  }

  get_flightpath(
    id: string | number,
    target: string,
    track_style: { color?: string; opacity?: number }
  ) {
    if (!id) {
      return;
    }
    this.api.flightpath.detail(id).subscribe((track) => {
      this.tracks[target] = track;
      this.swap_layer(this.create_feature(track, track_style), target);
      this.fit_bounds();
    });
  }

  create_feature(data, track_style = {}) {
    return L.geoJson(data, {
      style: (_feature) => {
        return track_style;
      },
    });
  }

  invalidateSize() {
    if (this.map) {
      this.map.invalidateSize(true);
    }
  }

  ngAfterViewInit() {
    this.invalidateSize();
  }

  ngOnChanges(change: SimpleChanges) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next(1);
    this.ngUnsubscribe.complete();
    this.map.remove();
  }
}
