import { Injectable } from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from "@angular/material/legacy-dialog";
import { Observable } from "rxjs";
import { MeterDialogComponent } from "../dialog/meter_group/dialog.component";
import { MissionDialogComponent } from "../dialog/mission/dialog.component";
import { CreateContactDialogComponent } from "../dialog/contact/dialog.component";
import { FileUploadDialogComponent } from "../dialog/file_upload/dialog.component";
import { ImportFileDialogComponent } from "../dialog/file_import/dialog.component";
import { MessageBoxDialog } from "../dialog/messagebox/dialog.component";
import { CustomerJobDialogComponent } from "../dialog/customerjob/dialog.component";
import { ImageryProjectDialogComponent } from "../dialog/imagery_project/dialog.component";
import { AddFlightDialogComponent } from "../dialog/add_flight/dialog.component";
import { CompleteDialogComponent } from "../dialog/complete-flight/dialog.component";
import { DialogDataService } from "@app/services/dialogData.service";
import { FlightLegDialogComponent } from "../dialog/flight_leg/dialog.component";
import { AddFlightLegDialogComponent } from "../dialog/add_flight_leg/dialog.component";
import { SelectAircraftDialogComponent } from "@app/dialog/select_aircraft/dialog.component";
import { SelectPayloadDialogComponent } from "@app/dialog/select_payload/dialog.component";
import { FlightLegStartDialogComponent } from "@app/dialog/flight_leg_start/dialog.component";
import { FlightLegStopDialogComponent } from "@app/dialog/flight_leg_stop/dialog.component";
import { QualificationDialogComponent } from "@app/dialog/qualification/dialog.component";
import { EmployeeQualificationDialogComponent } from "@app/dialog/employee_qualification/dialog.component";
import { AircraftQualificationDialogComponent } from "@app/dialog/aircraft_qualification/dialog.component";
import { GenericSelectDialogComponent } from "@app/dialog/generic_select/dialog.component";
import { GenericTextDialogComponent } from "@app/dialog/generic_text/dialog.component";
import { NoteDialog } from "@app/dialog/note_dialog/dialog.component";
import { TTSNDialogComponent } from "@app/dialog/ttsn_dialog/dialog.component";
import { EndpointDialogComponent } from "@app/dialog/endpoint_style/dialog.component";
import { PayloadIcons } from "@cleargrid/map_layers";

@Injectable()
export class DialogService {
  constructor(
    private dialog: MatDialog,
    private dialogDataService: DialogDataService
  ) {}

  public get_meters(allow_all: boolean = true): Observable<any> {
    let dialogRef: MatDialogRef<MeterDialogComponent>;
    const config = new MatDialogConfig();
    config.position = {};
    config.width = "250px";
    config.role = "dialog";

    dialogRef = this.dialog.open(MeterDialogComponent, config);
    dialogRef.componentInstance.allow_all = allow_all;

    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public get_mission(search): Observable<any> {
    let dialogRef: MatDialogRef<MissionDialogComponent>;
    const config = new MatDialogConfig();
    config.position = {};
    config.role = "dialog";
    dialogRef = this.dialog.open(MissionDialogComponent, config);
    dialogRef.componentInstance.search(search);

    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public add_flight(defaults: { [id: string]: any } = {}): Observable<any> {
    let dialogRef: MatDialogRef<AddFlightDialogComponent>;
    const config = new MatDialogConfig();

    config.height = "auto";
    config.width = "auto";
    config.role = "dialog";
    dialogRef = this.dialog.open(AddFlightDialogComponent, config);
    dialogRef.componentInstance.update(defaults);
    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public ttsn_dialog(aircraft_id: number, record_id?: number) {
    let dialogRef: MatDialogRef<TTSNDialogComponent>;
    const config = new MatDialogConfig();
    config.data = { record_id: record_id, aircraft_id: aircraft_id };
    dialogRef = this.dialog.open(TTSNDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public file_upload(): Observable<any> {
    let dialogRef: MatDialogRef<FileUploadDialogComponent>;
    const config = new MatDialogConfig();

    dialogRef = this.dialog.open(FileUploadDialogComponent, config);

    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public import_file(file_id: number): Observable<any> {
    let dialogRef: MatDialogRef<ImportFileDialogComponent>;
    const config = new MatDialogConfig();

    dialogRef = this.dialog.open(ImportFileDialogComponent, config);
    dialogRef.componentInstance.file = file_id;

    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public select_aircraft(): Observable<any> {
    let dialogRef: MatDialogRef<SelectAircraftDialogComponent>;
    const config = new MatDialogConfig();

    dialogRef = this.dialog.open(SelectAircraftDialogComponent, config);

    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public select_payload(): Observable<any> {
    let dialogRef: MatDialogRef<SelectPayloadDialogComponent>;
    const config = new MatDialogConfig();

    dialogRef = this.dialog.open(SelectPayloadDialogComponent, config);

    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public message_box(
    message: string,
    settings?: MBSettings
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<MessageBoxDialog, boolean>;
    const config = new MatDialogConfig();
    dialogRef = this.dialog.open(MessageBoxDialog, config);
    dialogRef.componentInstance.message = message;

    for (let setting in settings) {
      dialogRef.componentInstance[setting] = settings[setting];
    }

    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public create_contact(
    content_type: string,
    object_id: number
  ): Observable<any> {
    return this.edit_contact(content_type, object_id);
  }

  public edit_contact(
    content_type: string,
    object_id: number,
    contact?: any
  ): Observable<any> {
    let dialogRef: MatDialogRef<CreateContactDialogComponent>;
    const config = new MatDialogConfig();
    config.height = "500px";
    config.role = "dialog";
    dialogRef = this.dialog.open(CreateContactDialogComponent, config);
    dialogRef.componentInstance.content_type = content_type;
    dialogRef.componentInstance.object_id = object_id;
    if (contact) {
      dialogRef.componentInstance.contact = contact;
    }

    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public customerjob_detail() {
    let dialogRef: MatDialogRef<CustomerJobDialogComponent>;
    const config = new MatDialogConfig();
    config.width = "400px";
    config.role = "dialog";
    dialogRef = this.dialog.open(CustomerJobDialogComponent, config);

    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public imagery_project_creation() {
    let dialogRef: MatDialogRef<ImageryProjectDialogComponent>;
    const config = new MatDialogConfig();
    config.width = "400px";
    config.role = "dialog";
    dialogRef = this.dialog.open(ImageryProjectDialogComponent, config);

    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public complete_flight_dialog(flight_id: number) {
    let dialogRef: MatDialogRef<CompleteDialogComponent>;
    const config = new MatDialogConfig();
    config.role = "dialog";
    config.width = "700px";
    dialogRef = this.dialog.open(CompleteDialogComponent, config);
    dialogRef.componentInstance.flight_id = flight_id;

    this.dialogDataService.dialogSend(dialogRef);
    return dialogRef.afterClosed();
  }

  public get_flight_leg(flight_id: number): Observable<any> {
    let dialogRef: MatDialogRef<FlightLegDialogComponent>;
    const config = new MatDialogConfig();
    config.position = {};
    config.height = "400px";
    config.width = "800px";
    config.role = "dialog";
    dialogRef = this.dialog.open(FlightLegDialogComponent, config);
    dialogRef.componentInstance.flight_id = flight_id;
    dialogRef.componentInstance.flight_leg(flight_id);

    return dialogRef.afterClosed();
  }

  public set_flight_leg(
    flight_id: number,
    takeoff_airport: number,
    landing_airport: number,
    scheduled_launch: any,
    scheduled_recovery: any,
    complete_flight: boolean
  ): Observable<any> {
    let dialogRef: MatDialogRef<AddFlightLegDialogComponent>;
    const config = new MatDialogConfig();
    config.position = {};
    config.height = "auto";
    config.width = "650px";
    config.role = "dialog";
    dialogRef = this.dialog.open(AddFlightLegDialogComponent, config);
    dialogRef.componentInstance.flight_id = flight_id;
    dialogRef.componentInstance.takeoff_airport = takeoff_airport;
    dialogRef.componentInstance.landing_airport = landing_airport;
    dialogRef.componentInstance.scheduled_launch = scheduled_launch;
    dialogRef.componentInstance.scheduled_recovery = scheduled_recovery;
    dialogRef.componentInstance.landing_airport_select = !complete_flight;
    dialogRef.componentInstance.complete_flight = !complete_flight;

    return dialogRef.afterClosed();
  }

  public start_flight_leg(
    flight_id: number,
    leg_id: number = null
  ): Observable<any> {
    let dialogRef: MatDialogRef<FlightLegStartDialogComponent>;
    const config = new MatDialogConfig();
    config.position = {};
    config.height = "auto";
    config.width = "650px";
    config.role = "dialog";
    dialogRef = this.dialog.open(FlightLegStartDialogComponent, config);

    dialogRef.componentInstance.flight_id = flight_id;
    if (leg_id) {
      dialogRef.componentInstance.load_leg_data(leg_id);
    }

    return dialogRef.afterClosed();
  }

  public stop_flight_leg(
    flight_id: number,
    takeoff_id: number = null,
    leg_id: number = null
  ): Observable<any> {
    let dialogRef: MatDialogRef<FlightLegStopDialogComponent>;
    const config = new MatDialogConfig();
    config.position = {};
    config.height = "auto";
    config.width = "650px";
    config.role = "dialog";
    dialogRef = this.dialog.open(FlightLegStopDialogComponent, config);

    dialogRef.componentInstance.flight_id = flight_id;
    dialogRef.componentInstance.takeoff_id = takeoff_id;
    if (leg_id) {
      dialogRef.componentInstance.load_leg_data(leg_id);
    }

    return dialogRef.afterClosed();
  }

  public endpoint_style_form(styles: PayloadIcons) {
    let dialogRef: MatDialogRef<EndpointDialogComponent>;
    const config = new MatDialogConfig();
    dialogRef = this.dialog.open(EndpointDialogComponent, config);
    dialogRef.componentInstance.styles = styles;
    return dialogRef.afterClosed();
  }

  public qualification_form(id: number = null) {
    let dialogRef: MatDialogRef<QualificationDialogComponent>;
    const config = new MatDialogConfig();
    dialogRef = this.dialog.open(QualificationDialogComponent, config);
    dialogRef.componentInstance.id = id;
    return dialogRef.afterClosed();
  }

  public employee_qualification_form(employee_id: number, id: number = null) {
    let dialogRef: MatDialogRef<EmployeeQualificationDialogComponent>;
    const config = new MatDialogConfig();
    dialogRef = this.dialog.open(EmployeeQualificationDialogComponent, config);
    dialogRef.componentInstance.employee = employee_id;
    dialogRef.componentInstance.id = id;
    return dialogRef.afterClosed();
  }

  public aircraft_qualification_form(aircraft_id: number, id: number = null) {
    let dialogRef: MatDialogRef<AircraftQualificationDialogComponent>;
    const config = new MatDialogConfig();
    dialogRef = this.dialog.open(AircraftQualificationDialogComponent, config);
    dialogRef.componentInstance.aircraft = aircraft_id;
    dialogRef.componentInstance.id = id;
    return dialogRef.afterClosed();
  }

  public note_dialog(title: string = null) {
    let dialogRef: MatDialogRef<NoteDialog>;
    const config = new MatDialogConfig();
    dialogRef = this.dialog.open(NoteDialog, config);
    if (title) {
      dialogRef.componentInstance.title = title;
    }
    return dialogRef.afterClosed();
  }

  public get generic_select() {
    const dialog = this.dialog;
    class GenericSelect {
      dialog = dialog;
      constructor(
        private object_type: string,
        private source: Observable<any>,
        private list_text?: (obj: any) => string,
        private filter?: (e: any, i: number, _a: any[]) => boolean
      ) {}

      open() {
        let dialogRef: MatDialogRef<GenericSelectDialogComponent>;
        const config = new MatDialogConfig();
        config.data = { source: this.source, object_type: this.object_type };
        if (this.filter) {
          config.data.filter = this.filter;
        }
        if (this.list_text) {
          config.data.list_text = this.list_text;
        }
        dialogRef = this.dialog.open(GenericSelectDialogComponent, config);
        return dialogRef.afterClosed();
      }
    }
    return GenericSelect;
  }

  public get generic_text() {
    const dialog = this.dialog;
    class GenericText {
      dialog = dialog;
      constructor(
        private input_type?: string,
        private input_text?: string,
        private input_value?: string
      ) {}

      open() {
        let dialogRef: MatDialogRef<GenericTextDialogComponent>;
        const config = new MatDialogConfig();
        config.data = {
          input_type: this.input_type,
          input_text: this.input_text,
          input_value: this.input_value,
        };

        dialogRef = this.dialog.open(GenericTextDialogComponent, config);
        return dialogRef.afterClosed();
      }
    }
    return GenericText;
  }
}

export interface MBSettings {
  ok?: boolean;
  cancel?: boolean;
}
