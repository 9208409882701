import { UserComponent } from '@app/user/user.component';
import { AuthGuard } from '@app/common/auth.guard';


export const UserProfileRoute = {
  path: 'user',
  component: UserComponent,
  canActivate: [AuthGuard],
  children: [
    {path: '', component: UserComponent},
  ]
};

