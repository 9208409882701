import { M } from "@angular/cdk/keycodes";
import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { Meter } from "../types";

@Injectable()
export class MeterService extends BaseApiService<Meter> {
  component: string = "meter";
  customer_meters(customer_id: number) {
    return this.list({ customer_id: customer_id });
  }
  group_meters(group_id: number) {
    return this.list({ group_id: group_id });
  }

  collection_data_meters(day: number) {
    return this.list({ collection_date: day });
  }

  locate(meter_id: number) {
    return this.http_client.post(
      this.function_url("locate", meter_id),
      {},
      { headers: contentHeaders() }
    );
  }

  set_location(meter_id: number, location_id: number) {
    return this.http_client.post(
      this.function_url("set_location", meter_id),
      { location_id: location_id },
      { headers: contentHeaders() }
    );
  }

  by_serial(serial: string) {
    return this.list({ serial: serial });
  }
}
