import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { MaterialModule } from "./material.module";
import { ErrorHandler } from "@angular/core";
import { APP_BASE_HREF } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NotifierModule } from "angular-notifier";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { FileSaverModule } from "ngx-filesaver";

import { AppComponent, CustomErrorHandler } from "./app.component";
import { CustomerModule } from "./customer/customer.module";
import { EmployeeModule } from "./employee/employee.module";
import { ContactModule } from "./contact/module";
import { AirportModule } from "./airport/module";
import { RouteModule } from "./customer_route/module";
import { AirplaneModule } from "./airplane/module";
import { PayloadModule } from "./payload/module";
import { FlightModule } from "./flight/module";
import { MapModule } from "./map/module";
import { MeterReadingModule } from "./meter_reading/module";
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import { DebugComponent } from "./debug/debug";
import { DialogModule } from "./dialog/dialog.module";
import { CG_CommonModule } from "./common/module";
import { AppRoutingModule } from "./app.routes";
import { GenericListModule } from "./generic-list/module";
import { GenericFilterModule } from "./generic-filter/module";
import { UserModule } from "@app/user/user.module";
import { MissionModule } from "@app/mission/mission.module";
import { CustomerJobModule } from "@app/customerjob/customerjob.module";
import { ImageryProjectModule } from "@app/imageryproject/imageryproject.module";

import { JwtHttpInterceptor } from "./services/auth.intercept";
import { TaskService } from "./task/task.service";
import { TaskCountService } from "@app/services/task.count.service";
import { CalenderComponentModule } from "@app/calender/calender.module";
import { FlightLegModule } from "@app/flight-leg/flight-leg.module";

import { DialogDataService } from "@app/services/dialogData.service";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { customNotifierOptions } from "@app/notifier.option";
import { ConfigService } from "@app/services/config.service";
import { environment } from "@env/environment";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
} from "@abacritt/angularx-social-login";
import { AppStoreModule } from "@app/store/module";
import { QualificationModule } from "./qualification/module";
import { SearchModule } from "./search/module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { ReportModule } from "./reports/module";
import { MatMomentDatetimeModule } from "@mat-datetimepicker/moment";
import {
  MatDatetimepickerModule,
  MAT_DATETIME_FORMATS,
} from "@mat-datetimepicker/core";
import * as L from "leaflet";
import "leaflet-editable";
import "leaflet.markercluster";
import "leaflet-area-select";
import "leaflet-contextmenu";
import "leaflet-rotatedmarker";
import "leaflet.icon.glyph";
import "@cleargrid/leaflet-panel-layers";

@NgModule({
  declarations: [AppComponent, LoginComponent, HomeComponent, DebugComponent],
  imports: [
    AppStoreModule,
    DashboardModule,
    BrowserModule,
    FormsModule,
    CustomerModule,
    AirportModule,
    DialogModule,
    FileSaverModule,
    AirplaneModule,
    MeterReadingModule,
    CG_CommonModule,
    MapModule,
    FlightModule,
    MaterialModule,
    ContactModule,
    AppRoutingModule,
    EmployeeModule,
    MissionModule,
    CustomerJobModule,
    ImageryProjectModule,
    BrowserAnimationsModule,
    RouteModule,
    GenericListModule,
    GenericFilterModule,
    HttpClientModule,
    FlexLayoutModule,
    UserModule,
    CalenderComponentModule,
    FlightLegModule,
    PayloadModule,
    QualificationModule,
    SearchModule,
    ReportModule,
    SocialLoginModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    NotifierModule.withConfig(customNotifierOptions),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  exports: [MaterialModule],
  providers: [
    TaskCountService,
    TaskService,
    ErrorHandleService,
    DialogDataService,
    ConfigService,
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: APP_BASE_HREF, useValue: "/" },
    { provide: HTTP_INTERCEPTORS, useClass: JwtHttpInterceptor, multi: true },
    Title,
    {
      provide: MAT_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: "L",
          monthInput: "MMMM",
          timeInput: "LT",
          datetimeInput: "L LT",
        },
        display: {
          dateInput: "L",
          monthInput: "MMMM",
          datetimeInput: "L LT",
          timeInput: "LT",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
          popupHeaderDateLabel: "ddd, DD MMM",
        },
      },
    },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "193874312791-v5do8sbmi18i9be8rtjf7ovop5bsaprt.apps.googleusercontent.com",
              {
                scopes: "profile email",
              }
            ),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router) {}
}
