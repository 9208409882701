import { Component, OnInit, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { FlightListComponent } from "@app/flight/list/list.component";
import { FlightFormComponent } from "@app/flight/form/form.component";

@Component({
  selector: "add-flight-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class AddFlightDialogComponent implements OnInit {
  tab_index = null;
  flight = null;
  flight_search = { status: null };
  title = "Add Flight";
  @ViewChild("flight_form", { static: true }) flightform: FlightFormComponent;
  @ViewChild("flight_list", { static: true }) flightlist: FlightListComponent;

  constructor(public dialogRef: MatDialogRef<AddFlightDialogComponent>) {}
  ngOnInit() {}

  select_flight(flight) {
    this.flight = flight;
  }

  tab_change() {}

  update(flight) {
    if (this.flight === null) {
      this.flight = {};
    }
    for (let k in flight) {
      this.flight[k] = flight[k];
    }
    this.flightform.update(this.flight);
    if (this.flight.aircraft) {
      this.flightlist.search_param.aircraft = this.flight.aircraft;
      this.flightlist.get_flights();
    }
  }

  done() {
    const self = this;
    return (res) => {
      self.dialogRef.close(res);
    };
  }

  submit(button: HTMLButtonElement) {
    if (this.tab_index) {
      this.dialogRef.close(this.flight);
    } else if (this.flightform.valid) {
      this.flightform.call_api(this.done());
    }
  }
}
