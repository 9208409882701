import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import * as moment from "moment-timezone";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { SubSink } from "subsink";
import { ImageryProject } from "@app/api/types";

interface Project {
  id: number;
  project_name: string;
  customer: any;
  [id: string]: any;
}

@Component({
  selector: "imageryproject-form",
  templateUrl: "./form.component.html",
  styleUrls: ["../component.css"],
})
export class ImageryProjectFormComponent implements OnInit, OnDestroy {
  @Input() actions: boolean = true;
  project: Project = { id: null, project_name: null, customer: null };
  id: number;
  customers: any;
  form_errors: any = {
    non_field_error: null,
    name: null,
    start_date: null,
    end_date: null,
    customer: null,
  };
  error = false;
  error_message: string;
  project_errors: any = { detail: null };
  title = "Add New ImageryProject";
  subsink = new SubSink();

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private api: ApiService,
    private errorHandleService: ErrorHandleService,
    private titleService: Title
  ) {
    const title = "Tarnished Lamp " + this.title;
    this.titleService.setTitle(title);
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params["id"];
      if (this.id) {
        this.title = "Update ImageryProject";
        this.subsink.sink = this.api.imagery.project.detail(this.id).subscribe(
          (project: any) => {
            const title = "Tarnished Lamp " + this.title + " " + project.name;
            this.titleService.setTitle(title);
            this.project = project;
          },
          (errors) => {
            this.project_errors = errors.error;
          }
        );
      }
    });
    this.customer_list();
  }

  update(project) {
    if (this.project === null) {
      this.project = { id: null, project_name: null, customer: null };
    }
    for (let k in project) {
      this.project[k] = project[k];
    }
  }

  customer_list() {
    this.subsink.sink = this.api.customer.list().subscribe((res: any) => {
      this.customers = res.results;
    });
  }

  valid() {
    this.form_errors = {};
    let project = this.project;
    console.debug(this.project);
    if (!project.project_name) {
      this.form_errors["project_name"] = "Project name is required";
    }
    if (!project.customer) {
      this.form_errors["customer"] = "Customer is required";
    }
    if (moment(this.project.end_date).isBefore(this.project.start_date)) {
      this.form_errors["end_date"] = "End date should be after start date.";
    }
    if (moment(this.project.start_date).isAfter(this.project.end_date)) {
      this.form_errors["start_date"] = "Start date should be before end date.";
    }
    if (Object.values(this.form_errors).some((x) => x !== null)) {
      console.debug(this.form_errors);
      return false;
    }
    return true;
  }

  save() {
    if (this.valid()) {
      this.call_api();
    }
  }

  call_api(success: (res: any) => any = null) {
    let project: ImageryProject = {
      customer: this.project.customer.id,
      project_name: this.project.project_name,
      end_date: moment(this.project.end_date).format("YYYY-MM-DD"),
      start_date: moment(this.project.start_date).format("YYYY-MM-DD"),
    };

    let call = this.api.imagery.project.create(project);
    if (this.id) {
      call = this.api.imagery.project.update(this.id, project);
    }
    if (moment(this.project.end_date).isBefore(this.project.start_date)) {
      this.error = true;
      this.error_message = "End date should be greater then start date.";
    } else {
      call.subscribe(
        (res: any) => {
          if (success) {
            success(res);
          } else {
            this.location.back();
          }
        },
        (errors) => {
          if (!errors.status) {
            this.errorHandleService.sendError(
              "An error occurred while processing your request. If this keeps happening please contact support."
            );
          }
          this.form_errors = errors.error;
        }
      );
    }
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
