import { Component, Input, OnInit } from "@angular/core";
import { DialogService } from "@app/services/dialog.service";

@Component({
  selector: "flight-leg-takeoff",
  templateUrl: "./flight-leg-takeoff.component.html",
  styleUrls: ["./flight-leg.component.css"],
})
export class FlightLegTakeoffComponent implements OnInit {
  @Input("takeoff") takeoff: any;

  constructor(private dialog_service: DialogService) {}
  ngOnInit() {}
  edit_leg(button: HTMLButtonElement) {
    console.debug(this.takeoff);
    this.dialog_service
      .start_flight_leg(this.takeoff.flight, this.takeoff.id)
      .subscribe((e) => {
        console.debug(e);
      });
  }
}
