import { Component, OnInit, ViewChild } from "@angular/core";
import { GenericListComponent } from "@app/generic-list/generic-list.component";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ApiService } from "@app/api/api";

@Component({
  selector: "employee-list",
  templateUrl: "./list.component.html",
  styleUrls: ["../component.css"],
})
export class EmployeeListComponent implements OnInit {
  filters = [
    { label: "First Name", name: "first_name", maxlength: 10, type: "text" },
    { label: "Last Name", name: "last_name", maxlength: 10, type: "text" },
    { label: "Phone", name: "phone", maxlength: 15, type: "number" },
    { label: "Email", name: "email", maxlength: 25, type: "text" },
  ];
  @ViewChild(GenericListComponent, { static: true })
  genericlist: GenericListComponent;
  constructor(private api: ApiService) {}

  ngOnInit() {}
  getFilterData(value) {
    this.genericlist.filterValues = value;
  }
  get_employee_list(search_params: Observable<any>): Observable<any> {
    return search_params.pipe(
      switchMap((search_params) => {
        return this.api.employee.list(search_params);
      })
    );
  }
}
