<mat-card *ngIf="errors">
  <mat-error>{{error_message}}</mat-error>
</mat-card>
<mat-card *ngIf="!errors">
  <mat-card-title>{{flight.label}}</mat-card-title>
  <mat-card-subtitle class="row">
    <span class="spacer"> </span>
    <span>{{flight.last_modified | date}}</span>
  </mat-card-subtitle>
  <mat-card-content>
    <mat-spinner *ngIf="loading"></mat-spinner>
    <table *ngIf="!loading" width="100%">
      <tr>
        <td>&nbsp;</td>
        <td><b>Planned</b></td>
        <td><b>Actual</b></td>
      </tr>
      <tr>
        <td>Aircraft</td>
        <td>{{flight.aircraft.make}} {{flight.aircraft.model}}</td>
        <td>{{flight.aircraft.registration}}</td>
      </tr>
      <tr>
        <td>Launch</td>
        <td>{{flight.launch.properties.name}}</td>
        <td>{{flight.launch.properties.name}}</td>
      </tr>
      <tr>
        <td>Recover</td>
        <td>{{flight.recover.properties.name}}</td>
        <td>{{flight.recover.properties.name}}</td>
      </tr>
      <tr>
        <td colspan="3"><b>Flight Details</b></td>
      </tr>
      <tr>
        <td>Flight Area</td>
        <td>{{report.plan.flight_area|number:'1.0-0'}} square km</td>
        <td>{{report.actual.flight_area|number:'1.0-0'}} square km</td>
      </tr>
      <tr>
        <td>Transit Distance</td>
        <td>{{report.plan.transit_distance|number:'1.0-0'}} km</td>
        <td>{{report.actual.transit_distance|number:'1.0-0'}} km</td>
      </tr>
      <tr>
        <td>Work Distance</td>
        <td>{{report.plan.work_distance|number:'1.0-0'}} km</td>
        <td>{{report.actual.work_distance|number:'1.0-0'}} km</td>
      </tr>
      <tr>
        <td>Total Distance</td>
        <td>{{report.plan.flight_distance|number:'1.0-0'}} km</td>
        <td>{{report.actual.flight_distance|number:'1.0-0'}} km</td>
      </tr>
      <tr>
        <td>Flight Time</td>
        <td>{{report.plan.flight_time/3600|number:'1.2-2'}} hours</td>
        <td>{{report.actual.flight_time/3600|number:'1.2-2'}} hours</td>
      </tr>
      <tr>
        <td>Average Speed</td>
        <td>{{report.plan.speed|number:'1.2-2'}} knots</td>
        <td>{{report.actual.speed|number:'1.2-2'}} knots</td>
      </tr>
      <tr>
        <td colspan="3"><b>Meter Details</b></td>
      </tr>
      <tr *ngFor="let m of flight.meter_groups">
        <td>{{m.group_name}}</td>
        <td>{{meter_counts[m.group_name]}}</td>
        <td>{{meter_counts[m.group_name]}}</td>
      </tr>
      <tr>
        <td>Total Meters</td>
        <td>
          {{meters}}<mat-spinner
            *ngIf="count_requests!=0"
            style="height: 1em"
          ></mat-spinner>
        </td>
        <td>{{meters}}</td>
      </tr>
      <tr>
        <td colspan="3"><b>Operating Expenses</b></td>
      </tr>
      <tr>
        <td>Hourly</td>
        <td colspan="2">
          <mat-form-field>
            <input
              matInput
              placeholder="OpEx"
              [(ngModel)]="hourly.opex"
              (change)="recalculate()"
              type="number"
            />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>Flight Total</td>
        <td>{{report.plan.hourly_cost.opex|currency:'CAD':'symbol-narrow'}}</td>
        <td>
          {{report.actual.hourly_cost.opex|currency:'CAD':'symbol-narrow'}}
        </td>
      </tr>
      <tr>
        <td>Per Meter</td>
        <td>
          {{report.plan.cost_per_meter.opex|currency:'CAD':'symbol-narrow'}}
        </td>
        <td>
          {{report.actual.cost_per_meter.opex|currency:'CAD':'symbol-narrow'}}
        </td>
      </tr>
      <tr>
        <td colspan="3"><b>Capital Expenses</b></td>
      </tr>
      <tr>
        <td>Hourly</td>
        <td colspan="2">
          <mat-form-field>
            <input
              matInput
              placeholder="CapEx"
              [(ngModel)]="hourly.capex"
              (change)="recalculate()"
              type="number"
            />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>Flight Total</td>
        <td>{{report.plan.total_cost.capex|currency:'CAD':'symbol-narrow'}}</td>
        <td>
          {{report.actual.total_cost.capex|currency:'CAD':'symbol-narrow'}}
        </td>
      </tr>
      <tr>
        <td>Per Meter</td>
        <td>
          {{report.plan.cost_per_meter.capex|currency:'CAD':'symbol-narrow'}}
        </td>
        <td>
          {{report.actual.cost_per_meter.capex|currency:'CAD':'symbol-narrow'}}
        </td>
      </tr>
      <tr>
        <td colspan="3"><b>Total Cost</b></td>
      </tr>
      <tr>
        <td>Hourly</td>
        <td>{{hourly.total | currency:'CAD':'symbol-narrow'}}</td>
        <td>{{hourly.total | currency:'CAD':'symbol-narrow'}}</td>
      </tr>
      <tr>
        <td>Flight Total</td>
        <td>
          {{report.plan.total_cost.total | currency:'CAD':'symbol-narrow'}}
        </td>
        <td>
          {{report.actual.total_cost.total | currency:'CAD':'symbol-narrow'}}
        </td>
      </tr>
      <tr>
        <td>Per Meter</td>
        <td>
          {{report.plan.cost_per_meter.total|currency:'CAD':'symbol-narrow'}}
        </td>
        <td>
          {{report.actual.cost_per_meter.total|currency:'CAD':'symbol-narrow'}}
        </td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
