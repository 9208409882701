<mat-toolbar color="primary" fxLayout="row">
  <span fxFlex>Update Icon Styles</span>
  <span fxFlex fxLayoutAlign="end">
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      matTooltip="Close Dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </span>
</mat-toolbar>

<mat-card>
  <div fxLayout="column">
    <mat-card-content>
      <div fxLayout="column" style="width: 800px">
        <div
          fxLayout="row wrap"
          fxLayoutAlign="space-around space-around"
          fxLayoutGap="16px grid"
        >
          <endpoint-style [(icon)]="styles[0]" label="Error"></endpoint-style>
          <endpoint-style [(icon)]="styles[1]" label="Complete">
          </endpoint-style>
          <endpoint-style [(icon)]="styles[2]" label="Heard"></endpoint-style>
          <endpoint-style [(icon)]="styles[3]" label="Unheard"></endpoint-style>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row">
        <span fxFlex>
          <button type="button" mat-button (click)="dialogRef.close()">
            Cancel
          </button>
        </span>
        <span fxFlex fxLayoutAlign="end">
          <button
            type="button"
            mat-raised-button
            #submit_btn
            (click)="submit(submit_btn)"
          >
            OK
          </button>
        </span>
      </div>
    </mat-card-actions>
  </div>
</mat-card>
