<mat-toolbar color="primary">
  <div fxLayout="row" fxFlex="100%">
    <span fxFlex fxLayoutAlign="start">File Upload</span>
    <span fxFlex fxLayoutAlign="end">
      <button
        mat-icon-button
        (click)="dialogRef.close()"
        matTooltip="Close Dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </span>
  </div>
</mat-toolbar>
<mat-card class="file-content">
  <div fxLayout="column">
    <mat-card-content>
      <input type="file" #fileInput (change)="on_file_change()" />
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row" fxFlex="100%">
        <span fxFlex fxLayoutAlign="start">
          <button mat-button (click)="dialogRef.close()">Cancel</button>
        </span>
        <span fxFlex fxLayoutAlign="end">
          <button
            mat-raised-button
            [disabled]="!file"
            #submit_btn
            (click)="submit(submit_btn)"
          >
            Upload
          </button>
        </span>
      </div>
    </mat-card-actions>
  </div>
</mat-card>
