import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription, merge, timer, pipe, NEVER } from "rxjs";
import { map, filter, bufferCount, switchMap } from "rxjs/operators";
import { ApiService } from "@app/api/api";
import { RealTimeService } from "./realtime.service";

export interface FlightEvent {
  event: string;
  aircraft_id: number;
  aircraft: string;
  timestamp: string;
  location: [number,number];
  airport?: string;
  airport_id?: string;
}

@Injectable()
export class FlightEventService {
  private $latest: Subscription;
  private _events: BehaviorSubject<FlightEvent[]> = new BehaviorSubject<
    FlightEvent[]
  >([]);

  get events() {
    return merge(
      this._events.asObservable(),
      timer(5, 30 * 1000).pipe(map((e) => this._events.getValue()))
    );
  }

  aircraft_events(aircraft: number) {
    return this.events.pipe(
      map((events: FlightEvent[]) => {
        return events.find(
          (status: FlightEvent) => status.aircraft_id == aircraft
        );
      }),
      filter((e) => e != null)
    );
  }

  constructor(private api: ApiService, private rts: RealTimeService) {}

  add_event(event: FlightEvent) {
    console.debug(event);
    let events = this._events.getValue();
    console.log(events);
    events.push(event);
    this._events.next([...events]);
  }

  start() {
    if (this.$latest && !this.$latest.closed) {
      console.log("Flight Event service already running");
      return;
    }
    console.log("Starting Flight Event Service");

    this.$latest = this.rts.connected
      .pipe(
        switchMap((connected: boolean) => {
          if (connected) {
            console.log("Recieving Events");
            return this.rts.messages("flight_event");
          } else {
            console.log("events disconnected");
            return NEVER;
            //todo
          }
        })
      )
      .pipe(filter((e) => e != null))
      .subscribe(
        (event: FlightEvent) => {
          this.add_event(event);
        },
        (error) => {
          console.debug("error retrieving flight events", error);
        }
      );
  }

  stop() {
    if (this.$latest) this.$latest.unsubscribe();
  }
}
