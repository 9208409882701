import { Component, Input, OnInit } from "@angular/core";
import { ApiService } from "@app/api/api";
import { EndpointRecord } from "@cleargrid/map_layers";
interface MeterDetail {
  id: number;
  market_name: string;
  serial: string;
}
@Component({
  selector: "endpoint-detail",
  template: `
    <ng-template [ngIf]="meters.length == 0">
      <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between">
        <div fxFlexAlign="start">{{ record.id }}</div>
        <!--<div>{{ record.latitude }},{{ record.longitude }}</div>-->
        <div fxFlexAlign="end">{{ status[record.state] }}</div>
        <!--<div>
          <pre width="100%">{{ record | json }}</pre>
        </div>-->
      </div>
    </ng-template>

    <ng-template [ngIf]="meters.length > 0">
      <ng-template ngFor let-meter [ngForOf]="meters">
        <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between">
          <div fxFlexAlign="start">{{ record.id }} {{ meter.market_name }}</div>
          <!--<div>{{ record.latitude }},{{ record.longitude }}</div>-->
          <div>{{ status[record.state] }}</div>
          <!--<div>
            <pre width="100%">{{ record | json }}</pre>
          </div>-->
          <div>
            <a
              class="anchor"
              [href]="'#/meter_reading/' + meter.id"
              target="_blank"
              style="display: initial"
              matTooltip="Previous Readings"
            >
              <mat-icon>open_in_new</mat-icon>
            </a>
          </div>
        </div>
      </ng-template>
    </ng-template>
  `,
  styles: [],
})
export class PayloadEndpointDetailComponent implements OnInit {
  @Input() record: EndpointRecord;
  status: string[] = ["Error", "Complete", "Heard", "Unheard"];
  meters: MeterDetail[] = [];

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.api.meter.by_serial(this.record.id).subscribe((d: any) => {
      this.meters = d.features.map((d) => {
        return { id: d.id, ...d.properties };
      });
    });
  }
}

@Component({
  selector: "endpoint-list",
  template: `<section class="popup">
    <div>
      <div
        *ngFor="let record of data.slice(0, 5)"
        fxLayout="row"
        fxLayoutAlign="space-between"
      >
        <endpoint-detail fxFlex [record]="record"></endpoint-detail>
      </div>
      <div *ngIf="data.length > 5">{{ data.length - 5 }} more records</div>
    </div>
  </section>`,
  styles: [
    "section{display: contents !important;}  .popup{width: 200px; height: 100px;}",
  ],
})
export class PayloadEndpointListComponent implements OnInit {
  @Input() data: EndpointRecord[];

  ngOnInit() {}
}
