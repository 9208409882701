import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MapDataService {

  // Observable string sources
  private DataSource = new Subject<any>();

  // Observable streams
  data$ = this.DataSource.asObservable();
  // Service message commands
  add_data(string) {
    this.DataSource.next(string);
  }

}
