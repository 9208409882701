<mat-card>
  <mat-card-title>
    <div fxLayout="row">
      <span fxFlex class="header_label">Employees</span>
      <span fxFlex fxLayoutAlign="end">
        <a mat-icon-button class="anchor" [href]="'#/employee/new'">
          <mat-icon matTooltip="New Employee" [routerLink]="['new']"
            >add</mat-icon
          >
        </a>
      </span>
    </div>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <app-generic-filter
      [filterList]="filters"
      [store_name]="'employee'"
      (filterData)="getFilterData($event)"
    ></app-generic-filter>
    <app-generic-list
      [source]="get_employee_list"
      [router]="'employee'"
      [displayedColumns]="[
        'first_name',
        'last_name',
        'city',
        'phone',
        'email',
        'anchor'
      ]"
    >
    </app-generic-list>
  </mat-card-content>
</mat-card>
