import { Injectable } from "@angular/core";
import { Store, createSelector, select } from "@ngrx/store";
import { Subscription, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, tap } from "rxjs/operators";

import {
  MapState,
  selectTab,
  unselectAircraft,
  selectAircraft,
  unselectProfile,
  selectProfile,
  setTrackColour,
  TrackRequest,
  setTrackRequest,
  AircraftState,
  addMeterGroup,
  removeMeterGroup,
  showMeterGroup,
  hideMeterGroup,
  MeterGroupState,
  MissionState,
  addMission,
  removeMission,
  showMission,
  showMissionArea,
  showMissionReceived,
  Route,
  showMissionRouteMeters,
  showMissionRouteArea,
  Flight,
  showMissionFlightPlan,
  showMissionFlightTrack,
  clearMissions,
  addMissionRoutes,
  addMissionFlights,
  expandMission,
  setState,
  setLayer,
  setBounds,
} from "@app/store/map/sidebar/map_reducer";
interface AppState {
  map: MapState;
}

@Injectable()
export class MapStoreService {
  AircraftDetailSelector = () =>
    createSelector(
      (state: AppState, _props) => state.map,
      (map_state: MapState, { aircraft_id }) => map_state.aircraft[aircraft_id]
    );
  index$: Subscription;
  constructor(private store: Store<AppState>) {}

  public get_map_state(): Observable<MapState> {
    return this.store.select("map");
  }

  public get_current_tab(): Observable<number> {
    return this.store.pipe(
      map((state: AppState) => state.map.tab),
      distinctUntilChanged()
    );
  }

  public get_aircraft_details(aircraft_id: number): Observable<AircraftState> {
    return this.store.pipe(
      map((state: AppState) => state.map.aircraft[aircraft_id]),
      distinctUntilChanged()
    );
  }

  public get_meter_groups(): Observable<MeterGroupState[]> {
    return this.store.pipe(
      map((state: AppState) => state.map.meter_groups),
      distinctUntilChanged()
    );
  }
  public get_missions(): Observable<MissionState[]> {
    return this.store.select("map").pipe(
      map((map_state: MapState) => Object.values(map_state.mission)),
      distinctUntilChanged()
    );
  }
  public get_mission_flights(
    mission_id: number
  ): Observable<{ [id: number]: Flight }> {
    return this.store.pipe(
      map((state) => state.map.mission[mission_id].mission.flights),
      distinctUntilChanged()
    );
  }
  public get_mission_routes(
    mission_id: number
  ): Observable<{ [id: number]: Route }> {
    return this.store.pipe(
      map((state) => state.map.mission[mission_id].mission.routes),
      distinctUntilChanged()
    );
  }

  public set_state(state: MapState) {
    this.store.dispatch(setState({ state: state }));
  }
  public set_bounds(bounds: [[number, number], [number, number]]) {
    this.store.dispatch(setBounds({ bounds: bounds }));
  }
  public set_layer(layer: string) {
    this.store.dispatch(setLayer({ layer: layer }));
  }

  public select_aircraft(aircraft_id: number) {
    this.store.dispatch(selectAircraft({ aircraft_id: aircraft_id }));
  }

  public unselect_aircraft(aircraft_id: number) {
    this.store.dispatch(unselectAircraft({ aircraft_id: aircraft_id }));
  }
  public select_profile(aircraft_id: number) {
    this.store.dispatch(selectProfile({ aircraft_id: aircraft_id }));
  }
  public unselect_profile(aircraft_id: number) {
    this.store.dispatch(unselectProfile({ aircraft_id: aircraft_id }));
  }
  public set_colour(aircraft_id: number, colour: string | number) {
    this.store.dispatch(
      setTrackColour({ aircraft_id: aircraft_id, track_colour: colour })
    );
  }
  public set_track(aircraft_id: number, track: TrackRequest) {
    this.store.dispatch(
      setTrackRequest({ aircraft_id: aircraft_id, request: track })
    );
  }

  public change_tab(tab: number) {
    this.store.dispatch(selectTab({ tab: tab }));
  }

  public add_meter_group(metergroup: MeterGroupState) {
    this.store.dispatch(addMeterGroup({ metergroup: metergroup }));
    return metergroup;
  }
  public remove_meter_group(metergroup_id: number) {
    this.store.dispatch(removeMeterGroup({ metergroup_id: metergroup_id }));
  }
  public show_meter_group(metergroup_id: number) {
    this.store.dispatch(showMeterGroup({ metergroup_id: metergroup_id }));
  }
  public hide_meter_group(metergroup_id: number) {
    this.store.dispatch(hideMeterGroup({ metergroup_id: metergroup_id }));
  }

  public add_mission(mission: MissionState) {
    this.store.dispatch(addMission({ mission: mission }));
    return mission;
  }
  public remove_mission(mission_id: number) {
    this.store.dispatch(removeMission({ mission_id: mission_id }));
  }
  public clear_missions() {
    this.store.dispatch(clearMissions());
  }
  public show_mission(mission_id: number, display: boolean = true) {
    this.store.dispatch(showMission({ mission_id: mission_id, show: display }));
  }
  public expand_mission(mission_id: number, expanded: boolean = true) {
    this.store.dispatch(
      expandMission({ mission_id: mission_id, expanded: expanded })
    );
  }
  public show_mission_area(mission_id: number, display: boolean = true) {
    console.log(`show mission ${mission_id}:${display}`);
    this.store.dispatch(
      showMissionArea({ mission_id: mission_id, show: display })
    );
  }

  public show_mission_received(mission_id: number, display: boolean = true) {
    this.store.dispatch(
      showMissionReceived({ mission_id: mission_id, show: display })
    );
  }

  public add_mission_routes(mission_id: number, routes: Route[]) {
    if (routes.length) {
      this.store.dispatch(
        addMissionRoutes({ mission_id: mission_id, routes: routes })
      );
    }
  }

  public show_mission_route_meters(
    mission_id: number,
    route_id: number,
    display: boolean = true
  ) {
    this.store.dispatch(
      showMissionRouteMeters({
        mission_id: mission_id,
        route_id: route_id,
        show: display,
      })
    );
  }

  public show_mission_route_area(
    mission_id: number,
    route_id: number,
    display: boolean = true
  ) {
    this.store.dispatch(
      showMissionRouteArea({
        mission_id: mission_id,
        route_id: route_id,
        show: display,
      })
    );
  }

  public add_mission_flights(mission_id: number, flights: Flight[]) {
    if (flights.length) {
      this.store.dispatch(
        addMissionFlights({ mission_id: mission_id, flights: flights })
      );
    }
  }

  public show_mission_flight_plan(
    mission_id: number,
    flight_id: number,
    display: boolean = true
  ) {
    this.store.dispatch(
      showMissionFlightPlan({
        mission_id: mission_id,
        flight_id: flight_id,
        show: display,
      })
    );
  }

  public show_mission_flight_track(
    mission_id: number,
    flight_id: number,
    display: boolean = true
  ) {
    this.store.dispatch(
      showMissionFlightTrack({
        mission_id: mission_id,
        flight_id: flight_id,
        show: display,
      })
    );
  }
}
