<div fxLayout="column">
  <mat-toolbar color="primary" fxFlex fxLayout="row">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
      <span fxFlex="grow">{{ title }}</span>
      <button
        fxFlex="nogrow"
        mat-icon-button
        (click)="dialogRef.close()"
        matTooltip="Close Dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <mat-card fxFlex fxLayout="column">
    <mat-card-content class="add-flight-content">
      <mat-tab-group
        [(selectedIndex)]="tab_index"
        (selectedTabChange)="tab_change()"
      >
        <mat-tab label="New Flight">
          <flight-form #flight_form [actions]="false" [scheduling]="true">
          </flight-form>
        </mat-tab>
        <mat-tab label="Existing Flight" class="select-flight-tab">
          <flight-list
            #flight_list
            [search]="flight_search"
            [show_filters]="true"
            [navigate]="false"
            [dialog]="true"
            (select)="select_flight($event)"
          >
          </flight-list>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center">
      <button type="button" mat-button (click)="dialogRef.close()">
        Cancel
      </button>
      <button
        type="button"
        mat-raised-button
        #submit_btn
        (click)="submit(submit_btn)"
      >
        OK
      </button>
    </mat-card-actions>
  </mat-card>
</div>
