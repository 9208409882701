<div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between">
  <div fxLayout="column">
    <label fxFlex>
      <mat-checkbox
        class="payload-status-enable"
        [checked]="enabled===true"
        [value]="payload.id"
        (change)="toggle_payload($event)"
      >
      </mat-checkbox>
      Display {{payload.payload_name}} Endpoints
    </label>
    <div
      *ngIf="completion"
      style="margin-left: 2em; font-size: 0.85em"
      fxLayout="row"
      fxLayoutGap="20px"
    >
      <div fxLayout="column">
        <span>Error</span>
        <span>{{completion.error}}</span>
      </div>

      <div fxLayout="column">
        <span>Heard</span>
        <span>{{completion.heard}}</span>
      </div>

      <div fxLayout="column">
        <span>Complete</span>
        <span>{{completion.complete}}</span>
      </div>

      <div fxLayout="column">
        <span>Total</span>
        <span>{{completion.total}}</span>
      </div>
      <div>&nbsp;</div>
      <div fxLayout="column">
        <span>&nbsp;</span>
        <span>
          {{completion.complete/completion.total*100 | number:'1.0-2'}}%
        </span>
      </div>
    </div>
  </div>
  <div>
    <button
      mat-icon-button
      matTooltip="Configure Layer"
      (click)="configure_layer()"
      [disabled]="layer===undefined"
    >
      <mat-icon>settings</mat-icon>
    </button>
  </div>
</div>
