import { Subject } from "rxjs";

export class ErrorService {
  getError: Subject<boolean> = new Subject();

  setError(error: string) {
    console.log("Error: ", error);
    this.getError.next(true);
  }

  clearError() {
    this.getError.next(false);
  }
}
