import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { Flight } from "../types";
import { Observable, map } from "rxjs";
import * as moment from "moment";

@Injectable()
export class FlightService extends BaseApiService<Flight> {
  component: string = "flight";

  detail(id: string | number): Observable<Flight> {
    return super.detail(id).pipe(
      map((flight) => {
        for (const f of [
          "scheduled_launch",
          "scheduled_recovery",
          "actual_launch",
          "actual_recovery",
        ]) {
          flight[f] = flight[f] !== null ? moment(flight[f]) : null;
        }
        return flight as Flight;
      })
    );
  }

  generate_flightplan(flight: number) {
    return this.http_client.post(
      this.function_url("generate_flightplan", flight),
      {},
      { headers: contentHeaders() }
    );
  }

  copy_flightplan(flight: number, track_id: string) {
    return this.http_client.post(
      this.function_url("assign_flightplan", flight),
      { track_id: track_id },
      { headers: contentHeaders() }
    );
  }

  update_flightarea(flight: number) {
    return this.http_client.post(
      this.function_url("update_flightarea", flight),
      {},
      { headers: contentHeaders() }
    );
  }

  generate_flightpath(flight: number) {
    return this.http_client.post(
      this.function_url("generate_flightpath", flight),
      {},
      { headers: contentHeaders() }
    );
  }

  generate_collector_archive(flight: number, location_type?: string) {
    let data: { location_type?: string } = {};
    if (location_type) {
      data.location_type = location_type;
    }
    return this.http_client.post(
      this.function_url("generate_collector_archive", flight),
      data,
      { headers: contentHeaders() }
    );
  }

  download_meter_locations(flight: number, location_type?: string) {
    let data: { location_type?: string } = {};
    if (location_type) {
      data.location_type = location_type;
    }
    return this.http_client.post(
      this.function_url("download_meter_locations", flight),
      data,
      { headers: contentHeaders() }
    );
  }

  _upload_file(endpoint: string, object_id: number | string, datafile: any) {
    let formData = new FormData();
    formData.append("content_type", "flight");
    formData.append("datafile", datafile);
    // the api ignores this value though the serializer requires it, feed a known invalid number
    formData.append("object_id", "-1");

    let headers = contentHeaders();
    headers = headers.delete("Content-Type");

    return this.http_client.post(
      this.function_url(endpoint, object_id),
      formData,
      { headers: headers }
    );
  }

  upload_collector_result(object_id: number | string, datafile: any) {
    return this._upload_file("upload_collector_results", object_id, datafile);
  }

  upload_flightplan(object_id: number | string, datafile: any) {
    return this._upload_file("upload_flightplan", object_id, datafile);
  }

  search(params) {
    return this.list(params);
  }

  track(flight: number) {
    return this.get_with_retry(this.function_url("track", flight), {
      headers: contentHeaders(),
    });
  }

  plan(flight: number) {
    return this.get_with_retry(this.function_url("plan", flight), {
      headers: contentHeaders(),
    });
  }

  get_last_takeoff(flight: number) {
    return this.get_with_retry(this.function_url("last_takeoff", flight), {
      headers: contentHeaders(),
    });
  }

  remove_mission(flight: number, mission: number) {
    return this.post_with_retry(
      this.function_url("remove_mission", flight),
      { mission: mission },
      { headers: contentHeaders() }
    );
  }

  add_mission(flight: number, mission: number) {
    return this.post_with_retry(
      this.function_url("add_mission", flight),
      { mission: mission },
      { headers: contentHeaders() }
    );
  }
  cancel_flight(flight: number, note: string) {
    return this.post_with_retry(
      this.function_url("cancel", flight),
      { note: note },
      { headers: contentHeaders() }
    );
  }
  complete_flight(flight: number, note: string) {
    return this.post_with_retry(
      this.function_url("complete", flight),
      { note: note },
      { headers: contentHeaders() }
    );
  }
}
