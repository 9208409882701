import { Component, OnInit, OnDestroy, Input, Host } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { MeterGroup } from "@app/api/types";

@Component({
  selector: "metergroup-item",
  templateUrl: "commonhtml/metergrouplistitem.html",
  styles: [".clickable-mat-icon {cursor: pointer;}"],
})
export class MeterGroupListItem {
  @Input("group") group: MeterGroup = null;
  @Input("list") list: MeterGroupList;
  constructor(
    private api: ApiService,
    private router: Router,
    private errorHandleService: ErrorHandleService
  ) {}
  remove_group(button: HTMLButtonElement) {
    // stub for now
  }

  create_route(button: HTMLButtonElement, group: MeterGroup) {
    button.disabled = true;
    const customer_route = {
      customer: group.customer,
      description: "",
      name: group.group_name,
      meter_group: group.id,
    };
    this.api.customer_route.create(customer_route).subscribe({
      next: (res: any) => {
        this.router.navigate(["/customer_route", res.id, "edit"]);
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }
}

@Component({
  selector: "metergroups",
  templateUrl: "commonhtml/metergrouplist.html",
  styles: [".clickable-mat-icon {cursor: pointer;}"],
})
export class MeterGroupList implements OnInit, OnDestroy {
  @Input("customer") customer: number = null;
  @Input("mission") mission: number = null;
  @Input("delete") delete_fn = null;
  @Input("add") add_fn = null;
  $groups: Subscription;
  groups: MeterGroup[] = [];

  constructor(
    private api: ApiService,
    private router: Router,
    private errorHandleService: ErrorHandleService
  ) {}

  ngOnInit() {
    this.loadGroups();
  }

  loadGroups = () => {
    this.$groups = this.api.meter_group
      .list({ customer: this.customer, mission: this.mission })
      .subscribe((data: any) => {
        this.groups = data.results;
      });
  };

  add_group(button: HTMLButtonElement) {
    button.disabled = true;
    if (this.add_fn) {
      this.add_fn().subscribe({
        next: (res: any) => {
          if (res) {
            this.groups.push(res);
          }
        },
        complete: () => {
          button.disabled = false;
        },
      });
    }
  }

  ngOnDestroy() {
    if (this.$groups) {
      this.$groups.unsubscribe();
    }
  }
}
