<mat-toolbar color="primary">
  <span>Complete Flight</span>
  <span class="spacer"></span>
  <button mat-icon-button (click)="dialogRef.close()" matTooltip="Close Dialog">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-card>
  <mat-card-content class="add-flight-content">
    <div fxLayout="column">
      <div fxLayout="column">
        <mat-form-field>
          <mat-select placeholder="Aircraft" [(ngModel)]="flight.aircraft">
            <mat-option *ngFor="let a of aircraft" [value]="a.id"
              >{{ a.make }} {{ a.model }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <span fxFlex="50%" fxLayout="column">
          <mat-card>
            <mat-card-title>
              <mat-icon>flight_takeoff</mat-icon>
              Launch
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column">
                <div
                  class="errors"
                  *ngIf="error_schedule_date || error_actual_date"
                ></div>
                <mat-form-field fxFlex>
                  <mat-select placeholder="Airport" [(ngModel)]="flight.launch">
                    <mat-option *ngFor="let a of airports" [value]="a.id">{{
                      a.properties.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error *ngIf="form_errors.launch">{{
                  form_errors.launch
                }}</mat-error>
                <div fxFlex *ngIf="flight.mission_type != 'Quote'">
                  <span fxLayout="column">
                    <mat-form-field fxFlex>
                      <input
                        matInput
                        placeholder="Scheduled"
                        [(ngModel)]="flight.scheduled_launch"
                        type="datetime-local"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="error_scheduled_launch">{{
                      scheduled_launch_date_error
                    }}</mat-error>
                    <mat-form-field fxFlex>
                      <input
                        matInput
                        placeholder="Actual"
                        [(ngModel)]="flight.actual_launch"
                        type="datetime-local"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="error_actual_launch">{{
                      actual_launch_date_error
                    }}</mat-error>
                  </span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </span>
        <span fxFlex="50%" fxLayout="column">
          <mat-card>
            <mat-card-title>
              <mat-icon>flight_landing</mat-icon>
              Recovery
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column">
                <div
                  class="errors"
                  *ngIf="error_schedule_date || error_actual_date"
                >
                  <mat-error *ngIf="error_schedule_date">{{
                    scheduled_error
                  }}</mat-error>
                  <mat-error
                    *ngIf="error_actual_date"
                    class="actual_date_error"
                    >{{ actual_error }}</mat-error
                  >
                </div>
                <mat-form-field fxFlex>
                  <mat-select
                    placeholder="Airport"
                    [(ngModel)]="flight.recover"
                  >
                    <mat-option *ngFor="let a of airports" [value]="a.id">{{
                      a.properties.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error *ngIf="form_errors.recover">{{
                  form_errors.recover
                }}</mat-error>
                <div fxFlex *ngIf="flight.mission_type != 'Quote'">
                  <span fxLayout="column">
                    <mat-form-field fxFlex>
                      <input
                        matInput
                        placeholder="Scheduled"
                        [(ngModel)]="flight.scheduled_recovery"
                        type="datetime-local"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="error_scheduled_recovery">{{
                      scheduled_recovery_date_error
                    }}</mat-error>
                    <mat-form-field fxFlex>
                      <input
                        matInput
                        placeholder="Actual"
                        [(ngModel)]="flight.actual_recovery"
                        type="datetime-local"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="error_actual_recovery">{{
                      actual_recovery_date_error
                    }}</mat-error>
                  </span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </span>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions class="row">
    <button type="button" mat-button (click)="dialogRef.close()">Cancel</button>
    <span class="spacer"></span>
    <button
      type="button"
      mat-raised-button
      #complete_flight_btn
      (click)="complete_flight(complete_flight_btn)"
    >
      OK
    </button>
  </mat-card-actions>
</mat-card>
