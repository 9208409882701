<mat-card class="contact-detail">
  <mat-card-header>
    <mat-card-title>
      <mat-form-field>
        <input
          matInput
          placeholder="First Name"
          [(ngModel)]="contact.first_name"
          maxlength="32"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Last Name"
          [(ngModel)]="contact.last_name"
          maxlength="32"
        />
      </mat-form-field>
    </mat-card-title>
    <mat-card-subtitle>
      <mat-form-field>
        <input
          matInput
          placeholder="Title"
          [(ngModel)]="contact.title"
          maxlength="32"
        />
      </mat-form-field>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content
    ><p></p>
    <div class="row">
      <div class="spacer">
        <div class="row">
          <mat-form-field>
            <input
              matInput
              placeholder="Office Number"
              [(ngModel)]="contact.office"
              maxlength="16"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <input
              matInput
              placeholder="Home Number"
              [(ngModel)]="contact.home"
              maxlength="16"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <input
              matInput
              placeholder="Mobile Number"
              [(ngModel)]="contact.mobile"
              maxlength="16"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <input
              matInput
              placeholder="Fax Number"
              [(ngModel)]="contact.fax"
              maxlength="16"
            />
          </mat-form-field>
        </div>
      </div>
      <span class="spacer"></span>
      <div class="spacer">
        <div class="row">
          <mat-form-field>
            <input
              matInput
              placeholder="Primary Email"
              [(ngModel)]="contact.primary_email"
              type="email"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <input
              matInput
              placeholder="Secondary Email"
              [(ngModel)]="contact.secondary_email"
              type="email"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <input
              matInput
              placeholder="Website"
              [(ngModel)]="contact.website"
              type="url"
            />
          </mat-form-field>
        </div>
      </div>
      <span class="spacer"></span>
      <div class="spacer">
        <div class="row">
          <mat-form-field>
            <input
              matInput
              placeholder="facebook"
              [(ngModel)]="contact.facebook"
              type="url"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <input
              matInput
              placeholder="Twitter"
              [(ngModel)]="contact.twitter"
              type="url"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <input
              matInput
              placeholder="Linkedin"
              [(ngModel)]="contact.linkedin"
              type="url"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="show_save" class="row">
    <span class="spacer"></span>
    <button mat-button #save_btn (click)="save(save_btn)">Save</button>
  </mat-card-actions>
</mat-card>
