<mat-card *ngIf="form_errors.detail">
  <div class="detail-not-exist">Record Does Not Exist.</div>
</mat-card>
<div fxLayout="row" *ngIf="!form_errors.detail">
  <span fxFlex fxHide.xs="true"></span>
  <span fxFlex>
    <mat-card class="client-detail">
      <div fxLayout="column">
        <mat-card-header>
          <mat-card-title>
            <h2>{{ title }}</h2>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div fxFlex fxLayout="column">
            <mat-form-field>
              <input
                matInput
                placeholder="First Name"
                [(ngModel)]="employee.first_name"
                maxlength="255"
              />
            </mat-form-field>
            <mat-error *ngIf="form_errors.first_name">{{
              form_errors.first_name
            }}</mat-error>
            <mat-form-field>
              <input
                matInput
                placeholder="Last Name"
                [(ngModel)]="employee.last_name"
                maxlength="255"
              />
            </mat-form-field>
            <mat-error *ngIf="form_errors.last_name">{{
              form_errors.last_name
            }}</mat-error>
            <mat-form-field>
              <input
                matInput
                placeholder="City"
                [(ngModel)]="employee.city"
                maxlength="64"
              />
            </mat-form-field>
            <mat-error *ngIf="form_errors.city">{{
              form_errors.city
            }}</mat-error>
            <mat-form-field>
              <input
                matInput
                mask="(000)-000-0000"
                [showMaskTyped]="true"
                placeholder="Phone"
                [(ngModel)]="employee.phone"
              />
              <mat-hint>Phone Format:(+xxxxxxxxx)</mat-hint>
            </mat-form-field>
            <mat-error
              class="phone-error"
              *ngIf="form_errors.phone && form_errors.phone[0]"
              >{{ form_errors.phone[0] }}</mat-error
            >
            <mat-error *ngIf="form_errors.phone && form_errors.phone[1]">{{
              form_errors.phone[1]
            }}</mat-error>
            <mat-form-field>
              <input
                matInput
                placeholder="E-Mail"
                [(ngModel)]="employee.email"
                type="email"
              />
            </mat-form-field>
            <mat-error *ngIf="form_errors.email">{{
              form_errors.email
            }}</mat-error>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <span fxLayoutAlign="end">
            <button mat-raised-button #save_btn (click)="save(save_btn)">
              Save
            </button>
          </span>
        </mat-card-actions>
      </div>
    </mat-card>
  </span>
  <span fxFlex fxHide.xs="true"></span>
</div>
