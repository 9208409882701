<mat-card class="detail">
  <div *ngIf="deleted" class="detail-not-exist">Record Does Not Exist.</div>
  <div fxLayout="column" *ngIf="!deleted">
    <mat-card-header>
      <div fxFlex fxLayoutAlign="center">
        <mat-card-title>
          <h2>Airport Detail</h2>
        </mat-card-title>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column">
        <div fxFlex="20%">
          <span fxFlex style="word-break: break-all">
            {{ airport.properties.name }}
          </span>
          <span fxFlex fxLayoutAlign="end">
            <button
              mat-icon-button
              matTooltip="Delete Airport Details"
              #delete_airport_btn
              (click)="delete_airport(delete_airport_btn, airport.id)"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <a
              mat-icon-button
              class="anchor"
              [href]="'#/airport/' + airport.id + '/edit'"
            >
              <mat-icon
                [routerLink]="['edit']"
                matTooltip="Edit Airport Details"
              >
                edit
              </mat-icon>
            </a>
          </span>
        </div>
        <div fxFlex="20%">
          <span fxFlex>{{ airport.properties.icao_code }}</span>
          <span fxFlex fxLayoutAlign="end">
            {{ airport.properties.last_updated | date }}
          </span>
        </div>
        <div fxFlex="20%" *ngIf="airport.properties.field_altitude">
          Field Altitude {{ airport.properties.field_altitude }} feet
        </div>
        <app-airport-map
          fxFlex="20%"
          [airport_footprint]="airport.properties.footprint"
          [geojson]="[airport]"
        ></app-airport-map>
        <div fxFlex="20%">
          <div fxLayout="column">
            <mat-tab-group>
              <div fxFlex>
                <mat-tab label="Vendors">
                  FBO and other vendors based at this airport
                </mat-tab>
                <mat-tab label="Missions">
                  list of missions flown or scheduled to fly from this airport
                </mat-tab>
                <mat-tab label="Notes">
                  <notes [content_type]="'airport'" [object_id]="id"></notes>
                </mat-tab>
              </div>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </mat-card-content>
  </div>
</mat-card>
