import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '@app/api/api';

@Component({
  selector: 'route-list',
  templateUrl: './list.component.html',
  styleUrls: ['../component.css']
})
export class RouteListComponent implements OnInit, OnDestroy {
  @Input() show_filters: boolean = true;
  @Input() allow_add: boolean = true;
  @Input() navigate = true;
  @Output() select = new EventEmitter();
  customer_routes = [];
  selected_route = { id: null };
  $customer_route: Subscription;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
    this.get_customer_routes();
  }

  select_route(route) {
    if (!this.navigate) {
      this.selected_route = route;
      this.select.emit(route)
    }
  }

  get_customer_routes() {
    this.$customer_route = this.api.customer_route.list().subscribe((data: any) => {
      this.customer_routes = data.results
    });
  }

  ngOnDestroy () {
    if (this.$customer_route) {
      this.$customer_route.unsubscribe();
    }
  }
}

