<div fxLayout="column">
  <mat-paginator
    [pageSize]="100"
    [length]="totalSize"
    (page)="paginate($event)"
  >
  </mat-paginator>
  <table>
    <tr>
      <td *ngFor="let file of files">
        <table fxFlex>
          <file-list-entry [file]="file" [list]="this"></file-list-entry>
        </table>
      </td>
    </tr>
  </table>
  <div fxFlex="100%" fxLayout="row" *ngIf="allow_upload">
    <span fxFlex fxLayoutAlign="start">
      <span *ngIf="uploading" class="spinner">
        <mat-progress-spinner
          [color]="spinnerColor"
          [mode]="spinnerMode"
          [diameter]="30"
        ></mat-progress-spinner>
      </span>
    </span>
    <span fxFlex fxLayoutAlign="end">
      <button
        mat-icon-button
        #upload_file_btn
        (click)="upload_file(upload_file_btn)"
        matTooltip="Upload"
      >
        <mat-icon>file_upload</mat-icon>
      </button>
    </span>
  </div>
</div>
