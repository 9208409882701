import {
  Component,
  OnInit,
  Input,
  Injectable,
  AfterContentChecked,
  OnDestroy,
  ContentChildren,
  AfterContentInit,
  ViewChild,
  QueryList,
  ChangeDetectorRef,
} from "@angular/core";
import {
  Subscription,
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
} from "rxjs";
import {
  tap,
  debounceTime,
  distinctUntilChanged,
  map,
  filter,
} from "rxjs/operators";
import { ApiService } from "@app/api/api";
import { environment } from "@env/environment";
import { MatLegacyColumnDef as MatColumnDef, MatLegacyTable as MatTable } from "@angular/material/legacy-table";

@Component({
  selector: "app-generic-list",
  templateUrl: "./generic-list.component.html",
  styleUrls: ["./generic-list.component.css"],
})
@Injectable()
export class GenericListComponent
  implements OnInit, AfterContentChecked, OnDestroy, AfterContentInit
{
  @Input() source: (search: Observable<any>) => Observable<any>;
  @Input() displayedColumns: any[] = [];
  @Input() router: string;
  @Input() set params(params: { [id: string]: string }) {
    this.set_search(params);
  }

  @ContentChildren(MatColumnDef) children: QueryList<MatColumnDef>;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  searchParams: BehaviorSubject<{
    [id: string]: string | number;
  }> = new BehaviorSubject<{ [id: string]: string | number }>({
    page_size: environment.default_page_size,
  });

  update: Subject<any> = new Subject();
  filterValues = null;
  error = null;
  data = [];
  totalSize = null;
  items = null;
  pageSizeOptions: number[] = [25, 50, 100];
  spinnerColor = "primary";
  spinnerMode = "indeterminate";
  isLoading = true;
  feature = false;

  component = false;
  generated_columns = [];
  $paginate: Subscription;
  $searchParams: Subscription;
  $source: Subscription;

  set_search(value: { [key: string]: string | number }) {
    this.searchParams.next({ ...this.searchParams.getValue(), ...value });
  }

  set_headers(columns: string[]) {
    this.displayedColumns = columns;
    this.generated_columns = this.displayedColumns.filter(
      (name, _i, _a) =>
        this.children.find((c, _i, _a) => c.name == name) === undefined
    );
    this.children.forEach((child) => {
      this.table.addColumnDef(child);
    });
  }

  load_data() {
    this.update.next(true);
  }

  constructor(
    private api: ApiService,
    private changedetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.$source = this.source(
      combineLatest([
        this.searchParams
          .asObservable()
          .pipe(distinctUntilChanged(), debounceTime(500)),
        this.update.asObservable(),
      ]).pipe(map(([sp, _u]) => sp))
    )
      .pipe(
        tap((e) => {
          this.isLoading = true;
        }),
        filter((e) => e != null)
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.results) {
            this.data = data.results;
            this.totalSize = data.count;
            this.items = data.results.length;
          } else {
            this.data = data;
            this.totalSize = data.length;
            this.items = data.length;
          }
        },
        (error) => {
          console.log(error);
          (this.isLoading = false), (this.error = error);
        }
      );
    this.load_data();
  }

  ngAfterContentInit() {
    this.generated_columns = this.displayedColumns.filter(
      (name, _i, _a) =>
        this.children.find((c, _i, _a) => c.name == name) === undefined
    );
    this.children.forEach((child) => {
      this.table.addColumnDef(child);
    });
  }

  ngAfterContentChecked() {
    if (this.filterValues !== null) {
      this.set_search(this.filterValues);
      this.filterValues = null;
      this.data = null;
    }
  }

  title(str: string): string {
    return str
      .toLowerCase()
      .split("_")
      .map((word) => word.replace(word[0], word[0].toUpperCase()))
      .join(" ");
  }

  paginate(event) {
    this.set_search({ page: event.pageIndex + 1, page_size: event.pageSize });
  }

  ngOnDestroy() {
    if (this.$paginate) {
      this.$paginate.unsubscribe();
    }
    if (this.$source) {
      this.$source.unsubscribe();
    }
  }
}
