import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ContextmenuService } from "@app/services/contextmenu.service";
import { LayerService } from "@app/services/layer.service";
import { Subscription } from "rxjs";
import { ApiService } from "@app/api/api";
import * as L from "leaflet";
import { MeterTooltipComponent } from "@app/common/meter-toolip";
import { TooltipInjector } from "@app/common/map_tools";

import { MapStoreService } from "@app/services/store/map/sidebar/mapstore.service";

@Component({
  selector: "map-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  data = [];
  filtered_airports: any;
  filtered_meters = [];
  layer: any;
  error_message: string;
  $meter: Subscription;
  selected_tab = 0;

  @Input() search_meter_layer: any;

  constructor(
    private data_service: ContextmenuService,
    private api: ApiService,
    private layer_service: LayerService,
    private tooltip_injector: TooltipInjector,
    private map_store: MapStoreService
  ) {}

  ngOnInit() {
    this.map_store
      .get_current_tab()
      .subscribe((tab) => (this.selected_tab = tab));
    this.data_service.data$.subscribe((line: any) => {
      this.filtered_airports = line;
    });
    this.data_service.meterdata$.subscribe((res: any) => {
      this.filtered_meters = res;
    });
    this.data_service.metermsg$.subscribe((res: string) => {
      this.error_message = res;
    });
  }

  tab_change(event) {
    this.selected_tab = event;
    this.map_store.change_tab(event);
  }

  zoomtometer(serial) {
    if (this.layer) {
      this.layer_service.delete_layer("meter_group", this.layer);
    }
    this.$meter = this.api.meter
      .list({ serial: serial })
      .subscribe((res: any) => {
        if (res.count > 0) {
          res.features["0"].showpop = true;
          this.layer = this.create_layer(res);
          this.layer_service.add_layer("meter_group", this.layer);
          this.layer_service.zoom_layer("meter_group", this.layer);
          this.layer.options.pane = "tooltipPane";
        }
      });
  }

  create_layer(data) {
    return L.geoJson(data, {
      onEachFeature: this.tooltip_injector
        .InjectPopupComponent(MeterTooltipComponent)
        .bind(this),
    });
  }

  clear_list() {
    if (this.filtered_airports) {
      this.filtered_airports = null;
    }
    if (this.filtered_meters.length > 0) {
      this.filtered_meters = [];
      if (this.layer) {
        this.layer_service.delete_layer("meter_group", this.layer);
      }
    }
    if (this.error_message) {
      this.error_message = null;
    }
  }

  ngOnDestroy() {
    if (this.$meter) {
      this.$meter.unsubscribe();
    }
  }
}
