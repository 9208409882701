<mat-card>
  <mat-card-header>
    <mat-card-title class="row">
      <span class="spacer"></span>
      <mat-spinner *ngIf="loading"></mat-spinner>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-card>
      <mat-card-content>
        <mat-form-field>
          <textarea
            matInput
            class="note_edit"
            #ta
            [(ngModel)]="note.note"
            [rows]="ta.lines||1"
            cols="10"
            (focus)="ta.lines=5"
          ></textarea
          ><br />
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions class="row">
        <span class="spacer"></span>
        <button
          *ngIf="ta.lines>1"
          mat-icon-button
          matTooltip="Post"
          #save_note_btn
          (click)="save_note(save_note_btn);ta.lines=1"
        >
          <mat-icon>forward</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
    <div *ngFor="let n of notes">
      <mat-card #note_card>
        <mat-card-header>
          <mat-card-title class="row">
            <span class="author">{{n.created_by}}</span>
          </mat-card-title>
          <mat-card-subtitle class="row">
            <span class="post-date">{{n.created | date}}</span>
            <span class="spacer"></span>
            <button
              mat-icon-button
              (click)="note_card.edit_mode=!note_card.edit_mode"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="!note_card.edit_mode">{{n.note}}</div>
          <div *ngIf="note_card.edit_mode">
            <textarea
              class="note_edit"
              #ta
              [(ngModel)]="n.note"
              rows="5"
            ></textarea
            ><br />
            <div class="row">
              <span class="spacer"></span>
              <button
                mat-icon-button
                matTooltip="Post"
                #save_note_btn
                (click)="note_card.edit_mode=!save_note(save_note_btn,n.id)"
              >
                <mat-icon>forward</mat-icon>
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>
