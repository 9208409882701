<mat-list>
  <mat-list-item *ngFor="let group of groups" class="row">
    <metergroup-item [group]="group" [list]="this"></metergroup-item>
  </mat-list-item>
  <div class="row">
    <span class="spacer"></span>
    <mat-icon
      class="clickable-mat-icon"
      matTooltip="Add Meter Group"
      *ngIf="add_fn"
      #add_group_btn
      (click)="add_group(add_group_btn)"
      >add</mat-icon
    >
  </div>
</mat-list>
