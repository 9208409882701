import {
  Directive,
  Input,
  OnChanges,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  Output,
  EventEmitter,
} from "@angular/core";

import { DailyFlightsWidget } from "./widgets/dailyflights.widget";
import { DailyMissionsWidget } from "./widgets/dailymissions.widget";
import { BaseWidget } from "./widgets/base.widget";

const components = {
  dailyflights: DailyFlightsWidget,
  dailymissions: DailyMissionsWidget,
};

@Directive({
  selector: "[dashboardWidget]",
})
export class DashboardWidgetDirective implements OnChanges {
  @Input("dashboardWidget") componentRef: string;
  @Input() config;
  @Output() title = new EventEmitter();
  component: ComponentRef<BaseWidget>;

  constructor(
    private container: ViewContainerRef,
    private resolver: ComponentFactoryResolver
  ) {}
  ngOnChanges(): void {
    const component = components[this.componentRef];
    if (component) {
      const factory = this.resolver.resolveComponentFactory<BaseWidget>(
        component
      );
      this.component = this.container.createComponent(factory);
      this.component.instance.config = this.config;
      this.component.instance.title.subscribe((e) => {
        this.title.emit(e);
      });
    }
  }
}
