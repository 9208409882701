<mat-card>
    <mat-card-header fxLayout="row">
        <span fxFlex class="header_label" fxLayoutAlign="middle">User Profiles</span>
    </mat-card-header>

    <mat-divider fxLayout="row"></mat-divider>

    <mat-card-content>
        <div *ngIf="data != null">
            <div *ngFor="let key of data['tarnished_lamp'] | keyValuePipe">
                {{key.key}} : {{key.val}}
            </div>
        </div>
    </mat-card-content>
</mat-card>
