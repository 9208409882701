<div class="generic-list-main" fxFlex fxFlexFill fxLayout="column">
  <div
    class="example-container mat-elevation-z8"
    fxFlex
    fxFlexFill
    fxLayout="column"
  >
    <div class="generic-list-data" fxFlex="grow" style="overflow-y: auto">
      <div *ngIf="error">{{ error }}</div>
      <mat-table #table [dataSource]="data" [class.isMobile]>
        <ng-container
          *ngFor="let col of generated_columns"
          [cdkColumnDef]="col"
        >
          <mat-header-cell *cdkHeaderCellDef>{{ title(col) }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row; let i = index">
            <span class="mobile-label" *ngIf="col != 'anchor'">{{ col }}:</span>
            <div *ngIf="col != 'anchor'">{{ row[col] }}</div>
            <div *ngIf="feature && col != 'anchor'">
              {{ row.properties[col] }}
            </div>
            <a
              *ngIf="col == 'anchor'"
              [href]="'#/' + router + '/' + row.id"
            ></a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns; let i = index"
          [routerLink]="['/', router, row.id]"
        ></mat-row>
      </mat-table>

      <mat-card *ngIf="isLoading" class="spinner">
        <mat-progress-spinner
          [color]="spinnerColor"
          [mode]="spinnerMode"
          [diameter]="50"
        >
        </mat-progress-spinner>
      </mat-card>
    </div>
    <div fxFlex>
      <mat-paginator
        [pageSize]="items"
        [length]="totalSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="paginate($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
