import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";

import { CustomerService } from "@app/api/customer/customer.service";
import { CG_CommonModule } from "@app/common/module";
import { ContactModule } from "@app/contact/module";
import { NoteModule } from "@app/note/module";
import { MaterialModule } from "@app/material.module";
import { DialogModule } from "@app/dialog/dialog.module";
import { TaskService } from "@app/task/task.service";
import { CustomerJobDetailComponent } from "./detail/detail.component";
import { AnalysisDetailComponent } from "./detail/analysis-detail";
import { CustomerJobListComponent } from "./list/list.component";
import { CustomerJobFormComponent } from "./form/form.component";
import { JobResultsDetailComponent } from "./results/detail.component";
import { ResultAnalysisComponent } from "./results/analysis.component";
import { ReplacePipe } from "@app/common/pipes";
import { MeterReportDetailComponent } from "./report/meter.report";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    DialogModule,
    CG_CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ContactModule,
    NoteModule,
    RouterModule,
    FlexLayoutModule,
  ],
  exports: [
    CustomerJobDetailComponent,
    CustomerJobListComponent,
    CustomerJobFormComponent,
    JobResultsDetailComponent,
    ResultAnalysisComponent,
    AnalysisDetailComponent,
    MeterReportDetailComponent,
  ],
  providers: [CustomerService, TaskService],
  declarations: [
    CustomerJobDetailComponent,
    CustomerJobListComponent,
    CustomerJobFormComponent,
    JobResultsDetailComponent,
    ResultAnalysisComponent,
    ReplacePipe,
    AnalysisDetailComponent,
    MeterReportDetailComponent,
  ],
})
export class CustomerJobComponents {}
