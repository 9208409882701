import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import * as L from "leaflet";
import { combineLatest, interval, Subject, Subscription } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { environment } from "@env/environment";
import { LayerService } from "@app/services/layer.service";
import { ApiService } from "@app/api/api";
import { MeterTooltipComponent } from "@app/common/meter-toolip";
import { TooltipInjector } from "@app/common/map_tools";
import { MapStoreService } from "@app/services/store/map/sidebar/mapstore.service";
import { MissionState } from "@app/store/map/sidebar/map_reducer";
import { Mission } from "@app/api/types";
import { SubSink } from "subsink";

interface LayerState {
  layer: L.Layer;
  visible: boolean;
  update?: any;
}

@Component({
  selector: "map-mission",
  templateUrl: "./mission.map.component.html",
  styleUrls: ["./missions.component.css"],
})
export class MissionMapComponent implements OnInit, OnDestroy {
  _mission_state: MissionState;
  @Input("mission") set mission_state(value: MissionState) {
    this._mission_state = value;
  }
  get mission_state(): MissionState {
    return this._mission_state;
  }
  mission: Mission = {
    id: 0,
    label: "",
    mission_area: null,
    flights: [],
    routes: [],
    start_date: null,
    end_date: null,
    mission_id: null,
    mission_type: null,
    mission_status: null,
  };
  get mission_id(): number {
    return this.mission_state.mission.mission_id;
  }
  get display() {
    return this.mission_state.display;
  }
  set display(value) {
    this.mapstore.show_mission(this.mission_id, value);
    this.update_layers();
  }
  get received() {
    return this.mission_state.hide_recieved;
  }
  set received(value) {
    this.mapstore.show_mission_received(this.mission_id, value);
    this.update_layers();
  }
  get area() {
    return this.mission_state.show_area;
  }
  set area(value) {
    this.mapstore.show_mission_area(this.mission_id, value);
    this.update_layers();
  }

  layer_state: { [id: string]: LayerState } = {};
  subsink = new SubSink();
  constructor(
    private api: ApiService,
    private layer_service: LayerService,
    private tooltip_injector: TooltipInjector,
    private mapstore: MapStoreService
  ) {}

  ngOnInit() {
    this.api.mission.detail(this.mission_id).subscribe((mission) => {
      this.mission = mission;
      this.update_layers();
    });
    this.subsink.sink = combineLatest([
      this.mapstore.get_mission_flights(this.mission_id),
      this.mapstore.get_mission_routes(this.mission_id),
    ]).subscribe((_) => {
      this.update_layers();
    });
  }

  blackhole(event) {
    event.stopPropagation();
  }

  expanded(state: boolean) {
    if (state != this.mission_state.expanded) {
      this.mapstore.expand_mission(this.mission_id, state);
    }
  }

  update_route_areas(route) {
    const id = "route_" + route.id + "_area";
    let state = this.layer_state[id];
    if (state && state.layer) {
      if (this.display && route.area) {
        state.visible = true;
        this.layer_service.add_layer("mission", state.layer);
      } else {
        state.visible = true;
        this.layer_service.delete_layer("mission", state.layer);
      }
    } else if (this.display && route.area) {
      this.api.customer_route.detail(route.id).subscribe((res: any) => {
        state = {
          visible: true,
          layer: res.service_area
            ? L.GeoJSON.geometryToLayer(res.service_area)
            : null,
        };
        if (state.layer) {
          this.layer_service.add_layer("mission", state.layer);
        }
        this.layer_state[id] = state;
      });
    }
  }

  update_route_meters(route) {
    const id = "route_" + route.id + "_meters";
    let state = this.layer_state[id];
    if (state && state.layer) {
      if (this.display && route.meters) {
        state.visible = true;
        this.layer_service.add_layer("meter_group", state.layer);
      } else {
        this.layer_service.delete_layer("meter_group", state.layer);
        state.visible = true;
        state.layer = null;
      }
    } else if (this.display && route.meters) {
      state = { visible: true, layer: null };
      this.add_meters(route, state);
      this.layer_state[id] = state;
    }
  }

  add_meters(route, state, page = null) {
    this.subsink.sink = this.api.meter
      .list({
        route_id: route.id,
        page: page,
        page_size: environment.default_meter_page_size,
      })
      .subscribe((res: any) => {
        if (state.layer) {
          this.layer_service.delete_layer("meter_group", state.layer);
          state.layer.addData(res);
          this.layer_service.add_layer("meter_group", state.layer);
        } else {
          state.layer = this.create_layer(res);
          this.layer_service.add_layer("meter_group", state.layer);
          page = 1;
        }
        if (res.next) {
          this.add_meters(route, state, page + 1);
        }
      });
  }

  create_layer(data) {
    return L.geoJson(data, {
      onEachFeature: this.tooltip_injector
        .InjectPopupComponent(MeterTooltipComponent)
        .bind(this),
    });
  }

  update_mission_area() {
    const id = "mission_" + this.mission.id + "_area";
    let state = this.layer_state[id];
    if (state && state.layer) {
      if (this.mission_state.display && this.mission_state.show_area) {
        state.visible = true;
        this.layer_service.add_layer("mission", state.layer);
      } else {
        state.visible = true;
        this.layer_service.delete_layer("mission", state.layer);
      }
    } else if (this.mission_state.display && this.mission_state.show_area) {
      state = {
        visible: true,
        layer: this.mission.mission_area
          ? L.GeoJSON.geometryToLayer(this.mission.mission_area)
          : null,
      };
      if (state.layer) {
        this.layer_service.add_layer("mission", state.layer);
      }
      this.layer_state[id] = state;
    }
  }

  update_flight_plan(flight) {
    const id = "flight_" + flight.id + "_plan";
    let state = this.layer_state[id];
    if (state && state.layer) {
      if (this.display && flight.plan) {
        state.visible = true;
        this.layer_service.add_layer("mission", state.layer);
      } else {
        state.visible = true;
        this.layer_service.delete_layer("mission", state.layer);
      }
    } else if (this.display && flight.plan) {
      this.api.flight.plan(flight.id).subscribe((res: any) => {
        const data =
          Object.entries(res).length === 0 && res.constructor === Object;
        state = {
          visible: true,
          layer: !data ? L.GeoJSON.geometryToLayer(res) : null,
        };
        if (state.layer) {
          this.layer_service.add_layer("mission", state.layer);
        }
        this.layer_state[id] = state;
      });
    }
  }

  update_flight_track(flight) {
    const id = "flight_" + flight.id + "_area";
    let state = this.layer_state[id];
    if (state) {
      if (this.display && flight.track) {
        state.visible = true;
        this.layer_service.add_layer("mission", state.layer);
      } else {
        state.visible = true;
        this.layer_service.delete_layer("mission", state.layer);
      }
    } else if (this.display && flight.area) {
      this.api.flight.track(flight.id).subscribe((res: any) => {
        state = {
          visible: true,
          layer: L.GeoJSON.geometryToLayer(res),
        };
        if (flight.active) {
          state.update = interval(30 * 1000)
            .pipe(
              takeUntil(
                this.api.flight
                  .detail(flight.id)
                  .pipe(filter((res) => !res.active))
              )
            )
            .subscribe((res: any) => {
              this.api.flight.track(flight.id).subscribe((res: any) => {
                if (this.display && flight.track) {
                  this.layer_service.delete_layer("mission", state.layer);
                }
                state.layer = L.GeoJSON.geometryToLayer(res);
                if (this.display && flight.track) {
                  this.layer_service.add_layer("mission", state.layer);
                }
              });
            });
        }
        this.layer_state[id] = state;
      });
    }
  }

  update_layers() {
    for (let item of Object.values(this.mission_state.mission.routes)) {
      this.update_route_areas(item);
      this.update_route_meters(item);
    }
    for (let item of Object.values(this.mission_state.mission.flights)) {
      this.update_flight_plan(item);
      this.update_flight_track(item);
    }
    this.update_mission_area();
    this.zoom_to_mission();
  }

  zoom_to_mission() {
    if (this.display) {
      let layer: any = "mission_" + this.mission.id + "_area";
      if (this.layer_state[layer]) {
        layer = this.layer_state[layer].layer;
        this.layer_service.zoom_layer("mission", layer);
      }
    }
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
