<div *ngIf="error">
  {{error.detail}}
</div>
<mat-card class="clientjob-detail" *ngIf="!error">
  <mat-card-header class="row">
    <mat-card-title class="row">
      <span class="header_label">{{job.customer_name}}</span>
      <span class=spacer></span>
      <span class="header_label">{{job.name}}</span>
      <span class=spacer></span>
      <span class=spacer></span>
      <span>
        <mat-icon (click)='makelink()' matTooltip='Click to Copy link to Clipboard'>link</mat-icon>
        {{job.id}}
        </span>
    </mat-card-title>
    <mat-card-subtitle class="row">
      <span>{{job.id}}</span>
      <span class="spacer"></span>
      <span>Last Modified: {{job.last_updated |date}}</span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <button mat-raised-button (click)='create_results_archive(job?.results[0])'>Download Results archive</button>
    <mat-tab-group>
      <mat-tab label='Result Analysis'>
        <div>
          <result-analysis [job_result]='job_result' [data_logging]='job.data_logging'></result-analysis>
        </div>
      </mat-tab>

      <mat-tab label='Result Files'>
        <div>
          <file-list [object_id]="result?.id" [content_type]="'jobresult'" [allow_upload]=false #files></file-list>
        </div>
      </mat-tab>

      <mat-tab label="Notes">
        <notes [object_id]="id" [content_type]="'customerjob'"></notes>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
