import { CustomerDetailComponent } from './detail/detail.component';
import { CustomerListComponent } from './list/list.component';
import { CustomerFormComponent } from './form/form.component';
import { CustomerComponent } from './customer.component';
import { AuthGuard } from '@app/common/auth.guard';

export const CustomerRoute = {
  path: 'customer',
  component: CustomerComponent,
  canActivate: [AuthGuard],
  children: [
    {path: '', component: CustomerListComponent},
    {path: 'new', component: CustomerFormComponent},
    {path: ':id', component: CustomerDetailComponent},
    {path: ':id/edit', component: CustomerFormComponent},
  ]
};
