import { Component, OnInit, Input } from "@angular/core";
import { DashboardItem } from "./layout.service";

@Component({
  selector: "<dashboard-widget>",
  template: `
    <mat-card fxFlex fxFlexFill fxLayout="column">
      <mat-card-title fxFlexAlign="start">{{ title }}</mat-card-title>
      <mat-card-content style="overflow-y:auto" fxFlex="grow">
        <ng-container
          [dashboardWidget]="widget.widget"
          (title)="set_title($event)"
        >
        </ng-container>
      </mat-card-content>
      <mat-card-footer fxFlexAlign="end" style="padding-right:25px">
        footer
      </mat-card-footer>
    </mat-card>
  `,
  host: {
    style: "display:flex;flex-flow:column;flex:1 1 auto;height:100%",
  },
})
export class DashboardWidget implements OnInit {
  @Input() widget: DashboardItem = null;
  title: string = "Dashboard Widget";
  ngOnInit() {}

  set_title(title: string) {
    // console.log(`Title updated to ${title} `);
    this.title = title;
  }
}
