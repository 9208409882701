<mat-card>
  <div fxLayout="column">
    <mat-card-title fxLayout="row">
      <span fxFlex> Payload Assignments for {{ payload?.name }} </span>
      <span fxFlex fxLayoutAlign="end">
        <button
          *ngIf="payload"
          mat-icon-button
          matTooltip="Add Assignment"
          #add_assignment_btn
          (click)="add_assignment(add_assignment_btn)"
        >
          <mat-icon>add</mat-icon>
        </button>
      </span>
    </mat-card-title>
    <mat-card-content fxLayout="row">
      <mat-table
        [dataSource]="assignments"
        fxFlex
        style="margin-top: 1%"
        [class.isMobile]
      >
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.id }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef> Assigned </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.started | date : "medium" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="aircraft">
          <mat-header-cell *matHeaderCellDef> Aircraft </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.aircraft_registration }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expired">
          <mat-header-cell *matHeaderCellDef> Unassigned </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span *ngIf="row.expired">{{ row.expired | date : "medium" }}</span>
            <span *ngIf="!row.expired">
              <button
                mat-icon-button
                matTooltip="End Assignment"
                #end_assignment_btn
                (click)="end_assignment(end_assignment_btn, row.aircraft)"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="raw">
          <mat-header-cell *matHeaderCellDef> Raw </mat-header-cell>
          <mat-cell *matCellDef="let row" style="word-break: break-all">
            {{ row | json }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="unassign">
          <mat-header-cell *matHeaderCellDef> Unassign </mat-header-cell>
          <mat-cell *matCellDef="let row" style="word-break: break-all">
            <span class="mobile-label">Unassign:</span>
            Unassign control goes here
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </mat-card-content>
  </div>
</mat-card>
