import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  EventEmitter,
  Output,
} from "@angular/core";
import { environment } from "@env/environment";
import * as L from "leaflet";
import { point } from "leaflet";
import { TooltipInjector } from "./map_tools";

@Component({
  selector: "default-info-component",
  template: `
    <div><b>Default Point Information</b></div>
    <div class="row">
      ID
      <span class="spacer"></span>{{ feature.id }}
    </div>
    <div class="row">
      Coordinates
      <span class="spacer"></span>{{ feature.geometry.coordinates.join(", ") }}
    </div>
    <div>
      <pre> {{ feature.properties | json }}</pre>
    </div>
  `,
})
export class DefaultInfoComponent {
  @Input() feature: any;
}

@Component({
  selector: "point-map",
  template: `<link
      href="https://unpkg.com/leaflet@1.0.3/dist/leaflet.css"
      rel="stylesheet"
    />
    <div #map_div id="point_map"></div>`,
  styles: [
    "#point_map {min-height:200px !important;min-width:200px;max-height:600px;height:100%;}",
  ],
})
export class PointMap implements OnInit, OnDestroy {
  @Input("min_zoom") min_zoom: number = null;
  @Input("max_zoom") max_zoom: number = null;
  @Output("map_click") mapclick: EventEmitter<typeof L.DomEvent> =
    new EventEmitter<typeof L.DomEvent>();
  @Output() pointChange = new EventEmitter();

  _points = null;
  _point: L.LatLng = new L.LatLng(null, null);
  layer: L.GeoJSON = null;
  marker: L.Marker = null;
  map: L.Map = null;
  @ViewChild("map_div", { static: true }) mapContainer;

  constructor(private tooltip_injector: TooltipInjector) {}

  ngOnInit() {
    this.map = L.map(this.mapContainer.nativeElement, {
      minZoom: this.min_zoom,
      maxZoom: this.max_zoom,
    }).setView([54.5, -115.0], 13);
    this.map.on("click", this.map_click.bind(this));
    L.tileLayer(environment.osm_server, {
      maxZoom: 18,
      attribution: "",
      noWrap: true,
    }).addTo(this.map);
    this.add_layer();
  }

  map_click(e: any): void {
    this.mapclick.emit(e);
    if (!this.points) {
      this.point = e.latlng;
    }
  }

  add_layer(): void {
    if (this.map && this.layer) {
      const bounds = this.layer.getBounds();
      this.layer.addTo(this.map);
      if (bounds.isValid()) {
        this.map.fitBounds(bounds);
      } else {
        this.map.fitBounds(
          L.latLngBounds([48.734816, -120.508165], [60.129941, -109.7384207])
        );
      }
    }
  }

  @Input("points")
  set points(value) {
    this._points = value;
    this.layer = L.geoJson(value, {
      onEachFeature: this.tooltip_injector
        .InjectPopupComponent(DefaultInfoComponent)
        .bind(this),
    });
    this.add_layer();
  }

  get points() {
    return this._points;
  }

  @Input("point")
  set point(value: L.LatLng) {
    this._point = value;
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    if (value && this.map) {
      this.marker = new L.Marker(value).addTo(this.map);
      this.map.panTo(value);
    }
    this.pointChange.emit(this._point);
  }

  get point() {
    return this._point;
  }

  @Input("resize")
  set resize(value) {
    if (value) {
      this.map.invalidateSize({});
    }
  }

  ngOnDestroy() {}
}
