import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

interface CustomerRoute {
  id?: number;
  name: string;
  meter_group: number;
  service_area?: any;
  [id: string]: any;
}

@Component({
  selector: "route-form",
  templateUrl: "./form.component.html",
  styleUrls: ["../component.css"],
})
export class RouteFormComponent implements OnInit, OnDestroy {
  @ViewChild("map", { static: false }) route_map;

  customer_route: CustomerRoute = {
    name: "",
    meter_group: null,
    customer: null,
    description: "",
    service_area: false,
  };
  @Input() actions: boolean = true;
  id: number;
  _customer = new BehaviorSubject<any>(null);
  meter_groups = [];
  meter_groups_loading = false;
  errors = { service_area: null, non_field_error: null, detail: null };
  $customer_route: Subscription;
  $customer: Subscription;
  $meter_group: Subscription;

  set customer(value) {
    this._customer.next(value);
  }

  get customer() {
    return this._customer.getValue();
  }

  set selected_meter_group(value) {
    this.customer_route.meter_group = value;
    if (this.customer_route.name.length === 0) {
      const mg = this.meter_groups.find((e) => {
        return e.id === value;
      });
      if (mg) {
        this.customer_route.name = mg.group_name;
      }
    }
  }

  get selected_meter_group() {
    return this.customer_route.meter_group;
  }

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private api: ApiService,
    private errorHandleService: ErrorHandleService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      if (this.id) {
        this.errors.detail = null;
        this.$customer_route = this.api.customer_route
          .detail(this.id)
          .subscribe({
            next: (res: any) => {
              this.customer_route = res;
              this.$customer = this.api.customer.detail(res.customer).subscribe(
                (res: any) => {
                  this.customer = res;
                },
                (error) => {
                  console.error("error customer", error);
                }
              );
            },
            error: (errors) => {
              this.errors = errors.error;
              console.error("customer_route: ", errors);
            },
          });
      }
    });

    this._customer.asObservable().subscribe((customer) => {
      if (customer && customer.hasOwnProperty("id")) {
        this.customer_route.customer = customer.id;
        this.meter_groups_loading = true;
        this.meter_groups = [];
        this.$meter_group = this.api.meter_group
          .customer_groups(customer.id)
          .subscribe((data: any) => {
            this.meter_groups = data.results;
            this.meter_groups_loading = false;
          });
      }
    });
  }

  test(button: HTMLButtonElement) {
    let sa = this.route_map.service_area;
    if (sa.type === "Polygon") {
      sa.type = "MultiPolygon";
      sa.coordinates = [[sa.coordinates]];
    }
  }

  save(button: HTMLButtonElement) {
    this.customer_route.service_area = this.route_map.service_area;
    let call = this.api.customer_route.create(this.customer_route);
    if (this.id) {
      call = this.api.customer_route.update(this.id, this.customer_route);
    }
    button.disabled = true;
    call.subscribe({
      next: (res: any) => {
        this.location.back();
      },
      error: (errors) => {
        if (!errors.status) {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. If this keeps happening please contact support."
          );
        }
        this.errors = errors.error;
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    if (this.$customer_route) {
      this.$customer_route.unsubscribe();
    }
    if (this.$customer) {
      this.$customer.unsubscribe();
    }
    if (this.$meter_group) {
      this.$meter_group.unsubscribe();
    }
  }
}
