import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../api/api';
import { Subscription } from 'rxjs';

@Component({
  selector: "meter-popup",
  template: `<section class="popup">
                <div>Serial: {{ data.properties.serial }}</div>
                <div>Market: {{ data.properties.market_name }}</div>
                <div>Customer: {{ customer_name }}</div>
                <div>Created: {{ data.properties.created | date:'short' }}</div>
                <div>Updated: {{ data.properties.last_updated| date:'short' }}</div>
                <div>LatLng: {{ data.geometry.coordinates['1']|number:'1.5-7' }} ,{{ data.geometry.coordinates['0']|number:'1.5-7'}}</div>
                <div *ngIf="data.properties.extra_data">
                  <div *ngIf="data.properties.extra_data?.address">Address: {{  data.properties.extra_data?.address }}</div>
                  <div *ngIf="data.properties.extra_data?.location">Location: {{  data.properties.extra_data?.location }}</div>
                  <div style="white-space:pre-line;" *ngIf="data.properties.extra_data?.comment">
                    Comment: {{  data.properties.extra_data?.comment }}
                  </div>
                </div>
                <div *ngIf="!name">
                    <button mat-icon-button [routerLink]="['/','meter_reading',data.id]" matTooltip="view previous receptions">
                      View Previous Receptions <mat-icon>launch</mat-icon>
                    </button>
                </div>
  </section>`,
  styles: ['section{display: contents !important;}  .popup{width: 200px; height: 100px;}'],

})
export class MeterTooltipComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() name: any;
  customer_name: string = '';
  $customer: Subscription;
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.$customer = this.api.customer.detail(this.data.properties.customer).subscribe(
      (res: any) => { this.customer_name = res.name; }
    )
  }
  ngOnDestroy() {
    if (this.$customer) {
      this.$customer.unsubscribe();
    }
  }
}

@Component({
  selector: "reading-popup",
  template: `<section class="popup">
                <div>ID: {{ data.id }}</div>
                <div>Created: {{ data.created|date:'short' }}</div>
                <div>Location: {{ data.location.coordinates['1']|number:'1.5-7' }}, {{ data.location.coordinates['0']|number:'1.5-7'}}</div>
                <div>Altitude: {{ data.altitude }} ft asl</div>
                <div>Heading: {{ data.heading }}</div>
                <div>Strength: {{ data.strength|number:'1.3-5' }}</div>
  </section>`,
  styles: ['section{display: contents !important;}  .popup{width: 200px; height: 100px;}'],

})
export class MeterReadingTooltipComponent implements OnInit {
  @Input() data: any;

  ngOnInit() { }
}
