<mat-spinner *ngIf="loading"></mat-spinner>

<mat-card *ngIf="!loading">
  <div *ngIf="deleted" class="detail-not-exist">Record Does Not Exist</div>
  <mat-card-content *ngIf="!deleted">
    <div fxLayout="row">
      <span fxFlex>{{ mission.mission_type }} {{ mission.label }}</span>
      <span fxLayoutAlign="end">
        <div class="mission-status">
          <span *ngIf="mission.mission_status == 'Complete'">
            {{ mission.mission_status }}
          </span>
          <span *ngIf="mission.mission_status == 'Canceled'">
            {{ mission.mission_status }}
          </span>
          <span *ngIf="mission.mission_status == 'In Progress'">
            {{ mission.mission_status }}
          </span>
        </div>
        <button
          mat-icon-button
          matTooltip="Delete Mission Details"
          #delete_mission_btn
          (click)="delete_mission(delete_mission_btn, mission.id)"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <a
          mat-icon-button
          class="anchor"
          [href]="'#/mission/' + mission.id + '/edit'"
        >
          <mat-icon [routerLink]="['edit']" matTooltip="Edit Mission Details">
            edit
          </mat-icon>
        </a>
      </span>
    </div>

    <div fxLayout="row" fxLayoutAlign="end">
      <div fxLayout="column" fxLayoutAlign="end">
        <div>
          <span class="text-muted">start date :</span>
          {{ mission.start_date | date }}
        </div>
        <div>
          <span class="text-muted">end date :</span>
          {{ mission.end_date | date }}
        </div>
        <div>
          <span class="text-muted">last update :</span>
          {{ mission.last_updated | date }}
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around" fxLayout.xs="column">
      <button
        fxFlex="30%"
        mat-raised-button
        #schedule_flight_btn
        (click)="schedule_flight(schedule_flight_btn)"
      >
        Schedule Flight
      </button>
      <button
        fxFlex="30%"
        mat-raised-button
        #complete_mission_btn
        (click)="complete_mission(complete_mission_btn)"
      >
        Complete Mission
      </button>
    </div>

    <mat-tab-group (selectedTabChange)="onTabSelect($event)">
      <!-- Display for unknown missions-->
      <ng-template [ngIf]="mission_type == mission_types.unknown">
        <mat-tab label="Project">
          <p></p>
          <div fxLayout="row" fxLayoutAlign="space-around" fxLayout.xs="column">
            <button
              fxFlex="30%"
              mat-raised-button
              #add_customer_job_btn
              (click)="add_customer_job(add_customer_job_btn)"
            >
              Add Customer Job
            </button>
            <button
              fxFlex="30%"
              mat-raised-button
              #add_imagery_project_btn
              (click)="add_imagery_project(add_imagery_project_btn)"
            >
              Add Imagery Project
            </button>
          </div>
        </mat-tab>
      </ng-template>

      <!-- Image Mission specific Things-->
      <ng-template [ngIf]="mission_type == mission_types.imagery">
        <mat-tab label="Projects">
          <imageryproject-list
            #projects
            [search]="{ mission: mission.id }"
            [show_filters]="false"
            [allow_add]="false"
          >
          </imageryproject-list>
          <div fxLayout="row" fxLayoutAlign="space-around" fxLayout.xs="column">
            <button
              fxFlex="30%"
              mat-raised-button
              #add_imagery_project_btn
              (click)="add_imagery_project(add_imagery_project_btn)"
            >
              Add Imagery Project
            </button>
          </div>
        </mat-tab>
        <mat-tab label="Mission Area">
          <route-map
            #map
            [service_area]="mission.mission_area"
            [newpoly]="true"
          >
          </route-map>
          <button
            mat-raised-button
            #update_mission_area_btn
            (click)="update_mission_area(update_mission_area_btn)"
          >
            Update Mission Area
          </button>
        </mat-tab>
      </ng-template>

      <!-- Meter Mission specific Things-->
      <ng-template [ngIf]="mission_type == mission_types.meter">
        <mat-tab *ngIf="mission.routes.length > 0" label="Routes">
          <mission-routes
            [routes]="routes"
            [(selected_routes)]="selected_routes"
          >
          </mission-routes>
        </mat-tab>
        <mat-tab *ngIf="mission.mission_area" label="Mission Area">
          <route-map
            #map
            [service_area]="mission.mission_area"
            [newpoly]="false"
          >
          </route-map>
        </mat-tab>
        <mat-tab label="Jobs">
          <customerjob-list [mission]="id"></customerjob-list>
          <div fxLayout="row">
            <span fxFlex fxLayoutAlign="end">
              <button
                mat-raised-button
                #process_jobs_btn
                (click)="process_jobs(process_jobs_btn)"
              >
                Process Job Data
              </button>
            </span>
          </div>
        </mat-tab>
      </ng-template>
      <!--<mat-tab label="Waypoints">
          <waypoints [mission]="id" [resize]="mapRequiresResize"></waypoints>
        </mat-tab>-->

      <mat-tab *ngIf="mission.flights?.length > 0" label="Flights">
        <flight-list
          #flights
          [search]="{ mission: mission.id }"
          [show_filters]="false"
          [allow_add]="false"
        ></flight-list>
      </mat-tab>
      <mat-tab label="Files">
        <file-list
          [object_id]="id"
          [content_type]="'mission'"
          #files
        ></file-list>
      </mat-tab>
      <mat-tab label="Notes">
        <notes [object_id]="id" [content_type]="'mission'"></notes>
      </mat-tab>
      <mat-tab label="Raw">
        <pre>
          {{ mission | json }}
        </pre>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
