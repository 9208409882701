import { Injectable } from '@angular/core';
import { BaseApiService } from '@app/api/base_api';
import { contentHeaders } from '@app/common/headers';
import { Mission } from '../types';

@Injectable()
export class MissionService extends BaseApiService<Mission> {
  component: string = 'mission';

  search(params) {
    return this.list(params);
  }
  calculate_route(mission: number) {
    console.warn('Deprecated call to calculate route');
    // return this.http_client.post(this.function_url('calculate_route', mission), {}, {headers : contentHeaders()})
    return this.null()
  }

  process_job_data(mission: number) {
    return this.http_client.post(
      this.function_url('process_job_data', mission), {}, { headers: contentHeaders() })
  }

  generate_location_request(mission: number) {
    return this.http_client.post(
      this.function_url('generate_location_request', mission), {}, { headers: contentHeaders() })
  }

  process_location_result(mission: number, file_id: number) {
    return this.http_client.post(
      this.function_url('process_location_result', mission), { id: file_id }, { headers: contentHeaders() }
    )
  }

  add_flight(mission: number, flight_id: number) {
    return this.http_client.post(
      this.function_url('add_flight', mission), { flight_id: flight_id }, { headers: contentHeaders() }
    )
  }

  request_flight(mission: number) {
    return this.http_client.post(
      this.function_url('request_flight', mission), {}, { headers: contentHeaders() }
    )
  }

  complete(mission: number) {
    return this.http_client.post(
      this.function_url('complete', mission), {}, { headers: contentHeaders() }
    )
  }
}
