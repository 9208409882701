<div class="wrapper">
  <header>
    <mat-toolbar color="primary" fxLayout="row">
      <span fxFlex>Flight Leg Record</span>
      <span fxFlex fxLayoutAlign="end">
        <button
          mat-icon-button
          (click)="dialogRef.close()"
          title="Close Dialog"
        >
          <mat-icon>close</mat-icon>
        </button>
      </span>
    </mat-toolbar>
  </header>
  <div class="content content-height" [@slider]="visible">
    <div
      *ngIf="!visible"
      class="slide slide-takeoff"
      fxFlex
      fxLayout="column"
      fxLayout.xs="column"
    >
      <form [formGroup]="takeoff" (ngSubmit)="flight_leg_take_off()">
        <div fxLayout="column" fxLayout.gt-sm="row">
          <span
            fxFlex
            fxFlexAlign="center"
            fxLayout.sm="column"
            fxLayout.xs="column"
          >
            <span class="mat-input-wrapper">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Amount of Fuel"
                  type="text"
                  formControlName="amount_of_fuel"
                  required
                />
              </mat-form-field>
            </span>
          </span>

          <span
            fxFlex
            fxFlexAlign="center"
            fxLayout.sm="column"
            fxLayout.xs="column"
            fxLayoutGap="5px"
          >
            <span class="mat-input-wrapper">
              <mat-form-field>
                <mat-placeholder>Engine Start Time</mat-placeholder>
                <mat-datetimepicker-toggle
                  [for]="datetimePicker"
                  matSuffix
                ></mat-datetimepicker-toggle>
                <mat-datetimepicker
                  #datetimePicker
                  type="datetime"
                  openOnFocus="true"
                  timeInterval="1"
                ></mat-datetimepicker>
                <input
                  matInput
                  formControlName="engine_start_time"
                  [matDatetimepicker]="datetimePicker"
                  autocomplete="false"
                  required
                />
              </mat-form-field>
              <mat-error *ngIf="form_errors.engine_start_time">{{
                form_errors.engine_start_time
              }}</mat-error>
            </span>
          </span>

          <span
            fxFlex
            fxFlexAlign="center"
            fxLayout.sm="column"
            fxLayout.xs="column"
          >
            <span class="mat-input-wrapper">
              <mat-form-field>
                <mat-placeholder>Wheel-Up Time</mat-placeholder>
                <mat-datetimepicker-toggle
                  [for]="datetimePicker2"
                  matSuffix
                ></mat-datetimepicker-toggle>
                <mat-datetimepicker
                  #datetimePicker2
                  type="datetime"
                  openOnFocus="true"
                  timeInterval="1"
                ></mat-datetimepicker>
                <input
                  matInput
                  formControlName="wheel_up_time"
                  [matDatetimepicker]="datetimePicker2"
                  autocomplete="false"
                  required
                />
              </mat-form-field>
              <mat-error *ngIf="form_errors.wheel_up_time">{{
                form_errors.wheel_up_time
              }}</mat-error>
            </span>
          </span>
        </div>

        <div fxLayout="row" class="mt-2">
          <span fxFlex fxLayoutAlign="center" fxLayoutAlign.gt-sm="end">
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="!takeoff.valid"
            >
              Submit
            </button>
          </span>
        </div>
      </form>
    </div>
    <div
      *ngIf="visible"
      class="slide"
      fxFlex
      fxLayout="column"
      fxLayout.xs="column"
    >
      <form [formGroup]="landing" (ngSubmit)="flight_leg_landing()">
        <div fxLayout="column" fxLayout.gt-sm="row">
          <span
            *ngIf="complete_flight"
            fxFlex
            fxLayout.sm="column"
            fxLayout.xs="column"
            fxFlexAlign="center"
            fxLayoutGap="5px"
          >
            <span class="mat-input-wrapper">
              <mat-form-field>
                <mat-select
                  placeholder="Reason of Landing"
                  formControlName="reason"
                  (selectionChange)="landing_reason($event)"
                  required
                >
                  <mat-option></mat-option>
                  <mat-option value="fuel">Fuel</mat-option>
                  <mat-option value="end of flight">End of Flight</mat-option>
                  <mat-option value="weather">Weather</mat-option>
                  <mat-option value="precautionary">Precautionary</mat-option>
                  <mat-option value="emergency">Emergency</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </span>

          <span
            fxFlex
            fxLayout.sm="column"
            fxLayout.xs="column"
            fxFlexAlign="center"
          >
            <span class="mat-input-wrapper">
              <mat-form-field>
                <mat-select placeholder="Total Time" formControlName="ttsn">
                  <mat-option *ngFor="let t of ttsns" [value]="t.id">{{
                    t.ttsn
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </span>

          <span
            fxFlex
            fxLayout.sm="column"
            fxLayout.xs="column"
            fxFlexAlign="center"
            fxLayoutGap="5px"
            *ngIf="landing_airport_select"
          >
            <span class="mat-input-wrapper">
              <mat-form-field>
                <mat-select
                  placeholder="Landing Airport"
                  formControlName="airport"
                >
                  <mat-option *ngFor="let a of airports" [value]="a.id"
                    >{{ a.properties.name }} [{{
                      a.properties.icao_code
                    }}]</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </span>
          </span>
        </div>

        <div fxLayout="column" fxLayout.gt-sm="row">
          <span
            fxFlex
            fxLayout.sm="column"
            fxLayout.xs="column"
            fxFlexAlign="center"
          >
            <span class="mat-input-wrapper">
              <mat-form-field>
                <mat-placeholder>Wheel TouchDown Time</mat-placeholder>
                <mat-datetimepicker-toggle
                  [for]="datetimePicker4"
                  matSuffix
                ></mat-datetimepicker-toggle>
                <mat-datetimepicker
                  #datetimePicker4
                  type="datetime"
                  openOnFocus="true"
                  timeInterval="1"
                ></mat-datetimepicker>
                <input
                  matInput
                  formControlName="touchdown_time"
                  [matDatetimepicker]="datetimePicker4"
                  autocomplete="false"
                  required
                />
              </mat-form-field>
              <mat-error *ngIf="form_errors.touchdown_time">{{
                form_errors.touchdown_time
              }}</mat-error>
            </span>
          </span>

          <span
            fxFlex
            fxLayout.sm="column"
            fxLayout.xs="column"
            fxLayoutGap="5px"
            fxFlexAlign="center"
          >
            <span class="mat-input-wrapper">
              <mat-form-field>
                <mat-placeholder>Engine off Time</mat-placeholder>
                <mat-datetimepicker-toggle
                  [for]="datetimePicker3"
                  matSuffix
                ></mat-datetimepicker-toggle>
                <mat-datetimepicker
                  #datetimePicker3
                  type="datetime"
                  openOnFocus="true"
                  timeInterval="1"
                ></mat-datetimepicker>
                <input
                  matInput
                  formControlName="engine_off_time"
                  [matDatetimepicker]="datetimePicker3"
                  autocomplete="false"
                  required
                />
              </mat-form-field>
              <mat-error *ngIf="form_errors.engine_off_time">{{
                form_errors.engine_off_time
              }}</mat-error>
            </span>
          </span>
        </div>
        <div fxLayout="row" class="mt-2 mb-2 mb-md-0">
          <span fxFlex fxLayoutAlign="start">
            <button
              class="btn btn-primary"
              #goBack_btn
              (click)="goBack(goBack_btn, $event)"
            >
              Back
            </button>
          </span>
          <span fxFlex fxLayoutAlign="end">
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="!landing.valid"
            >
              Submit
            </button>
          </span>
        </div>
      </form>
    </div>
  </div>
</div>
