import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { CustomerRoute } from "../types";

@Injectable()
export class CustomerRouteService extends BaseApiService<CustomerRoute> {
  component: string = "customerroute";

  recalculate_locations(object_id: number) {
    return this.http_client.post(
      this.function_url("recalculate_locations", object_id),
      {},
      { headers: contentHeaders() }
    );
  }
}
