import { AirplaneDetailComponent } from './detail/detail.component';
import { AirplaneListComponent } from './list/list.component';
import { AirplaneFormComponent } from './form/form.component';
import { AirplaneComponent } from './component';
import { AuthGuard } from '@app/common/auth.guard';

export const AirplaneRoute = {
  path: 'airplane',
  component: AirplaneComponent,
  canActivate: [AuthGuard],
  children: [
    {path: '', component: AirplaneListComponent},
    {path: 'new', component: AirplaneFormComponent},
    {path: ':id', component: AirplaneDetailComponent},
    {path: ':id/edit', component: AirplaneFormComponent},
  ]
}
