import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "payload",
  templateUrl: "./component.html",
  styleUrls: ["./component.css"],
})
export class PayloadComponent implements OnInit {
  constructor(private titleService: Title) {
    const title = "Tarnished Lamp Payloads";
    this.titleService.setTitle(title);
  }

  ngOnInit() {}
}
