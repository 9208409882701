import { Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import * as moment from "moment-timezone";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

@Component({
  selector: "customer-form",
  templateUrl: "./form.component.html",
  styleUrls: ["../component.css"],
})
export class CustomerJobFormComponent implements OnInit, OnDestroy {
  job: { [id: string]: any } = {};
  id: number;
  customers: any;
  form_errors: any = {
    non_field_error: null,
    name: null,
    start_date: null,
    end_date: null,
    customer: null,
  };
  error = false;
  error_message: string;
  $customer_job: Subscription;
  $customer: Subscription;
  customer_job_error: any = { detail: null };
  title = "Add New CustomerJob";

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private api: ApiService,
    private errorHandleService: ErrorHandleService,
    private titleService: Title
  ) {
    const title = "Tarnished Lamp " + this.title;
    this.titleService.setTitle(title);
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params["id"];
      if (this.id) {
        this.title = "Update CustomerJob";
        this.$customer_job = this.api.customer_job.detail(this.id).subscribe(
          (job: any) => {
            const title =
              "Tarnished Lamp " + this.titleService + " " + job.name;
            this.titleService.setTitle(title);
            this.job = job;
          },
          (errors) => {
            this.customer_job_error = errors.error;
          }
        );
      }
    });
    this.customer_list();
  }

  customer_list() {
    this.$customer = this.api.customer.list().subscribe((res: any) => {
      this.customers = res.results;
    });
  }

  save(button: HTMLButtonElement) {
    if (!this.job.data_logging || this.job.data_logging === undefined) {
      this.job.data_logging = false;
    }
    let call = this.api.customer_job.create(this.job);
    if (this.id) {
      call = this.api.customer_job.update(this.id, this.job);
    }
    if (moment(this.job.due_date).isBefore(this.job.start_date)) {
      this.error = true;
      this.error_message = "End date should be greater then start date.";
    } else {
      button.disabled = false;
      call.subscribe({
        next: (res: any) => {
          this.location.back();
        },
        error: (errors) => {
          if (!errors.status) {
            this.errorHandleService.sendError(
              "An error occurred while processing your request. If this keeps happening please contact support."
            );
          }
          this.form_errors = errors.error;
        },
        complete: () => {
          button.disabled = false;
        },
      });
    }
  }

  ngOnDestroy() {
    if (this.$customer_job) {
      this.$customer_job.unsubscribe();
    }
    if (this.$customer) {
      this.$customer.unsubscribe();
    }
  }
}
