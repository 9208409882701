import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { Aircraft, APIListResponse, Employee } from "../types";
import { map } from "rxjs/operators";

@Injectable()
export class AircraftService extends BaseApiService<Aircraft> {
  component: string = "aircraft";

  search(params) {
    return this.list(params);
  }

  pilots(aircraft_id: number, params: any) {
    if (!params) {
      params = {};
    }
    if (aircraft_id) {
      return this.get_with_retry(this.function_url("pilots", aircraft_id), {
        params: params,
      }).pipe(map((data) => data as APIListResponse<Employee>));
    } else {
      return this.null();
    }
  }
}
