import {
  Component,
  Output,
  Input,
  EventEmitter,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { map, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { BaseWidget } from "./base.widget";

function completion(analysis: { [id: string]: any }) {
  let value = 0;
  if (analysis) {
    const values = Object.keys(analysis)
      .map((key) => analysis[key])
      .map((value): [number, number] => [
        value.idm[0] + value.scm[0],
        value.idm[1] + value.scm[1],
      ])
      .reduce(
        (a: [number, number], b: [number, number]) => [
          a[0] + b[0],
          a[1] + b[1],
        ],
        [0, 0]
      );
    value = values[1] ? values[0] / values[1] : 0;
  }
  value = value * 100;
  return `${value.toFixed(2)}%`;
}

@Component({
  selector: "<daily_missions_widget>",
  template: `
    <app-generic-list
      [displayedColumns]="[
        'mission_id',
        'start_date',
        'end_date',
        'mission_status',
        'completion'
      ]"
      [source]="get_mission_data"
      [params]="params"
      [router]="'mission'"
    >
    </app-generic-list>
  `,
})
export class DailyMissionsWidget extends BaseWidget implements OnInit {
  @Input() config: { params? } = {};
  today: string = "2020-10-08";
  params = { on: this.today };

  ngOnInit() {
    let d = new Date().toISOString();
    this.today = d.substring(0, d.indexOf("T"));
    this.params = { on: this.today };

    this.setTitle("Today's Missions");
  }

  get_mission_data(search_param: Observable<any>): Observable<any> {
    return search_param.pipe(
      switchMap((search_param) => {
        return this.api.mission.list(search_param).pipe(
          map((e: { results: any[] }) => {
            e.results = e.results.map((element) => {
              return {
                id: element.id,
                mission_id: element.mission_id,
                mission_status: element.mission_status,
                start_date: element.start_date?.substring(
                  0,
                  element.start_date.indexOf("T")
                ),
                end_date: element.end_date?.substring(
                  0,
                  element.end_date.indexOf("T")
                ),
                completion: completion(element.analysis),
              };
            });
            return e;
          })
        );
      })
    );
  }
}
