import { Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

@Component({
  selector: "employee-form",
  templateUrl: "./form.component.html",
  styleUrls: ["../component.css"],
})
export class EmployeeFormComponent implements OnInit, OnDestroy {
  employee: { [id: string]: any } = {};
  id: number;
  title = "Add New Employee";
  form_errors: any = {
    city: null,
    email: null,
    first_name: null,
    last_name: null,
    phone: null,
    non_field_error: null,
    detail: null,
  };
  $employee: Subscription;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private api: ApiService,
    private errorHandleService: ErrorHandleService,
    private titleService: Title
  ) {
    const title = "Tarnished Lamp " + this.title;
    this.titleService.setTitle(title);
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params["id"];
      if (this.id) {
        this.form_errors.detail = null;
        this.title = "Update Employee";
        this.$employee = this.api.employee.detail(this.id).subscribe(
          (employee: any) => {
            const title = "Tarnished Lamp " + this.title + " " + employee.name;
            this.titleService.setTitle(title);
            this.employee = employee;
          },
          (errors) => {
            this.form_errors = errors.error;
          }
        );
      }
    });
  }

  save(button: HTMLButtonElement) {
    let call = this.api.employee.create(this.employee);
    if (this.id) {
      call = this.api.employee.update(this.id, this.employee);
    } else {
      this.employee.ratings = [];
    }
    button.disabled = true;
    call.subscribe({
      next: (res: any) => {
        this.location.back();
      },
      error: (errors) => {
        if (!errors.status) {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. If this keeps happening please contact support."
          );
        }
        this.form_errors = errors.error;
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    if (this.$employee) {
      this.$employee.unsubscribe();
    }
  }
}
