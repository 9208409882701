<mat-card class="client-detail">
  <div class="detail-not-exist" *ngIf="errors.detail">
    Record Does Not Exist.
  </div>
  <div *ngIf="!errors.detail">
    <mat-card-header>
      <mat-card-title> Customer Route Form </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div fxLayout="column">
        <div fxLayout="row" fxLayout.xs="column" fxFlex="100%">
          <customer-select fxFlex [(ngModel)]="customer"></customer-select>

          <span fxFlex="5%"></span>

          <mat-form-field fxFlex="40%">
            <mat-select
              placeholder="Meter Group"
              [(ngModel)]="selected_meter_group"
              *ngIf="meter_groups"
            >
              <mat-option *ngFor="let mg of meter_groups" [value]="mg.id">
                {{ mg.group_name }}
                <span *ngIf="mg.group_id"> [{{ mg.group_id }}]</span>
              </mat-option>
            </mat-select>
            <mat-spinner *ngIf="meter_groups_loading"></mat-spinner>
          </mat-form-field>

          <span fxFlex="5%"></span>

          <mat-form-field fxFlex="25%">
            <input
              matInput
              placeholder="Route Name"
              [(ngModel)]="customer_route.name"
              maxlength="255"
            />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxFlex="100%">
          <mat-error *ngIf="errors.service_area"
            >Please select a Service Area of Customer Route on Map.</mat-error
          >
        </div>

        <div fxLayout="row" fxFlex="100%">
          <mat-expansion-panel fxFlex="100%">
            <mat-expansion-panel-header>
              <mat-panel-title> Route Description </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field fxFlex="100%">
              <textarea
                matInput
                placeholder="Route Description"
                [(ngModel)]="customer_route.description"
              ></textarea>
            </mat-form-field>
          </mat-expansion-panel>
        </div>

        <div fxLayout="row" fxFlex>
          <route-map
            #map
            [meter_set]="customer_route.meter_group"
            [service_area]="customer_route.service_area"
            [editable]="true"
            [newpoly]="true"
          ></route-map>
        </div>
      </div>
    </mat-card-content>

    <mat-card-actions fxLayoutAlign="end">
      <button mat-button #test_btn (click)="test(test_btn)">Test</button>
      <button mat-raised-button #save_btn (click)="save(save_btn)">Save</button>
    </mat-card-actions>
  </div>
</mat-card>
