import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Subscription } from "rxjs";

import { ApiService } from "@app/api/api";
import { tr } from "date-fns/locale";

@Component({
  selector: "mission-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class MissionDialogComponent implements OnInit, OnDestroy {
  search_param = {
    customer: null,
    launch: null,
    recover: null,
    aircraft: null,
    mission_type: null,
    mission_status: null,
    flown: null,
    from_date: null,
    until_date: null,
  };
  airports = [];
  aircraft = [];
  missions = [];
  minDate = "2000-01-01";
  maxDate = "2030-12-31";

  $mission_find: Subscription;
  $mission_airports: Subscription;
  $mission_aircraft: Subscription;
  $mission_search: Subscription;

  _customer;
  get customer() {
    return this._customer;
  }

  set customer(val: any) {
    if (!(val && val.hasOwnProperty("id"))) {
      return;
    }
    this._customer = val;
    this.search_param.customer = val.id;
  }

  constructor(
    public dialogRef: MatDialogRef<MissionDialogComponent>,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.$mission_airports = this.api.airport.list().subscribe((res: any) => {
      this.airports = res.results.features;
    });
    this.$mission_aircraft = this.api.aircraft.list().subscribe((res: any) => {
      this.aircraft = res.results;
    });
  }

  search(search_params) {
    if (
      search_params.customer !== null ||
      this.customer.id !== search_params.customer
    ) {
      this.$mission_search = this.api.customer
        .detail(search_params.customer)
        .subscribe((res: any) => {
          this.customer = res;
        });
    }
    this.search_param = search_params;
    this.find_missions();
  }

  find_missions(button?: HTMLButtonElement) {
    if (button) button.disabled = true;
    this.$mission_find = this.api.mission.search(this.search_param).subscribe({
      next: (res: any) => {
        this.missions = res.results;
      },
      complete: () => {
        if (button) button.disabled = false;
      },
    });
  }

  submit(button: HTMLButtonElement) {
    this.dialogRef.close(
      this.missions.filter((m) => {
        return m._ui_selected;
      })
    );
  }

  ngOnDestroy() {
    if (this.$mission_search) {
      this.$mission_search.unsubscribe();
    }
    if (this.$mission_airports) {
      this.$mission_airports.unsubscribe();
    }
    if (this.$mission_aircraft) {
      this.$mission_aircraft.unsubscribe();
    }
    if (this.$mission_find) {
      this.$mission_find.unsubscribe();
    }
  }
}
