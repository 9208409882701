<tr>
  <td width="80%" colspan="4" class="file-name">{{file.filename}}</td>
  <td width="10%">{{file.created | date}}</td>
  <td width="10%" class="file-size">{{file.filesize | bytes}}</td>
</tr>
<tr>
  <td width="90%" colspan="5">
    <tag-list
      style="margin: 0"
      [object_id]="file.id"
      [content_type]="'file'"
      [tags]="file.tags"
      [tagset]="list.tagset"
    ></tag-list>
  </td>
  <td width="10%" class="file-buttons">
    <button
      *ngIf="list.process_file_fn"
      mat-icon-button
      matTooltip="Process"
      #process_file_btn
      (click)="list.process_file(process_file_btn, file)"
    >
      <mat-icon>update</mat-icon>
    </button>
    <button
      *ngIf="list.allow_download"
      mat-icon-button
      #download_file_btn
      (click)="list.download_file(download_file_btn, file)"
      matTooltip="Download"
    >
      <mat-icon>file_download</mat-icon>
    </button>
    <button
      *ngIf="list.allow_delete"
      mat-icon-button
      matTooltip="Delete"
      #delete_file_btn
      (click)="list.delete_file(delete_file_btn, file)"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </td>
</tr>
<tr *ngIf="list.files_error.includes(file.id)" style="color: red">
  This File Does not Exist
</tr>
