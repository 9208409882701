import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LayerService {
  // Observable string sources
  private LayerCommandSource = new Subject<any>();

  // Observable streams
  layer_command$ = this.LayerCommandSource.asObservable();
  // Service message commands
  add_layer(type: string, layer) {
    const d = { class: type, layer: layer, command: 'add' };

    this.LayerCommandSource.next(d);
  }
  delete_layer(type: string, layer) {
    const d = { class: type, layer: layer, command: 'remove' };
    this.LayerCommandSource.next(d);
  }
  zoom_layer(type: string, layer) {
    const d = { class: type, layer: layer, command: 'zoom' };
    this.LayerCommandSource.next(d);
  }

  replace_track(old_track, new_track) {
    this.remove_track(old_track);
    this.add_track(new_track)
  }
  add_track(track) {
    const d = { class: 'track', layer: track, command: 'add' };
    this.LayerCommandSource.next(d);
  }
  zoom_track(track) {
    const d = { class: 'track', layer: track, command: 'zoom' };
    this.LayerCommandSource.next(d);
  }
  zoom_position(latlng) {
    const d = { class: 'position', layer: latlng, command: 'zoom' };
    this.LayerCommandSource.next(d);
  }
  remove_track(track) {
    const d = { class: 'track', layer: track, command: 'remove' };
    this.LayerCommandSource.next(d);
  }

}
