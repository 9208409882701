<mat-card>
  <mat-card-title>
    <div fxLayout="row">
      <span fxFlex class="header_label">Airports</span>
      <span fxFlex></span>
      <span fxFlex fxLayoutAlign="end">
        <a mat-icon-button class="anchor" [href]="'#/airport/new'">
          <mat-icon matTooltip="New Airport" [routerLink]="['new']"
            >add</mat-icon
          >
        </a>
      </span>
    </div>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div fxLayout="column">
      <app-generic-filter
        [filterList]="filters"
        [store_name]="'airport'"
        (filterData)="getFilterData($event)"
      ></app-generic-filter>
      <app-generic-list
        [source]="get_airport_list"
        [router]="'airport'"
        [displayedColumns]="['name', 'icao_code', 'use_count']"
      >
      </app-generic-list>
    </div>
  </mat-card-content>
</mat-card>
