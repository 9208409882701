import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Subscription } from "rxjs";

import { ApiService } from "@app/api/api";

@Component({
  selector: "metergroup-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class MeterDialogComponent implements OnInit, OnDestroy {
  tiles: boolean = false;
  _customer: number = null;
  _customer_check: boolean = true;
  selected_meter_group;
  meter_groups = [];
  allow_all = true;
  meter_groups_loading = false;
  $meter_group_subs: Subscription;

  constructor(
    public dialogRef: MatDialogRef<MeterDialogComponent>,
    private api: ApiService
  ) {}

  ngOnInit() {}

  get customer() {
    return this._customer;
  }

  set customer(value: any) {
    this._customer = value;

    this.meter_groups_loading = true;
    this.$meter_group_subs = this.api.meter_group
      .customer_groups(value.id)
      .subscribe((data: any) => {
        this.meter_groups = data.results;
        if (this.allow_all) {
          this.meter_groups.unshift({
            group_name: "All Meters",
            group_id: "",
            id: null,
          });
        }
        this.selected_meter_group = this.meter_groups[0];
        this.meter_groups_loading = false;
      });
  }

  submit(button: HTMLButtonElement) {
    if (this._customer) {
      this.dialogRef.close({
        customer: this.customer,
        metergroup: this.selected_meter_group,
        tiles: this.tiles,
      });
    } else {
      this._customer_check = false;
    }
  }

  ngOnDestroy() {
    if (this.$meter_group_subs) {
      this.$meter_group_subs.unsubscribe();
    }
  }
}
