<mat-chip-list>
  <mat-chip color="accent" *ngFor="let tag of tags" class="tag_label">
    <tag [tag]="tag"></tag>
  </mat-chip>
  <mat-chip color="accent" *ngIf="!__create_tag">
    <button
      mat-icon-button
      (click)="__create_tag=true"
      matTooltip="Add Tag"
      class="add-tag tag-button"
    >
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </mat-chip>
  <mat-chip color="accent" *ngIf="__create_tag">
    <mat-form-field *ngIf="!tagset.length" class="new-tag">
      <input matInput placeholder="New Tag" [(ngModel)]="new_tag" />
    </mat-form-field>
    <mat-select
      *ngIf="tagset.length"
      placeholder="New Tag"
      [(ngModel)]="new_tag"
      class="select-tag-option"
    >
      <mat-option *ngFor="let tag of tagset" [value]="tag">
        {{tag}}
      </mat-option>
    </mat-select>
    <button
      mat-icon-button
      #create_tag_btn
      (click)="create_tag(create_tag_btn)"
      matTooltip="Add Tag"
      class="tag-button"
    >
      <mat-icon>done</mat-icon>
    </button>
  </mat-chip>
</mat-chip-list>
