<mat-card>
  <mat-card-title fxLayout="row" style="margin-bottom: 10px" *ngIf="!compact">
    <span fxFlex class="header_label">Imagery Projects</span>
    <span fxFlex fxLayoutAlign="end">
      <a
        *ngIf="allow_add"
        mat-icon-button
        class="anchor"
        [href]="'#/imagery_project/new'"
      >
        <mat-icon
          matTooltip="New Project"
          [routerLink]="['/', 'imagery_project', 'new']"
        >
          add
        </mat-icon>
      </a>
    </span>
  </mat-card-title>
  <mat-divider fxLayout="column"></mat-divider>
  <mat-card-content
    class="imageryproject-list-container"
    fxLayout="column"
    style="margin-top: 20px"
  >
    <div fxLayout="column">
      <div fxFlex *ngIf="show_filters">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Filter </mat-panel-title>
            <mat-panel-description>
              <label *ngIf="search_param.mission">
                Mission: {{ search_param.mission }}
              </label>
              <label *ngIf="search_param.from_date" style="margin-left: 1%">
                After: {{ search_param.from_date }}
              </label>
              <label *ngIf="search_param.until_date" style="margin-left: 1%">
                Before: {{ search_param.until_date }}
              </label>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutGap="20px"
            fxLayoutGap.xs="5px"
          >
            <mat-form-field fxFlex>
              <input
                matInput
                placeholder="Mission"
                [(ngModel)]="search_param.mission"
                type="text"
              />
            </mat-form-field>
            <mat-form-field fxFlex>
              <input
                matInput
                placeholder="After"
                [(ngModel)]="search_param.from_date"
                type="date"
              />
            </mat-form-field>
            <mat-form-field fxFlex>
              <input
                matInput
                placeholder="Before"
                [(ngModel)]="search_param.until_date"
                type="date"
              />
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutGap="20px"
            fxLayoutGap.xs="5px"
          >
            <span fxFlex>
              <button
                mat-icon-button
                #get_projects_btn
                (click)="get_projects(get_projects_btn)"
                matTooltip="Search"
              >
                <mat-icon>search</mat-icon>
              </button>
            </span>
          </div>
        </mat-expansion-panel>
      </div>

      <div fxFlex>
        <mat-table
          [dataSource]="projects"
          style="margin-top: 1%"
          [class.isMobile]
        >
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">Id:</span>
              {{ row.id }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="customer_name">
            <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
            <mat-cell *matCellDef="let row" style="word-break: break-all">
              <span class="mobile-label">Customer Name:</span>
              {{ row.customer_name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Project Name </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">Project Name:</span>
              {{ row.project_name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="start_date">
            <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">Start Date:</span>
              {{ row.start_date | date }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="end_date">
            <mat-header-cell *matHeaderCellDef> End Date </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">End Date:</span>
              {{ row.end_date | date }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="anchor">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <a [href]="'#/imagery_project/' + row.id"></a>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            [routerLink]="['/', 'imagery_project', row.id]"
          ></mat-row>
        </mat-table>
        <mat-paginator
          *ngIf="totalCount > pageCount"
          [pageSize]="pageCount"
          [length]="totalCount"
          [pageSizeOptions]="pageSizeOptions"
          (page)="paginate($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
