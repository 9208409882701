import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import {
  Subscription,
  timer,
  Observable,
  Subject,
  BehaviorSubject,
  combineLatest,
} from "rxjs";
import { mergeMap, switchMap, tap, map } from "rxjs/operators";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { DialogService } from "@app/services/dialog.service";
import { MatLegacyTable as MatTable } from "@angular/material/legacy-table";
import { ACSService } from "@app/services/acs.service";
import { GenericListComponent } from "@app/generic-list/generic-list.component";
import { APIListResponse, Employee } from "@app/api/types";

@Component({
  selector: "airplane-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css"],
})
export class AirplaneDetailComponent implements OnInit, OnDestroy {
  data: { [id: string]: any } = {};
  _id: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  set id(value) {
    this._id.next(value);
  }
  get id() {
    return this._id.getValue();
  }
  active: string;
  $latest: Subscription;
  deleted = null;
  $aircraft: Subscription;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  @ViewChild("quals_list", { static: false })
  qualification_list: GenericListComponent;
  @ViewChild("ttsn_list", { static: false }) ttsn_list: GenericListComponent;

  payloadColumns = ["created", "payload", "unassign"];
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private acs: ACSService,
    private router: Router,
    private errorHandleService: ErrorHandleService,
    private titleService: Title,
    private dialogService: DialogService
  ) {}

  load() {
    this.$aircraft = this.api.aircraft.detail(this.id).subscribe(
      (airplane: any) => {
        const title = "Tarnished Lamp Airplane " + airplane.registration;
        this.titleService.setTitle(title);
        this.deleted = airplane.is_delete;
        this.active = airplane.active ? "Active" : "Inactive";
        this.data = airplane;
      },
      (errors) => {
        this.deleted = errors.error;
      }
    );
  }
  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params["id"];
      this.load();
      this.$latest = this.acs.aircraft_status(this.id).subscribe((res: any) => {
        this.data["gps_state"] = res ? res.properties.gps_state : "N/A";
      });
    });
  }

  delete_aircraft(button, id) {
    button.disabled = true;
    this.api.aircraft.delete(id).subscribe({
      next: (data: any) => {
        this.router.navigate(["airplane"]);
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    if (this.$aircraft) {
      this.$aircraft.unsubscribe();
    }
    if (this.$latest) {
      this.$latest.unsubscribe();
    }
  }

  add_assignment(button) {
    if (!this.data) {
      return;
    }
    button.disabled = true;
    this.dialogService
      .select_payload()
      .pipe(
        mergeMap((payload: any) => {
          if (!payload) {
            return this.api.payload.null();
          }
          return this.api.payload.assign(payload.ident, this.id);
        })
      )
      .subscribe({
        next: (data: any) => {
          this.load();
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  end_assignment(button, payload_ident: any, aircraft_id: any) {
    button.disabled = true;
    this.api.payload.unassign(payload_ident, aircraft_id).subscribe({
      next: (data: any) => {
        this.load();
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  qualification_form(button, id: number = null) {
    button.disabled = true;
    this.dialogService.aircraft_qualification_form(this.data.id, id).subscribe({
      next: (result) => {
        if (this.qualification_list) {
          this.qualification_list.load_data();
        }
      },
      complete: () => {
        button.disabled = false;
      },
    });
    return false;
  }

  remove_qualification(button, qual_id: number) {
    button.disabled = true;
    this.api.aircraft_qualification.delete(qual_id).subscribe({
      next: (result) => {
        if (this.qualification_list) {
          this.qualification_list.load_data();
        }
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  get get_pilot_data() {
    const parent = this;
    function _pilot_data(
      search_params: Observable<any>
    ): Observable<APIListResponse<Employee>> {
      //add subject for id and add it to the subscription
      return combineLatest([parent._id.asObservable(), search_params]).pipe(
        switchMap(([id, search_params]) =>
          parent.api.aircraft.pilots(id, search_params)
        )
      );
    }
    return _pilot_data;
  }

  get_aircraft_qualifications(search_params: Observable<any>) {
    return search_params.pipe(
      switchMap((search_params) => {
        return this.api.aircraft_qualification.list(search_params);
      })
    );
  }

  get_aircraft_flights(search_params: Observable<any>) {
    return search_params.pipe(
      switchMap((search_params) => {
        return this.api.flight.list(search_params);
      })
    );
  }

  ttsn_form(button, ttsn_id: number = null) {
    button.disabled = true;
    this.dialogService.ttsn_dialog(this.data.id, ttsn_id).subscribe({
      next: (result) => {
        if (this.ttsn_list) {
          this.ttsn_list.load_data();
        }
      },
      complete: () => {
        button.disabled = false;
      },
    });
    return false;
  }

  remove_ttsn(button, ttsn_id: number) {
    button.disabled = true;
    this.api.ttsn.delete(ttsn_id).subscribe({
      next: (result) => {
        if (this.ttsn_list) {
          this.ttsn_list.load_data();
        }
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  get_aircraft_ttsns(search_params: Observable<any>): Observable<any> {
    return search_params.pipe(
      switchMap((search_params) => {
        return this.api.ttsn.list(search_params);
      })
    );
  }
}
