import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Subscription } from "rxjs";

import { ApiService } from "@app/api/api";

@Component({
  selector: "selectpayload-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class SelectPayloadDialogComponent implements OnInit, OnDestroy {
  payload_list = [];
  selected_payload;
  $payload: Subscription;
  error_message: string = null;

  constructor(
    public dialogRef: MatDialogRef<SelectPayloadDialogComponent>,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.$payload = this.api.payload.list().subscribe(
      (data: any) => {
        this.payload_list = data.results.filter((e, i, a) => {
          return e.active;
        });
      },
      (error) => {
        this.error_message = error;
      }
    );
  }

  submit(button: HTMLButtonElement) {
    this.dialogRef.close(this.selected_payload);
  }

  ngOnDestroy() {
    if (this.$payload) {
      this.$payload.unsubscribe();
    }
  }
}
