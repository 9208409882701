import { Component, Input, OnInit } from "@angular/core";
import { DialogService } from "@app/services/dialog.service";

@Component({
  selector: "flight-leg-landing",
  templateUrl: "./flight-leg-landing.component.html",
  styleUrls: ["./flight-leg.component.css"],
})
export class FlightLegLandingComponent implements OnInit {
  @Input("landing") landing: any;

  constructor(private dialog_service: DialogService) {}
  ngOnInit() {}
  edit_leg(button: HTMLButtonElement) {
    console.debug(this.landing);
    this.dialog_service
      .stop_flight_leg(
        this.landing.flight,
        this.landing.takeoff,
        this.landing.id
      )
      .subscribe((e) => {
        console.debug(e);
      });
  }
}
