import { Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';


import { MaterialModule } from '@app/material.module';
import { ApiService } from '../api/api';
import { MeterReadingComponent } from './component';
import { MeterReadingDetailComponent } from './detail/detail.component';
import { MeterReadingListComponent } from './list/list.component';
import { MeterReadingFormComponent } from './form/form.component';


@NgModule({
  imports: [ CommonModule, RouterModule, MaterialModule, FlexLayoutModule ],
  exports: [
    MeterReadingComponent, MeterReadingDetailComponent, MeterReadingListComponent,
    MeterReadingFormComponent
  ],
  providers: [ ApiService, Title ],
  declarations: [
    MeterReadingComponent, MeterReadingDetailComponent, MeterReadingListComponent,
    MeterReadingFormComponent
  ]
})
export class MeterReadingModule {
}
