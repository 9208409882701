import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "customer",
  templateUrl: "./component.html",
  styleUrls: ["./component.css"],
})
export class CustomerJobComponent implements OnInit {
  constructor(private titleService: Title) {
    const title = "Tarnished Lamp CustomerJobs";
    this.titleService.setTitle(title);
  }

  ngOnInit() {}
}
