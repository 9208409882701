import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import { CG_CommonModule } from '@app/common/module';
import { ContactModule } from '@app/contact/module';
import { NoteModule } from '@app/note/module';

import { EmployeeService } from '@app/api/employee/api';
import { EmployeeComponent } from './employee.component';
import { MaterialModule } from '@app/material.module';
import { EmployeeDetailComponent } from './detail/detail.component';
import { EmployeeListComponent } from './list/list.component';
import { EmployeeFormComponent } from './form/form.component';
import { DialogModule } from '@app/dialog/dialog.module';
import { DialogService } from '@app/services/dialog.service';
import { GenericListModule } from '@app/generic-list/module';
import { GenericFilterModule } from '@app/generic-filter/module';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};


@NgModule({
  imports: [
      CommonModule, RouterModule, MaterialModule, DialogModule, CG_CommonModule, FormsModule, ContactModule,
      NoteModule, GenericListModule, GenericFilterModule, FlexLayoutModule, NgxMaskModule.forRoot(options)

  ],
  exports: [ EmployeeComponent, EmployeeDetailComponent, EmployeeListComponent, EmployeeFormComponent ],
  providers: [ EmployeeService, DialogService, Title ],
  declarations: [ EmployeeComponent, EmployeeDetailComponent, EmployeeListComponent, EmployeeFormComponent ]
})
export class EmployeeModule {
}
