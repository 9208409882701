import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';

interface RouteAnalysis {
    scm: any[],
    idm?: any[]
}

interface ResultAnalysis {
    [id: string]: RouteAnalysis
}

class RouteAnalysisData {
    route: string;
    scm_requested: number;
    scm_erts: number[];
    get scm_collected(): number { return this.scm_requested - this.scm_erts.length };
    idm_requested: number;
    idm_erts: number[];
    get idm_collected(): number { return this.idm_requested - this.idm_erts.length };

    constructor(route, data) {
        this.route = route;
        this.scm_requested = data.scm[1];
        this.scm_erts = data.scm[0];
        this.idm_requested = data.idm[1];
        this.idm_erts = data.idm[0];
    }
    get scm_percentage(): number {
        if (this.scm_requested) {
            return this.scm_collected / this.scm_requested * 100
        }
        return null
    };
    get idm_percentage(): number {
        if (this.idm_requested) {
            return this.idm_collected / this.idm_requested * 100
        }
        return null
    }
    get completion(): number {
        if (this.idm_percentage && this.scm_percentage) {
            return Math.min(this.idm_percentage, this.scm_percentage)
        }
        return this.scm_percentage || this.idm_percentage
    }

    get status(): string {
        if (this.completion > 98) {
            return 'pass'
        } else if (this.completion > 95) {
            return 'warn'
        }
        return 'fail'
    }

}

export class ResultAnalysisDataSource extends DataSource<any> {
    _filter_change = new BehaviorSubject('');
    _analysis_data = new BehaviorSubject(null);
    warnings: number = 0;
    get filter(): string { return this._filter_change.value; }
    set filter(filter: string) { this._filter_change.next(filter); }
    get analysis_data(): ResultAnalysis { return this._analysis_data.value; }
    set analysis_data(data: ResultAnalysis) { this._analysis_data.next(data); }
    constructor(private _sort: MatSort) {
        super();
    }
    /** Connect function called by the table to retrieve one stream containing the data to render. */

    get_filtered_data(): RouteAnalysisData[] {
        let lst = [];
        this.warnings = 0;
        const data = this.analysis_data;
        if (!data) {
            return []
        }
        for (let key in data) {
            lst.push(new RouteAnalysisData(key, data[key]))
        }

        return lst.filter((item: RouteAnalysisData) => {
            const searchStr = item.route.toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        });
    }

    get_sorted_data(): RouteAnalysisData[] {
        const data = this.get_filtered_data();
        if (!this._sort.active || this._sort.direction === '') { return data; }
        const field = this._sort.active;

        return data.sort((a, b) => {
            const propertyA: number | string = a[field];
            const propertyB: number | string = b[field];

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }
    connect(): Observable<RouteAnalysisData[]> {
        const displayDataChanges = [
            this._filter_change,
            this._analysis_data,
            this._sort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {
            return this.get_sorted_data()
        })
        );
    }

    disconnect() { }
}

@Component({
    selector: 'result-analysis',
    templateUrl: './analysis.component.html',
    styleUrls: ['../component.css']
})
export class ResultAnalysisComponent implements OnInit {
    @ViewChild('filter', { static: true }) filter: ElementRef;
    @Input('job_result') job_result;

    _data_logging = new BehaviorSubject(true);
    @Input('data_logging')
    set data_logging(value) { this._data_logging.next(value) }
    get data_logging() { return this._data_logging.value }

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    get displayed_columns() {
        let columns = ['route', 'scm_requested', 'scm_collected', 'scm_percentage'];
        if (this.data_logging) { columns = columns.concat(['idm_requested', 'idm_collected', 'idm_percentage']) }
        return columns
    }

    data_source: ResultAnalysisDataSource;

    get data() { return this.job_result.value }

    ngOnInit() {
        this.data_source = new ResultAnalysisDataSource(this.sort);
        this.sort.active = 'warnings';
        this.sort.direction = 'desc';
        this.sort.start = 'desc';
        this.job_result.subscribe((data) => {
            this.data_source.analysis_data = data.analysis
        });

        fromEvent(this.filter.nativeElement, 'keyup').pipe(
            debounceTime(150),
            distinctUntilChanged()
        ).subscribe(() => {
                if (!this.data_source) { return; }
                this.data_source.filter = this.filter.nativeElement.value;
        });
    }

}
