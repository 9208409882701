import { Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: "messagebox-dialog",
  templateUrl: "./dialog.component.html",
})
export class MessageBoxDialog implements OnInit {
  message: string = "";
  ok: boolean = true;
  cancel: boolean = true;
  constructor(public dialogRef: MatDialogRef<MessageBoxDialog, boolean>) {}
  ngOnInit() {}
}
