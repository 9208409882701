import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { Customer } from "../types";

@Injectable()
export class CustomerService extends BaseApiService<Customer> {
  component: string = "customer";

  autocomplete(start: string) {
    return this.list({ autocomplete: start });
  }

  search(params) {
    return this.list(params);
  }
}
