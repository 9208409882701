import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  NgZone,
  Component,
  Injectable,
} from "@angular/core";
import * as L from "leaflet";

@Injectable()
export class TooltipInjector {
  componentRef: ComponentRef<Component>;
  popup = L.popup({ className: "my-popup" });

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private zone: NgZone,
    private injector: Injector
  ) {}

  InjectPopupComponent(component) {
    const obj = this;
    return (feature, layer, name) => {
      layer.bindPopup(this.popup);
      layer.on({
        click: () => {
          this.ShowPopupContent(obj, component, feature, name,this.popup);
        },
      });
      if (layer.feature && layer.feature.showpop) {
        layer.on("add", (event) => {
          this.ShowPopupContent(obj, component, feature, name,this.popup);
          event.target.openPopup();
        });
      }
    };
  }

  ShowPopupContent(obj, component, data, name, popup) {
    const self = obj;
    self.zone.run(() => {
      if (self.componentRef) {
        self.componentRef.destroy();
      }
      const compFactory =
        self.componentFactoryResolver.resolveComponentFactory(component);
      self.componentRef = compFactory.create(self.injector);
      const attach = (c) => {
        c.instance.data = data;
        c.instance.name = name;
      };

      attach(self.componentRef);

      if (self.appRef["attachView"]) {
        // since 2.3.0
        self.appRef["attachView"](self.componentRef.hostView);
        self.componentRef.onDestroy(() => {
          self.appRef["detachView"](self.componentRef.hostView);
        });
      } else {
        self.appRef["registerChangeDetector"](
          this.componentRef.changeDetectorRef
        );
        self.componentRef.onDestroy(() => {
          self.appRef["unregisterChangeDetector"](
            this.componentRef.changeDetectorRef
          );
        });
      }

      const div = document.createElement("div");
      div.appendChild(self.componentRef.location.nativeElement);
      popup.setContent(div);
    });
  }
}
