<mat-expansion-panel
  [expanded]="mission_state.expanded"
  (opened)="expanded(true)"
  (closed)="expanded(false)"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-checkbox [(ngModel)]="display" (click)="blackhole($event)">
        {{ mission.label }}
      </mat-checkbox>
    </mat-panel-title>
    <mat-panel-description>
      <div fxLayout="row">
        {{ mission.short_desc }}
        <span class="spacer"></span>
        <a [href]="'#/mission/' + mission.id" class="anchor-mat-icon">
          <mat-icon
            [routerLink]="['/', 'mission', mission.id]"
            (click)="blackhole($event)"
          >
            launch
          </mat-icon>
        </a>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div fxLayout="row">
    <mat-checkbox [(ngModel)]="received">Remove Received Meters</mat-checkbox>
    <span class="spacer"></span>
    <mat-checkbox [(ngModel)]="area">Display Mission Area</mat-checkbox>
  </div>
  <mat-tab-group>
    <mat-tab label="Routes">
      <mission-routes
        *ngIf="mission.routes?.length > 0"
        [mission]="mission.id"
      ></mission-routes>
    </mat-tab>
    <mat-tab label="Flights">
      <mission-flights
        *ngIf="mission.flights?.length > 0"
        [mission]="mission.id"
      ></mission-flights>
    </mat-tab>
  </mat-tab-group>
</mat-expansion-panel>
