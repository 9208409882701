import { Action} from '@ngrx/store';
import { ActionTypes } from './actions';

export default interface MissionFlightsActionWithPayload<T> extends Action {
    type: string;
    flights: any;
}

export interface MissionFlights {
    flights: any;
}

export const initialState: MissionFlights = {
    flights: {}
};

export function mapSidebarMissionFlightsReducer(state = initialState, action: Action): MissionFlights {
    switch (action.type) {
        case ActionTypes.GetMissionFlights:
            return {
                ...state,
                flights: (action as MissionFlightsActionWithPayload<MissionFlights>).flights,
            };
        case ActionTypes.AssignMissionFlights:
            return {
                ...state,
                flights: (action as MissionFlightsActionWithPayload<MissionFlights>).flights,
            };
        default:
            return state;
    }
}
