import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { Subscription, forkJoin } from "rxjs";
import { mergeMap, switchMap } from "rxjs/operators";
import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";

@Component({
  selector: "payload-assignment-list",
  templateUrl: "./list.component.html",
  styleUrls: ["../component.css"],
})
export class PayloadAssignmentListComponent implements OnInit, OnDestroy {
  assignments = [];
  @Input("payload_id")
  payload_id: number = null;
  payload = null;
  displayedColumns: string[] = ["aircraft", "created", "expired"];
  $assignments: Subscription;
  $payload: Subscription;

  constructor(
    private api: ApiService,
    private dialogService: DialogService,
    private change: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.load();
  }

  load() {
    if (this.payload_id == undefined) {
      console.warn("Undefined payload id");
      return;
    }
    forkJoin([
      this.api.payload.assignments(this.payload_id),
      this.api.payload.detail(this.payload_id),
    ]).subscribe((data: any) => {
      this.payload = data[1];
      this.assignments = data[0];
      this.change.markForCheck();
    });
  }

  add_assignment(button: HTMLButtonElement) {
    if (!this.payload) {
      return;
    }
    button.disabled = true;
    this.dialogService
      .select_aircraft()
      .pipe(
        switchMap((ac) => {
          if (!ac) {
            return this.api.payload.null();
          }
          return this.api.payload.assign(this.payload.ident, ac.id);
        })
      )
      .subscribe({
        next: (data: any) => {
          this.load();
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  end_assignment(button: HTMLButtonElement, aircraft_id) {
    if (!this.payload) {
      return;
    }
    button.disabled = true;
    this.api.payload.unassign(this.payload.ident, aircraft_id).subscribe({
      next: (data: any) => {
        this.load();
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    if (this.$assignments) {
      this.$assignments.unsubscribe();
    }
    if (this.$payload) {
      this.$payload.unsubscribe();
    }
  }
}
