import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ImageryProjectFormComponent } from "@app/imageryproject/form/form.component";
import { ImageryProjectListComponent } from "@app/imageryproject/list/list.component";

@Component({
  selector: "imagery-project-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class ImageryProjectDialogComponent implements OnInit, OnDestroy {
  dialog_ok = false;
  error = false;
  error_message: string;

  tab_index = null;
  project = null;
  project_search = { mission: null };
  title = "Add Flight";
  @ViewChild("project_form", { static: true })
  projectform: ImageryProjectFormComponent;
  @ViewChild("project_list", { static: true })
  projectlist: ImageryProjectListComponent;

  constructor(public dialogRef: MatDialogRef<ImageryProjectDialogComponent>) {}

  ngOnInit() {}
  select_project(project) {
    this.project = project;
  }

  tab_change() {}

  update(project) {
    if (this.project === null) {
      this.project = {};
    }
    for (let k in project) {
      this.project[k] = project[k];
    }
    this.projectform.update(this.project);
    // if (this.project.aircraft) {
    //   this.projectlist.search_param.aircraft = this.project.aircraft;
    //   this.projectlist.get_projects();
    // }
  }

  done() {
    const self = this;
    return (res) => {
      self.dialogRef.close(res);
    };
  }

  submit(button: HTMLButtonElement) {
    if (this.tab_index) {
      this.dialogRef.close(this.project);
    } else if (this.projectform.valid) {
      this.projectform.call_api(this.done());
    }
  }

  ngOnDestroy() {}
}
