import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Optional,
  Self,
  Output,
  EventEmitter,
  OnDestroy,
  HostBinding,
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import {
  UntypedFormControl,
  ControlValueAccessor,
  NgControl,
} from "@angular/forms";
import { startWith, map, switchMap } from "rxjs/operators";
import { ApiService } from "@app/api/api";
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from "@angular/material/legacy-autocomplete";
import { MatLegacyFormFieldControl as MatFormFieldControl } from "@angular/material/legacy-form-field";
import { MatLegacyInput as MatInput } from "@angular/material/legacy-input";

interface Airport {
  icao_code: string;
  name: string;
  id: number;
}

@Component({
  selector: "airport-select",
  template: `
    <div>
      <input
        type="text"
        [placeholder]="_placeholder"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        (focus)="$event.target.select()"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="option_text">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
          [matTooltip]="option_text(option)"
        >
          {{ option_text(option) }}
        </mat-option>
      </mat-autocomplete>
    </div>
  `,
  styles: [],
  providers: [
    { provide: MatFormFieldControl, useExisting: AirportSelectComponent },
  ],
})
export class AirportSelectComponent
  implements
    OnInit,
    OnDestroy,
    ControlValueAccessor,
    MatFormFieldControl<Airport>
{
  @Input()
  get airport(): Airport {
    return this.value;
  }
  set airport(value: Airport) {
    this.value = value;
  }
  @Output() airportChange: EventEmitter<Airport> = new EventEmitter<Airport>();

  myControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<Airport[]>;
  stateChanges = new Subject<void>();
  get focused() {
    return false;
  }
  _value: Airport;

  set value(value: Airport | null) {
    this.writeValue(value);
    this.stateChanges.next();
  }

  get value() {
    return this._value;
  }
  static nextId = 0;

  @HostBinding() id = `airport-select-${AirportSelectComponent.nextId++}`;
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  _placeholder: string = "Select Airport";

  get empty() {
    return !this._value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = req;
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
    this._disabled ? this.myControl.disable() : this.myControl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  errorState = false;
  controlType = "airport-select";
  @HostBinding("attr.aria-describedby") describedBy = "";

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(" ");
  }
  onContainerClick(event: MouseEvent) {}

  constructor(
    private _element: ElementRef<HTMLInputElement>,
    @Optional() @Self() public ngControl: NgControl,
    private api: ApiService
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        if (typeof value === "undefined") {
          value = "";
        } else if (typeof value !== "string") {
          this._value = value;
          this.airportChange.emit(value);
          this._on_change(value);
          value = value.name;
        }
        return this.get_airports(value);
      })
    );
    this.writeValue(this.airport);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }
  option_text(option: Airport) {
    return option ? "[" + option.icao_code + "] " + option.name : "";
  }

  get_airports(q: string): Observable<Airport[]> {
    return this.api.airport.match(q).pipe(
      map((results: any) => {
        return results.features.map((e: any) => {
          return {
            id: e.id,
            name: e.properties.name,
            icao_code: e.properties.icao_code,
          };
        });
      })
    );
  }

  private _on_change = (_: any) => {};

  writeValue(value: Airport) {
    this._value = value;
    this.myControl.setValue(this.airport);
  }

  registerOnChange(fn: any) {
    this._on_change = fn;
  }

  registerOnTouched(fn: any) {}
}
