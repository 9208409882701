<div>
  <table
    #table
    mat-table
    matSort
    [dataSource]="sorted_dataset"
    fxFlexFill
    (matSortChange)="sort_change($event)"
  >
    <!--Group Headers-->
    <ng-container matColumnDef="blank_header">
      <th
        mat-header-cell
        *matHeaderCellDef
        [attr.colspan]="2"
        class="table-right-border no-lower-border"
      >
        &nbsp;
      </th>
    </ng-container>
    <ng-container matColumnDef="idm_header">
      <th
        mat-header-cell
        *matHeaderCellDef
        [attr.colspan]="3"
        class="table-right-border no-lower-border"
      >
        Data Logging Reads
      </th>
    </ng-container>
    <ng-container matColumnDef="scm_header">
      <th
        mat-header-cell
        *matHeaderCellDef
        [attr.colspan]="3"
        class="table-right-border no-lower-border"
      >
        Real Time Reads
      </th>
    </ng-container>
    <!-- Actual Data columns-->
    <ng-container matColumnDef="selected">
      <th mat-header-cell *matHeaderCellDef>
        &nbsp;
      </th>
      <td mat-cell *matCellDef="let route">
        <mat-checkbox [(ngModel)]="route._selected"></mat-checkbox>
      </td>
      <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-right-border"
        mat-sort-header
      >
        Name
      </th>
      <td mat-cell *matCellDef="let route" class="table-right-border">
        <a
          class="anchor"
          [href]="'#/customer_route/' + route.id"
          [routerLink]="['/', 'customer_route', route.id]"
        >
          {{route.name}}
          <mat-icon
            class="active"
            matTooltip="Customer Route Detail"
            style="transform: scale(0.5);"
            >launch</mat-icon
          >
        </a>
      </td>
      <td mat-footer-cell *matFooterCellDef class="table-right-border">
        Total
      </td>
    </ng-container>

    <ng-container matColumnDef="completion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Completion
      </th>
      <td mat-cell *matCellDef="let route">
        {{ route.completion | percent: "1.2-4" }}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{get_total_completion()|percent:"1.2-4"}}
      </td>
    </ng-container>

    <ng-container matColumnDef="area">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Area
      </th>
      <td mat-cell *matCellDef="let route">
        {{ route.area | number: "1.0-0" }} ha
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{get_total_area()|number:"1.0-0"}} ha
      </td>
    </ng-container>

    <!--SCM numbers-->
    <ng-container matColumnDef="scm_read">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Reads
      </th>
      <td mat-cell *matCellDef="let route">
        {{ route.scm_read }}
      </td>
      <td mat-footer-cell *matFooterCellDef>{{get_total('scm','reads')}}</td>
    </ng-container>

    <ng-container matColumnDef="scm_request">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Requested
      </th>
      <td mat-cell *matCellDef="let route">
        {{ route.scm_request}}
      </td>
      <td mat-footer-cell *matFooterCellDef>{{get_total('scm','request')}}</td>
    </ng-container>

    <ng-container matColumnDef="scm_pct">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-right-border"
        mat-sort-header
      >
        %
      </th>
      <td mat-cell *matCellDef="let route" class="table-right-border">
        {{ route.scm_pct |percent:"1.2-4"}}
      </td>
      <td mat-footer-cell *matFooterCellDef class="table-right-border">
        {{get_total_pct('scm')|percent:"1.2-4"}}
      </td>
    </ng-container>

    <!--IDM numbers-->
    <ng-container matColumnDef="idm_read">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Reads
      </th>
      <td mat-cell *matCellDef="let route">
        {{ route.idm_read }}
      </td>
      <td mat-footer-cell *matFooterCellDef>{{get_total('idm','reads')}}</td>
    </ng-container>

    <ng-container matColumnDef="idm_request">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Requested
      </th>
      <td mat-cell *matCellDef="let route">
        {{ route.idm_request }}
      </td>
      <td mat-footer-cell *matFooterCellDef>{{get_total('idm','request')}}</td>
    </ng-container>

    <ng-container matColumnDef="idm_pct">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-right-border"
        mat-sort-header
      >
        %
      </th>
      <td mat-cell *matCellDef="let route" class="table-right-border">
        {{ route.idm_pct|percent:"1.2-4" }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="table-right-border">
        {{get_total_pct('idm')|percent:"1.2-4"}}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="group_headers"
      class="no-lower-border"
    ></tr>
    <tr mat-header-row *matHeaderRowDef="displayed_columns"></tr>

    <tr mat-row *matRowDef="let row; columns: displayed_columns"></tr>

    <tr mat-footer-row *matFooterRowDef="displayed_columns"></tr>
  </table>
</div>
