import { Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ApiService } from '@app/api/api';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { UserComponent } from '@app/user/user.component';
import { MaterialModule } from '@app/material.module';
import { NoteModule } from '@app/note/module';
import { KeyvaluePipeModule } from '@app/user/keyvalue.pipe.module';

@NgModule({
  imports: [
      CommonModule, RouterModule, MaterialModule, NoteModule, FormsModule,
      FlexLayoutModule, KeyvaluePipeModule
  ],
  exports: [ UserComponent ],
  providers: [ ApiService, Title ],
  declarations: [ UserComponent ]
})
export class UserModule {
}
