import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { MapReducer } from "@app/store/map/sidebar/map_reducer";
import { mapSidebarMissionsReducer } from "@app/store/map/sidebar/missions/reducer";
import { mapSidebarMissionRoutesReducer } from "@app/store/map/sidebar/missions/routes/reducer";
import { mapSidebarMissionFlightsReducer } from "@app/store/map/sidebar/missions/flights/reducer";
import { mapSidebarMissionMapChecksReducer } from "@app/store/map/sidebar/missions/map_mission/reducer";
import { FilterReducer } from "@app/store/filter/filter_reducer";
import { calenderSidenavChecksReducer } from "@app/store/calender/reducer";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    StoreModule.forRoot(
      {
        map_sidebar_missions: mapSidebarMissionsReducer,
        map_sidebar_missions_map_checks: mapSidebarMissionMapChecksReducer,
        map_sidebar_mission_routes: mapSidebarMissionRoutesReducer,
        map_sidebar_mission_flights: mapSidebarMissionFlightsReducer,
        filters: FilterReducer,
        map: MapReducer,
        calender_sidenav_checks: calenderSidenavChecksReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 200, //  Retains last 25 states
    }),
  ],
  exports: [],
  providers: [],
  declarations: [],
})
export class AppStoreModule {}
