import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { Employee } from "../types";

@Injectable()
export class EmployeeService extends BaseApiService<Employee> {
  component: string = "employee";

  search(params) {
    return this.list(params);
  }

  aircraft(employee_id: number, params: any) {
    if (!params) {
      params = {};
    }
    if (employee_id) {
      return this.get_with_retry(this.function_url("aircraft", employee_id), {
        params: params,
      });
    } else {
      return this.null();
    }
  }
}
