import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import * as L from "leaflet";
import "leaflet-editable";
import * as turf from "@turf/turf";
import { DefaultInfoComponent } from "./pointmap";
import { TooltipInjector } from "./map_tools";
import { BaseMap } from "@app/base_map";

@Component({
  selector: "app-airport-map",
  template: ` <div #airportmap id="airportmap"></div> `,
  styles: ["#airportmap{flex: 1 1 auto;height:100%;min-height:500px;}"],
  host: {
    class: "main-map",
    style: "display:flex;flex-flow:column;flex:1 1 auto",
  },
})
export class AirportMapComponent extends BaseMap implements OnInit, OnDestroy {
  footprint: any = null;
  _points = null;
  _point: L.LatLng = new L.LatLng(null, null);
  layer: L.GeoJSON = null;
  marker: L.Marker = null;
  addMarker = false;
  isPolygon = true;

  @Input() editable: boolean = false;
  @Input() newPoly: boolean = false;
  @ViewChild("airportmap", { static: true }) mapContainer;
  @Output("map_click")
  mapclick: EventEmitter<typeof L.DomEvent> = new EventEmitter<
    typeof L.DomEvent
  >();
  @Output() pointChange = new EventEmitter();

  @Input("airport_footprint")
  set airport_footprint(value) {
    if (this.map && this.footprint) {
      this.map.removeLayer(this.footprint);
    }
    if (
      value &&
      value.coordinates[0] &&
      value.coordinates[0][0][0] !== undefined
    ) {
      for (let i = 0; i < value.coordinates[0][0].length; i++) {
        value.coordinates[0][0][i][0] =
          +value.coordinates[0][0][i][0].toFixed(7);
        value.coordinates[0][0][i][1] =
          +value.coordinates[0][0][i][1].toFixed(7);
      }
    }
    this.footprint =
      value && value.coordinates[0] && value.coordinates[0][0][0] !== undefined
        ? L.GeoJSON.geometryToLayer(value as any)
        : null;
    this.add_airport_footprint();
  }

  get airport_footprint() {
    let obj = this.map.editTools.currentPolygon;
    if (!obj) {
      return null;
    }
    let geom = obj.toGeoJSON().geometry;
    if (geom.type === "MultiPolygon") {
      return geom;
    } else if (geom.type === "Polygon") {
      geom = turf.multiPolygon([geom.coordinates]).geometry;
      return geom;
    }
  }

  @Input("point")
  set point(value: L.LatLng) {
    const latlng = value;
    latlng.lat = +latlng.lat.toFixed(7);
    latlng.lng = +latlng.lng.toFixed(7);
    this._point = latlng;
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    if (value && this.map) {
      this.marker = new L.Marker(latlng).addTo(this.map);
      this.map.panTo(latlng);
    }
    this.pointChange.emit(this._point);
  }

  get point() {
    return this._point;
  }

  @Input("geojson") set geojson(value) {
    this.points = value;
  }

  @Input("points")
  set points(value) {
    if (value[0].geometry) {
      this._points = value;
      this.layer = L.geoJson(value, {
        onEachFeature: this.tooltip_injector
          .InjectPopupComponent(DefaultInfoComponent)
          .bind(this),
      });
      this.add_layer();
      this.fit_bounds();
    }
  }

  get points() {
    return this._points;
  }

  constructor(private tooltip_injector: TooltipInjector) {
    super();
  }

  ngOnInit() {
    this.footprint_area();
    this.show_marker();
  }

  footprint_area() {
    this.map_build(this.mapContainer, this.newPoly);

    // this.layer_control = L.control
    //   .panelLayers(this.basemaps, this.overlays, {
    //     autoZIndex: false,
    //     selectorGroup: true,
    //     collapsed: true,
    //     collapsibleGroups: true,
    //   })
    //   .addTo(this.map);

    this.map.on("contextmenu", () => {});

    const self = this;

    L["AddMarkerControl"] = L.Control.extend({
      options: { position: "topleft" },
      onAdd: function (map) {
        let container = L.DomUtil.create("div", "leaflet-control leaflet-bar"),
          link = L.DomUtil.create("a", "", container);
        link["href"] = "#";
        link.title = "Add Marker";
        link.innerHTML = "🖈";

        L.DomEvent.on(link, "click", L.DomEvent.stop).on(link, "click", () => {
          map.editTools.startMarker();
          self.addMarker = true;
        });
        container.style.display = "block";
        map.editTools.on(
          "editable:enabled",
          () => (container.style.display = "none")
        );
        map.editTools.on(
          "editable:disable",
          () => (container.style.display = "block")
        );
        return container;
      },
    });

    if (this.newPoly === true) {
      this.map.addControl(new L["AddMarkerControl"]());
    }
    this.map.on("editable:vertex:deleted", () => this.validatePolygon());
    this.map.on("editable:drawing:clicked", (e: L.LeafletMouseEvent) => {
      this.validatePolygon();
      if (this.addMarker) {
        this.point = e.latlng;
        this.addMarker = false;
      }
    });
    if (this.footprint) {
      this.add_airport_footprint();
    }
  }

  validatePolygon() {
    this.isPolygon = true;
    if (this.airport_footprint) {
      if (this.airport_footprint.coordinates[0][0].length < 4) {
        this.isPolygon = false;
      }
    }
  }

  show_marker() {
    this.map.on("click", this.map_click.bind(this));
    this.add_layer();
  }

  add_airport_footprint() {
    if (!this.map || !this.footprint) {
      return;
    }
    this.footprint.addTo(this.map);
    if (this.editable) {
      this.footprint.enableEdit();
      this.map.editTools.currentPolygon = this.footprint;
    }
    this.fit_bounds();
  }

  fit_bounds() {
    console.log("fitting bounds");
    let bounds = this.footprint?.getBounds();
    if (bounds && bounds.isValid()) {
      console.log(bounds);
      this.map.fitBounds(bounds);
      return;
    }
    bounds = this.layer?.getBounds();
    if (bounds && bounds.isValid()) {
      this.map.fitBounds(bounds);
      return;
    }
  }

  map_click(e: any): void {
    this.mapclick.emit(e);
    if (!this.points) {
      if (this.addMarker) {
        this.point = e.latlng;
        this.addMarker = false;
      }
    }
  }

  add_layer(): void {
    if (this.map && this.layer) {
      const bounds = this.layer.getBounds();
      this.layer.addTo(this.map);
      if (bounds.isValid()) {
        if (this.editable) {
          this.map.fitBounds(bounds);
        } else {
          this.map.setZoom(14);
        }
      } else {
        this.map.fitBounds(
          L.latLngBounds([48.734816, -120.508165], [60.129941, -109.7384207])
        );
      }
    }
  }

  ngOnDestroy() {
    this.map.remove();
  }
}
