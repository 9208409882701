<mat-card>
  <div *ngIf="deleted" class="detail-not-exist">Record Does Not Exist.</div>
  <div fxLayout="column" *ngIf="!deleted">
    <mat-card-header>
      <mat-card-title>
        <h2 fxFlex>Airplane Detail</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column">
        <div fxLayout="row">
          <span fxFlex> {{ data.registration }} </span>
          <span fxFlex fxLayoutAlign="end">
            <button
              mat-icon-button
              matTooltip="Delete Airplane"
              #delete_aircraft_btn
              (click)="delete_aircraft(delete_aircraft_btn, data.id)"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <a
              mat-icon-button
              class="anchor"
              [href]="'#/airplane/' + data.id + '/edit'"
            >
              <mat-icon
                [routerLink]="['edit']"
                matTooltip="Edit Airplane Details"
              >
                edit
              </mat-icon>
            </a>
          </span>
        </div>
        <div fxLayout="row">
          <span fxFlex> {{ data.make }} {{ data.model }} </span>
          <span fxFlex> {{ active }} </span>
          <span fxFlex fxLayoutAlign="end">{{ data.last_updated | date }}</span>
        </div>
        <div fxLayout="row">
          <div fxFlex class="alert alert-{{ data.gps_state }}">
            GPS: <strong>{{ data.gps_state }}</strong>
          </div>
        </div>

        <div fxLayout="row">
          <p fxFlex>
            general airplane stuff purchase date 1ex build date airframe hours /
            last update of hours what else is generic and defines the plane?
          </p>
        </div>
        <div fxLayout="column" class="description">
          <mat-tab-group>
            <mat-tab label="Specification">
              <div fxFlex fxFlexLayout="column">
                <div fxLayout="row" fxFill *ngIf="data.ttsn">
                  <span fxLayout="row" fxFill>
                    <span fxFlex="30%"> TTSN </span>
                    <span>
                      {{ data.ttsn.ttsn }} on {{ data.ttsn.timestamp }}
                    </span>
                  </span>
                </div>
                <div fxLayout="row" fxFill *ngIf="data.fuel_capacity">
                  <span fxLayout="row" fxFill>
                    <span fxFlex="30%"> Fuel Capacity </span>
                    <span>
                      {{ data.fuel_capacity }} Gallons {{ data.fuel_type }}
                    </span>
                  </span>
                </div>
                <div fxLayout="row" fxFill *ngIf="data.fuel_rate">
                  <span fxLayout="row" fxFill>
                    <span fxFlex="30%"> Target Burn Rate </span>
                    <span> {{ data.fuel_rate }} Gallons per Hour </span>
                  </span>
                </div>
                <div fxLayout="row" fxFill *ngIf="data.speed">
                  <span fxLayout="row" fxFill>
                    <span fxFlex="30%"> Target Ground Speed </span>
                    <span> {{ data.speed }} knots </span>
                  </span>
                </div>
                <div fxLayout="row" fxFill *ngIf="data.turn_radius">
                  <span fxLayout="row" fxFill>
                    <span fxFlex="30%"> Target Turn Radius </span>
                    <span> {{ data.turn_radius }} meters </span>
                  </span>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Payloads">
              <div fxLayout="row">
                <span fxFlex fxLayoutAlign="end">
                  <button
                    *ngIf="id"
                    mat-icon-button
                    matTooltip="Add Assignment"
                    #add_assignment_btn
                    (click)="add_assignment(add_assignment_btn)"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </span>
              </div>
              <mat-table
                [dataSource]="data.current_payloads"
                fxFlex
                style="margin-top: 1%"
                [class.isMobile]
                ><ng-container matColumnDef="created">
                  <mat-header-cell *matHeaderCellDef>
                    Assigned
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.started | date : "medium" }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="payload">
                  <mat-header-cell *matHeaderCellDef> Payload </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.payload_name }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="unassign">
                  <mat-header-cell *matHeaderCellDef>
                    Unassign
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" style="word-break: break-all">
                    <button
                      mat-icon-button
                      matTooltip="End Assignment"
                      #end_assignment_btn
                      (click)="
                        end_assignment(
                          end_assignment_btn,
                          row.payload_ident,
                          row.aircraft
                        )
                      "
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="payloadColumns">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: payloadColumns">
                </mat-row>
              </mat-table>
            </mat-tab>
            <mat-tab label="Pilots">
              <app-generic-list
                [displayedColumns]="['name']"
                [source]="get_pilot_data"
                [router]="'employee'"
              >
              </app-generic-list>
            </mat-tab>
            <mat-tab label="Required qualifications">
              <div fxLayout="column">
                <div fxLayout="row">
                  <span fxFlex fxLayoutAlign="end">
                    <button
                      mat-icon-button
                      #qualification_form_btn
                      (click)="qualification_form(qualification_form_btn)"
                    >
                      <mat-icon matTooltip="New Qualification"> add </mat-icon>
                    </button>
                  </span>
                </div>
                <app-generic-list
                  #quals_list
                  [displayedColumns]="['qualification_name', 'edit']"
                  [source]="get_aircraft_qualifications"
                  [router]="'qualification'"
                  [params]="{ aircraft: data.id }"
                >
                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef>
                      &nbsp;
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <button
                        mat-icon-button
                        #remove_qualification_btn
                        (click)="
                          remove_qualification(
                            remove_qualification_btn,
                            row.id
                          );
                          $event.stopPropagation()
                        "
                      >
                        <mat-icon matTooltip="Remove Qualification">
                          delete
                        </mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                </app-generic-list>
              </div>
            </mat-tab>
            <mat-tab label="Flights">
              <app-generic-list
                [displayedColumns]="[
                  'status',
                  'launch',
                  'recovery',
                  'pilot',
                  'link'
                ]"
                [source]="get_aircraft_flights"
                [router]="'flight'"
                [params]="{ aircraft: data.id, mission_type: 'Operational' }"
              >
                <ng-container matColumnDef="launch">
                  <mat-header-cell *matHeaderCellDef> Launch </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.launch_airport }}
                    {{
                      row.actual_launch || row.scheduled_launch | date : "short"
                    }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="recovery">
                  <mat-header-cell *matHeaderCellDef>
                    Recovery
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.recover_airport }}
                    {{
                      row.actual_recovery || row.scheduled_recovery
                        | date : "short"
                    }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="pilot">
                  <mat-header-cell *matHeaderCellDef> Pilot </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.pilot_fname }} {{ row.pilot_lname }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.flight_status }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="link">
                  <mat-header-cell *matHeaderCellDef> &nbsp; </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <a class="anchor-mat-icon" [href]="'#/flight/' + row.id">
                      <mat-icon [routerLink]="['/', 'flight', row.id]">
                        launch
                      </mat-icon>
                    </a>
                  </mat-cell>
                </ng-container>
              </app-generic-list>
            </mat-tab>
            <mat-tab label="TTSN">
              <div fxLayout="row">
                <span fxFlex fxLayoutAlign="end">
                  <button
                    mat-icon-button
                    #ttsn_form_btn
                    (click)="ttsn_form(ttsn_form_btn)"
                  >
                    <mat-icon matTooltip="New TTSN"> add </mat-icon>
                  </button>
                </span>
              </div>
              <div fxLayout="column">
                <app-generic-list
                  #ttsn_list
                  [displayedColumns]="['time', 'ttsn', 'edit']"
                  [source]="get_aircraft_ttsns"
                  [params]="{ aircraft: data.id }"
                >
                  <ng-container matColumnDef="time">
                    <mat-header-cell *matHeaderCellDef> Time </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.timestamp | date : "short" }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef>
                      &nbsp;
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <button
                        mat-icon-button
                        #remove_ttsn_btn
                        (click)="
                          remove_ttsn(remove_ttsn_btn, row.id);
                          $event.stopPropagation()
                        "
                      >
                        <mat-icon matTooltip="Remove TTSN"> delete </mat-icon>
                      </button>
                      <button
                        mat-icon-button
                        #ttsn_form_btn
                        (click)="
                          ttsn_form(ttsn_form_btn, row.id);
                          $event.stopPropagation()
                        "
                      >
                        <mat-icon matTooltip="Edit TTSN"> edit </mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                </app-generic-list>
              </div>
            </mat-tab>

            <mat-tab label="Notes">
              <notes [content_type]="'aircraft'" [object_id]="id"></notes>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </mat-card-content>
  </div>
</mat-card>
