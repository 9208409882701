import { Component, OnDestroy, OnInit, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { EndpointIcon } from "@cleargrid/map_layers";

@Component({
  selector: "genericselect-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class EndpointDialogComponent implements OnInit, OnDestroy {
  styles: EndpointIcon[] = [];
  constructor(public dialogRef: MatDialogRef<EndpointDialogComponent>) {}

  ngOnInit() {}

  submit(button: HTMLButtonElement) {
    this.dialogRef.close(this.styles);
  }

  ngOnDestroy() {}
}
