<mat-tab-group
  class="mat-sidebar"
  id="map-sidebar-tab"
  [selectedIndex]="selected_tab"
  (selectedIndexChange)="tab_change($event)"
>
  <mat-tab label="Missions">
    <sidebar-missions></sidebar-missions>
  </mat-tab>
  <mat-tab label="Meters">
    <sidebar-meters [search_meter_layer]="search_meter_layer"></sidebar-meters>
  </mat-tab>
  <mat-tab label="Aircraft">
    <sidebar-aircraft></sidebar-aircraft>
  </mat-tab>
</mat-tab-group>

<button
  mat-icon-button
  (click)="clear_list()"
  *ngIf="filtered_airports || filtered_meters.length > 0 || error_message"
  class="list-clear-button"
  matTooltip="List Clear"
>
  <mat-icon>delete</mat-icon>
</button>

<!-- NearBy Airports List-->
<mat-list>
  <mat-list-item *ngFor="let airport of filtered_airports" class="list-style">
    <div fxLayout="row" fxFlex="100">
      <span fxFlex="80" class="airport-name">{{ airport.name }}</span>
      <span fxFlex="20" fxLayoutAlign="end">{{ airport.latlng }}</span>
    </div>
  </mat-list-item>
</mat-list>

<!-- NearBy Meters List-->
<mat-list>
  <mat-list-item *ngFor="let meter of filtered_meters" class="list-style">
    <span fxFlex>{{ meter.name }}</span>
    <span fxFlex fxLayoutAlign="end">
      <button
        mat-icon-button
        (click)="zoomtometer(meter.serial)"
        matTooltip="Zoom to Meter"
      >
        <mat-icon>center_focus_strong</mat-icon>
      </button>
    </span>
  </mat-list-item>
</mat-list>

<mat-list>
  <mat-list-item *ngIf="error_message">
    <p>{{ error_message }}</p>
  </mat-list-item>
</mat-list>
