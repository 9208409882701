import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Subject } from "rxjs";
import { mergeMap, takeUntil } from "rxjs/operators";

import { ApiService } from "@app/api/api";
import {
  Aircraft,
  Airport,
  Employee,
  FlightPath,
  MeterGroup,
  APIListResponse,
  Mission,
} from "@app/api/types";
import { SubSink } from "subsink";

interface MissionData {
  meter_groups: MeterGroup[];
  launch: Airport;
  recover: Airport;
  pilot: Employee;
  aircraft: Aircraft;
  flight_plan: FlightPath;
  flight_path: FlightPath;
  label?: string;
  last_modified?: string;
}

@Component({
  selector: "mission-report",
  templateUrl: "./component.html",
  styleUrls: ["../component.css"],
})
export class MissionReportComponent implements OnInit, OnDestroy {
  mission: MissionData = {
    meter_groups: [],
    launch: null,
    recover: null,
    pilot: null,
    aircraft: null,
    flight_plan: null,
    flight_path: null,
  };
  id: number;
  meter_counts = {};
  count_requests: number = 0;
  meters = 0;
  loading = true;
  mission_time = { planned: 0, actual: 0 };
  opex = { hourly: 270, total: 0 };
  capex = { hourly: 180, total: 0 };
  cost_per_meter = { capex: 0, opex: 0, total: 0 };
  sink = new SubSink();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private titleService: Title
  ) {}

  knots_to_meters(k) {
    return k * 0.514444;
  }

  ngOnInit() {
    this.loading = true;
    this.sink.sink = this.route.params
      .pipe(
        mergeMap((params) => {
          this.id = +params["id"];
          return this.api.mission.detail(this.id);
        }),
        mergeMap((mission: Mission) => {
          const title =
            "Tarnished Lamp Mission " + mission.mission_id + " Report";
          this.titleService.setTitle(title);
          return forkJoin([
            this.api.meter_group.mission_groups(this.id),
            this.api.airport.detail(mission.launch),
            this.api.airport.detail(mission.recover),
            this.api.aircraft.detail(mission.aircraft),
            this.api.employee.detail(mission.pilot),
            this.api.flightpath.detail(mission.flight_plan),
            this.api.flightpath.detail(mission.flight_path),
          ]);
        })
      )
      .subscribe(
        ([
          meter_groups,
          launch,
          recover,
          aircraft,
          pilot,
          flight_plan,
          flight_path,
        ]: [
          APIListResponse<MeterGroup>,
          Airport,
          Airport,
          Aircraft,
          Employee,
          FlightPath,
          FlightPath
        ]) => {
          this.mission.meter_groups = meter_groups.results;
          this.mission.launch = launch;
          this.mission.recover = recover;
          this.mission.aircraft = aircraft;
          this.mission.pilot = pilot;
          this.mission.flight_plan = flight_plan;
          this.mission.flight_path = flight_path;
          this.meters = 0;
          for (let m of this.mission.meter_groups) {
            this.count_requests++;
            this.api.meter_group
              .meter_count(m.id)
              .subscribe((meter_count: any) => {
                this.meter_counts[m.group_name] = meter_count.value;
                this.count_requests--;
                this.recalculate();
              });
          }
          this.recalculate();
          this.loading = false;
        }
      );
  }

  recalculate() {
    this.meters = 0;
    for (let m of this.mission.meter_groups) {
      this.meters = this.meters + this.meter_counts[m.group_name];
    }
    this.mission_time.planned =
      (this.mission.flight_plan.properties.length * 1000) /
      this.knots_to_meters(this.mission.aircraft.speed);
    this.opex.total = (this.opex.hourly * this.mission_time.planned) / 3600;
    this.capex.total = (this.capex.hourly * this.mission_time.planned) / 3600;
    this.cost_per_meter.capex = this.capex.total / this.meters;
    this.cost_per_meter.opex = this.opex.total / this.meters;
    this.cost_per_meter.total =
      this.cost_per_meter.opex + this.cost_per_meter.capex;
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }
}
