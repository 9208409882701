import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Title } from "@angular/platform-browser";

import * as L from "leaflet";
import "leaflet.markercluster";
import "leaflet-area-select";
import "leaflet-contextmenu";
import "leaflet-rotatedmarker";

import * as JsonUrl from "json-url";
import { Subscription } from "rxjs";
import { ContextmenuService } from "@app/services/contextmenu.service";
import { copyTextToClipboard } from "@app/common/clipboard";
import { LayerService } from "@app/services/layer.service";
import { MapDataService } from "@app/services/mapdata.service";
import { ApiService } from "@app/api/api";
import { HighchartsService } from "@app/services/highcharts.service";
import { SideNavRerenderService } from "@app/common/side_nav_rerender.service";
import { MeterTooltipComponent } from "@app/common/meter-toolip";
import { TooltipInjector } from "@app/common/map_tools";
import { BaseMap } from "@app/base_map";
import { delay, first } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { MapStoreService } from "@app/services/store/map/sidebar/mapstore.service";
import { PopupRequest, PopupService } from "./popup.service";

@Component({
  selector: "leaflet-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.css"],
  host: {
    class: "main-map",
  },
  providers: [LayerService],
})
export class MapComponent extends BaseMap implements OnInit, OnDestroy {
  markers: any;
  $watcher: Subscription;
  sidenavPosition = "side";
  isSidenavOpen = true;
  isSidenavCloseDisabled = true;
  next_btn = false;
  previous_btn = true;
  selected_points: string;
  zoom_points: L.LatLngBounds;
  search_meter = "";
  hide_profile = true;
  circle_marker: any;
  search_meter_layer: any;
  map_control_classes = ["mdl-textfield__input"];
  controls: any;
  input_box: any;
  $getAirports: Subscription;
  $getMeters: Subscription;
  $selectedMeters: Subscription;
  $selectedAirports: Subscription;
  profile_chart = false;

  @ViewChild("charts", { static: true }) public chartEl: ElementRef;
  @ViewChild("map", { static: true }) public mapEl: ElementRef;

  constructor(
    private menu_services: ContextmenuService,
    private api: ApiService,
    private layer_service: LayerService,
    private tooltip_injector: TooltipInjector,
    private map_data: MapDataService,
    media: MediaObserver,
    private hcs: HighchartsService,
    private side_nav_rerender_service: SideNavRerenderService,
    private titleService: Title,
    private router: Router,
    private map_store: MapStoreService,
    private route: ActivatedRoute,
    private popup: PopupService,
    private tooltipinjector: TooltipInjector
  ) {
    super();
    const title = "Tarnished Lamp Map";
    this.titleService.setTitle(title);
    layer_service.layer_command$.subscribe((layer: any) => {
      const fn_name = layer.command + "_" + layer.class;
      this[fn_name].bind(this)(layer.layer);
    });
    this.$watcher = media.asObservable().subscribe((change: MediaChange[]) => {
      this.sidenavPosition = "side";
    });
    this.side_nav_rerender_service.do_side_nav_rerender().subscribe(() => {
      this.sidenav("map");
      this.sidenav("sidebar");
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((qp) => {
      this.set_map_state(qp);
    });
    this.map_build(null, false);
    // TODO: the below allows us to update the state to include the map
    this.map.on("moveend", () => {
      const bounds = this.map.getBounds();
      this.map_store.set_bounds([
        [bounds.getSouth(), bounds.getEast()],
        [bounds.getNorth(), bounds.getWest()],
      ]);
    });
    this.map.on("baselayerchange", (layerchange) => {
      this.map_store.set_layer(layerchange.name);
    });
    this.chart_services();
    this.context_menu_items();
    console.log(L);
    console.log(L.markerClusterGroup);
    this.markers = L.markerClusterGroup();
    // this.markers = new MarkerClusterGroup();
    this.markers.addTo(this.map);
    this.hcs
      .chartControlDataReceived()
      .pipe(delay(100))
      .subscribe((res: any) => {
        this.profile_chart = res.data;
      });
    this.popup.requests().subscribe((request) => this.show_popup(request));
  }

  show_popup({
    location: location,
    child: component,
    data: data,
    title: title,
  }: PopupRequest) {
    let popup = L.popup({ className: "my-popup" });
    popup.setLatLng(location);
    //directly use ShowPopupContent as we don't need a callback
    this.tooltipinjector.ShowPopupContent(
      this.tooltipinjector,
      component,
      data,
      title,
      popup
    );
    //now we need to get it to the map
    popup.openOn(this.map);
  }

  chart_services() {
    this.hcs.data$.subscribe((res: any) => {
      if (res["init"]) {
        this.createChart();
      }
    });
    this.hcs.circle$.subscribe((res: any) => {
      if (res["state"] === "mouseOver") {
        this.create_circle_layer(res["position"], res["color"]);
      } else {
        this.remove_circle_layer();
      }
    });
    this.hcs.profile$.subscribe((res: any) => {
      this.hide_profile = res;
    });
  }

  get_link() {
    let codec = JsonUrl("lzma");
    this.map_store
      .get_map_state()
      .pipe(first())
      .subscribe((state) => {
        console.log(state);
        codec.compress(state).then((output) => {
          console.log(output);
          console.log(output.length);
          const tree = this.router.createUrlTree([], {
            queryParams: { state: output },
          });
          console.log(tree);
        });
      });
  }

  set_map_state(query: any) {
    const { state } = query;
    let codec = JsonUrl("lzma");
    if (state) {
      codec.decompress(state).then((state) => {
        console.log(state);
        this.map_store.set_state(state);
        this.map.fitBounds(state.bounds);
        this.basemaps[state.layer].addTo(this.map);
      });
    }
  }

  context_menu_items() {
    this.map
      .on("contextmenu", (e) => {
        const selectBox =
          this.mapEl.nativeElement.querySelector(".leaflet-zoom-box");
        if (selectBox) {
          selectBox.remove();
        }
        let target = e.originalEvent.target as HTMLElement;
        this.controls = target.className;
        this.input_box = e.originalEvent;
        if (!this.map_control_classes.includes(this.controls)) {
          this.map.contextmenu.removeAllItems();
          this.map.contextmenu.addItem({
            text: "Near by Airports",
            callback: this.getairports.bind(this),
          });
          this.map.contextmenu.addItem({
            text: "Near by Meters",
            callback: this.getmeters.bind(this),
          });
          this.map.contextmenu.addItem({
            text: "Copy Location",
            callback: this.Copytoclipboard.bind(this),
          });
          this.map.contextmenu.addItem({
            text: "Share Location",
          });
        } else {
          this.map.contextmenu.removeAllItems();
          this.map.contextmenu.addItem({
            text: "Paste",
            callback: this.paste.bind(this, this.input_box),
          });
        }
        this.map.contextmenuWidth = 140;
        this.map.contextmenu.showAt(e.latlng, e);
      })
      .on("click", (e) => {
        this.map.contextmenu.removeAllItems();
      });

    this.map.selectArea.enable();

    this.map.on("areaselected", (e: L.SelectAreaEvent) => {
      this.selected_points = e.bounds.toBBoxString();
      this.zoom_points = e.bounds;
      this.map.contextmenu.removeAllItems();
      this.map.contextmenu.addItem({
        text: "Zoom to area",
        callback: this.zoomarea.bind(this),
      });
      this.map.contextmenu.addItem({
        text: "Airports in area",
        callback: this.selectedairports.bind(this),
      });
      this.map.contextmenu.addItem({
        text: "Meters in area",
        callback: this.selectedmeters.bind(this),
      });
      this.map.contextmenu.addItem({
        text: "Share area",
      });
      this.map.contextmenuWidth = 140;
      this.map.contextmenu.showAt(e.bounds.getNorthEast(), e);
    });

    const selector = new L.Control({
      position: "topleft",
    });

    selector.onAdd = () => {
      const container = L.DomUtil.create("div", "mySelector"),
        textField = L.DomUtil.create("div", "mdl-textfield", container),
        input = L.DomUtil.create("input", "mdl-textfield__input", textField),
        label = L.DomUtil.create("label", "mdl-textfield__label", textField),
        icon = L.DomUtil.create("i", "material-icons", label);
      icon.innerHTML = "search";
      icon.setAttribute("title", "Search meter");
      input.type = "text";
      input.value = this.search_meter;
      input.setAttribute("placeholder", "Search meter with serial number");
      L.DomEvent.addListener(icon, "click", () => {
        if (input.value) {
          this.filter_meter(input.value);
          input.value = "";
        }
      });
      L.DomEvent.addListener(input, "keyup", (e: KeyboardEvent) => {
        if (!(e.key === "Enter" || e.keyCode == 13)) {
          return;
        }
        if (input.value) {
          this.filter_meter(input.value);
          input.value = "";
        }
      });
      return container;
    };
    selector.addTo(this.map);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.sidenav("map");
    this.sidenav("sidebar");
  }

  add_layer(layer) {
    if (layer != null) {
      this.map.addLayer(layer);
    } else {
      console.log("No layer to add");
    }
  }
  remove_layer(layer) {
    if (layer != null) {
      this.map.removeLayer(layer);
    } else {
      console.log("No layer to remove");
    }
  }
  zoom_meter_group(meter_group) {
    if (meter_group == null) {
      meter_group = this.markers;
    }
    const bounds = meter_group.getBounds();
    if (bounds.isValid()) {
      this.map.fitBounds(bounds);
    }
  }

  add_meter_group(meter_group) {
    this.markers.addLayer(meter_group);
  }

  remove_meter_group(meter_group) {
    this.markers.removeLayer(meter_group);
  }

  zoom_mission(mission) {
    if (mission !== null) {
      if (mission.getBounds() && mission.getBounds().isValid()) {
        this.map.fitBounds(mission.getBounds());
      }
    }
  }

  add_mission(mission) {
    this.map.addLayer(mission);
  }

  remove_mission(mission) {
    this.map.removeLayer(mission);
  }

  remove_track(track) {
    this.map.removeLayer(track);
  }

  add_track(track) {
    if (this.map) {
      this.map.addLayer(track);
    }
  }

  zoom_track(track) {
    const layer = track.getLayers()[0];
    if (this.map.hasLayer(layer)) {
      if (layer.hasOwnProperty("_bounds") && layer.getBounds().isValid()) {
        this.map.fitBounds(layer.getBounds());
      }
    }
  }

  zoom_position(position) {
    this.map.fitBounds(position);
  }

  sidenav(value) {
    if (this.profile_chart) {
      setTimeout(() => {
        this.createChart();
      }, 250);
    }
    if (value === "map") {
      this.isSidenavOpen = false;
      this.next_btn = true;
      this.previous_btn = false;
    } else {
      this.isSidenavOpen = true;
      this.next_btn = false;
      this.previous_btn = true;
    }
  }

  getairports(e) {
    const params = { lat: e["latlng"]["lat"], lng: e["latlng"]["lng"] };
    this.$getAirports = this.api.airport
      .filter_result(params, "airport_near")
      .subscribe((data: any) => {
        this.menu_services.airport_result(data, params, false);
      });
  }

  getmeters(e) {
    const params = { lat: e["latlng"]["lat"], lng: e["latlng"]["lng"] };
    this.$getMeters = this.api.meter
      .filter_result(params, "meter_near")
      .subscribe((data: any) => {
        this.menu_services.meter_result(data, params, false);
      });
  }

  selectedmeters() {
    const points = this.selected_points.split(",");
    const params = {
      swlng: points[0],
      swlat: points[1],
      nelng: points[2],
      nelat: points[3],
    };
    this.$selectedMeters = this.api.meter
      .filter_result(params, "within_bounds")
      .subscribe((data: any) => {
        this.menu_services.meter_result(data, params, true);
      });
  }

  selectedairports() {
    const points = this.selected_points.split(",");
    const param = {
      swlng: points[0],
      swlat: points[1],
      nelng: points[2],
      nelat: points[3],
    };
    this.$selectedAirports = this.api.airport
      .filter_result(param, "within_bounds")
      .subscribe((data: any) => {
        this.menu_services.airport_result(data, param, true);
      });
  }

  Copytoclipboard(e) {
    const text = e["latlng"]["lat"] + "," + e["latlng"]["lng"];
    copyTextToClipboard(text);
    this.menu_services.locationcopy();
  }

  async paste(e) {
    try {
      const text = await navigator["clipboard"].readText();
      this.input_box.target.value = text;
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err);
    }
  }

  zoomarea(e) {
    this.zoom_position(this.zoom_points);
  }

  create_layer(data) {
    return L.geoJson(data, {
      onEachFeature: this.tooltip_injector
        .InjectPopupComponent(MeterTooltipComponent)
        .bind(this),
    });
  }

  filter_meter(data) {
    this.search_meter = "";
    this.api.meter.list({ serial: data }).subscribe(
      (res: any) => {
        if (res.count > 0) {
          res.features["0"].showpop = true;
          const layer = this.create_layer(res);
          this.search_meter_layer = layer;
          this.layer_service.add_layer("meter_group", layer);
          this.layer_service.zoom_layer("meter_group", layer);
        } else {
          this.menu_services.display_meter_message();
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  createChart() {
    this.hide_profile = false;
    this.hcs.createChart(this.chartEl.nativeElement);
  }

  create_circle_layer(position, color) {
    const opt = { color: color, fillColor: color, fillOpacity: 0.1, radius: 3 };
    this.circle_marker = L.circleMarker(position, opt).addTo(this.map);
  }

  remove_circle_layer() {
    this.map.removeLayer(this.circle_marker);
  }

  ngOnDestroy() {
    if (this.$watcher) {
      this.$watcher.unsubscribe();
    }
    if (this.$getAirports) {
      this.$getAirports.unsubscribe();
    }
    if (this.$getMeters) {
      this.$getMeters.unsubscribe();
    }
    if (this.$selectedAirports) {
      this.$selectedAirports.unsubscribe();
    }
    if (this.$selectedMeters) {
      this.$selectedMeters.unsubscribe();
    }
    this.map.remove();
  }
}
