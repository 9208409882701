import { Component, OnInit, ViewChild } from "@angular/core";
import { GenericListComponent } from "@app/generic-list/generic-list.component";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ApiService } from "@app/api/api";

@Component({
  selector: "mission-list",
  templateUrl: "./list.component.html",
  styleUrls: ["../component.css"],
})
export class MissionListComponent implements OnInit {
  page = "mission";
  router = "mission";

  filters = [
    {
      label: "Mission Type",
      name: "mission_type",
      type: "select",
      option: ["Quote", "Operational", "Testing"],
    },
    {
      label: "Mission Status",
      name: "mission_status",
      type: "select",
      option: ["Pending", "In Progress", "Complete", "Canceled"],
    },
    {
      label: "From Date",
      name: "from_date",
      type: "date",
      minDate: "2000-01-01",
      mixDate: "2030-12-31",
    },
    {
      label: "Until Date",
      name: "until_date",
      type: "date",
      minDate: "2000-01-01",
      mixDate: "2030-12-31",
    },
    { label: "Mission ID", name: "mission_id", maxlength: 5, type: "text" },
  ];
  data = [];

  @ViewChild(GenericListComponent, { static: true })
  genericlist: GenericListComponent;

  constructor(private api: ApiService) {}

  ngOnInit() {}

  getFilterData(value) {
    this.genericlist.filterValues = value;
  }

  get_mission_list(search_params: Observable<any>): Observable<any> {
    return search_params.pipe(
      switchMap((search_params) => {
        return this.api.mission.list(search_params);
      })
    );
  }
}
