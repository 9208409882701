import { Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from '@app/api/api';
import { FormsModule } from '@angular/forms';

import { NoteComponent } from './note';
import { MaterialModule } from '@app/material.module';

@NgModule({
  imports: [ CommonModule, MaterialModule, FormsModule ],
  exports: [ NoteComponent ],
  providers: [ ApiService, Title ],
  declarations: [ NoteComponent ]
})
export class NoteModule {
}
