import { Action } from '@ngrx/store';

export enum ActionTypes {
    GetMissions = '[Map Sidebar Missions] Get Map Sidebar Missions',
    SpliceMissions = '[Map Sidebar Missions] Splice Map Sidebar Missions'
}

export class GetMissions implements Action {
    readonly type = ActionTypes.GetMissions;
    constructor() {}
}

export class SpliceMissions implements Action {
    readonly type = ActionTypes.SpliceMissions;
    constructor(public mission: any) {}
}

export type MapActionTypes = GetMissions | SpliceMissions;
