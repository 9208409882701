import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";

import * as missionRoutesActionTypes from "@app/store/map/sidebar/missions/routes/actions";
import MissionRoutesActionWithPayload, {
  MissionRoutes,
} from "@app/store/map/sidebar/missions/routes/reducer";

@Injectable()
export class MapSidebarMissionRoutesStoreService {
  missionRouteState$: Observable<MissionRoutes>;

  constructor(private store: Store<MissionRoutes>) {}

  public get_mission_routes() {
    let routeState = {};
    this.missionRouteState$ = this.store.pipe(
      map((state: any) => state.map_sidebar_mission_routes)
    );
    this.missionRouteState$
      .pipe(
        map((routeStateData) => {
          if (
            routeStateData &&
            routeStateData.routes &&
            Object.entries(routeStateData.routes).length > 0
          ) {
            routeState = routeStateData.routes;
          }
        })
      )
      .subscribe();
    return routeState;
  }

  public assign_mission_routes(routes) {
    const AssignMissionRoutes: MissionRoutesActionWithPayload<MissionRoutes> = {
      type: missionRoutesActionTypes.ActionTypes.AssignMissionRoutes,
      routes: routes,
    };
    this.store.dispatch(AssignMissionRoutes);
  }
}
