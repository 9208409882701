import { Component, OnInit, ViewChild } from "@angular/core";
import { GenericListComponent } from "@app/generic-list/generic-list.component";
import { ApiService } from "@app/api/api";
import { Observable, combineLatest } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { ACSService } from "@app/services/acs.service";
import { Aircraft, AircraftStatus, APIListResponse } from "@app/api/types";

@Component({
  selector: "airplane-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class AirplaneListComponent implements OnInit {
  filters: any[] = [
    { label: "Registration", name: "registration", maxlength: 6, type: "text" },
  ];
  @ViewChild(GenericListComponent, { static: true })
  genericlist: GenericListComponent;
  constructor(private api: ApiService, private acs: ACSService) {}

  ngOnInit() {}

  getFilterData(value: any) {
    this.genericlist.filterValues = value;
  }

  title(str: string): string {
    return str
      .toLowerCase()
      .split("_")
      .map((word) => word.replace(word[0], word[0].toUpperCase()))
      .join(" ");
  }

  get_gps_style(record) {
    let style = { color: "red" };
    switch (record.gps_state.toLowerCase()) {
      case "good": {
        style.color = "green";
        break;
      }
      case "offline": {
        style.color = "grey";
        break;
      }
      case "warn": {
        style.color = "yellow";
        break;
      }
      case "trouble": {
        style.color = "red";
        break;
      }
    }
    return style;
  }

  get get_aircraft_list() {
    const parent = this;
    function _aircraft_list(search_params: Observable<any>): Observable<any> {
      return search_params.pipe(
        switchMap((search_params) => {
          return combineLatest([
            parent.api.aircraft.list(search_params),
            parent.acs.status,
          ]).pipe(
            map(
              ([list, acs]: [APIListResponse<Aircraft>, AircraftStatus[]]) => {
                list.results.forEach((element) => {
                  const status = acs.find(
                    (e) => e.properties.aircraft == element.id
                  );
                  element.gps_state = status
                    ? status.properties.gps_state
                    : "N/A";
                });
                return list;
              }
            )
          );
        })
      );
    }
    return _aircraft_list;
  }
}
