import { ImageryProjectDetailComponent } from "./detail/detail.component";
import { ImageryProjectListComponent } from "./list/list.component";
import { ImageryProjectFormComponent } from "./form/form.component";
import { ImageryProjectComponent } from "./imageryproject.component";
import { AuthGuard } from "@app/common/auth.guard";

export const ImageryProjectRoutes = {
  path: "imagery_project",
  component: ImageryProjectComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: "",
      component: ImageryProjectListComponent,
    },
    {
      path: "new",
      component: ImageryProjectFormComponent,
    },
    {
      path: ":id",
      component: ImageryProjectDetailComponent,
    },
    {
      path: ":id/edit",
      component: ImageryProjectFormComponent,
    },
  ],
};
