import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
// actual api
import { BaseApiService } from "./base_api";
import { ApiService } from "./api";
// api components
import { AircraftService } from "./aircraft/api";
import { AircraftStatusService } from "./aircraft_status/api";
import { AirportService } from "./airport/api";
import { CustomerService } from "./customer/customer.service";
import { FileService } from "./file/api";
import { ContactService } from "./contact/api";
import { NoteService } from "./note/api";
import { TagService } from "./tag/api";
import { EmployeeService } from "./employee/api";
import { FlightService } from "./flight/api";
import { FlightPathService } from "./flightpath/api";
import { InvoiceService } from "./invoice/api";
import { InvoiceLineService } from "./invoice_line/api";
import { MeterService } from "./meter/meter.service";
import { MeterGroupService } from "./meter_group/meter_group.service";
import { MeterReadingService } from "./meter_reading/api";
import { MissionService } from "./mission/api";
import { PayloadService } from "./payload/api";
import { PurchaseOrderService } from "./purchase_order/api";
import { UserService } from "./user/api";
import { VendorService } from "./vendor/api";
import { WaypointService } from "./waypoint/api";
import { CustomerJobService } from "./customerjob/customerjob.service";
import { TaskService } from "./task/api";
import { CustomerRouteService } from "./customerroute/service";
import { JobResultService } from "./job_result/api";
import { MeterLocationService } from "./meter_location/api";
import {
  FlightLegTakeoffService,
  FlightLegLandingService,
  TTSNService,
} from "./flight-leg/api";
import { QualificationService } from "./qualification/api";
import { EmployeeQualificationService } from "./employeequalification/api";
import { AircraftQualificationService } from "./aircraftqualification/api";
import { MeterReportService } from "./meter_report/meter_report.service";
import { ReportService } from "./reports/report";
import { ImageryService } from "./imagery/api";
import { ImageryProjectService } from "./imagery/project";

@NgModule({
  imports: [HttpClientModule],
  exports: [],
  providers: [
    BaseApiService,
    ApiService,
    AircraftService,
    AircraftStatusService,
    AirportService,
    CustomerService,
    FileService,
    EmployeeService,
    FlightPathService,
    TaskService,
    InvoiceService,
    InvoiceLineService,
    MeterService,
    MeterGroupService,
    FlightService,
    MeterReadingService,
    MissionService,
    PurchaseOrderService,
    UserService,
    VendorService,
    ContactService,
    NoteService,
    TagService,
    WaypointService,
    CustomerJobService,
    MeterReportService,
    CustomerRouteService,
    JobResultService,
    FlightLegTakeoffService,
    FlightLegLandingService,
    TTSNService,
    MeterLocationService,
    PayloadService,
    QualificationService,
    EmployeeQualificationService,
    AircraftQualificationService,
    ReportService,
    ImageryService,
    ImageryProjectService,
  ],
})
export class ApiModule {}
