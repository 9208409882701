import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { Tag } from "../types";

@Injectable()
export class TagService extends BaseApiService<Tag> {
  component: string = "tag";

  get(content_type: string, object_id: number) {
    return this.list({ type: content_type, object: object_id });
  }
}
