import { MissionDetailComponent } from './detail/detail.component';
import { MissionListComponent } from './list/list.component';
import { MissionFormComponent } from './form/form.component';
import { MissionReportComponent } from './report/component';
import { MissionComponent } from './component';
import { AuthGuard } from '@app/common/auth.guard';

export const MissionRoutes = {
  path: 'mission',
  component: MissionComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '', component: MissionListComponent
    },
    {
      path: 'new', component: MissionFormComponent
    },
    {
      path: ':id', component: MissionDetailComponent
    },
    {
      path: ':id/edit', component: MissionFormComponent
    },
    {
      path: ':id/report', component: MissionReportComponent
    },
  ]
};

