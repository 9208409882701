<mat-card class="contact-detail">
  <mat-card-header>
    <mat-card-title>
      {{contact.first_name}} {{contact.last_name}}
    </mat-card-title>
    <mat-card-subtitle>
      {{contact.title}}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="spacer">
        <div class=row>
          <span>Office</span><span class="spacer"></span><span>{{contact.office}}</span>
        </div>
        <div class=row>
          <span>Home</span><span class="spacer"></span><span>{{contact.home}}</span>
        </div>
        <div class=row>
          <span>Mobile</span><span class="spacer"></span><span>{{contact.mobile}}</span>
        </div>
        <div class=row>
          <span>Fax</span><span class="spacer"></span><span>{{contact.fax}}</span>
        </div>
      </div>
      <span class="spacer"></span>
      <div class="spacer">
        <div class=row>
          <span>E-Mail</span><span class="spacer"></span><span>{{contact.primary_email}}</span>
        </div>
        <div class=row>
          <span>E-Mail</span><span class="spacer"></span><span>{{contact.secondary_email}}</span>
        </div>
        <div class=row>
          <span>Website</span><span class="spacer"></span><span>{{contact.website}}</span>
        </div>
      </div>
      <span class="spacer"></span>
      <div class="spacer">
        <div class=row>
          <span>Facebook</span><span class="spacer"></span><span>{{contact.facebook}}</span>
        </div>
        <div class=row>
          <span>Twitter</span><span class="spacer"></span><span>{{contact.twitter}}</span>
        </div>
        <div class=row>
          <span>Linkedin</span><span class="spacer"></span><span>{{contact.linkedin}}</span>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions class="row">
    <span class="spacer"></span>
    <button mat-button [routerLink]="['edit']">Edit</button>
  </mat-card-actions>
</mat-card>
