<link
  href="https://unpkg.com/font-awesome@4.7.0/css/font-awesome.css"
  rel="stylesheet"
/>

<div fxFlexFill class="calendar-wrapper">
  <mat-sidenav-container fxFlexFill>
    <mat-sidenav
      [disableClose]="isSidenavCloseDisabled"
      [mode]="sidenavPosition"
      [opened]="isSidenavOpen"
    >
      <tree-checklist (filter)="filter_tree($event)"></tree-checklist>
    </mat-sidenav>
    <mat-sidenav-content>
      <div fxFlex fxLayout="column" id="calendar">
        <!--
        <ng-template #modalContent let-close="close">
          <div class="modal-header">
            <h5 class="modal-title">Event action occurred</h5>
            <button
              type="button"
              class="close"
              #close_btn1
              (click)="close(close_btn1)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              Data:
              <pre>{{ modalData?.event.meta.data | json }}</pre>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              #close_btn2
              (click)="close(close_btn2)"
            >
              OK
            </button>
          </div>
        </ng-template>
        -->
        <br />
        <ng-template #loading>
          <div class="text-center">
            <i class="fa fa-spin fa-spinner fa-5x"></i>
            <br />
            Loading events...
          </div>
        </ng-template>

        <div [class]="isSidenavOpen ? calender_class : 'row text-center'">
          <div class="col-md-4">
            <div class="btn-group">
              <div
                class="btn btn-primary"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="calenderEvents(null, viewDate)"
              >
                Previous
              </div>
              <div
                class="btn btn-outline-secondary"
                mwlCalendarToday
                [(viewDate)]="viewDate"
                (viewDateChange)="calenderEvents(null, viewDate)"
              >
                Today
              </div>
              <div
                class="btn btn-primary"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="calenderEvents(null, viewDate)"
              >
                Next
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <h3>{{ viewDate | calendarDate : view + "ViewTitle" : "en" }}</h3>
          </div>
          <div class="col-md-4">
            <div class="btn-group">
              <div
                class="btn btn-primary"
                #calenderEvents_month_btn
                (click)="
                  calenderEvents(
                    calenderEvents_month_btn,
                    (view = CalendarView.Month)
                  )
                "
                [class.active]="view === CalendarView.Month"
              >
                Month
              </div>
              <div
                class="btn btn-primary"
                #calenderEvents_week_btn
                (click)="
                  calenderEvents(
                    calenderEvents_week_btn,
                    (view = CalendarView.Week)
                  )
                "
                [class.active]="view === CalendarView.Week"
              >
                Week
              </div>
              <div
                class="btn btn-primary"
                #calenderEvents_day_btn
                (click)="
                  calenderEvents(
                    calenderEvents_day_btn,
                    (view = CalendarView.Day)
                  )
                "
                [class.active]="view === CalendarView.Day"
              >
                Day
              </div>
            </div>
          </div>
        </div>
        <br />

        <!--<context-menu #basicMenu>-->
        <!--<ng-template contextMenuItem (execute)="addEvent($event)">-->
        <!--Add Flight-->
        <!--</ng-template>-->

        <!--<ng-template contextMenuItem divider="true"></ng-template>-->

        <!--<ng-template contextMenuItem (execute)="addEvent($event)">-->
        <!--Add Mission-->
        <!--</ng-template>-->
        <!--</context-menu>-->

        <ng-template
          #monthCellTemplate
          let-day="day"
          let-openDay="openDay"
          let-locale="locale"
          let-highlightDay="highlightDay"
          let-unhighlightDay="unhighlightDay"
          let-eventClicked="eventClicked"
        >
          <div
            class="fill-height"
            [contextMenu]="basicMenu"
            [contextMenuSubject]="day.date"
          >
            <div class="cal-cell-top">
              <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
                day.badgeTotal
              }}</span>
              <span class="cal-day-number">{{
                day.date | calendarDate : "monthViewDayNumber" : locale
              }}</span>
            </div>
            <div class="cal-events cell-totals">
              <span
                *ngFor="let group of day.eventGroups"
                class="badge badge-{{ group[0] }}"
                [ngClass]="event?.cssClass"
              >
                {{ group[1].length }}
              </span>
            </div>
          </div>
        </ng-template>

        <ng-template
          #eventActionsTemplate
          let-event="event"
          let-trackByActionId="trackByActionId"
        >
          <span *ngIf="event.actions" class="cal-event-actions">
            <a
              class="cal-event-action"
              *ngFor="let action of event.actions; trackBy: trackByActionId"
              [routerLink]="[
                '/',
                route_select(event.meta.eventType),
                event.id,
                'edit'
              ]"
              [ngClass]="action.cssClass"
              [innerHtml]="action.label"
              tabindex="0"
              role="button"
              [attr.aria-label]="{ action: action }"
            >
            </a>
          </span>
        </ng-template>

        <ng-template
          #weekHeaderTemplate
          let-days="days"
          let-locale="locale"
          let-dayHeaderClicked="dayHeaderClicked"
        >
          <div class="cal-day-headers">
            <div
              class="cal-header"
              *ngFor="let day of days"
              [class.cal-past]="day.isPast"
              [class.cal-today]="day.isToday"
              [class.cal-future]="day.isFuture"
              [class.cal-weekend]="day.isWeekend"
              (click)="dayHeaderClicked.emit({ day: day })"
              [contextMenu]="basicMenu"
              [contextMenuSubject]="day.date"
            >
              <b>{{
                day.date | calendarDate : "weekViewColumnHeader" : locale
              }}</b
              ><br />
              <span>{{
                day.date | calendarDate : "weekViewColumnSubHeader" : locale
              }}</span>
            </div>
          </div>
        </ng-template>

        <ng-template
          #dayHourSegmentTemplate
          let-segment="segment"
          let-locale="locale"
        >
          <div
            class="cal-hour-segment"
            [ngClass]="segment.cssClass"
            [contextMenu]="basicMenu"
            [contextMenuSubject]="segment.date"
          >
            <div [hidden]="!segment.isStart" class="cal-time">
              {{ segment.date | calendarDate : "dayViewHour" : locale }}
            </div>
          </div>
        </ng-template>

        <div
          fxFlex
          *ngIf="events$ | async; else loading; let events"
          [ngSwitch]="view"
        >
          <mwl-calendar-month-view
            *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [events]="events"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            [cellTemplate]="monthCellTemplate"
            [eventActionsTemplate]="eventActionsTemplate"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event, $event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            (beforeViewRender)="beforeMonthViewRender($event)"
          >
          </mwl-calendar-month-view>
          <mwl-calendar-week-view
            *ngSwitchCase="CalendarView.Week"
            [viewDate]="viewDate"
            [events]="events"
            [refresh]="refresh"
            [headerTemplate]="weekHeaderTemplate"
            [eventActionsTemplate]="eventActionsTemplate"
            (eventTimesChanged)="eventTimesChanged($event)"
            (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          >
          </mwl-calendar-week-view>
          <mwl-calendar-day-view
            *ngSwitchCase="CalendarView.Day"
            [viewDate]="viewDate"
            [events]="events"
            [refresh]="refresh"
            [hourSegmentTemplate]="dayHourSegmentTemplate"
            [eventActionsTemplate]="eventActionsTemplate"
            (eventTimesChanged)="eventTimesChanged($event)"
            (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          >
          </mwl-calendar-day-view>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <button mat-mini-fab *ngIf="this.nextbtn" (click)="sidenav('sidebar')">
    <mat-icon>skip_next</mat-icon>
  </button>
  <button mat-mini-fab *ngIf="this.previousbtn" (click)="sidenav('calendar')">
    <mat-icon>skip_previous</mat-icon>
  </button>
</div>
