import { Subject, Observable } from "rxjs";
import { Injectable } from "@angular/core";

export interface ErrorReport {
  message: string;
  data: any;
}
@Injectable()
export class ErrorHandleService {
  error: Subject<ErrorReport> = new Subject<ErrorReport>();

  sendError(message: string, data?: any) {
    this.error.next({ message: message, data: data });
  }

  getError(): Observable<ErrorReport> {
    return this.error.asObservable();
  }
}
