<div class="example-header">
    <mat-form-field floatPlaceholder="never">
        <input matInput #filter placeholder="Filter Routes">
    </mat-form-field>
</div>

<div class="mat-header-row">
    <div class="mat-header-cell"> &nbsp; </div>
    <div class="mat-header-cell"> Real Time </div>
    <div class="mat-header-cell" *ngIf='data_logging'> Data Logging </div>
</div>

<mat-table #table [dataSource]="data_source" matSort>

    <ng-container matColumnDef="route">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Route Name </mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.route}}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scm_requested">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Requested </mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.scm_requested}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="scm_collected">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Collected </mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.scm_collected}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="idm_requested">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Requested </mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.idm_requested}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="idm_collected">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Collected </mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.idm_collected}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="idm_percentage">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Percentage </mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.idm_percentage|number}}%
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="scm_percentage">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Percentage </mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.scm_percentage|number}}%
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayed_columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayed_columns;" [ngClass]='row.status'></mat-row>
</mat-table>