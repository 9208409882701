import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { combineLatest, Subscription } from "rxjs";

import { ApiService } from "@app/api/api";
import { MapStoreService } from "@app/services/store/map/sidebar/mapstore.service";
import { map } from "rxjs/operators";
import { Route } from "@app/store/map/sidebar/map_reducer";

export interface RouteState {
  area: boolean;
  meters: boolean;
  id: any;
}

@Component({
  selector: "mission-routes",
  templateUrl: "./mission.routes.html",
  styleUrls: ["./missions.component.css"],
})
export class MissionRoutesComponent implements OnInit, OnDestroy {
  @Input("mission") mission_id;
  @Output("update") state_change: EventEmitter<any> = new EventEmitter<any>();

  routes = [];
  routeState = {};
  allRoutesState = {};
  component = true;
  $routes: Subscription;

  constructor(private api: ApiService, private mapstore: MapStoreService) {}

  ngOnInit() {
    this.get_route_data();
  }

  update_route_data([route_state, data]) {
    this.routes = data.map((value) => {
      return {
        ...value,
        _area: route_state[value.id]?.area || false,
        _meters: route_state[value.id]?.meters || false,
      };
    });

    const update = data
      .filter((route) => route_state[route.id] === undefined)
      .map((route) => new Route(route.id, route.name));

    this.mapstore.add_mission_routes(this.mission_id, update);
  }

  get_route_data() {
    this.$routes = combineLatest([
      this.mapstore.get_mission_routes(this.mission_id),
      this.api.customer_route
        .list({ mission: this.mission_id })
        .pipe(map((data) => data["results"])),
    ]).subscribe((res) => this.update_route_data(res));
  }

  route(route_id: number) {
    return this.routes.find((value) => value.id == route_id);
  }

  update_area(route_id: number) {
    this.mapstore.show_mission_route_area(
      this.mission_id,
      route_id,
      this.route(route_id)._area
    );
    this.state_change.emit();
  }

  update_meters(route_id: number) {
    this.mapstore.show_mission_route_meters(
      this.mission_id,
      route_id,
      this.route(route_id)._meters
    );
    this.state_change.emit();
  }

  ngOnDestroy() {
    if (this.$routes) {
      this.$routes.unsubscribe();
    }
  }
}
