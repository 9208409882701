<mat-toolbar color="primary" fxLayout="row">
  <span fxFlex>Update aircraft TTSN</span>
  <span fxFlex fxLayoutAlign="end">
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      matTooltip="Close Dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </span>
</mat-toolbar>

<mat-card>
  <div fxLayout="column">
    <mat-card-content>
      <div fxLayout="column">
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <input
              matInput
              class="row"
              [placeholder]="'Date and Time'"
              [(ngModel)]="record.timestamp"
            />
          </mat-form-field>
          <mat-form-field fxFlex>
            <input
              matInput
              class="row"
              [placeholder]="'TTSN'"
              [(ngModel)]="record.ttsn"
            />
          </mat-form-field>
        </div>

        <div fxLayout="row" *ngIf="error_message">
          <mat-error>{{ error_message }}</mat-error>
        </div>
      </div>
      <div *ngIf="error_message">{{ error_message }}</div>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row">
        <span fxFlex>
          <button type="button" mat-button (click)="dialogRef.close()">
            Cancel
          </button>
        </span>
        <span fxFlex fxLayoutAlign="end">
          <button
            type="button"
            mat-raised-button
            #submit_btn
            (click)="submit(submit_btn)"
          >
            OK
          </button>
        </span>
      </div>
    </mat-card-actions>
  </div>
</mat-card>
