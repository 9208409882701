import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { SocialUser } from "@abacritt/angularx-social-login";
import { User } from "../types";
import { Observable } from "rxjs";

export type Token = string | null;
export interface TokenPair {
  access?: Token;
  refresh?: Token;
}

@Injectable()
export class UserService extends BaseApiService<User> {
  component: string = "user";
  customer_users(customer_id: number) {
    return this.list({ customer: customer_id });
  }

  login(username: string, password: string): Observable<TokenPair> {
    let body = JSON.stringify({ username: username, password: password });
    return this.http_client.post(
      this.config.api_server + "/api-token-auth/",
      body,
      { headers: contentHeaders() }
    );
  }

  refresh_token(refresh: string): Observable<TokenPair> {
    return this.http_client.post(
      this.config.api_server + "/api-token-refresh/",
      { refresh: refresh },
      { headers: contentHeaders() }
    );
  }

  social_login(token: string): Observable<TokenPair> {
    return this.http_client.post(
      this.config.api_server + "/api-auth/social/google-oauth2/",
      { access_token: token },
      { headers: contentHeaders() }
    );
  }

  social_config() {
    return this.http_client.get(
      this.config.api_server + "/api-auth/social/config/",
      { headers: contentHeaders() }
    );
  }

  profile(id: any) {
    return this.get_with_retry(this.function_url("profile", id), {
      headers: contentHeaders(),
    });
  }
}
