import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { Title } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";

import { ApiService } from "@app/api/api";
import { PayloadComponent } from "./component";
import { MaterialModule } from "@app/material.module";
import { PayloadDetailComponent } from "./detail/detail.component";
import { PayloadAssignmentListComponent } from "./assignment-list/list.component";
import { PayloadListComponent } from "./list/list.component";
import { PayloadFormComponent } from "./form/form.component";
import { NoteModule } from "@app/note/module";
import { GenericListModule } from "@app/generic-list/module";
import { GenericFilterModule } from "@app/generic-filter/module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NoteModule,
    FormsModule,
    GenericListModule,
    GenericFilterModule,
    FlexLayoutModule,
  ],
  exports: [
    PayloadComponent,
    PayloadDetailComponent,
    PayloadListComponent,
    PayloadFormComponent,
    PayloadAssignmentListComponent,
  ],
  providers: [ApiService, Title],
  declarations: [
    PayloadComponent,
    PayloadDetailComponent,
    PayloadListComponent,
    PayloadFormComponent,
    PayloadAssignmentListComponent,
  ],
})
export class PayloadModule {}
