import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { EndpointIcon } from "@cleargrid/map_layers";

@Component({
  selector: "endpoint-style",
  templateUrl: "./endpoint_style.html",
  styleUrls: ["./dialog.component.css"],
  providers: [],
})
export class EndpointStyleComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input()
  icon: EndpointIcon = null;
  @Output()
  iconChange: EventEmitter<EndpointIcon> = new EventEmitter<EndpointIcon>();

  @Input()
  label: string = "Unknown";

  @ViewChild("canvas") canvas: ElementRef;
  constructor() {}

  ngOnInit() {
    this.normalize();
  }
  ngOnDestroy() {}
  ngAfterViewInit() {
    console.log(this.canvas);
    this.update_preview();
  }

  normalize() {
    let style = this.icon.style;
    style.style = style.style || "circle";
    style.colour = style.colour || "RGBA(0,0,0,0,)";
    style.size = style.size || 5;
    style.width = style.width || 1;
  }
  update_preview() {
    const canvas = this.canvas.nativeElement;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      this.icon.canvas,
      0,
      0,
      30,
      (30 * this.icon.canvas.height) / this.icon.canvas.width
    );
  }

  update() {
    this.icon._render();
    this.update_preview();
    this.iconChange.emit(this.icon);
  }

  set_colour(value: string) {
    this.icon.style.colour = value;
    this.update();
  }
}
