import { Action, createAction, props, createReducer, on } from "@ngrx/store";
import * as L from "leaflet";

export enum MapActionTypes {
  SetState = "[map] Set Map State",
  SetBounds = "[map] Set Map Bounds",
  SetLayer = "[map] Set Map Layer",
  SelectTab = "[map] Select Tab",
  SelectAircraft = "[map] Select Aircraft",
  UnselectAircraft = "[map] Unselect Aircraft",
  SelectProfile = "[map] Select Profile",
  UnselectProfile = "[map] Unselect Profile",
  SetTrackColour = "[map] Set Track Colour",
  SetTrackRequest = "[map] Set Track Request",
  AddMeterGroup = "[map] Add Meter Group",
  RemoveMeterGroup = "[map] Remove Meter Group",
  ShowMeterGroup = "[map] Show Meter Group",
  HideMeterGroup = "[map] Hide Meter Group",

  AddMission = "[map] Add Mission",
  RemoveMission = "[map] Remove Mission",
  ClearMissions = "[map] Clear all missions",
  ShowMission = "[map] Show Mission",
  MissionExpanded = "[map] Mission Panel Expanded",
  ShowMissionArea = "[map -> mission] Show Mission Area",
  ShowMissionReceived = "[map -> mission] Show Received Meters",
  AddMissionRoutes = "[map -> mission] add routes",
  ShowMissionRouteMeters = "[map -> mission -> route] Show Route Meters",
  ShowMissionRouteArea = "[map -> mission -> route] Show Route Area",
  AddMissionFlights = "[map -> mission] add flights",
  ShowMissionFlightPlan = "[map -> mission -> flight] Show Flight Plan",
  ShowMissionFlightTrack = "[map -> mission -> flight] Show Flight Track",
}

export interface TrackRequest {
  span: number;
  direction: "before" | "after";
  base: string;
}

export class AircraftState {
  constructor(
    public selected: boolean = true,
    public profile: boolean = false,
    public track_colour: any = "blue",
    public request: TrackRequest = null
  ) {}
}

export class Route {
  constructor(
    public id: number,
    public name: string,
    public meters: boolean = false,
    public area: boolean = false
  ) {}
}

export class Flight {
  constructor(
    public id: number,
    public plan_id: number,
    public track_id: number,
    public plan: boolean = false,
    public track: boolean = false
  ) {}
}
export class Mission {
  constructor(
    public mission_id: number,
    public routes: { [id: number]: Route } = {},
    public flights: { [id: number]: Flight } = {}
  ) {}
}

export class MissionState {
  constructor(
    public mission: Mission,
    public display: boolean = true,
    public expanded: boolean = false,
    public show_area: boolean = false,
    public hide_recieved: boolean = false
  ) {}
}

export class MeterGroup {
  constructor(
    public id: number,
    public group_name: string,
    public group_id: string
  ) {}
}

export class Customer {
  constructor(public id: number, public name: string) {}
}

export class MeterGroupState {
  constructor(
    public display: boolean = true,
    public metergroup: MeterGroup,
    public customer: { id: number; name: string }
  ) {}
}

export interface MapState {
  tab: number;
  aircraft: { [id: number]: AircraftState };
  meter_groups: MeterGroupState[];
  mission: { [id: number]: MissionState };
  layer: String;
  bounds: [[number, number], [number, number]];
}

const initialState: MapState = {
  tab: 2,
  aircraft: {},
  meter_groups: [],
  mission: {},
  layer: "OSM",
  bounds: [
    [60, -110],
    [49, 120],
  ],
};

export const setState = createAction(
  MapActionTypes.SetState,
  props<{ state: MapState }>()
);
export const setBounds = createAction(
  MapActionTypes.SetBounds,
  props<{ bounds: [[number, number], [number, number]] }>()
);
export const setLayer = createAction(
  MapActionTypes.SetLayer,
  props<{ layer: string }>()
);

export const selectTab = createAction(
  MapActionTypes.SelectTab,
  props<{ tab: number }>()
);

export const selectAircraft = createAction(
  MapActionTypes.SelectAircraft,
  props<{ aircraft_id: number }>()
);

export const unselectAircraft = createAction(
  MapActionTypes.UnselectAircraft,
  props<{ aircraft_id: number }>()
);
export const selectProfile = createAction(
  MapActionTypes.SelectProfile,
  props<{ aircraft_id: number }>()
);
export const unselectProfile = createAction(
  MapActionTypes.UnselectProfile,
  props<{ aircraft_id: number }>()
);
export const setTrackColour = createAction(
  MapActionTypes.SetTrackColour,
  props<{ aircraft_id: number; track_colour: string | number }>()
);
export const setTrackRequest = createAction(
  MapActionTypes.SetTrackRequest,
  props<{ aircraft_id: number; request: TrackRequest }>()
);
export const addMeterGroup = createAction(
  MapActionTypes.AddMeterGroup,
  props<{ metergroup: MeterGroupState }>()
);
export const removeMeterGroup = createAction(
  MapActionTypes.RemoveMeterGroup,
  props<{ metergroup_id: number }>()
);
export const showMeterGroup = createAction(
  MapActionTypes.ShowMeterGroup,
  props<{ metergroup_id: number }>()
);
export const hideMeterGroup = createAction(
  MapActionTypes.HideMeterGroup,
  props<{ metergroup_id: number }>()
);

export const addMission = createAction(
  MapActionTypes.AddMission,
  props<{ mission: MissionState }>()
);
export const showMission = createAction(
  MapActionTypes.ShowMission,
  props<{ mission_id: number; show: boolean }>()
);
export const removeMission = createAction(
  MapActionTypes.RemoveMission,
  props<{ mission_id: number }>()
);
export const expandMission = createAction(
  MapActionTypes.MissionExpanded,
  props<{ mission_id: number; expanded: boolean }>()
);

export const clearMissions = createAction(MapActionTypes.ClearMissions);
// ShowMissionArea = "[map -> mission] Show Mission Area",
export const showMissionArea = createAction(
  MapActionTypes.ShowMissionArea,
  props<{ mission_id: number; show: boolean }>()
);
// ShowMissionReceived = "[map -> mission] Show Mission Area",
export const showMissionReceived = createAction(
  MapActionTypes.ShowMissionReceived,
  props<{ mission_id: number; show: boolean }>()
);
export const addMissionRoutes = createAction(
  MapActionTypes.AddMissionRoutes,
  props<{ mission_id: number; routes: Route[] }>()
);
// ShowMissionRouteMeters = "[map -> mission -> route] Show Route Meters",
export const showMissionRouteMeters = createAction(
  MapActionTypes.ShowMissionRouteMeters,
  props<{ mission_id: number; route_id: number; show: boolean }>()
);
// ShowMissionRouteArea = "[map -> mission -> route] Show Route Area",
export const showMissionRouteArea = createAction(
  MapActionTypes.ShowMissionRouteArea,
  props<{ mission_id: number; route_id: number; show: boolean }>()
);
// AddMissionFlight = "[map -> mission] add flight",
export const addMissionFlights = createAction(
  MapActionTypes.AddMissionFlights,
  props<{ mission_id: number; flights: Flight[] }>()
);
// ShowMissionFlightPlan = "[map -> mission -> flight] Show Flight Plan",
export const showMissionFlightPlan = createAction(
  MapActionTypes.ShowMissionFlightPlan,
  props<{ mission_id: number; flight_id: number; show: boolean }>()
);
// ShowMissionFlightTrack = "[map -> mission -> flight] Show Flight Track",
export const showMissionFlightTrack = createAction(
  MapActionTypes.ShowMissionFlightTrack,
  props<{ mission_id: number; flight_id: number; show: boolean }>()
);

function aircraft_property(
  property_name: string,
  value: boolean | ((action: any) => any),
  required: boolean = false
) {
  return (state: MapState, action: any) => {
    let aircraft_id = action.aircraft_id;
    let result = { ...state };
    let d: AircraftState = new AircraftState();

    if (aircraft_id in result.aircraft) {
      d = { ...result.aircraft[aircraft_id] };
    } else if (!required) {
      return result;
    }
    d[property_name] = typeof value === "function" ? value(action) : value;
    result.aircraft[aircraft_id] = d;
    return result;
  };
}
function clone_missionstate(missions_states: { [id: number]: MissionState }) {
  let missions: { [id: number]: MissionState } = { ...missions_states };
  // Object.keys(missions).forEach((key) => {
  //   const base = missions[key];
  //   let mission: MissionState = {
  //     ...base,
  //     mission: {
  //       ...base.mission,
  //       routes: { ...base.mission.routes },
  //       flights: { ...base.mission.flights },
  //     },
  //   };
  //   missions[key] = mission;
  // });
  return missions;
}

export const mapReducer = createReducer(
  initialState,
  on(setState, (s: MapState, { state }) => ({ ...state })),
  on(setBounds, (state: MapState, { bounds }) => {
    state.bounds = bounds;
    return state;
  }),
  on(setLayer, (state: MapState, { layer }) => {
    state.layer = layer;
    return state;
  }),
  on(selectTab, (state: MapState, { tab }) => ({ ...state, tab: tab })),
  on(selectAircraft, aircraft_property("selected", true, true)),
  on(unselectAircraft, aircraft_property("selected", false)),
  on(selectProfile, aircraft_property("profile", true)),
  on(unselectProfile, aircraft_property("profile", false)),
  on(
    setTrackColour,
    aircraft_property("track_colour", (action) => action.track_colour)
  ),
  on(
    setTrackRequest,
    aircraft_property("request", (action) => action.request)
  ),
  on(addMeterGroup, (state: MapState, { metergroup }) => {
    let s = { ...state };
    s.meter_groups.push(metergroup);
    return s;
  }),
  on(removeMeterGroup, (state: MapState, { metergroup_id }) => {
    let s = {
      ...state,
      meter_groups: state.meter_groups.filter(
        (e) => e.metergroup.id != metergroup_id
      ),
    };
    return s;
  }),
  on(showMeterGroup, (state: MapState, { metergroup_id }) => {
    let s = { ...state };
    s.meter_groups.forEach((e: MeterGroupState) => {
      if (e.metergroup.id == metergroup_id) {
        e.display = true;
      }
    });
    return s;
  }),
  on(hideMeterGroup, (state: MapState, { metergroup_id }) => {
    let s = { ...state };
    s.meter_groups.forEach((e: MeterGroupState) => {
      if (e.metergroup.id == metergroup_id) {
        e.display = true;
      }
    });
    return s;
  }),
  on(addMission, (state: MapState, { mission }) => {
    let s = { ...state };
    s.mission = clone_missionstate(s.mission);
    s.mission[mission.mission.mission_id] = mission;
    return s;
  }),
  on(showMission, (state: MapState, { mission_id, show }) => {
    let s = { ...state };
    s.mission = clone_missionstate(s.mission);
    s.mission[mission_id].display = show;
    return s;
  }),
  on(expandMission, (state: MapState, { mission_id, expanded }) => {
    let s = { ...state };
    s.mission = clone_missionstate(s.mission);
    s.mission[mission_id].expanded = expanded;
    return s;
  }),
  on(removeMission, (state: MapState, { mission_id }) => {
    let s = { ...state };
    s.mission = clone_missionstate(s.mission);
    delete s.mission[mission_id];
    return s;
  }),
  on(showMissionArea, (state: MapState, { mission_id, show }) => {
    let s = { ...state };
    s.mission = clone_missionstate(s.mission);
    s.mission[mission_id].show_area = show;
    return s;
  }),
  on(showMissionReceived, (state: MapState, { mission_id, show }) => {
    let s = { ...state };
    s.mission = clone_missionstate(s.mission);

    s.mission[mission_id].hide_recieved = show;
    return s;
  }),
  on(clearMissions, (state: MapState) => {
    let s = { ...state };
    s.mission = {};
    return s;
  }),
  on(addMissionRoutes, (state: MapState, { mission_id, routes }) => {
    let s = { ...state };
    s.mission = clone_missionstate(s.mission);
    let r = { ...s.mission[mission_id].mission.routes };
    routes.forEach((route) => {
      r[route.id] = route;
    });
    s.mission[mission_id].mission.routes = r;
    return s;
  }),
  // ShowMissionRouteMeters = "[map -> mission -> route] Show Route Meters",
  on(
    showMissionRouteMeters,
    (state: MapState, { mission_id, route_id, show }) => {
      let s = { ...state };
      s.mission = clone_missionstate(s.mission);
      let r = { ...s.mission[mission_id].mission.routes };
      r[route_id].meters = show;
      s.mission[mission_id].mission.routes = r;
      return s;
    }
  ),
  // ShowMissionRouteArea = "[map -> mission -> route] Show Route Area",
  on(
    showMissionRouteArea,
    (state: MapState, { mission_id, route_id, show }) => {
      let s = { ...state };
      s.mission = clone_missionstate(s.mission);
      let r = { ...s.mission[mission_id].mission.routes };
      r[route_id].area = show;
      s.mission[mission_id].mission.routes = r;
      return s;
    }
  ),
  // AddMissionFlight = "[map -> mission] add flight",
  on(addMissionFlights, (state: MapState, { mission_id, flights }) => {
    let s = { ...state };
    s.mission = clone_missionstate(s.mission);
    let f = { ...s.mission[mission_id].mission.flights };
    flights.forEach((flight) => {
      f[flight.id] = flight;
    });
    s.mission[mission_id].mission.flights = f;
    return s;
  }),
  // ShowMissionFlightPlan = "[map -> mission -> flight] Show Flight Plan",
  on(
    showMissionFlightPlan,
    (state: MapState, { mission_id, flight_id, show }) => {
      let s = { ...state };
      s.mission = clone_missionstate(s.mission);
      let f = { ...s.mission[mission_id].mission.flights };
      f[flight_id].plan = show;
      s.mission[mission_id].mission.flights = f;
      return s;
    }
  ),
  // ShowMissionFlightTrack = "[map -> mission -> flight] Show Flight Track",
  on(
    showMissionFlightTrack,
    (state: MapState, { mission_id, flight_id, show }) => {
      let s = { ...state };
      s.mission = clone_missionstate(s.mission);
      let f = { ...s.mission[mission_id].mission.flights };
      f[flight_id].track = show;
      s.mission[mission_id].mission.flights = f;
      return s;
    }
  )
);

export function MapReducer(state = initialState, action: Action): MapState {
  return mapReducer(state, action);
}
