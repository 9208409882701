import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

export interface FieldDefinition {
  type: "date" | "number" | "string";
  label: string;
  target: string;
  choices?: { [label: string]: string | number };
  value?: any;
}
export interface ReportNameDef {
  label: string;
  target: string;
}
export type ReportRecords = { [field: string]: any }[];
export type FieldDefinitions = FieldDefinition[];

@Injectable()
export class ReportService extends BaseApiService<unknown> {
  component: string = "reports";

  get_reports(): Observable<ReportNameDef[]> {
    return of([{ label: "Flight Times", target: "flighttime" }]);
  }

  get_fields(report: string): Observable<FieldDefinitions> {
    return this.get_with_retry(this.item_url(report)).pipe(
      map((a) => {
        return a as FieldDefinitions;
      })
    );
  }

  generate_report(
    report: string,
    params: { [key: string]: any }
  ): Observable<ReportRecords> {
    return this.post_with_retry(
      this.function_url("generate", report),
      params
    ).pipe(
      map((a) => {
        return a as ReportRecords;
      })
    );
  }

  download_report(
    report: string,
    params: { [key: string]: any },
    format: "csv" = "csv"
  ): Observable<any> {
    let url = this.function_url("generate", report);
    return this.post_with_retry(url, params, {
      headers: { Accept: `text/${format}` },
      responseType: "blob" as "json",
      observe: "response" as "body",
    });
  }
}
