import { ModuleWithProviders, NgModule, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';


@Pipe({ name: 'mapToIterable' })
export class MapToIterable implements PipeTransform {
  transform(dict: Object): any {
    let a = [];
    for (var key in dict) {
      if (dict.hasOwnProperty(key)) {
        a.push({ key: key, val: dict[key] });
      }
    }
    return a;
  }
}

@Pipe({ name: 'len' })
export class LengthPipe implements PipeTransform {
    transform(obj: Object): number {
        if ('length' in obj) {
            return obj['length']
        }
        let c = 0;
        for (var k in obj) {
            if (obj.hasOwnProperty(k)) {
                c++
            }
        }
        return c
    }
}

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    let keys = [];
    for (let key in value) {
      keys.push({ key: key, value: value[key] });
    }
    return keys;
  }
}

@Pipe({ name: 'bytes' })
export class BytesPipe implements PipeTransform {
  symbols = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  transform(value, args: string[]): any {
    let symbol;
    for (symbol of this.symbols ) {
      if (value < 1024) {
        break
      }
      value = value / 1024;
    }
    value = Math.round(value * 10) / 10;
    return value + ' ' + symbol
  }
}

@Pipe({ name: 'momentPipe' })
export class MomentPipe implements PipeTransform {
    transform(value: Date|moment.Moment, ...args: any[]): any {
        const [format] = args;
        if (value) {
          return moment(value).format(format);
        }
    }
}

@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {
    transform(value: string, strToReplace: string, replacementStr: string): string {
        if (!value || ! strToReplace || ! replacementStr) {
            return value;
        }
        return value.replace(new RegExp(strToReplace, 'g'), replacementStr);
    }
}


@NgModule({
    imports: [],
    exports: [MomentPipe],
    declarations: [MomentPipe],
    providers: []
})
export class PipeModule {
    static forRoot(): ModuleWithProviders<PipeModule> {
        return {
            ngModule: PipeModule,
            providers: [ ]
        };
    }
}
