<mat-card>
  <mat-card-title>
    <div fxLayout="row">
      <span fxFlex class="header_label">Reports</span>
      <span fxFlex></span>
    </div>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>
    <div fxLayout="row">
      <mat-form-field appearance="fill">
        <mat-label>Report</mat-label>
        <mat-select (selectionChange)="set_report($event)">
          <mat-option
            *ngFor="let report of available_reports"
            [value]="report.target"
          >
            {{report.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="active_report">
      <div>
        <div fxLayout="row" fxLayoutAlign="space-around">
          <span *ngFor="let field of report_fields">
            <mat-form-field appearance="fill" *ngIf="field.choices">
              <mat-label>{{field.label}}</mat-label>
              <mat-select [(value)]="field.value">
                <mat-option
                  *ngFor="let choice of field.choices | keyvalue"
                  [value]="choice.value"
                >
                  {{choice.key}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="field.type=='date'">
              <mat-label>{{field.label}}</mat-label>
              <input matInput type="date" [(ngModel)]="field.value" />
            </mat-form-field>
          </span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around">
          <button
            mat-raised-button
            #generate_report_btn
            (click)="generate_report(generate_report_btn)"
            *ngIf="!generating"
          >
            Generate Report
          </button>
          <span *ngIf="generating">
            Generating Report <mat-spinner></mat-spinner>
          </span>
          <button
            mat-raised-button
            #download_report_btn
            (click)="download_report(download_report_btn)"
            *ngIf="!downloading"
          >
            Download Report
          </button>
          <span *ngIf="downloading">
            Downloading Report <mat-spinner></mat-spinner>
          </span>
        </div>
      </div>
    </div>
    <div fxLayout="column">
      <app-generic-list
        fxFlex
        [source]="report_records()"
        [displayedColumns]="['status']"
      >
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.status}} </mat-cell>
        </ng-container>
      </app-generic-list>
    </div>
  </mat-card-content>
</mat-card>
