// Import our dependencies
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { SelectivePreloadingStrategy } from "./selective-preloading-strategy";
import { CustomerRoute } from "./customer/route";
import { EmployeeRoute } from "./employee/route";
import { ContactRoute } from "./contact/route";
import { RouteRoute } from "./customer_route/route";
import { FlightRoute } from "./flight/route";
import { AirplaneRoute } from "./airplane/route";
import { PayloadRoute } from "./payload/route";
import { AirportRoute } from "./airport/route";
import { MissionRoutes } from "@app/mission/route.module";
import { CustomerJobRoutes } from "@app/customerjob/route.module";
import { ImageryProjectRoutes } from "@app/imageryproject/route.module";
import { UserProfileRoute } from "@app/user/user.route";
import { LoginComponent } from "./login/login.component";
import { ReportComponent } from "./reports/report";
import { HomeComponent } from "./home/home.component";
import { DebugComponent } from "./debug/debug";
import { AuthGuard } from "./common/auth.guard";
import { CalenderComponent } from "@app/calender/calender.component";
import { MeterReadingDetailComponent } from "@app/meter_reading/detail/detail.component";
import { QualificationRoute } from "./qualification/route";
import { SearchRoute } from "./search/route";

// Define which component should be loaded based on the current URL
export const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "debug",
    component: DebugComponent,
  },
  {
    path: "calendar",
    component: CalenderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports",
    component: ReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "map",
    loadChildren: () => import("./map/module").then((m) => m.MapModule),
  },
  CustomerRoute,
  MissionRoutes,
  CustomerJobRoutes,
  ImageryProjectRoutes,
  AirportRoute,
  AirplaneRoute,
  ContactRoute,
  FlightRoute,
  EmployeeRoute,
  RouteRoute,
  UserProfileRoute,
  QualificationRoute,
  SearchRoute,
  PayloadRoute,
  {
    path: "meter_reading/:id",
    component: MeterReadingDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    component: HomeComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    // enableTracing: true, // <-- debugging purposes only
    preloadingStrategy: SelectivePreloadingStrategy,
    useHash: true
}),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, SelectivePreloadingStrategy],
})
export class AppRoutingModule {}
