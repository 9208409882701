import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef,
} from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import * as L from "leaflet";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { isThisISOWeek } from "date-fns";

@Component({
  selector: "airport-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class AirportFormComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild("map", { static: false }) airport_map;
  airport: { [id: string]: any } = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "point",
      coordinates: [null, null],
    },
  };
  coord: L.LatLng = new L.LatLng(null, null);
  id: number;
  altitude_conversion_factor: number = 1;
  title = "Add New Airport";
  form_errors = { detail: null };
  $airport_get: Subscription;
  $airport_post: Subscription;
  isPolygon: true;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private errorHandleService: ErrorHandleService,
    private titleService: Title,
    private cdr: ChangeDetectorRef
  ) {
    const title = "Tarnished Lamp " + this.title;
    this.titleService.setTitle(title);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      if (this.id) {
        this.form_errors.detail = null;
        this.title = "Update Airport";
        this.$airport_get = this.api.airport.detail(this.id).subscribe(
          (airport: any) => {
            const title =
              "Tarnished Lamp " +
              this.title +
              " " +
              airport.properties.icao_code;
            this.titleService.setTitle(title);
            this.airport = airport;
            this.coord = L.latLng(
              this.airport.geometry.coordinates[1],
              this.airport.geometry.coordinates[0]
            );
          },
          (errors) => {
            this.form_errors = errors.error;
          }
        );
      }
    });
  }

  ngAfterViewChecked() {
    if (this.isPolygon !== this.airport_map.isPolygon) {
      this.isPolygon = this.airport_map.isPolygon;
    }
    this.cdr.detectChanges();
  }

  save(button) {
    button.disabled = true;
    this.airport.properties.footprint = this.airport_map.airport_footprint;
    if (this.airport.properties.field_altitude) {
      this.airport.properties.field_altitude =
        this.airport.properties.field_altitude *
        this.altitude_conversion_factor;
    }
    let call = this.api.airport.create(this.airport);
    this.airport.geometry.coordinates = [this.coord.lng, this.coord.lat];
    if (this.id) {
      call = this.api.airport.update(this.id, this.airport);
    }
    this.$airport_post = call.subscribe({
      next: () => {
        this.location.back();
      },
      error: (errors) => {
        if (!errors.status) {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. If this keeps happening please contact support."
          );
        }
        this.form_errors = errors.error;
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    if (this.$airport_get) {
      this.$airport_get.unsubscribe();
    }
    if (this.$airport_post) {
      this.$airport_post.unsubscribe();
    }
  }
}
