<mat-card>
  <mat-card-title>
    <div fxLayout="row">
      <span fxFlex class="header_label">Aircraft</span>
      <span fxFlex></span>
      <span fxFlex fxLayoutAlign="end">
        <a mat-icon-button class="anchor" [href]="'#/airplane/new'">
          <mat-icon matTooltip="New Aircraft" [routerLink]="['new']">
            add
          </mat-icon>
        </a>
      </span>
    </div>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>
    <div fxLayout="column">
      <app-generic-filter
        fxFlex
        [filterList]="filters"
        [store_name]="'aircraft'"
        (filterData)="getFilterData($event)"
      >
      </app-generic-filter>
      <app-generic-list
        fxFlex
        [source]="get_aircraft_list"
        [router]="'airplane'"
        [displayedColumns]="[
          'active',
          'registration',
          'make',
          'model',
          'gps_state',
          'anchor'
        ]"
      >
        <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.active" style="color: green">
              check_circle
            </mat-icon>
            <mat-icon *ngIf="!row.active" style="color: red">cancel</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="gps_state">
          <mat-header-cell *matHeaderCellDef> GPS State </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span [style]="get_gps_style(row)">
              {{ title(row.gps_state) }}
            </span>
          </mat-cell>
        </ng-container>
      </app-generic-list>
    </div>
  </mat-card-content>
</mat-card>
