import {
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { forkJoin, Subject, Observable } from "rxjs";
import { mergeMap, takeUntil, switchMap } from "rxjs/operators";

import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { GenericListComponent } from "@app/generic-list/generic-list.component";

@Component({
  selector: "employee-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["../component.css"],
})
export class EmployeeDetailComponent implements OnInit, OnDestroy {
  employee: { [id: string]: any } = {};
  files = [];
  meter_groups = [];
  id: number;
  deleted = null;
  unSubscribe = new Subject();
  @ViewChild("quals_list", { static: false }) qualification_list;

  constructor(
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private errorHandleService: ErrorHandleService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        mergeMap((params) => {
          this.id = params["id"];
          return forkJoin(
            this.api.employee.detail(this.id),
            this.api.file.get("employee", this.id)
          );
        })
      )
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(
        (data: any) => {
          this.deleted = data.is_delete;
          this.employee = data[0];
          this.files = data[1].results;
          const title = "Tarnished Lamp Employee " + data[0].name;
          this.titleService.setTitle(title);
        },
        (errors) => {
          this.deleted = errors.error;
        }
      );
  }

  process_file(file_obj) {
    this.dialogService.import_file(file_obj.id).subscribe((res: any) => {
      if (res !== undefined) {
        this.api.file.import_file(file_obj.id, res).subscribe(
          (res: any) => {},
          (error) => {
            this.errorHandleService.sendError(
              "An error occurred while processing your request. If this keeps happening please contact support."
            );
          }
        );
      }
    });
  }

  delete_employee(button: HTMLButtonElement, id) {
    button.disabled = true;
    this.api.employee.delete(id).subscribe({
      next: (res: any) => {
        this.router.navigate(["employee"]);
      },
      error: (error) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  call() {
    console.debug("Call " + this.employee["phone"]);
  }
  email() {
    console.debug("email " + this.employee["email"]);
  }
  browse() {
    console.debug("browse " + this.employee["website"]);
  }

  qualification_form(id: number = null) {
    this.dialogService
      .employee_qualification_form(this.employee.id, id)
      .subscribe((result) => {
        if (this.qualification_list) {
          this.qualification_list.load_data();
        }
      });
    return false;
  }

  remove_qualification(qual_id: number) {
    this.api.employee_qualification.delete(qual_id).subscribe((result) => {
      if (this.qualification_list) {
        this.qualification_list.load_data();
      }
    });
  }

  ngOnDestroy() {
    this.unSubscribe.next(1);
    this.unSubscribe.complete();
  }

  get_employee_qualifications(search_params: Observable<any>): Observable<any> {
    return search_params.pipe(
      switchMap((search_params) => {
        return this.api.employee_qualification.list(search_params);
      })
    );
  }

  get get_employee_aircraft() {
    function _employee_aircraft(
      search_params: Observable<any>
    ): Observable<any> {
      return search_params.pipe(
        switchMap((search_params) => {
          return this.api.employee.aircraft(this.employee.id, search_params);
        })
      );
    }
    return _employee_aircraft.bind(this);
  }
}
