<mat-card>
  <div *ngIf="deleted" class="detail-not-exist">Record Does Not Exist.</div>
  <div fxLayout="column" *ngIf="!deleted">
    <mat-card-header>
      <mat-card-title>
        <h2 fxFlex>Payload Detail</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column">
        <div fxLayout="row">
          <span fxFlex> {{ data.registration }} </span>
          <span fxFlex fxLayoutAlign="end">
            <button
              mat-icon-button
              matTooltip="Delete Payload"
              #delete_payload_btn
              (click)="delete_payload(delete_payload_btn, data.id)"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <a
              mat-icon-button
              class="anchor"
              [href]="'#/payload/' + data.id + '/edit'"
            >
              <mat-icon
                [routerLink]="['edit']"
                matTooltip="Edit Payload Details"
              >
                edit
              </mat-icon>
            </a>
          </span>
        </div>
        <div fxLayout="row">
          <span fxFlex> {{ data.name }}</span>
          <span fxFlex>
            <label>Identification Code:&nbsp;</label>{{ data.ident }}
          </span>
          <span fxFlex> {{ active }} </span>
        </div>
        <div fxLayout="column">
          <label>Description</label>
          <p fxFlex>
            {{ data.description }}
          </p>
        </div>
        <div fxLayout="column" class="description">
          <mat-tab-group>
            <mat-tab label="Assignments">
              <payload-assignment-list
                [payload_id]="id"
              ></payload-assignment-list>
            </mat-tab>
            <mat-tab label="Notes">
              <notes
                [content_type]="'aircraftpayload'"
                [object_id]="id"
              ></notes>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </mat-card-content>
  </div>
</mat-card>
