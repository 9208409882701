import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { ColorPickerModule } from "ngx-color-picker";

import { CG_CommonModule } from "@app/common/module";
import { NoteModule } from "@app/note/module";
import { ApiModule } from "@app/api/api.module";
import { MaterialModule } from "@app/material.module";
import { DialogModule } from "@app/dialog/dialog.module";
import { LayerService } from "@app/services/layer.service";
import { MapDataService } from "@app/services/mapdata.service";
import { MiniMapComponent } from "./minimap";
import { MapComponent } from "./map.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { MetersComponent } from "./sidebar/meters/meters.component";
import { MissionsComponent } from "./sidebar/missions/missions.component";
import { MissionMapComponent } from "./sidebar/missions/mission.map.component";
import { MissionFlightsComponent } from "./sidebar/missions/mission.flights";
import { MissionRoutesComponent } from "./sidebar/missions/mission.routes";
import { AircraftComponent } from "./sidebar/aircraft/aircraft";
import { PayloadStatusComponent } from "./sidebar/aircraft/payload_status";
import {
  PayloadEndpointDetailComponent,
  PayloadEndpointListComponent,
} from "./sidebar/aircraft/endpoint_detail";
import { AircraftEntryComponent } from "./sidebar/aircraft/aircraft_entry";
import { ErrorService } from "./sidebar/aircraft/error.service";
import { ContextmenuService } from "@app/services/contextmenu.service";
import { MapRoutes } from "@app/map/route.module";
import { HighchartsService } from "@app/services/highcharts.service";
import { SideNavRerenderService } from "@app/common/side_nav_rerender.service";
import { ServicesModule } from "@app/services/module";
import { MatMomentDatetimeModule } from "@mat-datetimepicker/moment";
import { MatDatetimepickerModule } from "@mat-datetimepicker/core";
import { PopupService } from "./popup.service";
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
@NgModule({
  imports: [
    ServicesModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    DialogModule,
    CG_CommonModule,
    FormsModule,
    NoteModule,
    ApiModule,
    FlexLayoutModule,
    ColorPickerModule,
    RouterModule.forChild(MapRoutes),
    NgxMaskModule.forRoot(options),
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
  ],
  exports: [MapComponent, MiniMapComponent],
  providers: [
    LayerService,
    MapDataService,
    ErrorService,
    ContextmenuService,
    HighchartsService,
    SideNavRerenderService,
    PopupService,
  ],
  declarations: [
    MapComponent,
    MiniMapComponent,
    SidebarComponent,
    MetersComponent,
    MissionsComponent,
    AircraftComponent,
    AircraftEntryComponent,
    PayloadStatusComponent,
    PayloadEndpointDetailComponent,
    PayloadEndpointListComponent,
    MissionMapComponent,
    MissionFlightsComponent,
    MissionRoutesComponent,
  ],
})
export class MapModule {}
