<h2 style="text-align: center">{{query_string}}</h2>
<div *ngFor="let table of tables">
  <h3 style="text-align: center">{{table.title}}</h3>
  <app-generic-list
    [source]="table.source"
    [router]="table.router"
    [displayedColumns]="table.columns"
  >
  </app-generic-list>
</div>
