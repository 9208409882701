import { Injectable } from "@angular/core";
// local imports
import { AircraftService } from "./aircraft/api";
import { AircraftStatusService } from "./aircraft_status/api";
import { AirportService } from "./airport/api";
import { CustomerService } from "./customer/customer.service";
import { FileService } from "./file/api";
import { FlightService } from "./flight/api";
import { EmployeeService } from "./employee/api";
import { FlightPathService } from "./flightpath/api";
import { InvoiceService } from "./invoice/api";
import { InvoiceLineService } from "./invoice_line/api";
import { MeterService } from "./meter/meter.service";
import { MeterGroupService } from "./meter_group/meter_group.service";
import { MeterReadingService } from "./meter_reading/api";
import { MissionService } from "./mission/api";
import { MeterLocationService } from "./meter_location/api";
import { PurchaseOrderService } from "./purchase_order/api";
import { UserService } from "./user/api";
import { VendorService } from "./vendor/api";
import { ContactService } from "./contact/api";
import { NoteService } from "./note/api";
import { TagService } from "./tag/api";
import { JobResultService } from "./job_result/api";
import { TaskService } from "./task/api";
import { WaypointService } from "./waypoint/api";
import { CustomerJobService } from "./customerjob/customerjob.service";
import { CustomerRouteService } from "./customerroute/service";
import {
  FlightLegTakeoffService,
  FlightLegLandingService,
  TTSNService,
} from "./flight-leg/api";
import { PayloadService } from "./payload/api";
import { QualificationService } from "./qualification/api";
import { EmployeeQualificationService } from "./employeequalification/api";
import { AircraftQualificationService } from "./aircraftqualification/api";
import { MeterReportService } from "./meter_report/meter_report.service";
import { ReportService } from "./reports/report";
import { ImageryService } from "./imagery/api";

@Injectable()
export class ApiService {
  constructor(
    public aircraft: AircraftService,
    public aircraft_status: AircraftStatusService,
    public airport: AirportService,
    public customer: CustomerService,
    public customer_job: CustomerJobService,
    public customer_route: CustomerRouteService,
    public meter_report: MeterReportService,
    public employee: EmployeeService,
    public file: FileService,
    public flight: FlightService,
    public flightpath: FlightPathService,
    public invoice: InvoiceService,
    public invoice_line: InvoiceLineService,
    public job_result: JobResultService,
    public meter: MeterService,
    public meter_group: MeterGroupService,
    public meter_reading: MeterReadingService,
    public mission: MissionService,
    public purchase_order: PurchaseOrderService,
    public user: UserService,
    public vendor: VendorService,
    public contact: ContactService,
    public tag: TagService,
    public task: TaskService,
    public note: NoteService,
    public waypoint: WaypointService,
    public meter_location: MeterLocationService,
    public flightleg_takeoff: FlightLegTakeoffService,
    public flightleg_landing: FlightLegLandingService,
    public ttsn: TTSNService,
    public payload: PayloadService,
    public qualification: QualificationService,
    public employee_qualification: EmployeeQualificationService,
    public aircraft_qualification: AircraftQualificationService,
    public report: ReportService,
    public imagery: ImageryService
  ) {}
}
