import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ImageryProjectComponent } from "./imageryproject.component";
import { ImageryProjectComponents } from "./imageryproject.components";
import { FileUploadSpinnerService } from "@app/services/file-upload-spinner.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTreeModule } from "@angular/material/tree";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ImageryProjectComponents,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatChipsModule,
    MatAutocompleteModule,
  ],
  exports: [ImageryProjectComponent, ImageryProjectComponents],
  providers: [Title, FileUploadSpinnerService],
  declarations: [ImageryProjectComponent],
})
export class ImageryProjectModule {}
