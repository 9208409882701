import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { TokenService } from "@app/services/token.service";
import { map } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private token: TokenService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // tokenNotExpired looks up the token in local storage under the provided name

    return this.token.token.pipe(
      map((token) => {
        if (this.token.isValid(token)) {
          return true;
        }
        // If the token is expired redirect to the login page
        this.router.navigate(["/login"], {
          queryParams: {
            next: state.url,
            message: "Your session is exipred",
          },
        });
        return false;
      })
    );
  }
}
