import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { forkJoin, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";

import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { Aircraft, Airport, Flight } from "@app/api/types";

@Component({
  selector: "complete-flight-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class CompleteDialogComponent implements OnInit, OnDestroy {
  flight: Flight = {};
  flight_id: any;
  employees: any;
  airports: any;
  aircraft: any;
  names = { aircraft: {}, airports: {} };
  unSubscribe = new Subject();
  $flight: Subscription;
  form_errors: any = { aircraft: null, launch: null, recover: null };
  error_schedule_date = false;
  error_actual_date = false;
  error_scheduled_launch = false;
  error_scheduled_recovery = false;
  error_actual_launch = false;
  error_actual_recovery = false;
  scheduled_launch_date_error = "please enter scheduled lunch date";
  scheduled_recovery_date_error = "please enter scheduled recovery date";
  actual_launch_date_error = "please enter actual lunch date";
  actual_recovery_date_error = "please enter actual recovery date";
  scheduled_error =
    "Scheduled Recovery date should be greater then Scheduled Launch date.";
  actual_error =
    "Actual Recovery date should be greater then Actual Launch date.";

  constructor(
    private api: ApiService,
    private errorHandleService: ErrorHandleService,
    public dialogRef: MatDialogRef<CompleteDialogComponent>
  ) {
    forkJoin([this.api.airport.list(), this.api.aircraft.list()])
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(([airports, aircraft]: [Airport, Aircraft]) => {
        this.airports = airports.results.features;
        for (let a of this.airports) {
          this.names.airports[a.id] = a.properties.name;
        }
        this.aircraft = aircraft.results;
        for (let a of this.aircraft) {
          this.names.aircraft[a.id] = a.make + " " + a.model;
        }
      });
  }

  ngOnInit() {
    this.$flight = this.api.flight
      .detail(this.flight_id)
      .subscribe((res: any) => {
        this.flight = res;
        for (let f of [
          "scheduled_launch",
          "scheduled_recovery",
          "actual_launch",
          "actual_recovery",
        ]) {
          this.flight[f] =
            this.flight[f] !== null
              ? moment.utc(this.flight[f]).local().format("YYYY-MM-DDThh:mm")
              : null;
        }
      });
  }

  validate() {
    let check = false;
    if (!this.flight.scheduled_launch) {
      if (!this.flight.scheduled_recovery) {
        this.error_scheduled_launch = this.error_scheduled_recovery = true;
      } else {
        this.error_scheduled_launch = true;
      }
    } else {
      if (!this.flight.scheduled_recovery) {
        this.error_scheduled_recovery = true;
      } else {
        if (
          moment(this.flight.scheduled_launch).isBefore(
            this.flight.scheduled_recovery
          )
        ) {
          check = true;
          this.error_schedule_date = false;
        } else {
          this.error_schedule_date = true;
        }
      }
    }
    if (!this.flight.actual_launch) {
      if (!this.flight.actual_recovery) {
        this.error_actual_launch = this.error_actual_recovery = true;
      } else {
        this.error_actual_launch = true;
      }
    } else {
      if (!this.flight.actual_recovery) {
        this.error_actual_recovery = true;
      } else {
        if (
          moment(this.flight.actual_launch).isBefore(
            this.flight.actual_recovery
          )
        ) {
          this.error_actual_date = false;
          check = true;
        } else {
          this.error_actual_date = true;
        }
      }
    }
    return check;
  }

  complete_flight(button: HTMLButtonElement) {
    if (this.validate()) {
      button.disabled = true;
      this.flight_api().subscribe({
        next: (res: any) => {
          // this.location.back();
          this.dialogRef.close();
        },
        error: (errors) => {
          if (!errors.status) {
            this.errorHandleService.sendError(
              "An error occurred while processing your request. If this keeps happening please contact support."
            );
          }
          this.form_errors = errors.error;
        },
        complete: () => {
          button.disabled = false;
        },
      });
    }
  }

  flight_api() {
    for (let f of [
      "scheduled_launch",
      "scheduled_recovery",
      "actual_launch",
      "actual_recovery",
    ]) {
      this.flight[f] =
        this.flight[f] !== null ? moment(this.flight[f]).utc() : null;
    }
    return this.api.flight.update(this.flight_id, this.flight);
  }

  ngOnDestroy() {
    this.unSubscribe.next(1);
    this.unSubscribe.complete();
    if (this.$flight) {
      this.$flight.unsubscribe();
    }
  }
}
