import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable()
export class DialogDataService {
  dialog = new Subject<any>();

  public dialogSend(data) {
    this.dialog.next({ data: data });
  }

  public dialogReceive(): Observable<any> {
    return this.dialog.asObservable();
  }
}
