import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { Task } from "../types";
import { map } from "rxjs/operators";

export interface ITaskResult {
  task_id: string;
}

@Injectable()
export class TaskService extends BaseApiService<Task> {
  component: string = "task";

  detail(id: number | string) {
    if (id == null) {
      return this.null();
    }
    return this.get_with_retry(this.item_url(id), {
      headers: contentHeaders(),
    }).pipe(map((d) => d as Task));
  }
}
