import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { BehaviorSubject, Subscription, Subject, of, forkJoin } from "rxjs";
import { mergeMap, first, takeUntil } from "rxjs/operators";
import { TaskService } from "@app/task/task.service";
import { Location } from "@angular/common";
import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { copyTextToClipboard } from "@app/common/clipboard";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { FileUploadSpinnerService } from "@app/services/file-upload-spinner.service";
import { CustomerJob } from "@app/api/types";

@Component({
  selector: "customerjob-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["../component.css"],
})
export class CustomerJobDetailComponent implements OnInit, OnDestroy {
  customer_job = new BehaviorSubject<CustomerJob>({
    id: null,
    analysis: null,
    results: [],
  });
  get job(): CustomerJob {
    return this.customer_job.value;
  }
  set job(data: CustomerJob) {
    this.customer_job.next(data);
  }

  error = null;
  id: string;
  valid_tags = [
    "meter locations",
    "data logging request",
    "real time request",
    "CUI Route",
  ];
  validated = false;
  selected_tab: number = 0;
  uploading = false;
  analyze_button = true;
  warnings_acknowledged: boolean = false;
  spinnerColor = "primary";
  spinnerMode = "indeterminate";
  @ViewChild("files") files;
  get link() {
    return this.job.link;
  }
  unSubscribe = new Subject();
  $customer_job_load: Subscription;

  constructor(
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private tasks: TaskService,
    private location: Location,
    private errorHandleService: ErrorHandleService,
    private titleService: Title,
    private spinner: FileUploadSpinnerService
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        mergeMap((params) => {
          this.id = params["id"];
          return this.api.customer_job.detail(this.id);
        })
      )
      .pipe(takeUntil(this.unSubscribe))
      .subscribe({
        next: (job: any) => {
          const title = "Tarnished Lamp CustomerJob " + job.name;
          this.titleService.setTitle(title);
          this.job = job;
        },
        error: (error: any) => {
          this.id = null;
          this.error = error;
        },
      });
  }

  disable_button() {
    this.analyze_button = false;
  }

  add_file(button: HTMLButtonElement) {
    this.dialogService.file_upload().subscribe((res: any) => {
      if (res) {
        button.disabled = true;
        this.spinner.start_spinner().subscribe(() => (this.uploading = true));
        this.api.customer_job.upload_file(this.id, res).subscribe({
          next: (res: any) => {
            this.files.reload();
            if ("task_id" in res) {
              this.watch_analysis(res.task_id);
            }
          },
          complete: () => {
            button.disabled = false;
            this.uploading = false;
          },
        });
      }
    });
  }

  delete_job(button: HTMLButtonElement, id) {
    button.disabled = true;
    this.api.customer_job.delete(id).subscribe({
      next: (res: any) => {
        this.location.back();
      },
      error: (error) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  watch_analysis(task_id) {
    this.tasks.add_task(task_id, {
      message: "Analysis Scheduled",
      started: {
        message: "Analysis Running",
        always: true,
      },
      finished: {
        message: "Analysis Complete",
        callback: (job) => {
          this.files.reload();
          this.load_job();
        },
      },
    });
  }

  analyze_job(button: HTMLButtonElement) {
    button.disabled = true;
    this.api.customer_job.analyze_job(this.id).subscribe({
      next: (data: any) => {
        this.watch_analysis(data.task_id);
      },
      error: (error) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  load_job(tab: number = 0) {
    this.$customer_job_load = this.api.customer_job
      .detail(this.id)
      .subscribe((data) => {
        this.job = data;
        this.selected_tab = tab;
      });
  }

  submit_job(button: HTMLButtonElement) {
    let obs = [of(this.warnings_acknowledged)];
    if (!this.job["analysis"]) {
      obs.push(
        this.dialogService.message_box(
          "No analysis has been performed on this job, do you wish to continue?"
        )
      );
    }
    forkJoin(obs).subscribe({
      next: (res) => {
        if (res.indexOf(false) == -1) {
          button.disabled = true;
          this.api.customer_job.submit_job(this.id).subscribe({
            next: (data) => this.job_submitted(data),
            complete: () => {
              button.disabled = false;
            },
          });
        } else {
          this.dialogService
            .message_box(
              "Job analysis has detected multiple warnings, The warnings must " +
                "be marked as reviewed prior to submission",
              { cancel: false }
            )
            .subscribe((res: any) => {});
        }
      },
    });
  }

  job_submitted(res) {
    this.load_job();
  }

  render_link(id) {
    let link = this.router
      .createUrlTree(["../" + id], { relativeTo: this.route })
      .toString();
    return link;
  }

  makelink(button: HTMLButtonElement) {
    if (this.link) {
      copyTextToClipboard(this.render_link(this.link));
      return;
    }
    button.disabled = true;
    this.api.customer_job.make_link(this.id).subscribe({
      next: (res: any) => {
        copyTextToClipboard(this.render_link(res));
        this.load_job();
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  unsubmit_job(button: HTMLButtonElement) {
    button.disabled = true;
    this.api.customer_job.unsubmit_job(this.id).subscribe({
      next: (data) => {
        this.job_submitted(data);
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    this.unSubscribe.next(1);
    this.unSubscribe.complete();
    if (this.$customer_job_load) {
      this.$customer_job_load.unsubscribe();
    }
  }
}
