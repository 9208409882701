import { Component, OnInit, ViewChild } from "@angular/core";
import { GenericListComponent } from "@app/generic-list/generic-list.component";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ApiService } from "@app/api/api";

@Component({
  selector: "payload-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class PayloadListComponent implements OnInit {
  page = "payload";
  router = "payload";
  filters: any[] = [
    { label: "Registration", name: "registration", maxlength: 6, type: "text" },
  ];
  @ViewChild(GenericListComponent, { static: true })
  genericlist: GenericListComponent;
  constructor(private api: ApiService) {}

  ngOnInit() {}

  get_payload_list(search_params: Observable<any>): Observable<any> {
    return search_params.pipe(
      switchMap((search_params) => {
        return this.api.payload.list(search_params);
      })
    );
  }
}
