<div class="list">
    <div class="mat-table">
        <div class="mat-header-row">
            <div class="mat-header-cell">Route</div>
            <div class="mat-header-cell">Area</div>
            <div class="mat-header-cell">Meters</div>
            <div class="mat-header-cell">Link</div>
        </div>
        <div class="mat-row" *ngFor="let route of routes">
            <div class="mat-cell">
                <span class="mobile-label">Route:</span>
                {{route.name}}
            </div>
            <div class="mat-cell">
                <span class="mobile-label">Area:</span>
                <mat-checkbox [(ngModel)]='route._area' (change)='update_area(route.id)'></mat-checkbox>
            </div>
            <div class="mat-cell">
                <span class="mobile-label">Meters:</span>
                <mat-checkbox [(ngModel)]='route._meters' (change)='update_meters(route.id)'></mat-checkbox>
            </div>
            <div class="mat-cell">
                <span class="mobile-label">Link:</span>
                <a class="anchor-mat-icon" [href]="'#/customer_route/' + route.id">
                    <mat-icon [routerLink]="['/','customer_route',route.id]">launch</mat-icon>
                </a>
            </div>
        </div>
    </div>
</div>
