import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import * as moment from "moment";
import * as L from "leaflet";
import { GeoJsonObject } from "geojson";
import { DialogService } from "@app/services/dialog.service";
import { LayerService } from "@app/services/layer.service";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

import { MapStoreService } from "@app/services/store/map/sidebar/mapstore.service";
import { Mission, MissionState } from "@app/store/map/sidebar/map_reducer";
import { tap } from "rxjs/operators";

@Component({
  selector: "sidebar-missions",
  templateUrl: "./missions.component.html",
  styleUrls: ["./missions.component.css"],
})
export class MissionsComponent implements OnInit, OnDestroy {
  missions: Observable<MissionState[]>;
  $mission: Subscription;
  missionsMarkers = [];

  constructor(
    private dialogService: DialogService,
    private api: ApiService,
    private layer_service: LayerService,
    private errorHandleService: ErrorHandleService,
    private mapstore: MapStoreService
  ) {}

  ngOnInit() {
    this.missions = this.mapstore.get_missions();
  }

  add_mission(mission) {
    this.$mission = this.api.mission
      .detail(mission.id)
      .subscribe((res: any) => {
        this.mapstore.add_mission(new MissionState(new Mission(res.id)));
      });
  }

  public openDialog() {
    const now = moment();
    this.dialogService
      .get_mission({
        mission_type: "Operational",
        from_date: now.subtract(7, "day").format("YYYY-MM-DD"),
        until_date: now.add(7, "days").format("YYYY-MM-DD"),
      })
      .subscribe((res: any) => {
        if (res) {
          res.forEach((r) => {
            this.add_mission(r);
          });
        }
      });
  }

  remove_mission(mission_id) {
    this.mapstore.remove_mission(mission_id);
  }

  zoom_to_mission() {
    if (this.missionsMarkers.length) {
      const featCollection = {
        type: "FeatureCollection",
        features: this.missionsMarkers,
      };
      const layer = L.geoJSON(featCollection as GeoJsonObject);
      this.layer_service.zoom_layer("mission", layer);
    } else {
      this.errorHandleService.sendError("Please Select Mission to Zoom");
    }
  }

  clear_map() {
    this.mapstore.clear_missions();
  }

  ngOnDestroy() {
    if (this.$mission) {
      this.$mission.unsubscribe();
    }
  }
}
