<mat-card fxFlex fxLayout="column">
  <mat-card-title>
    <span> Landing </span>
    <span>
      <a mat-icon-button class="anchor">
        <mat-icon
          matTooltip="Edit Flight Landing"
          #edit_leg_btn
          (click)="edit_leg(edit_leg_btn)"
        >
          edit
        </mat-icon>
      </a>
    </span>
  </mat-card-title>
  <mat-card-content fxFlex="grow" fxLayout="column" fxLayoutAlign="start">
    <span *ngIf="landing">
      <div fxLayout="row">
        <span fxFlex>Airport</span>
        <span fxFlex fxLayoutAlign="end">
          {{ landing.airport_name }}
        </span>
      </div>
      <div fxLayout="row">
        <span fxFlex>Landing Reason</span>
        <span fxFlex fxLayoutAlign="end">
          {{ landing.reason }}
        </span>
      </div>
      <div fxLayout="row">
        <span fxFlex>Touch Down Time</span>
        <span fxFlex fxLayoutAlign="end">
          {{ landing.touchdown_time | momentPipe : "MMM Do YYYY, h:mm:ss a" }}
        </span>
      </div>
      <div fxLayout="row">
        <span fxFlex>Engine Off Time</span>
        <span fxFlex fxLayoutAlign="end">
          {{ landing.engine_off_time | momentPipe : "MMM Do YYYY, h:mm:ss a" }}
        </span>
      </div>
      <div fxLayout="row" *ngIf="landing.ttsn">
        <span fxFlex>TTSN</span>
        <span fxFlex fxLayoutAlign="end">
          {{ landing.ttsn }}
        </span>
      </div>
      <div fxLayout="row">
        <span fxFlex>Flight Total Time</span>
        <span fxFlex fxLayoutAlign="end">
          {{ landing.flight_total_time }}
        </span>
      </div>
      <div *ngIf="landing.note">
        <p>&nbsp;</p>
        {{ landing.note }}
      </div>
    </span>
    <span *ngIf="!landing">PENDING</span>
  </mat-card-content>
</mat-card>
