import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { File } from "../types";

@Injectable()
export class FileService extends BaseApiService<File> {
  component: string = "file";

  get(content_type: string, object_id: number, page: number = 1) {
    return this.list({ type: content_type, object: object_id, page: page });
  }

  create(fields: { content_type: string; object_id: number; datafile: any }) {
    const formData = new FormData();
    for (let f in fields) {
      formData.append(f, fields[f]);
    }

    let headers = contentHeaders();
    headers = headers.delete("Content-Type");

    return this.http_client.post(this.class_url(), formData, {
      headers: headers,
    });
  }

  download(file_id: number | string) {
    // TODO: use get with retry here as well
    return this.get_with_retry(this.function_url("download", file_id), {
      responseType: "blob" as "json",
      observe: "response" as "body",
    });
  }

  head(file_id: number) {
    return this.get_with_retry(this.function_url("head", file_id), {
      headers: contentHeaders(),
    });
  }

  import_file(file_id: number, import_data) {
    return this.http_client.post(
      this.function_url("import_file", file_id),
      import_data,
      { headers: contentHeaders() }
    );
  }
}
