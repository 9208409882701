import { Subject,Observable } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class FileUploadSpinnerService  {
    spinner = new Subject();

    upload_file() {
        this.spinner.next(1);
    }

    start_spinner(): Observable<any> {
        return this.spinner.asObservable();
    }
}
