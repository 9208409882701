import { Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

@Component({
  selector: "mission-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class CreateContactDialogComponent implements OnInit {
  contact: { [id: string]: any } = { id: null };
  object_id: number;
  content_type: string;
  show_save: boolean = true;

  public mask: any = {
    mask: [
      {
        mask: "(000)-000-0000",
        startsWith: "",
        lazy: false,
      },
    ],
    dispatch: function (appended, dynamicMasked) {
      const number = (dynamicMasked.value + appended).replace(/\D/g, "");

      return dynamicMasked.compiledMasks.find(function (m) {
        return number.indexOf(m.startsWith) === 0;
      });
    },
  };

  public url_mask: any = {
    mask: [
      {
        mask: "{https://}***********************************************************************************************",
        startsWith: "",
        lazy: false,
      },
    ],
    dispatch: function (appended, dynamicMasked) {
      const number = (dynamicMasked.value + appended).replace(/\D/g, "");

      return dynamicMasked.compiledMasks.find(function (m) {
        return number.indexOf(m.startsWith) === 0;
      });
    },
  };
  errors: any = {
    first_name: null,
    last_name: null,
    title: null,
    office: null,
    home: null,
    mobile: null,
    fax: null,
    primary_email: null,
    secondary_email: null,
    website: null,
    facebook: null,
    twitter: null,
    linkedin: null,
  };

  constructor(
    public dialogRef: MatDialogRef<CreateContactDialogComponent>,
    private api: ApiService,
    private errorHandleService: ErrorHandleService
  ) {}

  ngOnInit() {}

  save(button: HTMLButtonElement) {
    this.contact["content_type"] = this.content_type ? this.content_type : null;
    this.contact["object_id"] = this.object_id ? this.object_id : null;
    let call = this.api.contact.create(this.contact);
    if (this.contact.id) {
      call = this.api.contact.update(this.contact.id, this.contact);
    }
    button.disabled = true;
    call.subscribe({
      next: (res: any) => {
        this.dialogRef.close(res);
      },
      error: (errors) => {
        if (!errors.status) {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. If this keeps happening please contact support."
          );
        }
        this.errors = errors.error;
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }
}
