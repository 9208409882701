import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CustomerJobComponent } from "./customerjob.component";
import { CustomerJobComponents } from "./customerjob.components";
import { FileUploadSpinnerService } from "@app/services/file-upload-spinner.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTreeModule } from "@angular/material/tree";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CustomerJobComponents,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatChipsModule,
    MatAutocompleteModule,
  ],
  exports: [CustomerJobComponent, CustomerJobComponents],
  providers: [Title, FileUploadSpinnerService],
  declarations: [CustomerJobComponent],
})
export class CustomerJobModule {}
