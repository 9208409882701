<div id="full_page" fxLayout="column">
  <mat-toolbar
    [color]="color"
    class="header-nav"
    fxLayout="row"
    fxLayoutAlign="space-between"
    fxLayoutGap="5%"
    fxShow="true"
    fxHide.lt-xs
  >
    <span *ngIf="populate_toolbar" fxFlex>
      <a mat-icon-button href="#/home">
        <mat-icon inline="true" (click)="route('home')" matTooltip="Home">
          home
        </mat-icon>
      </a>
      <a mat-icon-button href="#/map">
        <mat-icon inline="true" (click)="route('map')" matTooltip="Map">
          map
        </mat-icon>
      </a>
      <a mat-icon-button href="#/customer">
        <mat-icon
          inline="true"
          (click)="route('customer')"
          matTooltip="Customer"
        >
          business_center
        </mat-icon>
      </a>
      <a mat-icon-button href="#/mission">
        <mat-icon inline="true" (click)="route('mission')" matTooltip="Mission">
          gesture
        </mat-icon>
      </a>
      <a mat-icon-button href="#/airplane">
        <mat-icon
          inline="true"
          (click)="route('airplane')"
          matTooltip="Airplane"
        >
          local_airport
        </mat-icon>
      </a>
      <a mat-icon-button href="#/payload">
        <mat-icon inline="true" (click)="route('payload')" matTooltip="Payload">
          router
        </mat-icon>
      </a>
      <a mat-icon-button href="#/airport">
        <mat-icon inline="true" (click)="route('airport')" matTooltip="Airport">
          flight_land
        </mat-icon>
      </a>
      <a mat-icon-button href="#/employee">
        <mat-icon
          inline="true"
          (click)="route('employee')"
          matTooltip="Employee"
        >
          person
        </mat-icon>
      </a>
      <a mat-icon-button href="#/calendar">
        <mat-icon
          inline="true"
          (click)="route('calendar')"
          matTooltip="Calendar"
        >
          calendar_today
        </mat-icon>
      </a>
      <a mat-icon-button href="#/reports">
        <mat-icon inline="true" (click)="route('reports')" matTooltip="Reports">
          auto_graph
        </mat-icon>
      </a>
    </span>
    <span
      fxFlex="grow"
      fxLayout="row"
      fxLayoutAlign="stretch"
      class="toolbar-search"
      *ngIf="search_enabled"
    >
      <span fxFlex="grow">
        <div id="search_entry">
          <mat-form-field id="search">
            <input
              matInput
              (keydown.enter)="
                router.navigate(['search', search ? search : ''])
              "
              [(ngModel)]="search"
            />
          </mat-form-field>
        </div>
      </span>
      <span fxFlex>
        <a mat-icon-button>
          <mat-icon
            inline="true"
            (click)="router.navigate(['search', search ? search : ''])"
            matTooltip="Search"
          >
            search
          </mat-icon>
        </a>
      </span>
    </span>
    <span fxFlex fxLayoutAlign="end" class="logout-icon">
      <button mat-icon-button>
        <mat-icon inline="true" (click)="logout()" matTooltip="Logout"
          >power_settings_new</mat-icon
        >
      </button>
    </span>
  </mat-toolbar>

  <div id="content" fxFlex="grow">
    <router-outlet></router-outlet>
  </div>
  <div id="footer" fxFlex fxLayoutAlign="end" fxLayout="row">
    <span fxFlex> cleargrid contact details </span>
    <span fxFlex fxLayoutAlign="end" class="task-spinner" *ngIf="taskCount">
      <span class="running-task">Running Task: {{ taskCount }}</span>
      <span style="float: right">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate"
          [diameter]="20"
        ></mat-progress-spinner>
      </span>
    </span>
  </div>
  <notifier-container></notifier-container>
</div>
