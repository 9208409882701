import * as L from "leaflet";
import "leaflet-editable";
import "leaflet-area-select";
import "leaflet-contextmenu";
import * as PanelLayers from "@cleargrid/leaflet-panel-layers";
import { environment } from "@env/environment";
import * as omnivore from "@mapbox/leaflet-omnivore";
import vectorTileLayer from "leaflet-vector-tile-layer";

export class BaseMap {
  map: L.Map = null;
  basemaps = [];
  polygonDeleteControl: any;
  layer_control: PanelLayers;

  overlays: any[] = [];

  constructor() {}

  map_build(map_container, map_edit = false) {
    const OSM = L.tileLayer(environment.osm_server, {
      maxZoom: 21,
      minZoom: 2,
      maxNativeZoom: 18,
      attribution: "",
      noWrap: true,
    });

    const VNC = L.tileLayer(environment.vnc_server, {
      maxZoom: 18,
      minZoom: 2,
      maxNativeZoom: 11,
      attribution: "",
      noWrap: true,
    });

    const Sat = L.tileLayer(environment.satellite_server, {
      maxZoom: 18,
      maxNativeZoom: 18,
      minZoom: 2,
      attribution: "",
      noWrap: true,
    });

    const sectional = L.tileLayer(environment.us_sectional_server, {
      maxZoom: 18,
      minZoom: 2,
      maxNativeZoom: 11,
      attribution: "",
      noWrap: true,
    });
    this.overlays = [];

    for (let group_name in environment.row_layers) {
      let data = environment.row_layers[group_name];
      let group = {
        group: group_name,
        layers: [],
        collapsed: true,
      };

      this.overlays.push(group);
      if (data.images) {
        for (let ndx = 0; ndx < data.images.length; ndx++) {
          let layer_def = data.images[ndx];
          group.layers.push({
            name: layer_def.name || `${group_name} Images ${ndx + 1}`,
            layer: L.tileLayer(layer_def.url, {
              maxZoom: layer_def.maxZoom || 18,
              minZoom: layer_def.minZoom || 7,
              attribution: "",
              noWrap: true,
              zIndex: layer_def.zIndex || 900,
            }),
          });
        }
      }
      if (data.overlay) {
        for (let ndx = 0; ndx < data.overlay.length; ndx++) {
          let layer_def = data.overlay[ndx];
          group.layers.push({
            name: layer_def.name || `${group_name} Overlay ${ndx + 1}`,
            layer: L.tileLayer(layer_def.url, {
              maxZoom: layer_def.maxZoom || 18,
              minZoom: layer_def.minZoom || 7,
              attribution: "",
              noWrap: true,
              zIndex: data.images.zIndex || 950,
            }),
          });
        }
      }
      if (data.vector) {
        for (let ndx = 0; ndx < data.vector.length; ndx++) {
          let layer_def = data.vector[ndx];
          console.log(layer_def);
          group.layers.push({
            name: layer_def.name || `${group_name} Vector ${ndx + 1}`,
            layer: vectorTileLayer(layer_def.url, {
              style: layer_def.style || {},
              zIndex: layer_def.zIndex || 1000,
              maxZoom: layer_def.maxZoom || 18,
              minZoom: layer_def.minZoom || 7,
              attribution: "",
              noWrap: true,
            }),
          });
        }
      }
    }

    const southWest = L.latLng(-84.6308, -179.9945),
      northEast = L.latLng(85.0491, 175.7552),
      bounds = L.latLngBounds(southWest, northEast);

    this.basemaps = [
      { name: "OSM", layer: OSM, active: true },
      { name: "Satellite", layer: Sat },
      { name: "VNC", layer: VNC },
      { name: "US Sectional", layer: sectional },
    ];

    L["AddLayerControl"] = L.Control.extend({
      position: "topright",
      onAdd: () => {
        const container = L.DomUtil.create("div", "mySelector"),
          textField = L.DomUtil.create("div", "mdl-textfield", container),
          input = L.DomUtil.create("input", "mdl-textfield__input", textField),
          label = L.DomUtil.create("label", "mdl-textfield__label", textField),
          icon = L.DomUtil.create("i", "material-icons", label);
        icon.innerHTML = "note_stack_add";
        icon.setAttribute("title", "Add Layer");
        input.type = "file";
        input.setAttribute("placeholder", "File to add");
        L.DomEvent.addListener(icon, "click", () => {
          if (input.value) {
            this.add_file_layer(input);
          }
        });
        L.DomEvent.addListener(input, "keyup", (e: KeyboardEvent) => {
          if (!(e.key === "Enter" || e.keyCode == 13)) {
            return;
          }
          if (input.value) {
            this.add_file_layer(input);
          }
        });
        return container;
      },
    });

    if (map_container) {
      this.map = L.map(map_container.nativeElement, {
        center: L.latLng(52.33, -113.76),
        zoom: 7,
        maxBounds: bounds,
        maxBoundsViscosity: 1.0,
        layers: [OSM],
        editable: true,
      });

      let deletePolygon = false;

      const deleteShape = function (e) {
        if (deletePolygon) {
          if (this.editEnabled()) {
            this.editor.deleteShapeAt(e.latlng);
            deletePolygon = false;
          }
        }
      };

      L["NewPolygonControl"] = L.Control.extend({
        options: {
          position: "topleft",
        },
        onAdd: (map) => {
          let container = L.DomUtil.create(
              "div",
              "leaflet-control leaflet-bar"
            ),
            link = L.DomUtil.create("a", "", container);
          link["href"] = "#";
          link.title = "Create a new polygon";
          link.innerHTML = "▱";

          L.DomEvent.on(link, "click", L.DomEvent.stop).on(
            link,
            "click",
            () => {
              if (!map.editTools.currentPolygon) {
                map.editTools.currentPolygon = map.editTools.startPolygon();
                return;
              }
              map.editTools.currentPolygon.editor.newShape();
              if (!map.editTools.currentPolygon.editor._drawing) {
                this.polygonDeleteControl.style.display = "block";
                map.editTools.currentPolygon = map.editTools.stopDrawing();
              }
            }
          );
          container.style.display = "block";
          map.editTools.on(
            "editable:enabled",
            () => (container.style.display = "none")
          );
          map.editTools.on(
            "editable:disable",
            () => (container.style.display = "block")
          );
          return container;
        },
      });

      L["PolygonDeleteControl"] = L.Control.extend({
        options: {
          position: "topleft",
        },
        onAdd: (map) => {
          let container = L.DomUtil.create(
              "div",
              "leaflet-control leaflet-bar"
            ),
            link = L.DomUtil.create("a", "", container);
          link["href"] = "#";
          link.title = "Delete polygon";
          link.innerHTML = "x";

          L.DomEvent.on(link, "click", L.DomEvent.stop).on(
            link,
            "click",
            () => {
              deletePolygon = true;
            }
          );
          container.style.display = "block";
          map.editTools.on(
            "editable:drawing:start",
            () => (container.style.display = "none")
          );
          map.editTools.on(
            "editable:drawing:end",
            () => (container.style.display = "block")
          );
          map.editTools.on(
            "editable:enabled",
            () => (container.style.display = "none")
          );
          map.editTools.on(
            "editable:disable",
            () => (container.style.display = "block")
          );
          this.polygonDeleteControl = container;
          return container;
        },
      });

      this.map.on("baselayerchange", (e) => {
        // if (e.name === "US Sectional") {
        //   this.map.setView(new L.LatLng(41.489, -100.599), 7);
        // } else if (e.name === "VNC") {
        //   this.map.setView(new L.LatLng(52.33, -113.76), 7);
        // }
      });

      this.map.on("layeradd", (e) => {
        if (e.layer instanceof L.Path) {
          e.layer
            .on("click", L.DomEvent.stop)
            .on("click", deleteShape, e.layer)
            .on("dblclick", L.DomEvent.stop)
            .on("dblclick", e.layer.toggleEdit);
        }
      });

      if (map_edit) {
        this.map.addControl(new L["NewPolygonControl"]());
        this.map.addControl(new L["PolygonDeleteControl"]());
      }
    } else {
      this.map = L.map("map", {
        center: L.latLng(52.33, -113.76),
        zoom: 7,
        maxBounds: bounds,
        maxBoundsViscosity: 1.0,
        layers: [OSM],
      });
    }

    let layer_config = {
      autoZIndex: false,
      selectorGroup: true,
      collapsed: true,
      collapsibleGroups: true,
    };

    this.layer_control = new PanelLayers(
      this.basemaps,
      this.overlays,
      layer_config
    );
    this.layer_control.addTo(this.map);

    // this.map.addControl(new L["AddLayerControl"]());
    L.control.scale().addTo(this.map);
  }

  add_file_layer(input: HTMLInputElement) {
    let file = input.files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      console.log(e);
      let data = e.target.result as string;
      let layer = null;
      if (file.name.endsWith(".geojson")) {
        layer = L.geoJson(JSON.parse(data), {});
      } else if (file.name.endsWith(".kml")) {
        layer = omnivore.kml.parse(data);
      }
      if (layer) {
        layer.addTo(this.map);
        this.layer_control.addOverlay({ layer: layer }, file.name);
      }
      input.value = "";
    };
    reader.readAsText(file);
  }
}
