import { BaseApiService } from "@app/api/base_api";
import { Injectable } from "@angular/core";
import { contentHeaders } from "@app/common/headers";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { Airport } from "../types";

@Injectable()
export class AirportService extends BaseApiService<Airport> {
  component: string = "airport";
  match_cache: { [id: string]: any } = {};

  search(params) {
    return this.list(params);
  }

  match(q: string, n: number = 20) {
    const qtime = Math.floor(Date.now() / 1000);
    if (q in this.match_cache && qtime - this.match_cache[q][0] < 30) {
      return of(this.match_cache[q][1]);
    } else {
      return this.http_client
        .get(this.function_url("match"), {
          headers: contentHeaders(),
          params: { q: q, n: n.toString() },
        })
        .pipe(
          map((object) => {
            this.match_cache[q] = [qtime, object];
            return object;
          })
        );
    }
  }
}
