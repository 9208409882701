import { Component, Input, OnInit } from "@angular/core";
interface AnalysisData {
  labels: { [id: string]: string };
  routes: { [route_name: string]: { [id: string]: number } };
  totals: { [id: string]: number };
}

@Component({
  selector: "analysis-detail",
  templateUrl: "./analysis-detail.html",
  styleUrls: ["../component.css"],
})
export class AnalysisDetailComponent implements OnInit {
  columns: string[];
  displayed_columns: string[];
  table_data: { [id: string]: any }[];
  totals: { [id: string]: any };
  labels: { [id: string]: string };

  @Input() set analysis(analysis: AnalysisData) {
    if (analysis?.labels) {
      this.labels = analysis.labels;
    } else {
      this.labels = {
        added: "Added Meters",
        duplicates: "Duplicate Meters",
        idm_only: "Data logging only",
        meters: "Meters",
        missing_location: "Missing Location",
        multiple_idm: "Multiple Data Logging Requests",
        multiple_locations: "Multiple Locations",
        multiple_scm: "Mutiple Real Time Requests",
        outside_service_area: "Outside Service Area",
        removed: "Removed Meters",
        scm_only: "Real Time Only",
        updated_location: "Updated Locations",
      };
    }
    if (analysis?.totals) {
      this.totals = analysis?.totals;
    } else {
      this.totals = {};
    }

    this.columns = Object.keys(this.labels);
    this.displayed_columns = ["route_name", ...this.columns];
    if (analysis?.routes) {
      this.table_data = Object.entries(analysis.routes).map(
        ([route_name, route_analysis]: [string, { [id: string]: number }]) => {
          return { route_name: route_name, ...route_analysis };
        }
      );
      if (analysis?.routes && Object.keys(this.totals).length == 0) {
        Object.keys(this.labels).forEach((column) => {
          this.totals[column] = Object.values(analysis.routes).reduce(
            (accumulator: number, route) => {
              return accumulator + route[column];
            },
            0
          );
        });
      }
    }
  }

  column_label(column: string): string {
    return this.labels[column];
  }

  ngOnInit() {}
}
