import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { Qualification } from "../types";

@Injectable()
export class QualificationService extends BaseApiService<Qualification> {
  component: string = "qualification";

  get(field, value) {
    let params = {};
    params[field] = value;
    return this.list(params);
  }
}
