import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { Note } from "../types";

@Injectable()
export class NoteService extends BaseApiService<Note> {
  component: string = "note";

  get(content_type: string, object_id: number) {
    return this.list({ type: content_type, object: object_id });
  }
}
