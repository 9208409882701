import { Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { forkJoin, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { Mission } from "@app/api/types";
import { SubSink } from "subsink";
@Component({
  selector: "mission-form",
  templateUrl: "./form.component.html",
  styleUrls: ["../component.css"],
})
export class MissionFormComponent implements OnInit, OnDestroy {
  mission: Mission = {
    start_date: null,
    end_date: null,
    mission_id: null,
    mission_type: "Operational",
    label: "",
    mission_status: "Pending",
  };
  files = [];
  employees = [];
  airports = [];
  aircraft = [];
  names = { aircraft: {}, airports: {} };
  id: number;
  labels = {
    type: ["Quote", "Operational", "Testing"],
    status: ["Pending", "In Progress", "Complete", "Canceled"],
  };
  title = "Add New Mission";
  form_error: any = { mission_id: null, non_field_error: null, detail: null };
  dateError = false;
  errorMessage: string;
  subsink = new SubSink();

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private api: ApiService,
    private errorHandleService: ErrorHandleService,
    private titleService: Title
  ) {
    const title = "Tarnished Lamp " + this.title;
    this.titleService.setTitle(title);
    this.subsink.sink = forkJoin([
      this.api.airport.list(),
      this.api.aircraft.list(),
      this.api.employee.list(),
    ]).subscribe((data: any) => {
      this.employees = data[2].results;
      this.airports = data[0].results.features;
      for (let a of this.airports) {
        this.names.airports[a.id] = a.properties.name;
      }
      this.aircraft = data[1].results;
      for (let a of this.aircraft) {
        this.names.aircraft[a.id] = a.make + " " + a.model;
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params["id"];
      if (this.id) {
        this.form_error.detail = null;
        this.title = "Update Mission";
        this.subsink.sink = this.api.mission.detail(this.id).subscribe({
          next: (mission: Mission) => {
            const title =
              "Tarnished Lamp " + this.title + " " + mission.mission_id;
            this.titleService.setTitle(title);
            this.mission = mission;
          },
          error: (errors) => {
            this.form_error = errors.error;
          },
        });
      }
    });
  }

  save(button: HTMLButtonElement) {
    let call = this.api.mission.create(this.mission);
    if (this.id) {
      call = this.api.mission.update(this.id, this.mission);
    }
    this.mission.start_date = moment(this.mission.start_date).format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    this.mission.end_date = moment(this.mission.end_date).format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    if (moment(this.mission.end_date).isBefore(this.mission.start_date)) {
      this.dateError = true;
      this.errorMessage = "End date should be greater then start date.";
    } else {
      button.disabled = true;
      call.subscribe({
        next: (res: any) => {
          this.location.back();
        },
        error: (errors) => {
          if (!errors.status) {
            this.errorHandleService.sendError(
              "An error occurred while processing your request. If this keeps happening please contact support."
            );
          }
          this.form_error = errors.error;
        },
        complete: () => {
          button.disabled = false;
        },
      });
    }
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
