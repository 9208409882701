import { Subject, Observable } from "rxjs";

export class SideNavRerenderService {
  side_nav = new Subject();

  rerender_side_nav() {
    this.side_nav.next(1);
  }

  do_side_nav_rerender(): Observable<any> {
    return this.side_nav.asObservable();
  }
}
