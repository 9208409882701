import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { Subscription } from "rxjs";

import { environment } from "@env/environment";
import { ApiService } from "@app/api/api";
import { SubSink } from "subsink";

@Component({
  selector: "flight-list",
  templateUrl: "./list.component.html",
  styleUrls: ["../component.css"],
})
export class FlightListComponent implements OnInit, OnDestroy {
  @Input("search")
  search_param = {
    mission: null,
    launch: null,
    recover: null,
    aircraft: null,
    type: null,
    status: null,
    flown: null,
    from_date: null,
    until_date: null,
    page: null,
    page_size: environment.default_page_size,
  };
  @Input() show_filters = true;
  @Input() allow_add = true;
  @Input() dialog = false;
  @Input() navigate = true;
  @Input() compact = false;
  @Output() select = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  flights = [];
  aircraft = [];

  totalCount: number = 0;
  pageCount: number = 0;
  selected_flight = { id: null };
  pageSizeOptions: number[] = [10, 25, 50, 100];
  displayedColumns: string[] = [
    "id",
    "scheduled_launch",
    "aircraft",
    "launch_airport",
    "recover_airport",
    "pilot_name",
    "status",
    "anchor",
  ];
  subsink = new SubSink();

  constructor(private api: ApiService) {}

  ngOnInit() {
    if (this.dialog) {
      this.displayedColumns.pop();
    }
    this.subsink.sink = this.api.aircraft.list().subscribe((res: any) => {
      this.aircraft = res.results;
    });
    this.get_flights();
  }

  select_flight(flight) {
    if (!this.navigate) {
      this.selected_flight = flight;
      this.select.emit(flight);
    }
  }

  get_flights(button?: HTMLButtonElement) {
    if (button) button.disabled = true;
    this.subsink.sink = this.api.flight.list(this.search_param).subscribe({
      next: (data: any) => {
        this.flights = data.results;
        this.totalCount = data.count;
        this.pageCount = data.results.length;
        if (this.paginator) {
          this.paginator.pageIndex = 0;
        }
      },
      complete: () => {
        if (button) button.disabled = false;
      },
    });
  }
  paginate(event) {
    this.search_param.page_size = event.pageSize;
    this.search_param.page = event.pageIndex + 1;
    this.get_flights();
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
