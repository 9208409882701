import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { GridsterConfig, GridsterItem } from "angular-gridster2";
import { DashboardItem, LayoutService } from "./layout.service";

@Component({
  selector: "<dashboard>",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  dashboard: Array<GridsterItem>;

  get options(): GridsterConfig {
    return this.layoutService.options;
  }
  get widgets(): DashboardItem[] {
    return this.layoutService.widgets;
  }
  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
  }
}
