<div fxLayout="column">
  <div fxFlex *ngIf="error">
    <p class="detail-not-exist">Customer Job {{ error.error.detail }}</p>
  </div>
  <mat-card class="clientjob-detail" *ngIf="!error && job.id">
    <div fxFlex fxLayout="column">
      <mat-card-header>
        <div fxFlex fxLayout="column">
          <div fxLayout="row">
            <span fxFlex class="header_label" style="word-break: break-all">{{
              job.customer_name
            }}</span>
            <span fxFlex fxLayoutAlign="end">
              <button
                mat-icon-button
                matTooltip="Delete Job"
                #delete_job_btn
                (click)="delete_job(delete_job_btn, job.id)"
              >
                <mat-icon>delete</mat-icon>
              </button>
              <a
                mat-icon-button
                class="anchor"
                style="width: auto !important"
                [href]="'#/customer_job/' + job.id + '/edit'"
              >
                <mat-icon matTooltip="Edit Job Details" [routerLink]="['edit']"
                  >edit</mat-icon
                >
              </a>
            </span>
          </div>

          <div fxLayout="row">
            <span class="header_label">{{ job.name }}</span>
            <span fxFlex fxLayoutAlign="end">
              <mat-icon matTooltip="Job Time">access_time</mat-icon>
            </span>
          </div>

          <div fxLayout="row" fxLayout.xs="column">
            <span fxFlex>
              <mat-icon
                class="stats-link"
                #makelink_btn
                (click)="makelink(makelink_btn)"
                matTooltip="Click to Copy link to Clipboard"
                >link</mat-icon
              >
              {{ job.id }}</span
            >
            <span fxFlex fxLayout="column">
              <div fxFlex fxLayout="row">
                <span fxFlex fxLayoutAlign="end" fxLayoutAlign.xs="none">
                  <span class="text-muted">Due Date : </span
                  >{{ job.due_date | date }}
                </span>
              </div>
              <div fxFlex fxLayout="row">
                <span fxFlex fxLayoutAlign="end" fxLayoutAlign.xs="none">
                  <span class="text-muted">Last Modified : </span
                  >{{ job.last_updated | date }}
                </span>
              </div>
            </span>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div fxFlex fxLayout="column">
          <div fxFlex *ngIf="job.results.length == 0">
            <span *ngIf="job.created">
              <button
                *ngIf="!job.commit_date"
                mat-raised-button
                #add_file_btn
                (click)="add_file(add_file_btn)"
              >
                Add Files to Job
              </button>
              <button
                *ngIf="!job.commit_date"
                mat-raised-button
                #analyze_job_btn
                (click)="analyze_job(analyze_job_btn)"
              >
                Analyse Job
              </button>
              <button
                *ngIf="job.commit_date"
                mat-raised-button
                #unsubmit_job_btn
                (click)="unsubmit_job(unsubmit_job_btn)"
              >
                Un-Submit Job
              </button>
            </span>
            <span *ngIf="uploading" class="spinner">
              <mat-progress-spinner
                [color]="spinnerColor"
                [mode]="spinnerMode"
                [diameter]="30"
              ></mat-progress-spinner>
            </span>
          </div>

          <div fxFlex *ngIf="job.results.length > 0">
            <button mat-raised-button [routerLink]="['results']">
              Results
            </button>
          </div>

          <mat-tab-group
            class="customerjob-analysis"
            [(selectedIndex)]="selected_tab"
          >
            <mat-tab label="Request Analysis">
              <analysis-detail [analysis]="job.analysis"></analysis-detail>
              <div
                fxLayout="column"
                *ngIf="!job.commit_date"
                style="padding-top: 20px"
              >
                <mat-checkbox [(ngModel)]="warnings_acknowledged">
                  <p>
                    I confirm the above is correct to the best of my knowledge
                  </p>
                </mat-checkbox>
                <button
                  mat-raised-button
                  [disabled]="!warnings_acknowledged"
                  #submit_job_btn
                  (click)="submit_job(submit_job_btn)"
                >
                  Submit Job
                </button>
              </div>
            </mat-tab>

            <mat-tab label="Request Files">
              <file-list
                [object_id]="id"
                [content_type]="'customerjob'"
                [tagset]="valid_tags"
                [allow_upload]="false"
                #files
              ></file-list>
            </mat-tab>

            <mat-tab label="Notes">
              <notes [object_id]="id" [content_type]="'customerjob'"></notes>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-card-content>
    </div>
  </mat-card>
</div>
