<mat-card>
    <mat-card-title class="row">
        <span class="header_label">Customer Routes</span>
        <span class="spacer"></span>
        <a mat-icon-button class="anchor" [href]="'#/customer_route/new'">
            <mat-icon matTooltip="New Route" [routerLink]="['new']">add</mat-icon>
        </a>
    </mat-card-title>
    <mat-card-content>
        <mat-list>
            <mat-list-item *ngFor="let route of customer_routes" [routerLink]="[route.id]" class="row active">
                <span>{{route.customer_name}}</span>
                <span class=spacer></span>
                <span>{{route.name}}</span>
                <span class=spacer></span>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>
