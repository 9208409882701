import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { TaskService } from "@app/task/task.service";

@Component({
  selector: "customer-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["../component.css"],
})
export class CustomerDetailComponent implements OnInit, OnDestroy {
  customer: { [id: string]: any } = {};
  files = [];
  users = [];
  meter_groups = [];
  meterset;
  id: number;
  selected_tab: number = 0;
  deleted = null;
  unSubscribe = new Subject();
  @ViewChild("map") map;
  @ViewChild("meterGroups") meterGroupList;
  fileImportError = { non_field_errors: null, detail: null };

  get options() {
    let lst = [];
    for (let key in this.customer.options) {
      lst.push(this.customer.options[key]);
    }
    return lst;
  }

  constructor(
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private errorHandleService: ErrorHandleService,
    protected tasks: TaskService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params["id"];
      this.load_data();
    });
    this.route.queryParams.subscribe((qp: any) => {
      let tab = qp.tab;
      switch (tab) {
        case "metergroups":
          tab = 3;
          break;
        default:
          this.selected_tab = tab;
      }
    });
  }

  load_data = () => {
    forkJoin([
      this.api.customer.detail(this.id),
      this.api.meter_group.customer_groups(this.id),
      this.api.file.get("customer", this.id),
      this.api.user.customer_users(this.id),
    ])
      .pipe(takeUntil(this.unSubscribe))
      .subscribe({
        next: (data: any) => {
          const title = "Tarnished Lamp Customer " + data[0].name;
          this.titleService.setTitle(title);
          this.deleted = data[0].is_delete;
          this.customer = data[0];
          this.meter_groups = data[1].results;
          this.files = data[2].results;
          this.users = data[3].results;
        },
        error: (errors) => {
          this.deleted = errors.error;
        },
      });
  };

  process_file = (file_obj) => {
    this.dialogService.import_file(file_obj.id).subscribe((res: any) => {
      if (res !== undefined) {
        this.api.file.import_file(file_obj.id, res).subscribe({
          next: (task: any) => {
            this.tasks.add_task(task.task_id, {
              message: "Processing CSV File Scheduled",
              started: {
                message: "Processing CSV File",
              },
              finished: {
                message: "Processing CSV File Complete",
                callback: (job) => {
                  this.load_data();
                  this.meterGroupList.loadGroups();
                },
              },
            });
          },
          error: (errors) => {
            this.fileImportError = errors.error;
          },
        });
      }
    });
  };

  call(button: HTMLButtonElement) {
    console.debug("Call " + this.customer["phone"]);
  }
  email(button: HTMLButtonElement) {
    console.debug("email " + this.customer["email"]);
  }
  browse(button: HTMLButtonElement) {
    console.debug("browse " + this.customer["website"]);
  }
  update(button: HTMLButtonElement) {
    button.disabled = true;
    this.customer.service_area = this.map.service_area;
    this.api.customer.update(this.id, this.customer).subscribe({
      next: (res: any) => {
        this.load_data();
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  delete_customer(button: HTMLButtonElement, id) {
    button.disabled = true;
    this.api.customer.delete(id).subscribe({
      next: (data: any) => {
        this.router.navigate(["customer"]);
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  tab_selected(e) {
    if (e.tab.textLabel === "Service Area") {
      this.map.invalidateSize();
      this.map.fit_bounds();
    }
  }

  ngOnDestroy() {
    this.unSubscribe.next(1);
    this.unSubscribe.complete();
  }
}
