<mat-card>
  <mat-card-title>
    <div fxLayout="row">
      <span fxFlex class="header_label">Qualifications</span>
      <span fxFlex></span>
      <span fxFlex fxLayoutAlign="end">
        <button mat-icon-button (click)="qualification_form()">
          <mat-icon matTooltip="New Qualification"> add </mat-icon>
        </button>
      </span>
    </div>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>
    <div fxLayout="column">
      <app-generic-list
        #list
        fxFlex
        [source]="get_qualification_list"
        [router]="'qualification'"
        [displayedColumns]="[
            'name',
            'maximum_validity',
            'confirmation_period',
            'edit'
          ]"
      >
        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef> &nbsp; </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button
              mat-icon-button
              (click)="qualification_form(row.id); $event.stopPropagation()"
            >
              <mat-icon matTooltip="Edit Qualification"> edit </mat-icon>
            </button>
          </mat-cell>
        </ng-container>
      </app-generic-list>
    </div>
  </mat-card-content>
</mat-card>
