<mat-toolbar color="primary">
  <div fxLayout="row" fxFlex="100%">
    <span fxFlex fxLayoutAlign="start">Add Meters</span>
    <span fxFlex fxLayoutAlign="end">
      <button
        mat-icon-button
        (click)="dialogRef.close()"
        matTooltip="Close Dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </span>
  </div>
</mat-toolbar>
<mat-card>
  <div fxLayout="column" fxLayoutGap="20px">
    <mat-card-content>
      <div fxLayout="column">
        <customer-select [(ngModel)]="customer"></customer-select>
        <mat-error *ngIf="!_customer && !_customer_check"
          >Select Customer</mat-error
        >

        <mat-form-field>
          <mat-select
            placeholder="Meter Group"
            [(ngModel)]="selected_meter_group"
            name="meter_group"
          >
            <mat-option *ngFor="let mg of meter_groups" [value]="mg">
              <span> {{ mg.group_name }} </span>
              <span *ngIf="mg.group_id"> [{{ mg.group_id }}]</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-spinner *ngIf="meter_groups_loading"></mat-spinner>

        <!--<mat-radio-group class="row" [(ngModel)]="tiles">
          <mat-radio-button [value]="true">
            Tiles
          </mat-radio-button>
          <span class="spacer"></span>
          <mat-radio-button [value]="false">
            Markers
          </mat-radio-button>
        </mat-radio-group> -->
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row">
        <span fxFlex>
          <button type="button" mat-button (click)="dialogRef.close()">
            Cancel
          </button>
        </span>
        <span fxFlex fxLayoutAlign="end">
          <button
            type="button"
            mat-raised-button
            #submit_btn
            (click)="submit(submit_btn)"
          >
            OK
          </button>
        </span>
      </div>
    </mat-card-actions>
  </div>
</mat-card>
