import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpHeaders,
} from "@angular/common/http";
import { of, Observable, throwError } from "rxjs";
import { delay, tap, retryWhen, mergeMap, retry, map } from "rxjs/operators";
import { contentHeaders } from "@app/common/headers";
import { NotificationService } from "@app/services/notification.service";
import { ConfigService } from "@app/services/config.service";
import { APIListResponse } from "./types";

@Injectable()
export class BaseApiService<T> {
  component: string;

  get server(): string {
    return this.config.api_server + "/api/v2/";
  }

  constructor(
    protected http_client: HttpClient,
    protected notifier: NotificationService,
    protected config: ConfigService
  ) {}

  class_url(): string {
    return this.server + this.component + "/";
  }

  item_url(item: number | string): string {
    return this.class_url() + item + "/";
  }

  function_url(function_name: string, item?: number | string) {
    if (item) {
      return this.item_url(item) + function_name + "/";
    } else {
      return this.class_url() + function_name + "/";
    }
  }

  list(query = {}): Observable<APIListResponse<T>> {
    let params = new HttpParams();
    for (const k in query) {
      if (query[k] != null) {
        if (query[k] instanceof Array) {
          for (const a of query[k]) {
            params = params.append(k, a);
          }
        } else {
          params = params.set(k, query[k]);
        }
      }
    }
    return this.get_with_retry(this.class_url(), {
      headers: contentHeaders(),
      params: params,
    }).pipe(
      map((response) => {
        return response as APIListResponse<T>;
      }),
      tap((res) => {
        console.debug(`list ${this.component}`, res);
      })
    );
  }

  find(query): Observable<APIListResponse<T>> {
    return this.get_with_retry(this.function_url("search"), {
      headers: contentHeaders(),
      params: query,
    }).pipe(
      map((response) => {
        return response as APIListResponse<T>;
      }),
      tap((res) => {
        console.debug(`find ${this.component}`, res);
      })
    );
  }

  detail(id: number | string): Observable<T> {
    if (id == null) return this.null();
    return this.get_with_retry(this.item_url(id), {
      headers: contentHeaders(),
    }).pipe(
      map((response) => {
        return response as T;
      }),
      tap((res) => {
        console.debug(`detail ${this.component}`, res);
      })
    );
  }

  create(data: T) {
    return this.http_client
      .post(this.class_url(), data, {
        headers: contentHeaders(),
      })
      .pipe(
        tap((res) => {
          console.debug(`create ${this.component}`, res);
        })
      );
  }

  update(id: number | string, data: T) {
    return this.http_client
      .put(this.item_url(id), data, {
        headers: contentHeaders(),
      })
      .pipe(
        tap((res) => {
          console.debug(`update ${this.component}`, res);
        })
      );
  }

  delete(id: number | string) {
    return this.http_client
      .delete(this.item_url(id), {
        headers: contentHeaders(),
      })
      .pipe(
        tap((res) => {
          console.debug(`delete ${this.component}`, res);
        })
      );
  }

  null() {
    return of(null);
  }

  filter_result(param = {}, function_name): Observable<APIListResponse<T>> {
    return this.get_with_retry(this.function_url(function_name), {
      params: this.get_params(param),
      headers: contentHeaders(),
    }).pipe(
      map((response) => {
        return response as APIListResponse<T>;
      })
    );
  }

  get_params(params_list = {}) {
    let params = new HttpParams();
    for (const k in params_list) {
      if (params_list[k] != null) {
        params = params.set(k, params_list[k]);
      }
    }
    return params;
  }

  /*******************************************************************************/
  /* the below are wrappers around HTTP methods with retry capabilities included */
  /* currently only get_with_retry is used, however some or all of the remainder */
  /* may see use in the future                                                   */
  /*******************************************************************************/
  post_with_retry(
    url: string,
    body: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: "body";
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: "json";
      withCredentials?: boolean;
    }
  ): Observable<unknown> {
    return this.http_client.post(url, body, options).pipe(
      retryWhen((error) => {
        return this._retry(error);
      }),
      map((response) => {
        return response as unknown;
      })
    );
  }

  delete_with_retry(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: "body";
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: "json";
      withCredentials?: boolean;
    }
  ): Observable<unknown> {
    return this.http_client.put(url, options).pipe(
      retryWhen((error) => {
        return this._retry(error);
      })
    );
  }

  put_with_retry(
    url: string,
    body: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: "body";
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: "json";
      withCredentials?: boolean;
    }
  ): Observable<unknown> {
    return this.http_client.put(url, body, options).pipe(
      retryWhen((error) => {
        return this._retry(error);
      }),
      map((response) => {
        return response as unknown;
      })
    );
  }

  get_with_retry(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: "body";
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: "json";
      withCredentials?: boolean;
    }
  ): Observable<unknown> {
    return this.http_client.get(url, options).pipe(
      retryWhen((error) => this._retry(error)),
      map((response) => {
        return response as unknown;
      })
    );
  }

  _retry(error: Observable<HttpErrorResponse>) {
    const arr = [0, 1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 300];
    return error.pipe(
      tap(console.debug),
      mergeMap((err: HttpErrorResponse, count: number) => {
        let delay_time: number = 300;
        if (count < arr.length) {
          delay_time = arr[count];
        }
        if (err instanceof HttpErrorResponse) {
          const error_class: number = parseInt(
            err.status.toString().substring(0, 1)
          );
          if (error_class === 5 || err.status === 0) {
            this.notifier.add_notification(
              "Error communicating with server. Retrying in " +
                delay_time +
                " seconds",
              undefined,
              delay_time * 1000
            );
            return of(count).pipe(delay(delay_time * 1000));
          }
          return throwError(() => {
            return err;
          });
        }
      })
    );
  }
}
