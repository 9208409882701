import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

@Component({
  selector: "contact-form",
  templateUrl: "./form.component.html",
  styleUrls: ["../component.css"],
})
export class ContactFormComponent implements OnInit, OnDestroy {
  @Input("content_type")
  content_type: string;
  @Input("object_id")
  object_id: number;
  @Input("show_save")
  show_save: boolean = true;

  contact: { [id: string]: any } = {};
  id: number;
  $contact: Subscription;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private errorHandleService: ErrorHandleService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      if (this.id) {
        this.$contact = this.api.contact
          .detail(this.id)
          .subscribe((res: any) => {
            this.contact = res;
          });
      }
    });
  }

  save(button: HTMLButtonElement) {
    button.disabled = true;
    this.contact["content_type"] = this.content_type ? this.content_type : null;
    this.contact["object_id"] = this.object_id ? this.object_id : null;
    let call = this.api.contact.create(this.contact);
    if (this.id) {
      call = this.api.contact.update(this.id, this.contact);
    }
    call.subscribe({
      next: (res: any) => {
        this.location.back();
      },
      error: (error1) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    if (this.$contact) {
      this.$contact.unsubscribe();
    }
  }
}
