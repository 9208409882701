<mat-toolbar color="primary">
  <span>{{ title }}</span>
  <span class="spacer"></span>
  <button
    mat-icon-button
    (click)="dialogRef.close(false)"
    matTooltip="Close Dialog"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-card>
  <mat-card-content>
    <textarea [(ngModel)]="note" cols="30" rows="10"></textarea>
  </mat-card-content>
  <mat-card-actions>
    <div fxLayout="row">
      <span fxFlex>
        <button type="button" mat-button (click)="dialogRef.close()">
          Cancel
        </button>
      </span>
      <span fxFlex fxLayoutAlign="end">
        <button
          type="button"
          mat-raised-button
          #submit_btn
          (click)="submit(submit_btn)"
        >
          OK
        </button>
      </span>
    </div>
  </mat-card-actions>
</mat-card>
