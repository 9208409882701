import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Subject, Subscription } from "rxjs";
import { mergeMap, takeUntil } from "rxjs/operators";
import * as turf from "@turf/turf";
import { ApiService } from "@app/api/api";

function knots_to_meters(k: number): number {
  let mps = k * 0.514444;
  // console.log(`${k} knots => ${mps} m/s`);
  return mps;
}
function meters_to_knots(k: number): number {
  let kts = k * 1.94384;
  // console.log(`${k} m/s => ${kts} knots`);
  return kts;
}
function seconds_to_hours(k: number): number {
  let h = k / 3600;
  // console.log(`${k} seconds => ${h} hours`);
  return h;
}

function track_in_area(
  line: turf.Feature<turf.LineString | turf.MultiLineString>,
  poly: turf.Feature<turf.Polygon | turf.MultiPolygon>
): turf.Feature<turf.MultiLineString> {
  if (!line) {
    return turf.multiLineString([]);
  }

  let mline: turf.Feature<turf.MultiLineString>;
  if (line.geometry.type == "LineString") {
    mline = turf.multiLineString([line.geometry.coordinates]);
  } else {
    mline = turf.multiLineString(line.geometry.coordinates);
  }

  if (!poly) {
    return mline;
  }

  let parts = mline.geometry.coordinates
    .map((part) => {
      let split = turf.lineSplit(turf.lineString(part), poly);
      let oddPair = 1;
      if (turf.booleanPointInPolygon(turf.point(part[0]), poly)) {
        oddPair = 0;
      }
      return split.features
        .filter((_element, i) => (i + oddPair) % 2 === 0)
        .map((element) => element.geometry.coordinates);
    })
    .reduce((prev, current) => prev.concat(current), []);
  return turf.multiLineString(parts);
}

class Expense {
  constructor(public capex: number = 0, public opex: number = 0) {}
  get total(): number {
    return this.capex + this.opex;
  }
}

class PartialReport {
  constructor(
    public flight_distance: number = 0,
    public flight_area: number = 0,
    public work_distance: number = 0,
    public _flight_time: number = null,
    public _speed: number = 0,
    public hourly_cost: Expense = new Expense(),
    public total_cost: Expense = new Expense(),
    public cost_per_meter: Expense = new Expense()
  ) {}

  get transit_distance(): number {
    return this.flight_distance - this.work_distance;
  }

  get flight_distance_m(): number {
    return this.flight_distance * 1000;
  }

  get speed(): number {
    if (this._speed == null && this._flight_time) {
      this._speed = meters_to_knots(this.flight_distance_m / this._flight_time);
    }
    return this._speed;
  }

  set speed(value: number) {
    this._flight_time = null;
    this._speed = value;
  }

  get flight_time(): number {
    if (this._flight_time == null && this._speed) {
      this._flight_time = this.flight_distance_m / knots_to_meters(this._speed);
    }
    return this._flight_time;
  }

  set flight_time(value: number) {
    this._flight_time = value;
    this._speed = null;
  }
}

class Report {
  constructor(
    public plan: PartialReport = new PartialReport(),
    public actual: PartialReport = new PartialReport()
  ) {}
}

@Component({
  selector: "flight-report",
  templateUrl: "./component.html",
  styleUrls: ["../component.css"],
})
export class FlightReportComponent implements OnInit, OnDestroy {
  flight: { [id: string]: any } = {
    meter_groups: [],
    launch: null,
    recover: null,
    pilot: null,
    aircraft: null,
    flight_actual: null,
    flight_path: null,
  };
  id: number;
  meter_counts = {};
  count_requests: number = 0;
  meters = 0;
  report: Report = new Report();
  hourly: Expense = new Expense(180, 270);
  loading = true;
  unSubscribe = new Subject();
  $mission_groups: Subscription;
  $meter_count: Subscription;
  errors = false;
  error_message =
    "No flight actual or track currently exists to generate a report";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.route.params
      .pipe(
        mergeMap((params) => {
          this.id = +params["id"]; // (+) converts string 'id' to a number
          return this.api.flight.detail(this.id);
        }),
        mergeMap((flight) => {
          this.flight = flight;
          this.flight.meter_groups = [];
          return forkJoin([
            this.api.airport.detail(flight.launch),
            this.api.airport.detail(flight.recover),
            this.api.aircraft.detail(flight.aircraft),
            this.api.employee.detail(flight.pilot),
            this.api.flightpath.detail(flight.flight_plan),
            this.api.flightpath.detail(flight.flight_path),
          ]);
        })
      )
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(([launch, recover, aircraft, pilot, plan, path]) => {
        this.flight.launch = launch;
        this.flight.recover = recover;
        this.flight.aircraft = aircraft;
        this.flight.pilot = pilot;
        this.flight.flight_plan = plan;
        this.flight.flight_path = path;
        this.meters = 0;
        for (let mission of this.flight.missions) {
          this.$mission_groups = this.api.meter_group
            .mission_groups(mission)
            .subscribe((groups: any) => {
              for (let m of groups.results) {
                this.flight.meter_groups.push(m);
                this.count_requests++;
                this.$meter_count = this.api.meter_group
                  .meter_count(m.id)
                  .subscribe((meter_count: any) => {
                    this.meter_counts[m.group_name] = meter_count.value;
                    this.count_requests--;
                    this.recalculate();
                  });
              }
            });
        }
        this.recalculate();
        this.loading = false;
      });
  }

  work_track(area, track): turf.AllGeoJSON {
    return turf.multiLineString(
      turf
        .lineSplit(track, area)
        .features.filter((line, _index, _array) => {
          return area.coordinates
            .map((c) => turf.booleanContains(turf.polygon(c), line))
            .reduce((prev: boolean, cur: boolean) => prev && cur, true);
        })
        .map((line) => line.geometry.coordinates)
    );
  }

  recalculate() {
    this.meters = 0;
    for (let i = 0; i < this.flight.meter_groups.length; i++) {
      let m = this.flight.meter_groups[i];
      this.meters = this.meters + this.meter_counts[m.group_name];
    }

    if (this.flight.actual_launch && this.flight.actual_recovery) {
      if (this.flight.flight_path) {
        this.report.actual.flight_distance = this.flight.flight_path.properties.length;
        if (this.flight.flight_area) {
          this.report.actual.flight_area = turf.convertArea(
            turf.area(this.flight.flight_area),
            "meters",
            "kilometers"
          );
          this.report.actual.work_distance = turf.length(
            track_in_area(this.flight.flight_track, this.flight.flight_area),
            {
              units: "kilometers",
            }
          );
        }
      }

      // Calculate actual flight time in seconds
      let launch = new Date(this.flight.actual_launch);
      let recover = new Date(this.flight.actual_recovery);
      this.report.actual.flight_time =
        (recover.getTime() - launch.getTime()) / 1000;

      this.report.actual.total_cost.opex =
        this.hourly.opex * seconds_to_hours(this.report.actual.flight_time);
      this.report.actual.total_cost.capex =
        this.hourly.capex * seconds_to_hours(this.report.actual.flight_time);
      if (this.meters !== 0) {
        this.report.actual.cost_per_meter.capex =
          this.report.actual.total_cost.capex / this.meters;
        this.report.actual.cost_per_meter.opex =
          this.report.actual.total_cost.opex / this.meters;
      } else {
        this.report.actual.cost_per_meter.capex = NaN;
        this.report.actual.cost_per_meter.opex = NaN;
      }
    }
    if (this.flight.flight_area) {
      this.report.plan.flight_area = turf.convertArea(
        turf.area(this.flight.flight_area),
        "meters",
        "kilometers"
      );
    }

    if (this.flight.flight_plan) {
      this.report.plan.work_distance = turf.length(
        track_in_area(this.flight.flight_plan, this.flight.flight_area),
        {
          units: "kilometers",
        }
      );
      this.report.plan.flight_distance = this.flight.flight_plan.properties.length;
      this.report.plan.speed = this.flight.aircraft.speed;

      this.report.plan.total_cost.opex =
        this.hourly.opex * seconds_to_hours(this.report.plan.flight_time);
      this.report.plan.total_cost.capex =
        this.hourly.capex * seconds_to_hours(this.report.plan.flight_time);
      if (this.meters !== 0) {
        this.report.plan.cost_per_meter.capex =
          this.report.plan.total_cost.capex / this.meters;
        this.report.plan.cost_per_meter.opex =
          this.report.plan.total_cost.opex / this.meters;
      } else {
        this.report.plan.cost_per_meter.capex = NaN;
        this.report.plan.cost_per_meter.opex = NaN;
      }
    } else if (!this.flight.flight_path) {
      this.errors = true;
    }
  }

  ngOnDestroy() {
    this.unSubscribe.next(1);
    this.unSubscribe.complete();
    if (this.$meter_count) {
      this.$meter_count.unsubscribe();
    }
    if (this.$mission_groups) {
      this.$mission_groups.unsubscribe();
    }
  }
}
