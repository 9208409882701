export const environment = {
  production: true,
  //commit: build_env.COMMIT || 'Unknown',
  api_server: "",
  socket_server:
    "" /*haproxy is set to detect the updgrade and route appropriately in production*/,
  osm_server: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  vnc_server:
    "https://arcgis.fltplan.com/arcgis/rest/services/fltplan/canadian_vnc/MapServer/tile/{z}/{y}/{x}",
  satellite_server:
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  us_sectional_server:
    "https://arcgis2.fltplan.com/arcgis/rest/services/fltplan/sectionals/MapServer/tile/{z}/{y}/{x}",
  env_name: "Beta",
  token_refresh_time: 15,
  default_page_size: 10,
  default_meter_page_size: 100,
  row_layers: {
    "ATCO 2022 Program": {
      images: [
        {
          url: "https://tiles.cleargrid.io/tile/5aad58b0-3740-49da-bb2b-a94f8e806fcd/{z}/{x}/{y}",
          minZoom: 6,
          maxZoom: 22,
        },
      ],
      overlay: [
        {
          url: "https://tiles.cleargrid.io/tile/dcaeae51-d386-461b-909e-2b1f4ca43841/{z}/{x}/{y}",
          minZoom: 6,
          maxZoom: 22,
        },
      ],
    },
    "ATCO 2023 Program": {
      images: [
        {
          url: "https://tiles.cleargrid.io/tile/dc41a291-2bdb-476e-b75e-ad2d704a4462/{z}/{x}/{y}",
          minZoom: 6,
          maxZoom: 22,
        },
      ],
    },
    "ATCO 2024 Program": {
      images: [
        {
          name: "Imagery",
          url: "https://tiles.cleargrid.io/tile/20e3f50b-e282-4143-aaaa-c746f0dcd7b7/{z}/{x}/{y}",
          minZoom: 6,
          maxZoom: 22,
        },
      ],
      vector: [
        {
          name: "Pipelines",
          url: "https://row.cleargrid.io/1/project/11/tile/lines/{z}/{x}/{y}",
          minZoom: 6,
          maxZoom: 22,
          style: {
            weight: 2,
            color: "#ff0000",
          },
          zIndex: 1000,
        },
        {
          name: "ROW",
          url: "https://row.cleargrid.io/1/project/11/tile/row/{z}/{x}/{y}",
          minZoom: 6,
          maxZoom: 22,
          style: {
            weight: 2,
            color: "#00ff00",
            fill: false,
          },
          zIndex: 999,
        },
        {
          name: "Capture Area",
          url: "https://row.cleargrid.io/1/project/11/tile/area/{z}/{x}/{y}",
          minZoom: 6,
          maxZoom: 22,
          style: {
            weight: 2,
            color: "#0000ff",
            fill: false,
          },
          zIndex: 998,
        },
      ],
    },
  },
};
