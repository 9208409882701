import { Component, OnInit, ViewChild } from "@angular/core";
import { GenericListComponent } from "@app/generic-list/generic-list.component";
import { switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { ApiService } from "@app/api/api";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "customer-list",
  templateUrl: "./list.component.html",
  styleUrls: ["../component.css"],
})
export class CustomerListComponent implements OnInit {
  page = "customer";
  router = "customer";
  filters = [
    { label: "Code", name: "code", maxlength: 5, type: "text" },
    { label: "Name", name: "name", maxlength: 20, type: "text" },
  ];
  @ViewChild(GenericListComponent, { static: true })
  genericlist: GenericListComponent;

  constructor(private api: ApiService) {}

  ngOnInit() {}

  getFilterData(value) {
    this.genericlist.filterValues = value;
  }
  get_customer_list(search_params: Observable<any>): Observable<any> {
    return search_params.pipe(
      switchMap((search_params) => {
        return this.api.customer.list(search_params);
      })
    );
  }
}
