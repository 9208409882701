import { Component, OnInit, Directive, Output, EventEmitter, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ApiService } from '@app/api/api';

@Component({
  selector: 'customer-select',
  templateUrl: './commonhtml/customer_select.html',
})
export class CustomerSelectComponent implements OnInit, OnDestroy {
  state_ctl: UntypedFormControl;
  filtered_states: any;
  customers = [];
  search_text: string;
  _customer: any = '';
  customers_loading = true;
  pending = null;
  @Input('customerJobDialog') customerJobDialog: string = null;
  @Output()
  customer_change: EventEmitter<number>;
  $customers: Subscription;

  constructor(
    private api: ApiService,
  ) {
    this.state_ctl = new UntypedFormControl(['', Validators.required]);
    this.filtered_states = this.state_ctl.valueChanges
      .pipe(
        startWith(null),
        map(name => this.filter_states(name))
      );
    this.customer_change = new EventEmitter();
  }

  ngOnInit() {
    this.$customers = this.api.customer.search({ page_size: 0 }).subscribe(
      (data: any) => {
        this.customers = data;
        this.customers_loading = false;
        if (this.pending) {
          this.customer_id = this.pending;
          this.pending = null;
        }
      });
  }

  generateRegex(input: String) {
    let string = '^';
    let arr;
    // input becomes an instance of the customer object,
    // it only makes sense to trim on a string. if its not a string
    // we return an empty string
    try {
      arr = input.trim().split(' ');
    } catch (err) {
      return ''
    }
    arr.forEach(function (chars, i) {
      string += chars + '\\w*' + (arr.length - 1 > i ? '\\s+' : '');
    });
    return string
  }

  filter(input, subject) {
    const regex = this.generateRegex(input);
    const reg1 = new RegExp(regex, 'i').test(subject.name);
    const reg2 = new RegExp(regex, 'i').test(subject.code);
    return reg1 || reg2;
  }

  filter_states(val: string) {
    return val ? this.customers.filter((s) => this.filter(val, s)) : this.customers;
  }

  customer_display(obj): string {
    return obj ? obj.name : obj
  }

  get customer_id() {
    return this._customer.id
  }
  set customer_id(value: number) {
    if (this.customers_loading) { this.pending = value } else {
      this._customer = this.customers.find((customer) => { return (customer.id == value) })
    }
  }

  get customer() {
    return this._customer
  }

  set customer(value: any) {
    if (this.set_customer(value)) {
      this.customer_change.emit(value)
    }
  }

  set_customer(value: any) {
    if (!value || !value.hasOwnProperty('id')) {
      return
    }
    this._customer = value;
    return value
  }

  ngOnDestroy() {
    if (this.$customers) {
      this.$customers.unsubscribe();
    }
  }
}

const CUSTOMER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomerSelectAccessor),
  multi: true
};

@Directive({
  selector: 'customer-select',
  host: { '(customer_change)': 'onChange($event)' },
  providers: [CUSTOMER_VALUE_ACCESSOR]
})
export class CustomerSelectAccessor implements ControlValueAccessor {
  onChange = (_) => { };
  onTouched = () => { };

  constructor(private host: CustomerSelectComponent) { }

  writeValue(value: any): void {
    if (!value) {
      return
    }
    else if (value.hasOwnProperty('id')) {
      this.host.set_customer(value)
    }
    else {
      this.host.customer_id = value;
    }
  }

  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }
}
