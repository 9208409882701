<div fxLayout="row" fxLayoutAlign="center">
  <mat-card>
    <div fxLayout="column">
      <mat-card-content>
        <div fxLayout="column">
          <div fxLayout="row" *ngIf="!project.customer">
            <customer-select
              fxFlex
              [customerJobDialog]="'dialog'"
              [(ngModel)]="project.customer"
            ></customer-select>
            <mat-error fxFlex *ngIf="!project.customer?.id">
              Select Customer For Project
            </mat-error>
          </div>
          <div fxLayout="row" *ngIf="project.customer">
            Customer: {{ project.customer_name }}
          </div>

          <span fxLayout="row">
            <mat-form-field fxFlex>
              <input
                matInput
                id="project_name"
                name="project_name"
                placeholder="Project Name"
                [(ngModel)]="project.project_name"
                required
              />
            </mat-form-field>
            <mat-error fxFlex *ngIf="!project.project_name">
              Project Name is required
            </mat-error>
          </span>

          <div fxLayout="row">
            <mat-form-field fxFlex>
              <input
                matInput
                [matDatepicker]="startpicker"
                placeholder="Start Date"
                [(ngModel)]="project.start_date"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startpicker"
              ></mat-datepicker-toggle>
              <mat-datepicker touchUi #startpicker></mat-datepicker>
              <mat-error>Start Date is required</mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row">
            <mat-form-field fxFlex>
              <input
                matInput
                [matDatepicker]="duepicker"
                placeholder="End Date"
                [(ngModel)]="project.end_date"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="duepicker"
              ></mat-datepicker-toggle>
              <mat-datepicker touchUi #duepicker></mat-datepicker>
              <mat-error>Due Date is Required</mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" *ngIf="error">
            <mat-error>{{ error_message }}</mat-error>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions *ngIf="actions">
        <span fxFlex fxLayoutAlign="end">
          <button type="button" mat-raised-button (click)="(save)">Save</button>
        </span>
      </mat-card-actions>
    </div>
  </mat-card>
</div>
