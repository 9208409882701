import { Component, Input, OnInit, OnChanges, OnDestroy } from "@angular/core";
import { first } from "rxjs/operators";
import * as L from "leaflet";
import { DialogService } from "@app/services/dialog.service";
import { ApiService } from "@app/api/api";
import { LayerService } from "@app/services/layer.service";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { environment } from "@env/environment";
import { MeterTooltipComponent } from "@app/common/meter-toolip";
import { TooltipInjector } from "@app/common/map_tools";

import { MapStoreService } from "@app/services/store/map/sidebar/mapstore.service";
import {
  MeterGroupState,
  MeterGroup,
  Customer,
} from "@app/store/map/sidebar/map_reducer";
import { SubSink } from "subsink";
export interface MeterGroupLayer {
  id: number;
  customer_name: string;
  group: string;
  loading: boolean;
  layer: any;
  display: boolean;
  index: number;
}

@Component({
  selector: "sidebar-meters",
  templateUrl: "./meters.component.html",
  styleUrls: ["./meters.component.css"],
})
export class MetersComponent implements OnInit, OnChanges, OnDestroy {
  displayed_groups: MeterGroupLayer[] = [];
  index: number = 0;
  error_spinner_value: number = 100;
  api_call_error: boolean = false;
  color: string = "warn";
  mode: string = "determinate";
  execute: number[] = [];
  call_spinner_value: number[] = [];
  @Input() search_meter_layer: any;
  search_meter_layers = [];
  store_check: boolean = true;
  subsink = new SubSink();

  constructor(
    private dialogService: DialogService,
    private api: ApiService,
    private layer_service: LayerService,
    private tooltip_injector: TooltipInjector,
    private storeService: MapStoreService
  ) {}

  ngOnInit() {
    this.storeService
      .get_meter_groups()
      .pipe(first())
      .subscribe((metergroups: MeterGroupState[]) => {
        metergroups.forEach((e) => this.add_meters(e));
      });
  }

  ngOnChanges(): void {
    if (this.search_meter_layer) {
      this.search_meter_layers.push(this.search_meter_layer);
    }
  }

  add_meters(
    def: MeterGroupState,
    list_entry: MeterGroupLayer = null,
    page = null
  ) {
    if (def == null) {
      return;
    }

    const customer: string = def.customer.name;
    let meter_group: string;
    if (def.metergroup) {
      meter_group = def.metergroup.group_name;
      if (def.metergroup.group_id) {
        meter_group = meter_group + " [" + def.metergroup.group_id + "]";
      }
    }

    if (!list_entry) {
      list_entry = {
        id: def.metergroup.id,
        customer_name: customer,
        group: meter_group,
        loading: true,
        layer: null,
        display: def.display,
        index: this.index++,
      };
      this.displayed_groups.push(list_entry);
      this.execute.push(0);
      this.call_spinner_value.push(0);
    }

    this.subsink.sink = this.api.meter
      .list({
        customer_id:
          def.metergroup && def.metergroup.id ? null : def.customer.id,
        group_id: def.metergroup ? def.metergroup.id : null,
        page: page,
        page_size: environment.default_meter_page_size,
      })
      .subscribe({
        next: (res: any) => {
          const total_meter = res.count;
          const meter_per_call = res.features.length;
          this.execute[list_entry.index] += meter_per_call;
          this.call_spinner_value[list_entry.index] =
            (this.execute[list_entry.index] / total_meter) * 100;
          if (list_entry.layer) {
            list_entry.layer.addData(res);
          } else {
            list_entry.layer = this.create_layer(res);
            page = 1;
          }
          if (this.layer_service) {
            this.layer_service.add_layer("meter_group", list_entry.layer);
          }

          if (res.next) {
            this.add_meters(def, list_entry, page + 1);
          } else {
            this.execute[list_entry.index] = 0;
            this.call_spinner_value[list_entry.index] = 0;
            list_entry.loading = false;
          }
        },
        error: (error) => {
          list_entry.loading = false;
          this.api_call_error = true;
        },
      });
  }

  toggle(event: MatCheckboxChange, meter_group: MeterGroupLayer) {
    if (event.checked) {
      this.storeService.show_meter_group(meter_group.id);
      this.layer_service.add_layer("meter_group", meter_group.layer);
    } else {
      this.storeService.hide_meter_group(meter_group.id);
      this.layer_service.delete_layer("meter_group", meter_group.layer);
    }
  }

  create_layer(data) {
    return L.geoJson(data, {
      onEachFeature: this.tooltip_injector
        .InjectPopupComponent(MeterTooltipComponent)
        .bind(this),
    });
  }

  remove_meters(def: MeterGroupLayer) {
    this.storeService.remove_meter_group(def.id);
    const index = this.displayed_groups.indexOf(def, 0);
    if (def.layer) {
      this.layer_service.delete_layer("meter_group", def.layer);
    }
    if (index > -1) {
      this.displayed_groups.splice(index, 1);
      //    this.storeService.splice_meters(this.displayed_groups);
    }
  }

  remove_search_meters(layer) {
    this.layer_service.delete_layer("meter_group", layer);
  }

  zoom_to_meters(def) {
    const layer = def ? def.layer : null;
    this.layer_service.zoom_layer("meter_group", layer);
  }

  clear_map() {
    for (const mg of this.displayed_groups.slice()) {
      this.remove_meters(mg);
    }
    for (const layer of this.search_meter_layers) {
      this.remove_search_meters(layer);
    }
    //this.storeService.splice_meters(this.displayed_groups);
  }

  openDialog() {
    this.dialogService
      .get_meters()
      .pipe(first())
      .subscribe((res: any) => {
        let metergroup: MeterGroupState = new MeterGroupState(
          true,
          new MeterGroup(
            res.metergroup.id,
            res.metergroup.group_name,
            res.metergroup.group_id
          ),
          new Customer(res.customer.id, res.customer.name)
        );

        this.add_meters(this.storeService.add_meter_group(metergroup));
      });
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
