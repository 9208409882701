import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector : 'customer',
  templateUrl : './customer.component.html',
  styleUrls : [ './component.css' ]
})
export class CustomerComponent implements OnInit {
  constructor(private titleService: Title) {
    const title = 'Tarnished Lamp Customers';
    this.titleService.setTitle(title);
  }

  ngOnInit() {}
}
