import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { ApiService } from '@app/api/api';
import { AirplaneComponent } from './component';
import { MaterialModule } from '@app/material.module';
import { AirplaneDetailComponent } from './detail/detail.component';
import { AirplaneListComponent } from './list/list.component';
import { AirplaneFormComponent } from './form/form.component';
import { NoteModule } from '@app/note/module';
import { GenericListModule } from '@app/generic-list/module';
import { GenericFilterModule } from '@app/generic-filter/module';

@NgModule({
  imports: [
      CommonModule, RouterModule, MaterialModule, NoteModule, FormsModule, GenericListModule,
      GenericFilterModule, FlexLayoutModule
  ],
  exports: [
      AirplaneComponent, AirplaneDetailComponent, AirplaneListComponent, AirplaneFormComponent
  ],
  providers: [ ApiService, Title ],
  declarations: [
      AirplaneComponent, AirplaneDetailComponent, AirplaneListComponent, AirplaneFormComponent
  ]
})
export class AirplaneModule {
}
