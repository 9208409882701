import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@app/material.module';
import { KeyValuePipe } from '@app/user/keyvalue.pipe';

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule],
  exports: [KeyValuePipe],
  declarations: [KeyValuePipe]
})
export class KeyvaluePipeModule {
}
