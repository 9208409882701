import {CalenderActionTypes} from './actions';
import {Action} from '@ngrx/store';

export default interface ActionWithPayload<T> extends Action {
  type: string;
  tree_checks: any;
}

export interface CheckState {
  tree_checks: any;
}

export const initialState: CheckState = {
  tree_checks: []
};

export function calenderSidenavChecksReducer(state = initialState, action: Action): CheckState {
  switch (action.type) {
    case CalenderActionTypes.GetChecks:
      return {
        ...state,
        tree_checks: (action as ActionWithPayload<CheckState>).tree_checks
      };
    case CalenderActionTypes.ToggleChecks:
      return {
        ...state,
        tree_checks: (action as ActionWithPayload<CheckState>).tree_checks
      };
    default:
      return state;
  }
}
