import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { FlightLegLanding, FlightLegTakeoff, TTSN } from "../types";

@Injectable()
export class FlightLegTakeoffService extends BaseApiService<FlightLegTakeoff> {
  component: string = "flighttakeoff";

  search(params) {
    return this.list(params);
  }
}

@Injectable()
export class FlightLegLandingService extends BaseApiService<FlightLegLanding> {
  component: string = "flightlanding";

  search(params) {
    return this.list(params);
  }
}

@Injectable()
export class TTSNService extends BaseApiService<TTSN> {
  component: string = "ttsn";

  search(params) {
    return this.list(params);
  }
}
