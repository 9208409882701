<span class="data" fxLayout="column">
  <mat-expansion-panel [expanded]="false" style="margin-bottom: 10px">
    <mat-expansion-panel-header>
      <div
        fxLayout="row"
        fxFlex="100%"
        (click)="material_expansion_prevention( $event)"
      >
        <div fxFlex="65%">
          <mat-checkbox
            style="float: left"
            [checked]="aircraft_checked"
            (change)="select_aircraft($event)"
            class="data-detail"
            #aircraft_track
          ></mat-checkbox>
          <div class="aircraft-filter-data">
            <div>
              {{aircraft.registration}} {{aircraft.make}} {{aircraft.model}}
            </div>
            <div
              class="gps-numeric-detail"
              matTooltip="{{aircraft_status.latitude|number:'1.0-4'}},{{aircraft_status.longitude|number:'1.0-4'}} {{aircraft_status.age}}"
            >
              {{aircraft_status.altitude|number:'1.0-0' }}'ASL
              {{aircraft_status.heading|number:'3.0-0' }}°T
              {{aircraft_status.ground_speed|number:'1.0-0' }}kts
            </div>
          </div>
        </div>
        <div fxFlex="35%">
          <span fxFlex class="track-spinner" *ngIf="loading_track">
            <mat-progress-spinner
              [color]="spinnerColor"
              [mode]="spinnerMode"
              [diameter]="22"
            ></mat-progress-spinner>
          </span>
          <span fxFlex class="zoom-button alert-{{aircraft_status.gps_state}}">
            <button
              mat-icon-button
              matTooltip="{{status_text}}"
              (click)="aircraft_last_reported_position( $event)"
            >
              <mat-icon *ngIf="aircraft_status.gps_state=='offline'"
                >gps_off</mat-icon
              >
              <mat-icon *ngIf="aircraft_status.gps_state!='offline'"
                >gps_fixed</mat-icon
              >
            </button>
          </span>
          <span fxFlex class="zoom-button">
            <button
              mat-icon-button
              matTooltip="Radio Off"
              (click)="zoom_button( $event)"
            >
              <mat-icon>perm_scan_wifi</mat-icon>
            </button>
          </span>
          <span fxFlex class="zoom-button">
            <button
              mat-icon-button
              (click)="zoom( $event)"
              [disabled]="track_button"
              matTooltip="Zoom to Aircraft Track"
            >
              <mat-icon>center_focus_strong</mat-icon>
            </button>
          </span>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div fxLayout="column" fxLayoutGap="0.5em">
      <div fxLayout="row">
        Refresh Track Every&nbsp;
        <input
          matInput
          type="number"
          [min]="1"
          [max]="300"
          [(ngModel)]="trackRefreshInterval"
          style="width: 3em"
        />
        &nbsp;Seconds
      </div>
      <div fxLayout="row">
        Display&nbsp;
        <input
          digitOnly
          matInput
          type="number"
          [min]="0"
          [max]="99"
          [value]="hourSpan"
          [(ngModel)]="hourSpan"
          maxlength="2"
          style="width: 2.25em"
        />
        &nbsp;hour{{hourSpan>1?'s':''}} of track&nbsp;
        <mat-select [(ngModel)]="basis" style="width: 4.5em">
          <mat-option value="before">before</mat-option>
          <mat-option *ngIf="display_time" value="after">after</mat-option>
        </mat-select>
        &nbsp;
        <span
          *ngIf="display_time"
          style="cursor: pointer; color: blue; text-decoration: underline"
          (click)="picker.open()"
        >
          <input
            matInput
            [max]="currentCalenderDate"
            [matDatetimepicker]="picker"
            [value]="display_time"
            (dateChange)="onChangeDate($event)"
            style="width: 0px"
          />
          <mat-datetimepicker
            #picker
            type="datetime"
            openOnFocus="true"
            timeInterval="60"
          >
          </mat-datetimepicker>
          {{display_time}}
        </span>
        <span *ngIf="display_time" style="font-size: smaller">
          &nbsp;[
          <span
            (click)="use_now()"
            style="cursor: pointer; color: blue; text-decoration: underline"
          >
            now
          </span>
          ]
        </span>
        <span
          *ngIf="!display_time"
          (click)="set_time()"
          style="cursor: pointer; color: blue; text-decoration: underline"
        >
          now
        </span>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20x">
        <label fxFlex>Select track color</label>
        <input
          fxFlex
          [colorPicker]="track_color"
          [cpOKButton]="true"
          [cpOKButtonClass]="'mat-raised-button'"
          [cpPosition]="'bottom'"
          [style.background]="track_color"
          [value]="track_color"
          (colorPickerSelect)="set_colour($event)"
        />
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutGap="20px"
        style="margin-top: 20px"
      >
        <label fxFlex>
          <mat-checkbox
            class="aircraft-statistics"
            [checked]="profile_checked"
            (change)="aircraft_profile($event)"
          >
          </mat-checkbox>
          Display Profile
        </label>
      </div>
      <div
        fxLayout="column"
        fxLayout.xs="column"
        fxLayoutGap="20px"
        class="payload-list"
      >
        <div *ngFor="let payload of aircraft.current_payloads">
          <payload-status [payload]="payload"> </payload-status>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
        <span fxFlex>
          <button
            mat-icon-button
            matTooltip="Assign Payload"
            #assign_payload_btn
            (click)="assign_payload(assign_payload_btn)"
          >
            <mat-icon>router</mat-icon>
          </button>
        </span>
        <span fxFlex>
          <button
            mat-icon-button
            matTooltip="Enter Flight Leg Details"
            #update_flightleg_btn
            (click)="update_flightleg(update_flightleg_btn)"
          >
            <mat-icon>local_gas_station</mat-icon>
          </button>
        </span>
        <span fxFlex fxLayoutAlign="end">
          <button
            mat-icon-button
            matTooltip="Update Track"
            (click)="requiredTrack()"
          >
            <mat-icon>update</mat-icon>
          </button>
        </span>
      </div>
    </div>
  </mat-expansion-panel>
</span>
