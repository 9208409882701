import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef,
  Input,
  Host,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { FileSaverService } from "ngx-filesaver";
import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { File } from "@app/api/types";

@Component({
  selector: "file-list-entry",
  templateUrl: "commonhtml/filelistentry.html",
  styles: [
    ".file-name {font-weight:bold;max-width:60%;text-overflow:ellipsis;white-space:nowrap;overflow-x:hidden;}",
    ".file-size, .file-buttons {text-align:right;}",
    ".spinner { justify-content: center; align-items: center; position: relative; left: 100%; top: 4px; }",
  ],
})
export class FileListEntry {
  @Input("file") file: File = {};
  @Input("list") list: FileList;
}

@Component({
  selector: "file-list",
  templateUrl: "commonhtml/filelist.html",
  styles: [
    ".file-name {font-weight:bold;max-width:60%;text-overflow:ellipsis;white-space:nowrap;overflow-x:hidden;}",
    ".file-size, .file-buttons {text-align:right;}",
    ".spinner { justify-content: center; align-items: center; position: relative; left: 100%; top: 4px; }",
  ],
})
export class FileList implements OnInit, OnDestroy {
  @Input("content_type") content_type: string = "";
  @Input("object_id") object_id: number = 0;
  @Input("tagset") tagset = [];
  @Input("process_file") process_file_fn: (file_obj) => Observable<any> = null;
  @Input("allow_upload") allow_upload = true;
  @Input("allow_delete") allow_delete = true;
  @Input("allow_download") allow_download = true;
  uploading = false;
  page = 1;
  totalSize = 100;
  spinnerColor = "primary";
  spinnerMode = "indeterminate";
  files = [];
  files_error = [];
  $files: Subscription;

  constructor(
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
    private errorService: ErrorHandleService,
    private fileSaverService: FileSaverService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.reload();
  }

  reload() {
    this.$files = this.api.file
      .get(this.content_type, this.object_id, this.page)
      .subscribe((data: any) => {
        this.totalSize = data.count;
        this.files = data.results;
      });
  }

  paginate(event) {
    this.page = event.pageIndex + 1;
    this.reload();
  }

  next_page() {
    this.page += 1;
    this.reload();
  }

  prev_page() {
    this.page -= 1;
    this.reload();
  }

  ngOnDestroy() {
    if (this.$files) {
      this.$files.unsubscribe();
    }
  }

  upload_file(button: HTMLButtonElement) {
    button.disabled = true;

    this.dialogService
      .file_upload()
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.uploading = true;
            this.api.file
              .create({
                content_type: this.content_type,
                object_id: this.object_id,
                datafile: res,
              })
              .pipe(first())
              .subscribe({
                next: (res: any) => {
                  this.uploading = false;
                  this.reload();
                },
                error: (error: any) => {
                  this.errorService.sendError(
                    "Error occur while uploading file."
                  );
                  this.uploading = false;
                },
              });
          }
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  download_file(button: HTMLButtonElement, file: File) {
    const ndx = this.files.indexOf(file);
    button.disabled = true;

    if (ndx > -1) {
      this.api.file
        .download(file.id)
        .pipe(first())
        .subscribe({
          next: (res: any) => {
            const data = new Blob([res.body], {
              type: "text/plain;charset=utf-8",
            });
            this.fileSaverService.save(data, file.filename);
          },
          error: (error) => {
            if (error.status === 500) {
              this.files_error.push(file.id);
            }
          },
          complete: () => {
            button.disabled = false;
          },
        });
    }
  }

  delete_file(button: HTMLButtonElement, file: File) {
    button.disabled = true;
    const ndx = this.files.indexOf(file);
    if (ndx > -1) {
      this.api.file
        .delete(file.id)
        .pipe(first())
        .subscribe({
          next: (res: any) => {
            this.files.splice(ndx, 1);
          },
          error: (error1) => {
            console.error(error1);
          },
          complete: () => {
            button.disabled = false;
          },
        });
    }
  }
  process_file(button: HTMLButtonElement, file: File) {
    button.disabled = true;
    this.process_file_fn(file).subscribe({
      complete: () => (button.disabled = false),
    });
  }
}
