import { AirportDetailComponent } from './detail/detail.component';
import { AirportListComponent } from './list/list.component';
import { AirportFormComponent } from './form/form.component';
import { AirportComponent } from './component';
import { AuthGuard } from '@app/common/auth.guard';

export const AirportRoute = {
  path: 'airport',
  component: AirportComponent,
  canActivate: [AuthGuard],
  children: [
    {path: '', component: AirportListComponent},
    {path: 'new', component: AirportFormComponent},
    {path: ':id', component: AirportDetailComponent},
    {path: ':id/edit', component: AirportFormComponent},
  ]
};
