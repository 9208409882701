import { Action } from '@ngrx/store';
import { ActionTypes } from '@app/store/map/sidebar/missions/routes/actions';

export default interface MissionRoutesActionWithPayload<T> extends Action {
    type: string;
    routes: any;
}

export interface MissionRoutes {
    routes: any;
}

export const initialState: MissionRoutes = {
    routes: {}
};

export function mapSidebarMissionRoutesReducer(state = initialState, action: Action): MissionRoutes {
    switch (action.type) {
        case ActionTypes.GetMissionRoutes:
            return {
                ...state,
                routes: (action as MissionRoutesActionWithPayload<MissionRoutes>).routes
            };
        case ActionTypes.AssignMissionRoutes:
            return {
                ...state,
                routes: (action as MissionRoutesActionWithPayload<MissionRoutes>).routes
            };
        default:
            return state;
    }
}
