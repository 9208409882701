import { Component, Input } from "@angular/core";

@Component({
  selector: "boolean-field",
  template: `
    <div>
      <mat-icon *ngIf="value" style="color: green;">check_circle</mat-icon>
      <mat-icon *ngIf="!value" style="color: red;">cancel</mat-icon>
    </div>
  `,
})
export class BooleanComponent {
  @Input("value") value: boolean;
}
