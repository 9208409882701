import { Action } from '@ngrx/store';

export enum ActionTypes {
    GetMissionRoutes = '[Mission Routes] Get Mission Routes',
    AssignMissionRoutes = '[Mission Routes] Assign Mission Routes'
}

export class GetMissionRoutes implements Action {
    readonly type = ActionTypes.GetMissionRoutes;
    constructor() {}
}

export class AssignMissionRoutes implements Action {
    readonly type = ActionTypes.AssignMissionRoutes;
    constructor(public routes: {}) {}
}

export type MapMissionRouteActions = GetMissionRoutes | AssignMissionRoutes;
