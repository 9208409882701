import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FilterStoreService } from "@app/services/store/filter/filterstore.service";
import * as moment from "moment";

interface FilterConfig {
  name: string;
  type: string;
  value?: string;
}
interface Dict {
  [id: string]: any;
}
@Component({
  selector: "app-generic-filter",
  templateUrl: "./generic-filter.component.html",
  styleUrls: ["./generic-filter.component.css"],
})
export class GenericFilterComponent implements OnInit {
  panelOpenState = false;
  searchParams: Dict = {};
  search_for = [];
  _filterConfig: FilterConfig[] = [];
  now = moment().format("YYYY-MM-DD");
  yesterday = moment(this.now).subtract(1, "day").format("YYYY-MM-DD");
  tomorrow = moment(this.now).add(1, "day").format("YYYY-MM-DD");
  @Input() filterList: any[] = [];
  @Input() store_name: string;
  @Output() filterData = new EventEmitter<any>();

  constructor(private storeService: FilterStoreService) {}

  ngOnInit() {
    if (this.store_name === "mission") {
      this.searchParams["from_date"] = this.yesterday;
      this.searchParams["until_date"] = this.tomorrow;
    }
    this._filterConfig = this.filterList;
    const filter_state = this.storeService.get_component_state(this.store_name);
    if (filter_state) {
      this.searchParams = { ...this.searchParams, ...filter_state };
    }
    if (Object.entries(this.searchParams).length) {
      this.search_for = Object.values(this.searchParams);
      this.filterValues(this.searchParams);
    }
  }

  filterValues(value: Dict) {
    this.storeService.assign_filter_values(this.store_name, value);
    this.filterData.emit(value);
  }
}
