import { Injectable } from '@angular/core';
import { BaseApiService } from '@app/api/base_api';
import { Contact } from '../types';

@Injectable()
export class ContactService extends BaseApiService<Contact> {
  component: string = 'contact';

  get(content_type: string, object_id: number) {
    return this.list({ type: content_type, object: object_id });
  }
}
