<mat-card>
  <mat-card-title>{{mission.label}}</mat-card-title>
  <mat-card-subtitle class="row">
    <span class="spacer">    </span>
    <span>{{mission.last_modified | date}}</span>
  </mat-card-subtitle>
  <mat-card-content>
    <mat-spinner *ngIf="loading"></mat-spinner>
    <table *ngIf="!loading">
      <tr>
        <td>&nbsp;</td>
        <td><b>Planned</b></td>
        <td><b>Actual</b></td>
      </tr>
      <tr>
        <td>Aircraft</td>
        <td>{{mission.aircraft.make}} {{mission.aircraft.model}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Launch</td>
        <td>{{mission.launch.properties.name}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Recover</td>
        <td>{{mission.recover.properties.name}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td colspan="3"><b>Flight Details</b></td>
      </tr>
      <tr>
        <td>Flight Distance</td>
        <td>{{mission.flight_plan.properties.length|number:'1.0-0'}} km</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Flight Time</td>
        <td>{{mission_time.planned/3600|number:'1.2-2'}} hours</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Average Speed</td>
        <td>{{mission.aircraft.speed}} knots</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td colspan="3"><b>Meter Details</b></td>
      </tr>
      <tr *ngFor="let m of mission.meter_groups">
        <td>{{m.group_name}}</td>
        <td>{{meter_counts[m.group_name]}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Total Meters</td>
        <td>{{meters}}<mat-spinner *ngIf="count_requests!=0" style="height:1em;"></mat-spinner></td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td colspan="3"><b>Operating Expenses</b></td>
      </tr>
      <tr>
        <td>Hourly</td>
        <td>
          <mat-form-field>
            <input matInput placeholder="OpEx" [(ngModel)]="opex.hourly" (change)="recalculate()" type="number">
          </mat-form-field>
        </td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Flight Total</td>
        <td>{{opex.total|currency:'USD':true}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Per Meter</td>
        <td>{{cost_per_meter.opex|currency:'USD':true}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td colspan="3"><b>Capital Expenses</b></td>
      </tr>
      <tr>
        <td>Hourly</td>
        <td>
          <mat-form-field>
            <input matInput placeholder="CapEx" [(ngModel)]="capex.hourly" (change)="recalculate()" type="number">
          </mat-form-field>
        </td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Flight Total</td>
        <td>{{capex.total|currency:'USD':true}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Per Meter</td>
        <td>{{cost_per_meter.capex|currency:'USD':true}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td colspan="3"><b>Total Cost</b></td>
      </tr>
      <tr>
        <td>Hourly</td>
        <td>{{(capex.hourly+opex.hourly) | currency:'USD':true}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Flight Total</td>
        <td>{{(capex.total+opex.total) | currency:'USD':true}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Per Meter</td>
        <td>{{cost_per_meter.total|currency:'USD':true}}</td>
        <td>&nbsp;</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>

