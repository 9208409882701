import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { FileSaverService } from "ngx-filesaver";
import { Subscription, Observable } from "rxjs";
import { filter, first, mergeMap, switchMap, tap } from "rxjs/operators";
import * as moment from "moment";

import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { TaskService } from "@app/task/task.service";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { FlightLegService } from "@app/services/flight-leg.service";
import { GenericListComponent } from "@app/generic-list/generic-list.component";
import { SubSink } from "subsink";
import { Flight } from "@app/api/types";

@Component({
  selector: "mission-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["../component.css"],
})
export class FlightDetailComponent implements OnInit, OnDestroy {
  flight: Flight = { id: null, mission_type: "" };
  names = {
    aircraft: {},
    airports: {},
    meter_groups: {},
    customers: {},
    employees: {},
  };
  mapRequiresResize = false;
  id: number;
  @ViewChild("files") files;
  @ViewChild("trackmap") trackmap;
  @ViewChild("mission_list") mission_list: GenericListComponent;
  loopControl = true;
  form_errors: any = { non_field_error: null, detail: null };
  add_flight_leg = true;
  location_type = "Reported";
  selected_tab;
  subsink = new SubSink();

  get flight_active() {
    if (this.flight.flight_status) {
      return (
        this.flight.flight_status != "Complete" &&
        this.flight.flight_status != "Canceled"
      );
    } else {
      return true;
    }
  }
  get flight_complete() {
    return this.flight.flight_status == "Complete";
  }

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private tasks: TaskService,
    private fileSaverService: FileSaverService,
    private errorHandleService: ErrorHandleService,
    private location: Location,
    private cdRef: ChangeDetectorRef,
    private titleService: Title,
    private flightLegService: FlightLegService
  ) {
    this.flightLegService.get_flight_data().subscribe((data: any) => {
      this.load_flight();
      if (data.data.landing_reason === "end of flight") {
        this.add_flight_leg = false;
      }
    });
  }

  get has_legs(): boolean {
    if (this.flight.flight_legs) {
      return this.flight.flight_legs.length > 0;
    }
    return false;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.load_flight();
    });
  }

  load_flight() {
    this.subsink.sink = this.api.flight.detail(this.id).subscribe({
      next: (flight) => {
        const title = "Tarnished Lamp Flight " + flight.aircraft_label;
        this.titleService.setTitle(title);
        this.flight = flight;
        if (flight.actual_recovery) {
          this.add_flight_leg = false;
        }
        this.trackmap.update(this.flight);
      },
      error: (errors) => {
        this.form_errors = errors.error;
      },
    });
  }

  calculate_route(button: HTMLButtonElement) {
    button.disabled = true;
    this.api.flight.generate_flightplan(this.id).subscribe({
      next: (res: any) => {
        this.tasks.add_task(res.task_id, {
          message: "Start Working on Flight Plan",
          started: {
            message: "Generating Flight Plan",
          },
          finished: {
            message: "Flight Plan Generation Complete",
            callback: (job) => {
              this.load_flight();
            },
          },
        });
      },
      error: (error) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  calculate_track(button: HTMLButtonElement) {
    button.disabled = true;
    this.api.flight.generate_flightpath(this.id).subscribe({
      next: (res: any) => {
        this.tasks.add_task(res.task_id, {
          message: "Start Working on Flight Path",
          started: {
            message: "Generating Flight Path",
          },
          finished: {
            message: "Flight Path Generation Complete",
            callback: (_job) => {
              this.load_flight();
            },
          },
        });
      },
      error: (error) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  set_flight_leg(complete_flight: boolean) {
    this.dialogService
      .set_flight_leg(
        this.flight.id,
        this.flight.launch,
        this.flight.recover,
        this.flight.scheduled_launch,
        this.flight.scheduled_recovery,
        complete_flight
      )
      .subscribe((res: any) => {});
  }

  _upload_file(endpoint, complete) {
    console.log(this);
    this.dialogService
      .file_upload()
      .pipe(first())
      .subscribe((res: any) => {
        if (res) {
          endpoint(this.id, res)
            .pipe(first())
            .pipe(complete)
            .subscribe((res: any) => {
              this.files.reload();
              if ("task_id" in res) {
                this.watch_analysis(res.task_id);
              }
            });
        }
      });
  }

  upload_results(button: HTMLButtonElement) {
    button.disabled = true;
    this._upload_file(
      this.api.flight.upload_collector_result.bind(this.api.flight),
      tap({
        complete: () => {
          button.disabled = false;
        },
      })
    );
  }

  upload_flightplan(button: HTMLButtonElement) {
    button.disabled = true;
    this._upload_file(
      this.api.flight.upload_flightplan.bind(this.api.flight),
      tap({
        complete: () => {
          button.disabled = false;
        },
      })
    );
  }

  watch_analysis(task_id, task_name: string = "Analysis") {
    this.tasks.add_task(task_id, {
      message: task_name + " Scheduled",
      started: {
        message: task_name + " Running",
        always: true,
      },
      finished: {
        message: task_name + " Complete",
        callback: (job) => {
          this.load_flight();
          this.files.reload();
        },
      },
    });
  }

  onTabSelect(event) {
    if (event.tab.textLabel === "Tracks") {
      this.trackmap.invalidateSize();
      this.trackmap.update(this.flight);
    }
  }

  download_collector_files(button: HTMLButtonElement) {
    button.disabled = true;
    this.api.flight
      .generate_collector_archive(this.id, this.location_type)
      .subscribe({
        next: (task: any) => {
          this.tasks.add_task(task.task_id, {
            message: "Scheduled Archive Generation",
            started: {
              message: "Generating Archive",
            },
            finished: {
              message: "Archive Complete",
              callback: (job) => {
                this.files.reload();
                this.download_file(job.result);
              },
            },
          });
        },
        error: (errors) => {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. If this keeps happening please contact support."
          );
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  download_meter_locations(button: HTMLButtonElement) {
    button.disabled = true;
    this.api.flight
      .download_meter_locations(this.id, this.location_type)
      .subscribe({
        next: (task: any) => {
          this.tasks.add_task(task.task_id, {
            message: "Scheduled Location File Generation",
            started: {
              message: "Generating Location File",
            },
            finished: {
              message: "File Complete",
              callback: (job) => {
                this.files.reload();
                this.download_file(job.result);
              },
            },
          });
        },
        error: (errors) => {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. If this keeps happening please contact support."
          );
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  download_file(file) {
    this.subsink.sink = this.api.file
      .download(file.pk)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          const data = new Blob([res.body], {
            type: "text/plain;charset=utf-8",
          });
          this.fileSaverService.save(data, file.filename);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  delete_flight(button: HTMLButtonElement, id) {
    button.disabled = true;
    this.api.flight.delete(id).subscribe({
      next: (data: any) => {
        this.location.back();
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  remove_payload(button: HTMLButtonElement, ident) {
    button.disabled = true;
    this.api.payload.unassign(ident, this.flight.aircraft).subscribe({
      next: (data: any) => {
        this.load_flight();
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  add_payload(button: HTMLButtonElement) {
    button.disabled = true;
    this.dialogService
      .select_payload()
      .pipe(
        switchMap((payload) => {
          if (!payload) {
            return this.api.payload.null();
          }
          return this.api.payload.assign(payload.ident, this.flight.aircraft);
        })
      )
      .subscribe({
        next: (data: any) => {
          this.load_flight();
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  add_mission(button: HTMLButtonElement) {
    let from_date: moment.Moment = this.flight.scheduled_launch;
    if (this.flight.actual_launch) {
      from_date = this.flight.actual_launch;
    }

    let until_date: moment.Moment = this.flight.scheduled_recovery;
    if (this.flight.actual_recovery) {
      until_date = this.flight.actual_recovery;
    }
    until_date = until_date.add(1, "day");

    let mission_select = new this.dialogService.generic_select(
      "Mission",
      this.api.mission.list({
        from_date: from_date.format("YYYY-MM-DD"),
        until_date: until_date.format("YYYY-MM-DD"),
      }),
      (obj) => {
        return obj.mission_id;
      }
    );
    button.disabled = true;
    mission_select
      .open()
      .pipe(
        filter((mission) => mission != undefined && mission != null),
        switchMap((mission: any) => {
          return this.api.flight.add_mission(this.id, mission.id);
        })
      )
      .subscribe({
        next: (result) => {
          this.mission_list.load_data();
          this.load_flight();
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  copy_flightplan(button: HTMLButtonElement) {
    let dialog = new this.dialogService.generic_text(
      "Track ID",
      "Select Track"
    );
    button.disabled = true;
    dialog
      .open()
      .pipe(
        switchMap((track_id) => {
          console.log(track_id);
          return this.api.flight.copy_flightplan(this.id, track_id);
        })
      )
      .subscribe({
        next: (res) => {
          this.load_flight();
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  update_flightarea(button: HTMLButtonElement) {
    button.disabled = true;
    this.api.flight.update_flightarea(this.id).subscribe({
      next: (res) => {
        this.load_flight();
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  remove_mission(button: HTMLButtonElement, id) {
    button.disabled = true;
    this.api.flight.remove_mission(this.id, id).subscribe({
      next: (result) => {
        this.mission_list.load_data();
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  get_flight_missions(search_params: Observable<any>): Observable<any> {
    return search_params.pipe(
      switchMap((search_params) => {
        return this.api.mission.list(search_params);
      })
    );
  }

  cancel_flight(button: HTMLButtonElement) {
    button.disabled = true;
    this.dialogService
      .note_dialog("Reason for Flight Cancelation")
      .pipe(switchMap((note) => this.api.flight.cancel_flight(this.id, note)))
      .subscribe({
        next: (result) => {
          this.load_flight();
          console.log(`Cancel Flight: ${result}`);
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  complete_flight(button: HTMLButtonElement) {
    button.disabled = true;
    this.dialogService
      .note_dialog("Reason for Flight Completion")
      .pipe(
        filter((note) => note),
        switchMap((note) => {
          return this.api.flight.complete_flight(this.id, note);
        })
      )
      .subscribe({
        next: (result) => {
          this.load_flight();
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  add_crew(button: HTMLButtonElement) {
    console.log("No Op for crew addition");
  }

  remove_crew(button: HTMLButtonElement) {
    console.log("No Op for crew removal");
  }

  location_type_selected(event) {
    console.log(event);
    console.log(this.location_type);
  }

  ngOnDestroy() {
    this.loopControl = true;
    this.subsink.unsubscribe();
  }
}
