import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import * as missionActionTypes from "@app/store/map/sidebar/missions/actions";
import ActionWithPayload, {
  Missions,
} from "@app/store/map/sidebar/missions/reducer";

@Injectable()
export class MapSidebarMissionsStoreService {
  missionState$: Observable<Missions>;

  constructor(private store: Store<any>) {}

  public get_missions(): any {
    let missions = [];
    this.missionState$ = this.store.pipe(
      map((state: any) => state.map_sidebar_missions)
    );
    this.missionState$
      .pipe(
        map((data: any) => {
          if (data && data.missions && data.missions.length) {
            missions = data.missions;
          }
        })
      )
      .subscribe();
    return missions;
  }

  public splice_missions(missions) {
    const SpliceMissions: ActionWithPayload<Missions> = {
      type: missionActionTypes.ActionTypes.SpliceMissions,
      missions: missions,
    };
    this.store.dispatch(SpliceMissions);
  }
}
