import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FlatpickrModule } from "angularx-flatpickr";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
// import { ContextMenuModule } from 'ngx-contextmenu';

import { ApiService } from "@app/api/api";
import { CG_CommonModule } from "@app/common/module";
import { NoteModule } from "@app/note/module";
import { CalenderComponent } from "@app/calender/calender.component";
import { MaterialModule } from "@app/material.module";
import { TreeChecklistComponent } from "@app/calender/sidenav/tree-checklist";

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    CG_CommonModule,
    NoteModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    // ContextMenuModule.forRoot({
    //   useBootstrap4: true
    // })
  ],
  exports: [CalenderComponent],
  providers: [ApiService],
  declarations: [CalenderComponent, TreeChecklistComponent],
})
export class CalenderComponentModule {}
