import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay,
  CalendarView,
} from "angular-calendar";
import { Title } from "@angular/platform-browser";
import { Subject, Observable, forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import {
  startOfDay,
  endOfDay,
  endOfMonth,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  format,
} from "date-fns";
import { ApiService } from "@app/api/api";
import { colors } from "@app/calender/colors";
import { CustomDateFormatter } from "@app/calender/custom-date-formatter.provider";

@Component({
  selector: "app-calender",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated, // hack to get the styles to apply locally
  templateUrl: "./calender.component.html",
  styleUrls: ["./calender.component.css"],
  providers: [
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class CalenderComponent implements OnInit {
  // @ViewChild("modalContent", { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: { action: string; event: CalendarEvent };

  calender_class = "new-class row text-center";

  missionSelect: any = {};
  flightSelect: any = {};

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent("Edited", event, this.events$);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  events$: Observable<Array<CalendarEvent<{ events: any }>>>;

  // sidenav functionalities
  sidenavPosition = "side";
  isSidenavOpen = true;
  isSidenavCloseDisabled = true;
  nextbtn = false;
  previousbtn = true;

  constructor(private api: ApiService, private titleService: Title) {
    const title = "Tarnished Lamp Calendar";
    this.titleService.setTitle(title);
  }

  getDates() {
    return {
      getStart: {
        month: startOfMonth,
        week: startOfWeek,
        day: startOfDay,
      }[this.view],
      getEnd: {
        month: endOfMonth,
        week: endOfWeek,
        day: endOfDay,
      }[this.view],
    };
  }

  filter_tree(node) {
    const { getStart, getEnd } = this.getDates();

    if (Object.keys(node).length === 0) {
      this.flightSelect = {};
      this.missionSelect = {};
    } else {
      const params = {
        from_date: format(getStart(this.viewDate), "yyyy-MM-dd"),
        until_date: format(getEnd(this.viewDate), "yyyy-MM-dd"),
      };
      const missionParams = params;
      const flightParams = params;

      const mission = [];
      const flight = [];

      for (const key of Object.keys(node)) {
        if (node[key] === "Mission") {
          mission.push(key);
          missionParams["mission_type"] = mission;
          this.missionSelect["mission_type"] = mission;
        } else {
          flight.push(key);
          flightParams["aircraft_registration"] = flight;
          this.flightSelect["aircraft_registration"] = flight;
        }
      }

      const missionCall = this.api.mission.search(missionParams);
      const flightCall = this.api.flight.search(flightParams);

      const calls = [of({}), of({})];
      if (mission.length > 0) {
        calls[0] = missionCall;
      }
      if (flight.length > 0) {
        calls[1] = flightCall;
      }
      this.events$ = this.synchronizeApiCall(calls);
    }
  }

  ngOnInit() {}

  route_select(eventType) {
    return eventType === "mission" ? "mission" : "flight";
  }

  calenderEvents(button, e): void {
    const { getStart, getEnd } = this.getDates();

    const params = {
      from_date: format(getStart(this.viewDate), "yyyy-MM-dd"),
      until_date: format(getEnd(this.viewDate), "yyyy-MM-dd"),
    };
    const flightParams = params;
    const missionParams = params;

    const calls = [of({}), of({})];

    if (
      this.flightSelect.hasOwnProperty("aircraft_registration") ||
      this.missionSelect.hasOwnProperty("mission_type")
    ) {
      if (
        this.flightSelect.hasOwnProperty("aircraft_registration") &&
        this.flightSelect["aircraft_registration"].length > 0
      ) {
        flightParams["aircraft_registration"] =
          this.flightSelect["aircraft_registration"];
      }
      if (
        this.missionSelect.hasOwnProperty("mission_type") &&
        this.missionSelect["mission_type"].length > 0
      ) {
        missionParams["mission_type"] = this.missionSelect["mission_type"];
      }
    } else {
      flightParams["cal"] = "calendar";
      missionParams["cal"] = "calendar";
    }

    const mission = this.api.mission.search(missionParams);
    const flight = this.api.flight.search(flightParams);

    if (
      missionParams.hasOwnProperty("cal") &&
      flightParams.hasOwnProperty("cal")
    ) {
      calls[0] = mission;
      calls[1] = flight;
    } else {
      if (missionParams.hasOwnProperty("mission_type")) {
        calls[0] = mission;
      }
      if (flightParams.hasOwnProperty("aircraft_registration")) {
        calls[1] = flight;
      }
    }

    this.events$ = this.synchronizeApiCall(calls);
  }

  synchronizeApiCall(calls): any {
    return forkJoin(calls).pipe(
      map((res: Array<any>) => {
        let missions: Array<any> = [];
        if (res[0].hasOwnProperty("results")) {
          missions = res[0].results.map((data: any) => {
            return {
              title:
                '<a href="#/mission/' + data.id + '">' + data.label + "</a>",
              id: data.id,
              start: new Date(data.start_date),
              end: new Date(data.end_date),
              color: colors.yellow,
              actions: this.actions,
              allDay: true,
              meta: {
                data,
                type: "warning",
                eventType: "mission",
                incrementsBadgeTotal: false,
              },
            };
          });
        }
        let flights: Array<any> = [];
        if (res[1].hasOwnProperty("results")) {
          if (res[1].results.length > 0) {
            flights = res[1].results.map((data: any) => {
              return {
                title:
                  '<a href="#/flight/' +
                  data.id +
                  '">' +
                  data.aircraft_registration +
                  "</a>",
                id: data.id,
                start: new Date(data.scheduled_launch),
                end: new Date(data.scheduled_recovery),
                color: colors.blue,
                actions: this.actions,
                meta: {
                  data,
                  type: "info",
                  eventType: "flight",
                  incrementsBadgeTotal: true,
                },
              };
            });
          }
        }
        return missions.concat(flights);
      })
    );
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((cell) => {
      const groups: any = {};
      cell.badgeTotal = cell.events.filter(
        (event) => event.meta.incrementsBadgeTotal
      ).length;
      cell.events.forEach((event: CalendarEvent<{ type: string }>) => {
        groups[event.meta.type] = groups[event.meta.type] || [];
        groups[event.meta.type].push(event);
      });
      cell["eventGroups"] = Object.entries(groups);
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      this.activeDayIsOpen = !(
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      );
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.refresh.next(1);
  }

  handleEvent(action: string, event: CalendarEvent, e): boolean {
    if (
      e.sourceEvent.ctrlKey ||
      e.sourceEvent.metaKey ||
      (e.sourceEvent.button && e.sourceEvent.button == 1)
    )
      return;
    // e.sourceEvent.preventDefault();
    // this.modalData = { event, action };
    // if (action === 'Clicked') {
    //   this.modal.open(this.modalContent, { size: 'lg' });
    // }
  }

  // addEvent(event): void {
  //   let meta_type = 'info';
  //   let schedule_color = colors.blue;
  //   let end_date = addDays(event.item, 1);
  //   let allDay = false;
  //   if (event.event.srcElement.innerText === 'Add Mission') {
  //     meta_type = 'warning';
  //     schedule_color = colors.yellow;
  //     end_date = addDays(event.item, 2);
  //     allDay = true;
  //   }
  //   this.events.push({
  //     title: event.event.srcElement.innerText,
  //     start: startOfDay(event.item),
  //     end: end_date,
  //     color: schedule_color,
  //     actions: this.actions,
  //     allDay: allDay,
  //     draggable: true,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true
  //     },
  //     meta: {
  //       type: meta_type
  //     }
  //   });
  //   this.refresh.next(1);
  // }

  sidenav(value) {
    if (value === "calendar") {
      this.isSidenavOpen = false;
      this.nextbtn = true;
      this.previousbtn = false;
    } else {
      this.isSidenavOpen = true;
      this.nextbtn = false;
      this.previousbtn = true;
    }
  }
}
