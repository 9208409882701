import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import * as calenderSidenavActionType from "@app/store/calender/actions";
import ActionWithPayload, { CheckState } from "@app/store/calender/reducer";

@Injectable()
export class CalenderSidenavStoreService {
  checks$: Observable<CheckState>;

  constructor(private store: Store<CheckState>) {}

  public get_checks_state() {
    let filter_state = [];
    this.checks$ = this.store.pipe(
      map((state: any) => state.calendar_sidenav_checks)
    );
    this.checks$
      .pipe(
        map((checks) => {
          if (checks && checks.tree_checks && checks.tree_checks.length) {
            filter_state = checks.tree_checks;
          }
        })
      )
      .subscribe();
    return filter_state;
  }

  public push_checks(value) {
    const FilterValuesAction: ActionWithPayload<CheckState> = {
      type: calenderSidenavActionType.CalenderActionTypes.ToggleChecks,
      tree_checks: value,
    };
    this.store.dispatch(FilterValuesAction);
  }

  public assign_checks(node) {
    const tree_state = this.get_checks_state();
    const index = tree_state.find((val) => val.item === node.item);
    if (!index) {
      tree_state.push(node);
    }
    this.push_checks(tree_state);
  }

  public remove_checks(node) {
    const tree_state = this.get_checks_state();
    const index = tree_state.findIndex((val) => val.item === node.item);
    if (index > -1) {
      tree_state.splice(index, 1);
    }
    this.push_checks(tree_state);
  }
}
