import { Component, OnDestroy, OnInit, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ApiService } from "@app/api/api";
import * as moment from "moment";
import { Subscription, Observable } from "rxjs";

export interface optionConfig {
  record_id?: number;
  aircraft_id?: number;
}

export interface TTSN {
  id: number;
  ttsn: number;
  aircraft: number;
  aircraft_name?: string;
  timestamp: string | moment.Moment;
}

@Component({
  selector: "genericselect-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class TTSNDialogComponent implements OnInit, OnDestroy, optionConfig {
  error_message: string = null;
  record_id: number = null;
  aircraft_id: number = null;
  record: TTSN = { id: null, ttsn: null, aircraft: null, timestamp: null };
  aircraft = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: optionConfig,
    public dialogRef: MatDialogRef<TTSNDialogComponent>,
    public api: ApiService
  ) {
    Object.assign(this, data);
  }

  ngOnInit() {
    if (this.record_id) {
      console.log(`Getting TTSN record ${this.record_id}`);
      this.api.ttsn.detail(this.record_id).subscribe((data: TTSN) => {
        this.record = data;
        this.record.timestamp = moment(this.record.timestamp).format(
          "YYYY-MM-DD HH:mm"
        );
      });
    }
    console.log(`Getting Aircraft record ${this.aircraft_id}`);
    this.api.aircraft.detail(this.aircraft_id).subscribe((data) => {
      this.aircraft = data;
      this.record.aircraft = data.id;
    });
    this.record.aircraft = this.aircraft_id;
  }

  submit(button: HTMLButtonElement) {
    let api = this.api.ttsn.create;
    if (this.record_id) {
      api = (record: TTSN) => {
        return this.api.ttsn.update(this.record_id, record);
      };
    }
    button.disabled = true;
    api(this.record).subscribe({
      next: (data: TTSN) => {
        this.dialogRef.close(data);
      },
      error: (error) => {
        this.error_message = error;
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {}
}
