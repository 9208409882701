import { EmployeeDetailComponent } from './detail/detail.component';
import { EmployeeListComponent } from './list/list.component';
import { EmployeeFormComponent } from './form/form.component';
import { EmployeeComponent } from './employee.component';
import { AuthGuard } from '@app/common/auth.guard';

export const EmployeeRoute = {
  path: 'employee',
  component: EmployeeComponent,
  canActivate: [AuthGuard],
  children: [
    {path: '', component: EmployeeListComponent},
    {path: 'new', component: EmployeeFormComponent},
    {path: ':id', component: EmployeeDetailComponent},
    {path: ':id/edit', component: EmployeeFormComponent},
  ]
};
