import { NgModule } from "@angular/core";
import { IConfig } from "ngx-mask";

import { MapSidebarMissionsStoreService } from "@app/services/store/map/sidebar/missions/store.service";
import { MapSidebarMissionRoutesStoreService } from "@app/services/store/map/sidebar/missions/routes/store.service";
import { MapSidebarMissionFlightsStoreService } from "@app/services/store/map/sidebar/missions/flights/store.service";
import { MapSidebarMissionMapChecksStoreService } from "@app/services/store/map/sidebar/missions/map_mission/store.service";
import { MapStoreService } from "@app/services/store/map/sidebar/mapstore.service";
import { FilterStoreService } from "@app/services/store/filter/filterstore.service";
import { CalenderSidenavStoreService } from "@app/services/store/calender/store.service";
import { ACSService } from "./acs.service";
import { RealTimeService } from "./realtime.service";
import { TokenService } from "./token.service";
import { FlightEventService } from "./flight_event.service";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [],
  exports: [],
  providers: [
    MapSidebarMissionsStoreService,
    MapSidebarMissionRoutesStoreService,
    MapSidebarMissionFlightsStoreService,
    MapStoreService,
    MapSidebarMissionMapChecksStoreService,
    FilterStoreService,
    CalenderSidenavStoreService,
    ACSService,
    RealTimeService,
    TokenService,
    FlightEventService,
  ],
  declarations: [],
})
export class ServicesModule {}
