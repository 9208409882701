import { Routes } from '@angular/router';
import { AuthGuard } from '@app/common/auth.guard';
import { MapComponent } from './map.component';

export const MapRoutes: Routes = [{
    path: 'map',
    component: MapComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '', component: MapComponent
      }
    ]
}];

