<mat-form-field fxFlex>
    <input matInput placeholder="Customer" [matAutocomplete]="auto" [formControl]="state_ctl" [(ngModel)]="customer"
           [maxlength]="225" required>
    <mat-error *ngIf="!customerJobDialog && state_ctl.errors">Name is required</mat-error>
</mat-form-field>
<mat-spinner *ngIf="customers_loading"></mat-spinner>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="customer_display" autoActiveFirstOption="true">
    <mat-option *ngFor="let state of filtered_states | async" [value]="state">
        {{ state.name }}
    </mat-option>
</mat-autocomplete>
