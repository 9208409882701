import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { CG_CommonModule } from '@app/common/module';
import { NoteModule } from '@app/note/module';
import { DialogModule } from '@app/dialog/dialog.module';
import { ApiService } from '@app/api/api';
import { RouteComponent } from './component';
import { MaterialModule } from '@app/material.module';
import { RouteDetailComponent, Nl2BrPipe } from './detail/detail.component';
import { RouteListComponent } from './list/list.component';
import { RouteFormComponent } from './form/form.component';
import { TaskService } from '@app/task/task.service';


@NgModule({
  imports: [
      FormsModule, CommonModule, RouterModule, MaterialModule, CG_CommonModule, NoteModule,
      ReactiveFormsModule, DialogModule, FlexLayoutModule
  ],
  exports: [
    RouteComponent, RouteDetailComponent, RouteListComponent, RouteFormComponent
  ],
  providers: [ ApiService, TaskService, Title ],
  declarations: [
    RouteComponent, RouteDetailComponent, RouteListComponent, RouteFormComponent, Nl2BrPipe
  ]
})
export class RouteModule {}
