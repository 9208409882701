<mat-card *ngIf="form_errors.detail">
  <div class="detail-not-exist">Record Does Not Exist.</div>
</mat-card>
<div fxLayout="row" fxLayoutAlign="center" *ngIf="!form_errors.detail">
  <mat-card class="client-detail" id="payload-form">
    <div fxLayout="column">
      <mat-card-header>
        <mat-card-title>
          <h2 fxFlex>{{ title }}</h2>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="column">
          <mat-error *ngIf="form_errors.non_field_error">{{
            form_errors.non_field_error
          }}</mat-error>
          <mat-form-field fxFlex>
            <input
              matInput
              placeholder="Name"
              [(ngModel)]="payload.name"
              maxlength="12"
              required
            />
          </mat-form-field>
          <mat-error *ngIf="form_errors.name">
            {{ form_errors.name }}
          </mat-error>
          <mat-form-field fxFlex>
            <input
              matInput
              placeholder="Ident"
              [(ngModel)]="payload.ident"
              maxlength="8"
              required
            />
          </mat-form-field>
          <mat-error *ngIf="form_errors.ident">
            {{ form_errors.ident }}
          </mat-error>
          <mat-slide-toggle [(ngModel)]="payload.active">
            Active
          </mat-slide-toggle>
          <mat-form-field fxFlex>
            <textarea
              matInput
              placeholder="Collector Description"
              [(ngModel)]="payload.description"
              required
            >
            </textarea>
          </mat-form-field>
          <mat-error *ngIf="form_errors.ident">
            {{ form_errors.ident }}
          </mat-error>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="column" fxLayoutGap="20px">
          <span fxFlex fxLayoutAlign="end">
            <button mat-raised-button #save_btn (click)="save(save_btn)">
              Save
            </button>
          </span>
          <span fxFlex></span>
        </div>
      </mat-card-actions>
    </div>
  </mat-card>
</div>
