import { Component, OnInit, ViewChild } from "@angular/core";
import { GenericListComponent } from "@app/generic-list/generic-list.component";
import { Observable } from "rxjs";
import { switchMap, map, tap } from "rxjs/operators";
import { ApiService } from "@app/api/api";

@Component({
  selector: "airport-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class AirportListComponent implements OnInit {
  filters = [
    { label: "Name", name: "name", maxlength: 20, type: "text" },
    { label: "ICAO Code", name: "icao_code", maxlength: 4, type: "text" },
  ];
  @ViewChild(GenericListComponent, { static: true })
  genericlist: GenericListComponent;
  constructor(private api: ApiService) {}

  ngOnInit() {}
  getFilterData(value) {
    this.genericlist.filterValues = value;
  }

  get_airport_list(search_params: Observable<any>): Observable<any> {
    return search_params.pipe(
      switchMap((search_params) => {
        return this.api.airport.list(search_params);
      }),
      map((results: any) => {
        return {
          count: results.count,
          results: results.results.features.map((element) => {
            return { id: element.id, ...element.properties };
          }),
        };
      })
    );
  }
}
