import { Subject, Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class FlightLegService {
  flight_leg = new Subject();

  send_flight_data(value) {
    this.flight_leg.next({ data: value });
  }

  get_flight_data(): Observable<any> {
    return this.flight_leg.asObservable();
  }
}
