<div class="wrapper">
  <header>
    <mat-toolbar color="primary" fxLayout="row">
      <span fxFlex>Flight Leg Record</span>
      <span fxFlex fxLayoutAlign="end">
        <button
          mat-icon-button
          (click)="dialogRef.close()"
          title="Close Dialog"
        >
          <mat-icon>close</mat-icon>
        </button>
      </span>
    </mat-toolbar>
  </header>
  <div class="content content-height">
    <form [formGroup]="landing" (ngSubmit)="flight_leg_landing()">
      <div fxLayout="column" fxLayout.gt-sm="row">
        <span
          fxFlex
          fxLayout.sm="row"
          fxLayout.xs="row"
          fxFlexAlign="center"
          fxLayoutGap="5px"
        >
          <span class="mat-input-wrapper">
            <mat-form-field>
              <mat-select
                placeholder="Reason of Landing"
                formControlName="reason"
                required
              >
                <mat-option></mat-option>
                <mat-option value="fuel">Fuel</mat-option>
                <mat-option value="end of flight">End of Flight</mat-option>
                <mat-option value="weather">Weather</mat-option>
                <mat-option value="precautionary">Precautionary</mat-option>
                <mat-option value="emergency">Emergency</mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span class="mat-input-wrapper">
            <mat-form-field style="width: 5em">
              <input
                matInput
                placeholder="TTSN"
                type="text"
                formControlName="ttsn"
              />
            </mat-form-field>
          </span>
        </span>

        <span
          fxFlex
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxFlexAlign="center"
          fxLayoutGap="5px"
        >
          <span class="mat-input-wrapper">
            <mat-form-field>
              <airport-select
                placeholder="Landing Airport"
                formControlName="airport"
              >
              </airport-select>
            </mat-form-field>
          </span>
        </span>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row">
        <span
          fxFlex
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxFlexAlign="center"
        >
          <span class="mat-input-wrapper">
            <mat-form-field>
              <mat-placeholder>Wheel TouchDown Time</mat-placeholder>
              <mat-datetimepicker-toggle [for]="datetimePicker4" matSuffix>
              </mat-datetimepicker-toggle>
              <mat-datetimepicker
                #datetimePicker4
                type="datetime"
                openOnFocus="true"
                timeInterval="1"
              >
              </mat-datetimepicker>
              <input
                matInput
                formControlName="touchdown_time"
                [matDatetimepicker]="datetimePicker4"
                autocomplete="false"
                required
              />
            </mat-form-field>
            <mat-error *ngIf="form_errors.touchdown_time">
              {{ form_errors.touchdown_time }}
            </mat-error>
          </span>
        </span>

        <span
          fxFlex
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxLayoutGap="5px"
          fxFlexAlign="center"
        >
          <span class="mat-input-wrapper">
            <mat-form-field>
              <mat-placeholder>Engine off Time</mat-placeholder>
              <mat-datetimepicker-toggle
                [for]="datetimePicker3"
                matSuffix
              ></mat-datetimepicker-toggle>
              <mat-datetimepicker
                #datetimePicker3
                type="datetime"
                openOnFocus="true"
                timeInterval="1"
              ></mat-datetimepicker>
              <input
                matInput
                formControlName="engine_off_time"
                [matDatetimepicker]="datetimePicker3"
                autocomplete="false"
                required
              />
            </mat-form-field>
            <mat-error *ngIf="form_errors.engine_off_time">
              {{ form_errors.engine_off_time }}
            </mat-error>
          </span>
        </span>
      </div>

      <div fxLayout="column">
        <mat-form-field [hidden]="!show_note">
          <mat-placeholder> Note </mat-placeholder>
          <textarea matInput formControlName="note"></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="form_errors.non_field_errors">
        <mat-error *ngFor="let error of form_errors.non_field_errors">
          {{ error }}
        </mat-error>
      </div>

      <div fxLayout="row" class="mt-2 mb-2 mb-md-0">
        <span fxFlex>
          <button
            mat-icon-button
            (click)="show_note = !show_note"
            type="button"
          >
            <mat-icon>note</mat-icon>
          </button>
        </span>

        <span fxFlex fxLayoutAlign="end">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="!landing.valid"
          >
            Submit
          </button>
        </span>
      </div>
    </form>
  </div>
</div>
