import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Subscription } from "rxjs";

import { ApiService } from "@app/api/api";

@Component({
  selector: "selectaircraft-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class SelectAircraftDialogComponent implements OnInit, OnDestroy {
  aircraft_list = [];
  selected_aircraft;
  $aircraft: Subscription;
  error_message: string = null;

  constructor(
    public dialogRef: MatDialogRef<SelectAircraftDialogComponent>,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.$aircraft = this.api.aircraft.list().subscribe(
      (data: any) => {
        this.aircraft_list = data.results.filter((e, i, a) => {
          return e.active;
        });
      },
      (error) => {
        this.error_message = error;
      }
    );
  }

  submit(button: HTMLButtonElement) {
    this.dialogRef.close(this.selected_aircraft);
  }

  ngOnDestroy() {
    if (this.$aircraft) {
      this.$aircraft.unsubscribe();
    }
  }
}
