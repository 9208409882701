import { Injectable } from "@angular/core";
import { Store, createSelector } from "@ngrx/store";
import { tap } from "rxjs/operators";
import { Subscription } from "rxjs";

import { Filter, assignFilters } from "@app/store/filter/filter_reducer";

interface AppState {
  filters: any;
}

@Injectable()
export class FilterStoreService {
  Filter$: Subscription;

  constructor(private store: Store<AppState>) {}

  public get_filter_values(name: string) {
    let filter_values = {};
    this.Filter$ = this.store
      .select(
        createSelector(
          (state: AppState) => state.filters,
          (filters: Filter) => filters[name]
        )
      )
      .pipe(
        tap((filterValues) => {
          filter_values = filterValues;
        })
      )
      .subscribe();
    return filter_values;
  }

  public assign_filter_values(
    component_name: string,
    value: { [id: string]: any }
  ) {
    this.store.dispatch(
      assignFilters({
        component: component_name,
        filter: value,
      })
    );
  }

  public get_component_state(component_name: string) {
    const filters = this.get_filter_values(component_name);
    if (!filters) {
      return {};
    }
    if (Object.entries(filters).length) {
      return filters;
    }
    return {};
  }
}
