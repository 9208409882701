import { ContactDetailComponent } from './detail/detail.component';
import { ContactListComponent } from './list/list.component';
import { ContactFormComponent } from './form/form.component';
import { ContactComponent } from './component';
import { AuthGuard } from '@app/common/auth.guard';

export const ContactRoute = {
  path: 'contact',
  component: ContactComponent,
  canActivate: [AuthGuard],
  children: [
    {path: '', component: ContactListComponent},
    {path: 'new', component: ContactFormComponent},
    {path: ':id', component: ContactDetailComponent},
    {path: ':id/edit', component: ContactFormComponent},
  ]
};
