import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from '@app/api/api';
import { ErrorHandleService } from '@app/services/errorHandle.service';

@Component({
  selector: 'contact-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['../component.css']
})
export class ContactDetailComponent implements OnInit, OnDestroy {

  contact: { [id: string]: any } = {};
  $contact: Subscription;
  id: number;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private api: ApiService,
      private errorHandleService: ErrorHandleService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number
      this.$contact =  this.api.contact.detail(this.id).subscribe(data => {
          this.contact = data;
           }, (errors) => {
        this.errorHandleService.sendError('Desired Contact Not Found');
      });
    })
  }

  ngOnDestroy() {
      if (this.$contact) {
        this.$contact.unsubscribe();
      }
  }

}
