<mat-toolbar color="primary">
  <span>File Import</span>
  <span class="spacer"></span>
  <button mat-icon-button (click)="dialogRef.close()" matTooltip="Close Dialog">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ file_detail.filename }}
    </mat-card-title>
    <mat-card-subtitle class="row">
      <span>{{ file_detail.filesize }} bytes</span>
      <span class="spacer"></span>
      <span>{{ file_detail.created | date }}</span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="import_data.default_group_name"
          placeholder="Default Group Name"
        />
      </mat-form-field>
      <span class="spacer"></span>
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="import_data.default_group_id"
          placeholder="Default Group ID"
        />
      </mat-form-field>
      <span class="spacer"></span>
      <span class="form_label"> First Row Contains</span>
      <mat-radio-group [(ngModel)]="import_data.headers">
        <mat-radio-button [value]="false"> Data </mat-radio-button>
        <mat-radio-button [value]="true"> Headers </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row" style="overflow-x: auto">
      <table *ngIf="file_head" class="spacer">
        <tr>
          <td *ngFor="let field of import_data.fields">
            <mat-select [(ngModel)]="field.value">
              <mat-option
                *ngFor="let f of field_headers | keys"
                [value]="f.key"
                >{{ f.value }}</mat-option
              >
            </mat-select>
          </td>
        </tr>
        <ng-template ngFor let-row [ngForOf]="file_head">
          <tr *ngIf="!first || !import_data.headers">
            <td *ngFor="let cell of row">
              {{ cell }}
            </td>
          </tr>
        </ng-template>
      </table>
    </div>
  </mat-card-content>
  <mat-card-actions class="row">
    <button type="button" mat-button (click)="dialogRef.close()">Cancel</button>
    <span class="spacer"></span>
    <button
      type="button"
      mat-raised-button
      #import_btn
      (click)="import(import_btn)"
    >
      Import
    </button>
  </mat-card-actions>
</mat-card>
