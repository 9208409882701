<mat-progress-bar
  *ngIf="request_fail"
  [mode]="barMode"
  [color]="barColor"
></mat-progress-bar>
<mat-card>
  <mat-card-content>
    <mat-list class="aircraft-list">
      <mat-accordion>
        <mat-list-item *ngFor="let ac of aircraft">
          <aircraft-entry
            [aircraft]="ac"
            [display_profile_check]="check_status"
          ></aircraft-entry>
        </mat-list-item>
      </mat-accordion>
    </mat-list>
  </mat-card-content>
  <mat-paginator
    class="sidenav-aircraft-paginator"
    [length]="totalCount"
    [pageSize]="pageCount"
    [pageSizeOptions]="[10, 25, 50, 100]"
    (page)="page_event($event)"
  >
  </mat-paginator>
</mat-card>
