import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { combineLatest, Subscription } from "rxjs";

import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { MapStoreService } from "@app/services/store/map/sidebar/mapstore.service";
import { Flight } from "@app/store/map/sidebar/map_reducer";
import { map } from "rxjs/operators";

export interface FlightState {
  plan: boolean;
  track: boolean;
  id: any;
}

@Component({
  selector: "mission-flights",
  templateUrl: "./mission.flights.html",
  styleUrls: ["./missions.component.css"],
})
export class MissionFlightsComponent implements OnInit, OnDestroy {
  @Input("mission") mission_id;
  @Output("update") state_change: EventEmitter<any> = new EventEmitter<any>();
  flights = [];
  flight_state = {};
  $flights: Subscription;
  $states: Subscription;
  constructor(
    private api: ApiService,
    private dialogService: DialogService,
    private mapstore: MapStoreService
  ) {}

  ngOnInit() {
    this.get_flight_data();
  }

  update_flight_data([flight_state, data]) {
    this.flights = data.map((value) => {
      return {
        ...value,
        _plan: flight_state[value.id]?.plan || false,
        _track: flight_state[value.id]?.track || false,
      };
    });

    const update = data
      .filter((flight) => flight_state[flight.id] === undefined)
      .map(
        (flight) =>
          new Flight(flight.id, flight.flight_plan, flight.flight_path)
      );

    this.mapstore.add_mission_flights(this.mission_id, update);
  }

  get_flight_data() {
    this.$flights = combineLatest([
      this.mapstore.get_mission_flights(this.mission_id),
      this.api.flight
        .list({ mission: this.mission_id })
        .pipe(map((data) => data["results"])),
    ]).subscribe((res) => this.update_flight_data(res));
  }

  flight(flight_id: number) {
    return this.flights.find((value) => value.id == flight_id);
  }
  update_plan(flight_id) {
    this.mapstore.show_mission_flight_plan(
      this.mission_id,
      flight_id,
      this.flight(flight_id)._plan
    );
    this.state_change.emit();
  }

  update_track(flight_id) {
    this.mapstore.show_mission_flight_track(
      this.mission_id,
      flight_id,
      this.flight(flight_id)._track
    );
    this.state_change.emit();
  }

  public flight_leg(button: HTMLButtonElement, flight_id) {
    button.disabled = true;
    this.dialogService.get_flight_leg(flight_id).subscribe({
      next: (res: any) => {},
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    if (this.$flights) {
      this.$flights.unsubscribe();
    }
  }
}
