import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ApiService } from "@app/api/api";
import { Router } from "@angular/router";
import { DialogService } from "@app/services/dialog.service";
import { GenericListComponent } from "@app/generic-list/generic-list.component";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "qualification",
  templateUrl: "./component.html",
  styleUrls: ["./component.css"],
})
export class QualificationComponent implements OnInit {
  page = "qualification";

  @ViewChild(GenericListComponent, { static: true }) qualification_list;
  constructor(
    private titleService: Title,
    private api: ApiService,
    public router: Router,
    private dialog_Service: DialogService
  ) {
    const title = "Tarnished Lamp Qualifications";
    this.titleService.setTitle(title);
  }

  ngOnInit() {
    this.api.qualification.list().subscribe((data) => {
      console.debug(data);
    });
  }

  qualification_form(id: number = null) {
    this.dialog_Service.qualification_form(id).subscribe((result) => {
      if (this.qualification_list) {
        this.qualification_list.load_data();
      }
    });
    return false;
  }

  get_qualification_list(search_params: Observable<any>): Observable<any> {
    return search_params.pipe(
      switchMap((search_params) => {
        return this.api.qualification.list(search_params);
      })
    );
  }
}
