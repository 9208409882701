<mat-card>
  <mat-card-content>
    <mat-list class="meters-map-list">
      <div fxFlex="100%" fxLayout="column">
        <mat-list-item
          *ngFor="let meter_group of displayed_groups"
          class="msk1"
        >
          <div
            fxFlex="70%"
            fxFlex.xs="50%"
            fxLayoutAlign="start"
            class="meter-label"
          >
            <mat-checkbox
              [checked]="meter_group.display"
              (change)="toggle($event, meter_group)"
            >
              <div class="meter-customer">{{ meter_group.customer_name }}</div>
              <div>{{ meter_group.group }}</div>
            </mat-checkbox>
          </div>
          <div fxFlex="30%" fxFlex.xs="50%" fxLayoutAlign="end" class="msk">
            <mat-progress-spinner
              *ngIf="meter_group['loading']"
              color="primary"
              [mode]="mode"
              [value]="call_spinner_value[meter_group.index]"
              [diameter]="16"
            ></mat-progress-spinner>
            <mat-progress-spinner
              *ngIf="api_call_error"
              [color]="color"
              [mode]="mode"
              [value]="error_spinner_value"
              [diameter]="16"
            ></mat-progress-spinner>
            <button
              mat-icon-button
              (click)="zoom_to_meters(meter_group)"
              matTooltip="Zoom to Meters"
            >
              <mat-icon>center_focus_strong</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="remove_meters(meter_group)"
              matTooltip="Remove Meters"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </div>
    </mat-list>
  </mat-card-content>

  <mat-card-actions>
    <div fxLayout="row">
      <span fxFlex fxLayoutAlign="start">
        <button mat-icon-button (click)="clear_map()" matTooltip="Clear Meters">
          <mat-icon>layers_clear</mat-icon>
        </button>
      </span>
      <span fxFlex fxLayoutAlign="center">
        <button
          mat-icon-button
          (click)="zoom_to_meters(null)"
          matTooltip="View all selected Meters"
        >
          <mat-icon>zoom_out_map</mat-icon>
        </button>
      </span>
      <span fxFlex fxLayoutAlign="end">
        <button mat-icon-button (click)="openDialog()" matTooltip="Add Meters">
          <mat-icon>add</mat-icon>
        </button>
      </span>
    </div>
  </mat-card-actions>
</mat-card>
