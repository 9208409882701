<div
  fxFlex="0 0 25em"
  fxLayout="column"
  fxLayoutGap="10px grid"
  fxLayoutAlign="space-around"
  *ngIf="icon"
  style="border: 1px solid black; margin: 10px"
>
  <div fxFlex fxLayoutAlign="center">
    <h3>{{label}}</h3>
  </div>
  <div fxFlex fxLayoutAlign="center">
    <canvas [width]="30" [height]="30" #canvas></canvas>
  </div>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutGap="10px grid"
    fxLayoutAlign="space-around"
  >
    <label fxFlex> Shape </label>
    <mat-select
      [(ngModel)]="icon.style.style"
      (selectionChange)="update()"
      fxFlex="0 1 10em"
    >
      <mat-option value="circle" default>Circle</mat-option>
      <mat-option value="square">Square</mat-option>
      <mat-option value="triangle">Triangle</mat-option>
    </mat-select>
  </div>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutGap="10px grid"
    fxLayoutAlign="space-around"
  >
    <label fxFlex>Colour</label>
    <input
      fxFlex="0 1 10em"
      [colorPicker]="icon.style.colour"
      [cpOKButton]="true"
      [cpOKButtonClass]="'mat-raised-button'"
      [cpPosition]="'bottom'"
      [style.background]="icon.style.colour"
      [value]="icon.style.colour"
      (colorPickerSelect)="set_colour($event)"
    />
  </div>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutGap="10px grid"
    fxLayoutAlign="space-around"
  >
    <label fxFlex>Size</label>
    <input
      fxFlex="0 1 10em"
      digitOnly
      matInput
      type="number"
      [min]="0"
      [max]="10"
      [value]="width"
      [(ngModel)]="icon.style.size"
      maxlength="2"
      (change)="update()"
    />
  </div>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutGap="10px grid"
    fxLayoutAlign="space-around"
  >
    <label fxFlex> Fill </label>
    <mat-checkbox
      [(ngModel)]="icon.style.fill"
      (change)="update()"
    ></mat-checkbox>
  </div>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutGap="10px grid"
    fxLayoutAlign="space-around"
  >
    <label fxFlex>Width</label>
    <input
      fxFlex="0 1 10em"
      digitOnly
      matInput
      type="number"
      [min]="0"
      [max]="10"
      [value]="width"
      [(ngModel)]="icon.style.width"
      maxlength="2"
      (change)="update()"
    />
  </div>
</div>
