import {
  Component,
  Output,
  Input,
  EventEmitter,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { switchMap, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { BaseWidget } from "./base.widget";
import * as moment from "moment";

@Component({
  selector: "<daily_flights_widget>",
  template: `
    <app-generic-list
      [displayedColumns]="['id', 'takeoff', 'landing', 'pilot', 'aircraft']"
      [source]="get_flight_data"
      [params]="params"
      [router]="'flight'"
    >
    </app-generic-list>
  `,
})
export class DailyFlightsWidget extends BaseWidget implements OnInit {
  @Input() config: { params? } = {};
  today: string = "2020-11-8";
  params = { on: this.today };

  ngOnInit() {
    let d = new Date().toISOString();
    this.today = d.substring(0, d.indexOf("T"));
    this.params = { on: this.today };

    this.setTitle("Today's Flights");
  }

  get_flight_data(search_param: Observable<any>): Observable<any> {
    return search_param.pipe(
      switchMap((search_param) => {
        return this.api.flight.list(search_param).pipe(
          map((e: { results: any[] }) => {
            e.results = e.results.map((element) => {
              const airport_time = (e: string) => {
                const actual = element[`actual_${e}`];
                const scheduled = element[`scheduled_${e}`];
                const icao = element[`${e}_icao`]
                  ? element[`${e}_icao`]
                  : element[`recover_icao`];
                let [t, s] = actual ? [actual, false] : [scheduled, true];
                if (t) {
                  t = t.format("hh:mm");
                  t = s ? `[${t}]` : t;
                } else {
                  t = "--";
                }
                return `${icao} ${t}`;
              };
              for (const f of [
                "scheduled_launch",
                "scheduled_recovery",
                "actual_launch",
                "actual_recovery",
              ]) {
                element[f] = element[f] !== null ? moment(element[f]) : null;
              }
              return {
                id: element.id,
                takeoff: airport_time("launch"),
                landing: airport_time("recovery"),
                pilot: `${element.pilot_fname} ${element.pilot_lname}`,
                aircraft: element.aircraft_registration,
              };
            });
            return e;
          })
        );
      })
    );
  }
}
