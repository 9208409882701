<div>
  <div class="">
    <h1>Environment {{environment.env_name}}</h1>
    <div>
      <pre> {{environment|json}}</pre>
    </div>
    <div>
      <button #simulate_error_btn (click)="simulate_error(simulate_error_btn)">
        Simulate Error</button
      ><br />
      <airport-select [(airport)]="airport"></airport-select><br />
      <pre>{{airport|json}}</pre>
      <boolean-field [value]="true"></boolean-field>
    </div>
    <div>
      <b>API Server</b>
      <pre> {{api_server|json}}</pre>
      <b>Socket Server</b>
      <pre> {{socket_server|json}}</pre>
      <mat-radio-group
        [(ngModel)]="api_server"
        (change)="other_api_server_selected($event)"
      >
        <mat-radio-button value="https://my.cleargrid.io">
          Production
        </mat-radio-button>
        <br />

        <mat-radio-button value="https://mybeta-api.cleargrid.7627.network">
          Beta
        </mat-radio-button>
        <br />

        <mat-radio-button value="http://127.0.0.1:8000">
          Local
        </mat-radio-button>
        <br />

        <mat-radio-button value="">
          Other
          <mat-form-field *ngIf="api_server_edit">
            <br />
            <input matInput [(ngModel)]="api_server" />
          </mat-form-field>
        </mat-radio-button>
        <br />
      </mat-radio-group>
    </div>
    <h1>Token</h1>
    <h2 *ngIf="jwt">Your JWT is:</h2>
    <pre *ngIf="jwt" class="jwt"><code>{{ jwt }}</code></pre>
    <pre *ngIf="jwt" class="jwt"><code>{{ decodedJwt | json }}</code></pre>
    <pre *ngIf="jwt" class="jwt"><code>{{ expired | json }}</code></pre>
    <pre *ngIf="jwt" class="jwt"><code>{{ expiry | date }}</code></pre>
    <button #remove_token_btn (click)="remove_token(remove_token_btn)">
      Remove Token
    </button>
  </div>
</div>
