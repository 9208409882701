<div fxFlex>
  <!--  <div *ngIf="!record_available" fxLayout="row">
    <mat-card fxFlex>
      <div fxFlex="column">
        <mat-card-title>
          <mat-icon>speaker_notes_off</mat-icon>
          Flight Leg For this Flight is Not Available
        </mat-card-title>
      </div>
    </mat-card>
  </div>-->

  <div fxLayout="column">
    <mat-card fxFlex fxLayout="column">
      <mat-card-title fxFlex> </mat-card-title>
      <mat-card-content fxFlex fxLayout="Column">
        <span
          fxFlex
          fxLayout="column"
          *ngFor="let flight_leg_takeoff of flight_leg_takeoff_list; index as i"
        >
          <mat-card fxFlex fxLayout="column">
            <mat-card-title fxFlex>
              <span> Leg {{ i + 1 }} </span>
            </mat-card-title>
            <mat-card-content fxLayout="row">
              <flight-leg-takeoff fxFlex [takeoff]="flight_leg_takeoff">
              </flight-leg-takeoff>
              <mat-card
                *ngIf="!flight_leg_takeoff.landing"
                fxFlex
                fxLayout="column"
              >
                <mat-card-title fxFlex>
                  <span> Landing </span>
                </mat-card-title>
                <mat-card-content fxFlex fxLayout="column">
                  <span fxFlex fxLayoutAlign="center">
                    <button
                      mat-button
                      #stop_flight_leg_btn
                      (click)="
                        stop_flight_leg(
                          stop_flight_leg_btn,
                          flight_leg_takeoff.id
                        )
                      "
                      matTooltip="End Flight Leg"
                    >
                      <mat-icon>flight_land</mat-icon>
                      Coming down is the hardest thing
                      <mat-icon>flight_land</mat-icon>
                    </button>
                  </span>
                </mat-card-content>
              </mat-card>
              <flight-leg-landing
                *ngIf="flight_leg_takeoff.landing"
                fxFlex
                [landing]="flight_leg_landing_list[i]"
              >
              </flight-leg-landing>
            </mat-card-content>
          </mat-card>
        </span>
        <mat-card
          *ngIf="last_leg_complete && flight_active"
          fxFlex
          fxLayout="column"
        >
          <mat-card-title fxFlex>
            <span> Start New Leg </span>
          </mat-card-title>
          <mat-card-content fxFlex fxLayout="column">
            <span fxFlex fxLayoutAlign="center">
              <button
                mat-button
                #start_flight_leg_btn
                (click)="start_flight_leg(start_flight_leg_btn)"
                matTooltip="Start Flight Leg"
              >
                <mat-icon>flight_takeoff</mat-icon>
                Kick the tires, Light the fires
                <mat-icon>flight_takeoff</mat-icon>
              </button>
            </span>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </mat-card>
  </div>
</div>
