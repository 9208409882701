import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Subscription } from "rxjs";
import * as moment from "moment-timezone";

import { ApiService } from "@app/api/api";

@Component({
  selector: "metergroup-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class CustomerJobDialogComponent implements OnInit, OnDestroy {
  _customer: any = null;
  job_name: string;
  due_date: Date;
  start_date: Date;
  meter_groups: any[] = [];
  selected_meter_group: any = null;
  meter_groups_loading: boolean = false;
  allow_all = true;
  data_logging = true;
  dialog_ok = false;
  error = false;
  error_message: string;
  $customer_groups: Subscription;

  get customer() {
    return this._customer;
  }

  set customer(val: any) {
    if (!val || !val.hasOwnProperty("id")) {
      return;
    }
    this._customer = val;
    this.data_logging = this._customer.options.data_logging.value;
    this.load_groups();
  }

  constructor(
    public dialogRef: MatDialogRef<CustomerJobDialogComponent>,
    private api: ApiService
  ) {}

  load_groups() {
    if (!this._customer || !this._customer.hasOwnProperty("id")) {
      return;
    }
    this.meter_groups_loading = true;
    this.$customer_groups = this.api.meter_group
      .customer_groups(this._customer.id)
      .subscribe((data: any) => {
        this.meter_groups = data.results;
        this.meter_groups.unshift({
          group_name: "Load Data",
          group_id: "",
          id: null,
        });
        this.selected_meter_group = this.meter_groups[0];
        this.meter_groups_loading = false;
      });
  }

  ngOnInit() {}

  submit(button: HTMLButtonElement) {
    this.dialog_ok = true;
    if (this.customer && this.job_name) {
      if (moment(this.start_date).isSameOrBefore(this.due_date)) {
        this.dialogRef.close({
          customer: this.customer.id,
          name: this.job_name,
          due_date: moment(this.due_date).format("YYYY-MM-DDTHH:mm:ss"),
          start_date: moment(this.start_date).format("YYYY-MM-DDTHH:mm:ss"),
          meter_group: this.selected_meter_group.id,
          data_logging: this.data_logging,
        });
      } else {
        this.error = true;
        this.error_message =
          "End date should be greater then or equal to start date.";
      }
    }
  }

  ngOnDestroy() {
    if (this.$customer_groups) {
      this.$customer_groups.unsubscribe();
    }
  }
}
