import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ApiService } from "@app/api/api";
import { FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";

import { GenericFilterComponent } from "./generic-filter.component";
import { MaterialModule } from "@app/material.module";
import { NoteModule } from "@app/note/module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NoteModule,
    FormsModule,
    FlexLayoutModule,
  ],
  exports: [GenericFilterComponent],
  providers: [ApiService],
  declarations: [GenericFilterComponent],
})
export class GenericFilterModule {}
