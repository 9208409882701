import { Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ApiService } from "@app/api/api";
import { MaterialModule } from "@app/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AuthGuard } from "./auth.guard";
import { FileList, FileListEntry } from "./filelist";
import { TagList, TagItem } from "./taglist";
import { BooleanComponent } from "./boolean.component";
import { MeterGroupList, MeterGroupListItem } from "./metergroup";
import { TooltipInjector } from "./map_tools";
import { WaypointList } from "./waypoints";
import { PointMap, DefaultInfoComponent } from "./pointmap";
import { NotificationService } from "../services/notification.service";
import { RouteMapComponent } from "./route-map";
import { AirportMapComponent } from "./airport-map";
import { MapToIterable, LengthPipe, KeysPipe, BytesPipe } from "./pipes";
import { DigitOnlyDirective, MaskDirective } from "./directives";
import {
  CustomerSelectAccessor,
  CustomerSelectComponent,
} from "./customer_select";
import {
  MeterTooltipComponent,
  MeterReadingTooltipComponent,
} from "./meter-toolip";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { AirportSelectComponent } from "./airport-select";

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ],
  exports: [
    FileList,
    TagList,
    MeterGroupList,
    WaypointList,
    PointMap,
    MapToIterable,
    LengthPipe,
    KeysPipe,
    RouteMapComponent,
    AirportMapComponent,
    AirportSelectComponent,
    CustomerSelectAccessor,
    CustomerSelectComponent,
    BooleanComponent,
    DigitOnlyDirective,
    MaskDirective,
  ],
  providers: [
    ApiService,
    AuthGuard,
    NotificationService,
    TooltipInjector,
    ErrorHandleService,
    Title,
  ],
  declarations: [
    BooleanComponent,
    FileList,
    FileListEntry,
    TagList,
    TagItem,
    MeterGroupList,
    MeterGroupListItem,
    WaypointList,
    PointMap,
    DefaultInfoComponent,
    MapToIterable,
    LengthPipe,
    KeysPipe,
    BytesPipe,
    RouteMapComponent,
    AirportMapComponent,
    AirportSelectComponent,
    CustomerSelectAccessor,
    CustomerSelectComponent,
    MeterTooltipComponent,
    MeterReadingTooltipComponent,
    DigitOnlyDirective,
    MaskDirective,
  ],
})
export class CG_CommonModule {}
