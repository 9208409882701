<mat-card *ngIf="form_errors.detail">
  <div class="detail-not-exist">Record Does Not Exist.</div>
</mat-card>
<mat-card *ngIf="!form_errors.detail">
  <mat-card-header>
    <div fxLayout="row">
      <mat-card-title *ngIf="flight" class="row">
        {{ flight.label }}
      </mat-card-title>
      <mat-card-title *ngIf="!flight" class="row">New flight</mat-card-title>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="column">
        <mat-form-field>
          <mat-select
            placeholder="Type"
            [(ngModel)]="flight.flight_type"
            (selectionChange)="type_selected($event)"
          >
            <mat-option *ngFor="let t of labels.type" [value]="t">
              {{ t }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="form_errors.flight_type">
          {{ form_errors.flight_type }}
        </mat-error>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-select
            placeholder="Aircraft"
            [(ngModel)]="flight.aircraft"
            (selectionChange)="aircraft_selected($event)"
          >
            <mat-option *ngFor="let a of aircraft" [value]="a.id">
              {{ a.registration }} {{ a.make }} {{ a.model }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="form_errors.aircraft">
          {{ form_errors.aircraft }}
        </mat-error>
        <mat-form-field *ngIf="flight.flight_type != 'Planning'">
          <mat-select
            placeholder="Pilot"
            [(ngModel)]="flight.pilot"
            [disabled]="!pilots || pilots.length < 1"
          >
            <mat-option [value]="null">No Pilot</mat-option>
            <mat-option *ngFor="let p of pilots" [value]="p.id">
              {{ p.first_name }} {{ p.last_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="form_errors.pilot">
          {{ form_errors.pilot }}
        </mat-error>
      </div>
      <div fxLayout="row">
        <span fxFlex="50%" fxLayout="column">
          <mat-card>
            <mat-card-title>
              <mat-icon>flight_takeoff</mat-icon>
              Launch
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column">
                <mat-form-field fxFlex>
                  <airport-select [(airport)]="flight.launch"></airport-select>
                </mat-form-field>
                <mat-error *ngIf="form_errors.launch">
                  {{ form_errors.launch }}
                </mat-error>
                <div fxFlex *ngIf="flight.mission_type != 'Quote'">
                  <span fxLayout="column">
                    <mat-form-field fxFlex>
                      <mat-datetimepicker-toggle
                        [for]="scheduledlaunch"
                        matSuffix
                      >
                      </mat-datetimepicker-toggle>
                      <mat-datetimepicker
                        #scheduledlaunch
                        type="datetime"
                        openOnFocus="true"
                        timeInterval="5"
                      >
                      </mat-datetimepicker>
                      <input
                        matInput
                        placeholder="Scheduled"
                        [(ngModel)]="flight.scheduled_launch"
                        [matDatetimepicker]="scheduledlaunch"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="form_errors.scheduled_launch">
                      {{ form_errors.scheduled_launch }}
                    </mat-error>
                  </span>
                  <span *ngIf="!scheduling" fxLayout="column">
                    <mat-form-field fxFlex>
                      <mat-datetimepicker-toggle [for]="actuallaunch" matSuffix>
                      </mat-datetimepicker-toggle>
                      <mat-datetimepicker
                        #actuallaunch
                        type="datetime"
                        openOnFocus="true"
                        timeInterval="1"
                      >
                      </mat-datetimepicker>
                      <input
                        matInput
                        placeholder="Actual"
                        [(ngModel)]="flight.actual_launch"
                        [matDatetimepicker]="actuallaunch"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="form_errors.actual_launch">
                      {{ form_errors.actual_launch }}
                    </mat-error>
                  </span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </span>
        <span fxFlex="50%" fxLayout="column">
          <mat-card>
            <mat-card-title>
              <mat-icon>flight_landing</mat-icon>
              Recovery
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column">
                <mat-form-field fxFlex>
                  <airport-select [(airport)]="flight.recover">
                  </airport-select>
                </mat-form-field>
                <mat-error *ngIf="form_errors.recover">
                  {{ form_errors.recover }}
                </mat-error>
                <div fxFlex *ngIf="flight.mission_type != 'Quote'">
                  <span fxLayout="column">
                    <mat-form-field fxFlex>
                      <mat-datetimepicker-toggle
                        [for]="scheduledrecovery"
                        matSuffix
                      >
                      </mat-datetimepicker-toggle>
                      <mat-datetimepicker
                        #scheduledrecovery
                        type="datetime"
                        openOnFocus="true"
                        timeInterval="5"
                      >
                      </mat-datetimepicker>
                      <input
                        matInput
                        placeholder="Scheduled"
                        [(ngModel)]="flight.scheduled_recovery"
                        [matDatetimepicker]="scheduledrecovery"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="form_errors.scheduled_recovery">
                      {{ form_errors.scheduled_recovery }}
                    </mat-error>
                  </span>
                  <span fxLayout="column" *ngIf="!scheduling">
                    <mat-form-field fxFlex>
                      <mat-datetimepicker-toggle
                        [for]="actualrecovery"
                        matSuffix
                      >
                      </mat-datetimepicker-toggle>
                      <mat-datetimepicker
                        #actualrecovery
                        type="datetime"
                        openOnFocus="true"
                        timeInterval="1"
                      >
                      </mat-datetimepicker>
                      <input
                        matInput
                        placeholder="Actual"
                        [(ngModel)]="flight.actual_recovery"
                        [matDatetimepicker]="actualrecovery"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="form_errors.actual_recovery">
                      {{ form_errors.actual_recovery }}
                    </mat-error>
                  </span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </span>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="actions">
    <span fxLayout="row">
      <button mat-raised-button #save_btn (click)="save(save_btn)">Save</button>
    </span>
  </mat-card-actions>
</mat-card>
