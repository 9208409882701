<mat-card>
  <mat-card-content>
    <mat-accordion>
      <map-mission
        *ngFor="let m of missions | async"
        [mission]="m"
      ></map-mission>
    </mat-accordion>
  </mat-card-content>
  <mat-card-actions>
    <div fxFlex fxLayout="row">
      <span fxFlex fxLayoutAlign="start">
        <button
          mat-icon-button
          (click)="clear_map()"
          matTooltip="Clear Missions"
        >
          <mat-icon>layers_clear</mat-icon>
        </button>
      </span>
      <span fxFlex fxLayoutAlign="center">
        <button
          mat-icon-button
          (click)="zoom_to_mission()"
          matTooltip="View all Missions"
        >
          <mat-icon>zoom_out_map</mat-icon>
        </button>
      </span>
      <span fxFlex fxLayoutAlign="end">
        <button mat-icon-button (click)="openDialog()" matTooltip="Add Mission">
          <mat-icon>add</mat-icon>
        </button>
      </span>
    </div>
  </mat-card-actions>
</mat-card>
