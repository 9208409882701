<mat-card>
  <div fxLayout="column">
    <mat-card-title fxLayout="row">
      <span fxFlex class="header_label">Customer Jobs</span>
      <span fxFlex fxLayoutAlign="end">
        <button
          *ngIf="mission"
          mat-icon-button
          matTooltip="Add Customer Job"
          #add_customer_job_btn
          (click)="add_customer_job(add_customer_job_btn)"
        >
          <mat-icon>add</mat-icon>
        </button>
      </span>
    </mat-card-title>
    <mat-card-content fxLayout="row">
      <mat-table
        [dataSource]="jobs"
        fxFlex
        style="margin-top: 1%"
        [class.isMobile]
      >
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="mobile-label">Id:</span>
            {{ row.id }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="customer_name">
          <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
          <mat-cell *matCellDef="let row" style="word-break: break-all">
            <span class="mobile-label">Customer Name:</span>
            {{ row.customer_name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Job Name </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="mobile-label">Job Name:</span>
            {{ row.name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="start_date">
          <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="mobile-label">Start Date:</span>
            {{ row.start_date | date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="commit_date">
          <mat-header-cell *matHeaderCellDef> Commit Date </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="mobile-label">Commit Date:</span>
            {{ row.commit_date | date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="due_date">
          <mat-header-cell *matHeaderCellDef> Due Date </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="mobile-label">Due Date:</span>
            {{ row.due_date | date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="mobile-label">Status:</span>
            <mat-icon *ngIf="row.commit_date" style="color: green"
              >check_circle</mat-icon
            >
            <mat-icon *ngIf="!row.commit_date" style="color: red"
              >cancel</mat-icon
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="anchor">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a [href]="'#/customer_job/' + row.id"></a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          [routerLink]="['/', 'customer_job', row.id]"
        ></mat-row>
      </mat-table>
    </mat-card-content>
  </div>
</mat-card>
