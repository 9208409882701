import { Component, OnDestroy, OnInit, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

export interface optionConfig {
  input_type?: string;
  input_text?: string;
  input_value?: string;
}

@Component({
  selector: "generictext-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class GenericTextDialogComponent implements optionConfig {
  input_type: string = "Object";
  input_text: string = "Value";
  input_value: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: optionConfig,
    public dialogRef: MatDialogRef<GenericTextDialogComponent>
  ) {
    Object.assign(this, data);
  }

  submit(button: HTMLButtonElement) {
    this.dialogRef.close(this.input_value);
  }
}
