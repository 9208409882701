import { FlightDetailComponent } from './detail/detail.component';
import { FlightListComponent } from './list/list.component';
import { FlightFormComponent } from './form/form.component';
import { FlightReportComponent } from './report/component';
import { FlightComponent } from './component';
import { AuthGuard } from '@app/common/auth.guard';

export const FlightRoute = {
  path: 'flight',
  component: FlightComponent,
  canActivate: [AuthGuard],
  children: [
    {path: '', component: FlightListComponent},
    {path: 'new', component: FlightFormComponent},
    {path: ':id', component: FlightDetailComponent},
    {path: ':id/edit', component: FlightFormComponent},
    {path: ':id/report', component: FlightReportComponent},
  ]
};
