import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { Observable } from "rxjs";
import { MeterGroup } from "../types";

@Injectable()
export class MeterGroupService extends BaseApiService<MeterGroup> {
  component: string = "metergroup";

  customer_groups(customer_id: number) {
    return this.list({ customer: customer_id });
  }

  mission_groups(mission_id: number) {
    return this.list({ mission: mission_id });
  }

  meter_count(mission_id: number) {
    return this.get_with_retry(this.function_url("meter_count", mission_id), {
      headers: contentHeaders(),
    });
  }
}
