<mat-toolbar color="primary" fxLayout="row">
  <span fxFlex>Add Customer Job</span>
  <span fxFlex fxLayoutAlign="end">
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      matTooltip="Close Dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </span>
</mat-toolbar>

<mat-card>
  <div fxLayout="column">
    <mat-card-content>
      <div fxLayout="column">
        <div fxLayout="row">
          <customer-select
            fxFlex
            [customerJobDialog]="'dialog'"
            [(ngModel)]="customer"
          ></customer-select>
          <mat-error fxFlex *ngIf="!customer?.id && dialog_ok">
            Select a Customer For Job</mat-error
          >
        </div>

        <span fxLayout="row">
          <mat-form-field fxFlex>
            <input
              matInput
              id="job_name"
              name="job_name"
              placeholder="Job Name"
              [(ngModel)]="job_name"
              required
            />
          </mat-form-field>
          <mat-error fxFlex *ngIf="!job_name && dialog_ok"
            >Job Name is required</mat-error
          >
        </span>

        <div fxLayout="row">
          <mat-form-field fxFlex>
            <input
              matInput
              [matDatepicker]="startpicker"
              placeholder="Start Date"
              [(ngModel)]="start_date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startpicker"
            ></mat-datepicker-toggle>
            <mat-datepicker touchUi #startpicker></mat-datepicker>
            <mat-error>Start Date is required</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field fxFlex>
            <input
              matInput
              [matDatepicker]="duepicker"
              placeholder="Due Date"
              [(ngModel)]="due_date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="duepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker touchUi #duepicker></mat-datepicker>
            <mat-error>Due Date is Required</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-checkbox fxFlex [(ngModel)]="data_logging"
            >Data Logging</mat-checkbox
          >
        </div>

        <div fxLayout="row">
          <mat-form-field fxFlex>
            <mat-select
              class="row"
              placeholder="Meter Group"
              [(ngModel)]="selected_meter_group"
              name="meter_group"
            >
              <mat-option *ngFor="let mg of meter_groups" [value]="mg">
                {{ mg.group_name }}
                <span *ngIf="mg.group_id"> [{{ mg.group_id }}]</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-spinner
            [mode]="Indeterminate"
            *ngIf="meter_groups_loading"
          ></mat-spinner>
        </div>

        <div fxLayout="row" *ngIf="error">
          <mat-error>{{ error_message }}</mat-error>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row">
        <span fxFlex>
          <button type="button" mat-button (click)="dialogRef.close()">
            Cancel
          </button>
        </span>
        <span fxFlex fxLayoutAlign="end">
          <button
            type="button"
            mat-raised-button
            #submit_btn
            (click)="submit(submit_btn)"
          >
            OK
          </button>
        </span>
      </div>
    </mat-card-actions>
  </div>
</mat-card>
