<mat-toolbar color="primary" fxLayout="row">
  <span fxFlex>Select {{ object_type }}</span>
  <span fxFlex fxLayoutAlign="end">
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      matTooltip="Close Dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </span>
</mat-toolbar>

<mat-card>
  <div fxLayout="column">
    <mat-card-content>
      <div fxLayout="column">
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <mat-select
              class="row"
              [placeholder]="object_type"
              [(ngModel)]="selected_object"
              name="aircraft"
            >
              <mat-option *ngFor="let obj of object_list" [value]="obj">
                {{ list_text(obj) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" *ngIf="error_message">
          <mat-error>{{ error_message }}</mat-error>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row">
        <span fxFlex>
          <button type="button" mat-button (click)="dialogRef.close()">
            Cancel
          </button>
        </span>
        <span fxFlex fxLayoutAlign="end">
          <button
            type="button"
            mat-raised-button
            #submit_btn
            (click)="submit(submit_btn)"
          >
            OK
          </button>
        </span>
      </div>
    </mat-card-actions>
  </div>
</mat-card>
