import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Subscription } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { ApiService } from "@app/api/api";
import { File } from "@app/api/types";

@Component({
  selector: "file-upload-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class ImportFileDialogComponent implements OnInit, OnDestroy {
  import_data = {
    fields: [],
    default_group_name: "",
    default_group_id: "",
    headers: false,
  };
  file_head = null;
  file_detail: File = { filename: "", filesize: "", created: "" };
  file_id = 0;
  default_fields = ["serial", "", "", "", "", "", "latitude", "longitude"];
  field_headers = {
    group_id: "Group ID",
    group_name: "Group Name",
    serial: "Serial Number",
    latitude: "Latitude",
    longitude: "Longitude",
  };
  $file_subs: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ImportFileDialogComponent>,
    private api: ApiService
  ) {}

  ngOnInit() {}

  set file(value: number) {
    this.file_id = value;
    this.import_data.fields = [];
    this.$file_subs = this.api.file
      .detail(this.file_id)
      .pipe(
        mergeMap((res) => {
          this.file_detail = res;
          return res.filename.endsWith("csv")
            ? this.api.file.head(this.file_id)
            : this.api.file.null();
        })
      )
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.file_head = res;
        for (let i = 0; i < res[0].length; i++) {
          this.import_data.fields.push({ value: this.default_fields[i] || "" });
        }
      });
  }

  submit() {
    this.dialogRef.close(null);
  }

  import(button: HTMLButtonElement) {
    const fields = [];
    let ndx = 0;
    for (let i of this.import_data.fields) {
      fields.push(i.value || "row_" + ndx);
      ndx = ndx + 1;
    }
    this.import_data.fields = fields;
    this.dialogRef.close(this.import_data);
  }

  ngOnDestroy() {
    if (this.$file_subs) {
      this.$file_subs.unsubscribe();
    }
  }
}
