<mat-card fxFlex fxLayout="column">
  <mat-card-title fxFlex>
    <span> Takeoff </span>
    <span>
      <a mat-icon-button class="anchor">
        <mat-icon
          matTooltip="Edit Flight Takeoff"
          #edit_leg_btn
          (click)="edit_leg(edit_leg_btn)"
        >
          edit
        </mat-icon>
      </a>
    </span>
  </mat-card-title>
  <mat-card-content fxFlex fxLayout="column">
    <div fxLayout="row">
      <span fxFlex>Airport</span>
      <span fxFlex fxLayoutAlign="end">
        {{ takeoff.airport_name }}
      </span>
    </div>
    <div fxLayout="row">
      <span fxFlex>Engine Start Time</span>
      <span fxFlex fxLayoutAlign="end">
        {{ takeoff.engine_start_time | momentPipe : "MMM Do YYYY, h:mm:ss a" }}
      </span>
    </div>
    <div fxLayout="row">
      <span fxFlex>Wheel up Time</span>
      <span fxFlex fxLayoutAlign="end">
        {{ takeoff.wheel_up_time | momentPipe : "MMM Do YYYY, h:mm:ss a" }}
      </span>
    </div>
    <div fxLayout="row">
      <span fxFlex>Amount of Fuel</span>
      <span fxFlex fxLayoutAlign="end">
        {{ takeoff.amount_of_fuel }}
      </span>
    </div>
    <div *ngIf="takeoff.note">
      <p>&nbsp;</p>
      {{ takeoff.note }}
    </div>
  </mat-card-content>
</mat-card>
