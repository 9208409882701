import { ActionTypes } from './actions';
import { Action } from '@ngrx/store';

export default interface ActionWithPayload<T> extends Action {
    type: string;
    missions: any[];
}

export interface Missions {
    missions: any;
}

export const initialState: Missions = {
    missions: []
};

export function mapSidebarMissionsReducer(state = initialState, action: Action): Missions {
    switch (action.type) {
        case ActionTypes.GetMissions:
            return {
                ...state,
                missions: (action as ActionWithPayload<Missions>).missions
            };
        case ActionTypes.SpliceMissions:
            return {
                ...state,
                missions: (action as ActionWithPayload<Missions>).missions
            };
        default:
            return state;
    }
}
