<mat-card *ngIf="form_error.detail">
  <div class="detail-not-exist">Record Does Not Exist.</div>
</mat-card>
<div fxLayout="row" *ngIf="!form_error.detail">
  <span fxFlex fxLayoutAlign="center">
    <mat-card class="client-detail">
      <div fxLayout="column">
        <mat-card-header>
          <mat-card-title>
            <h2>{{ title }}</h2>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutGap.xs="0px"
            fxLayoutGap="30px"
          >
            <div fxLayout="column">
              <mat-error *ngIf="form_error.non_field_error">{{
                form_error.non_field_error
              }}</mat-error>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Customer Name"
                  [(ngModel)]="customer.name"
                  maxlength="255"
                />
              </mat-form-field>
              <mat-error *ngIf="form_error.name">{{
                form_error.name
              }}</mat-error>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Customer Code"
                  [(ngModel)]="customer.code"
                  maxlength="4"
                />
              </mat-form-field>
              <mat-error *ngIf="form_error.code">{{
                form_error.code
              }}</mat-error>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Address"
                  [(ngModel)]="customer.address1"
                  maxlength="100"
                />
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Extra address"
                  [(ngModel)]="customer.address2"
                  maxlength="100"
                />
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  placeholder="City"
                  [(ngModel)]="customer.city"
                  maxlength="64"
                />
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Province"
                  [(ngModel)]="customer.province"
                  maxlength="32"
                />
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Country"
                  [(ngModel)]="customer.country"
                />
              </mat-form-field>
              <mat-error *ngIf="form_error.country">{{
                form_error.country
              }}</mat-error>
            </div>
            <div fxLayout="column">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Postal Code"
                  [(ngModel)]="customer.postal_code"
                />
              </mat-form-field>
              <mat-error *ngIf="form_error.postal_code">
                {{ form_error.postal_code }}
              </mat-error>
              <mat-form-field>
                <input
                  matInput
                  mask="(000)-000-0000"
                  [showMaskTyped]="true"
                  placeholder="Phone"
                  [(ngModel)]="customer.phone"
                />
                <mat-hint>Phone Format:(+xxxxxxxxx)</mat-hint>
              </mat-form-field>
              <mat-error *ngIf="form_error.phone">{{
                form_error.phone
              }}</mat-error>
              <mat-form-field>
                <input
                  matInput
                  mask="(000)-000-0000"
                  [showMaskTyped]="true"
                  placeholder="Fax"
                  [(ngModel)]="customer.fax"
                />
                <mat-hint>Fax Format:(+xxxxxxxxx)</mat-hint>
              </mat-form-field>
              <mat-error *ngIf="form_error.fax">{{ form_error.fax }}</mat-error>
              <mat-form-field>
                <input
                  matInput
                  placeholder="E-Mail"
                  [(ngModel)]="customer.email"
                  type="email"
                />
              </mat-form-field>
              <mat-error *ngIf="form_error.email && form_error.email[0]">
                {{ form_error?.email[0] }}
              </mat-error>
              <mat-error
                class="second-error"
                *ngIf="form_error.email && form_error.email[1]"
              >
                {{ form_error?.email[1] }}
              </mat-error>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Website"
                  [(ngModel)]="customer.website"
                  type="url"
                />
              </mat-form-field>
              <mat-error *ngIf="form_error.website && form_error.website[0]">
                {{ form_error?.website[0] }}
              </mat-error>
              <mat-error
                class="second-error"
                *ngIf="form_error.website && form_error.website[1]"
              >
                {{ form_error?.website[1] }}
              </mat-error>
              <span fxLayout="row" fxLayoutAlign="end">
                <span>
                  <button mat-raised-button #save_btn (click)="save(save_btn)">
                    Save
                  </button>
                </span>
              </span>
            </div>
          </div>
        </mat-card-content>
      </div>
    </mat-card>
  </span>
</div>
