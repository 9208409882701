import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import * as L from "leaflet";
import { timer, of, Subscription, Observable } from "rxjs";
import { mergeMap, filter, switchMap, catchError } from "rxjs/operators";
import * as moment from "moment";
import { ApiService } from "@app/api/api";
import { LayerService } from "@app/services/layer.service";
import { ErrorService } from "./error.service";
import { HighchartsService } from "@app/services/highcharts.service";
import { DialogService } from "@app/services/dialog.service";
import { RealTimeService } from "@app/services/realtime.service";
import { ErrorHandleService } from "@app/services/errorHandle.service";

import { SideNavRerenderService } from "@app/common/side_nav_rerender.service";
import { MapStoreService } from "@app/services/store/map/sidebar/mapstore.service";
import {
  TrackRequest,
  AircraftState,
} from "@app/store/map/sidebar/map_reducer";
import { ACSService } from "@app/services/acs.service";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { Aircraft, AircraftStatus, Flight } from "@app/api/types";

export const MY_MOMENT_FORMATS = {
  parseInput: "YYYY-MM-DD HH:mm",
  fullPickerInput: "YYYY-MM-DD HH:mm",
  datePickerInput: "YYYY-MM-DD",
  monthYearLabel: "MM YYYY",
  dateA11yLabel: "L",
  monthYearA11yLabel: "MM YYYY",
  timePickerInput: "HH:mm",
};

@Component({
  selector: "aircraft-entry",
  templateUrl: "./aircraft_entry.html",
  styleUrls: ["./aircraft.css"],
  providers: [],
})
export class AircraftEntryComponent implements OnInit, OnDestroy {
  @Input("aircraft")
  public aircraft: Aircraft;
  @Input("display_profile_check") display_profile_check: {};
  @ViewChild("aircraft_track", { static: true })
  aircraft_track_checkBox: ElementRef;
  aircraftState = {};
  aircraft_checked: boolean;
  track_request: TrackRequest = null;

  _profile_checked: boolean;
  set profile_checked(value: boolean) {
    this._profile_checked = value;
    if (this.aircraft.id) {
      this.display_profile_check[this.aircraft.id] = value;
      const values = Object.values(this.display_profile_check);
      if (!values.filter(Boolean).length) {
        this.chartService.chartControlDataSend(false);
      }
    }
  }

  get profile_checked(): boolean {
    return this._profile_checked;
  }

  $track: Subscription;
  $status: Subscription;
  $statistic: Subscription;
  $store: Subscription;
  track_color = "blue";
  layer = null;
  track_points = null;
  chart_points = [];
  request_fail = false;
  loading_track = false;
  spinnerColor = "primary";
  spinnerMode = "indeterminate";
  cardinal_directions = [
    "N",
    "NNE",
    "NE",
    "ENE",
    "E",
    "ESE",
    "SE",
    "SSE",
    "S",
    "SSW",
    "SW",
    "WSW",
    "W",
    "WNW",
    "NW",
    "NNW",
  ];
  currentDate: moment.Moment = null;
  currentCalenderDate = moment().utc().format("YYYY-MM-DD");
  hour: number = null;
  hourSpan: number = 10;
  track_retry: boolean = true;
  static_retry: boolean = true;
  track_button: boolean = true;
  trackRefreshInterval: number = 60;
  statisticRefreshInterval: number = 5;
  aircraft_status = {
    latitude: null,
    longitude: null,
    altitude: null,
    gps_state: null,
    age: null,
    direction: null,
    ground_speed: null,
    heading: null,
    source: null,
  };
  empty_obs = new Observable();
  request_time: moment.Moment;
  basis: "before" | "after" = "before";
  format: string = "HH00 DD MMM";

  get status_text(): string {
    let text: string;
    if (this.aircraft_status.gps_state === null) {
      return "N/A";
    }
    text = "GPS " + this.aircraft_status.gps_state;
    if (this.aircraft_status.source !== null) {
      text = text + " via " + this.aircraft_status.source;
    }
    return text;
  }

  get display_time() {
    if (!this.request_time) return this.request_time;
    let t = this.request_time.clone();
    if (this.basis === "after") {
      t.subtract(this.hourSpan, "hours");
    }
    return t.format(this.format);
  }

  constructor(
    private api: ApiService,
    private layer_service: LayerService,
    private errorService: ErrorService,
    private chartService: HighchartsService,
    private rts: RealTimeService,
    private sideNavRerenderService: SideNavRerenderService,
    private errorHandleService: ErrorHandleService,
    private changeDetector: ChangeDetectorRef,
    private storeService: MapStoreService,
    private dialogService: DialogService,
    private acs: ACSService
  ) {
    this.currentDate = moment().utc();
  }

  set_time() {
    this.request_time = moment();
  }

  use_now() {
    this.request_time = undefined;
    this.basis = "before";
  }

  ngOnInit() {
    this.$status = this.acs
      .aircraft_status(this.aircraft.id)
      .subscribe((update: AircraftStatus) => {
        this.update_status(update);
      });

    this.$store = this.storeService
      .get_aircraft_details(this.aircraft.id)
      .pipe(filter((state) => state !== undefined))
      .subscribe((state: AircraftState) => {
        this.update_state(state);
      });
  }

  update_state(state: AircraftState) {
    let refresh = false;

    if (state.selected && !this.aircraft_checked) {
      this.update_layer();
      this.display_track();
    } else if (!state.selected && this.aircraft_checked) {
      this.hide_status();
    }

    if (this.track_request != state.request) {
      console.log("Updating track request");
      this.track_request = state.request;
      this.request_time = moment().utc();
      this.hourSpan = 10;
      if (state.request) {
        if (state.request.base) {
          this.request_time = moment(state.request.base, moment.ISO_8601);
        }
        this.hourSpan = state.request.span ? state.request.span : 10;
        this.basis = state.request.direction;
        refresh = true;
      }
      this.hour = this.request_time.get("hour");
    }

    let api_date: any = this.request_time ? this.request_time : moment();
    api_date = api_date.format("YYYY-MM-DD");

    if (
      (state.profile && !this.profile_checked) ||
      (this.profile_checked && refresh)
    ) {
      console.log("Displaying profile");
      this.chartService.chartControlDataSend(true);

      this.track_statistics(this.hour, api_date, this.hourSpan);
      this.sideNavRerenderService.rerender_side_nav();
    } else if (!state.profile && this.profile_checked) {
      this.chartService.remove_aircraft(this.aircraft.id);
      this.$statistic.unsubscribe();
    }

    if (this.track_color != state.track_colour) {
      this.track_color = state.track_colour;
      this.update_layer();
    }
    if (refresh) {
      this.display_track(this.hour, api_date, this.hourSpan);
    }
    this.aircraft_checked = state.selected;
    this.profile_checked = state.profile;
    this.changeDetector.detectChanges();
  }

  check_display_track_retry(hour, date, span) {
    if (!this.track_retry) {
      return this.empty_obs;
    }
    this.track_retry = false;
    return this.api.aircraft_status.track({
      aircraft: this.aircraft.id,
      hour: hour,
      date: date,
      span: span,
    });
  }

  display_track(hour = null, date = null, span = 10) {
    if (this.trackRefreshInterval >= 1 && this.trackRefreshInterval <= 300) {
      if (this.$track) this.$track.unsubscribe();
      this.$track = timer(5, this.trackRefreshInterval * 1000)
        .pipe(
          mergeMap((res) => this.check_display_track_retry(hour, date, span))
        )
        .subscribe({
          next: (res: any) => {
            this.errorService.clearError();
            this.track_retry = true;
            this.loading_track = res.hasOwnProperty("pending");
            this.track_points = [];
            for (let p of res.track) {
              this.track_points.push([p[1], p[0]]);
            }
            this.update_layer();
          },
          error: (error) => {
            this.errorService.setError(error);
            this.request_fail = true;
          },
        });
    } else {
      this.errorHandleService.sendError(
        "MilliSecond are not allow in Track Refresh Interval Field."
      );
    }
  }

  build_icon() {
    const transform =
      "transform:rotate(" + this.aircraft_status.heading + "deg);";
    let style = "";
    for (const prefix of ["-o-", "-ms-", "-moz-", "-webkit-", ""]) {
      style = style + prefix + transform;
    }
    return L.divIcon({
      html:
        '<img src="assets/map/icons/airplane.png" height=32 style="' +
        style +
        '"></img><div class="map-icon-label">' +
        this.aircraft.registration +
        "</div>",
      iconAnchor: [16, 16],
      className: "dummy-class",
    });
  }

  build_tooltip() {
    return (
      "" +
      this.aircraft_status.altitude +
      " ft ASL, heading " +
      Number(this.aircraft_status.heading).toFixed() +
      "(" +
      this.aircraft_status.direction +
      ") at " +
      Number(this.aircraft_status.ground_speed).toFixed() +
      " kts"
    );
  }

  update_layer() {
    const layer = L.layerGroup([]);
    if (this.track_points) {
      // TODO: uniquely style each track
      layer.addLayer(
        L.polyline(this.track_points, { color: this.track_color })
      );
    }

    if (this.aircraft_status.longitude && this.aircraft_status.latitude) {
      // TODO: style marker based on gps_state
      layer.addLayer(
        L.marker(
          [this.aircraft_status.latitude, this.aircraft_status.longitude],
          {
            icon: this.build_icon(),
          }
        ).bindTooltip(this.build_tooltip(), {
          direction: "bottom",
        })
      );
    }
    if (this.layer) {
      this.layer_service.replace_track(this.layer, layer);
    } else {
      this.layer_service.add_track(layer);
    }
    this.layer = layer;

    if (this.layer && this.layer.getLayers()[0]) {
      const track_layer = this.layer.getLayers()[0];
      if (
        track_layer.hasOwnProperty("_bounds") &&
        track_layer.getBounds().isValid()
      ) {
        this.track_button = false;
      }
    }
  }

  update_status(status: AircraftStatus) {
    this.aircraft_status.gps_state = status.properties.gps_state;
    this.aircraft_status.source = status.properties.source;
    this.aircraft_status.altitude = status.properties.altitude;
    this.aircraft_status.longitude = status.geometry.coordinates[0];
    this.aircraft_status.latitude = status.geometry.coordinates[1];
    this.aircraft_status.age = moment(status.properties.timestamp).fromNow();
    this.aircraft_status.heading = status.properties.heading;
    if (status.properties.heading !== null) {
      this.aircraft_status.direction =
        this.cardinal_directions[
          Math.floor((status.properties.heading + 11.25) / 22.5) % 16
        ];
    } else {
      this.aircraft_status.direction = "?";
    }
    this.aircraft_status.ground_speed = status.properties.ground_speed;
    this.update_layer();
  }

  hide_status() {
    this.loading_track = false;
    this.errorService.clearError();
    if (this.layer) {
      this.track_button = true;
      this.layer_service.remove_track(this.layer);
      if (this.$track) {
        this.$track.unsubscribe();
      }
      this.track_points = null;
    }
    this.update_layer();
  }

  zoom(event) {
    event.stopPropagation();
    if (this.layer) {
      this.layer_service.zoom_track(this.layer);
    }
  }

  material_expansion_prevention(event) {
    event.stopPropagation();
  }

  set_colour(value: number | string) {
    this.storeService.set_colour(this.aircraft.id, value);
  }

  select_aircraft(event: MatCheckboxChange) {
    const is_checked = typeof event === "object" ? event.checked : event;
    if (is_checked) {
      this.storeService.select_aircraft(this.aircraft.id);
    } else {
      this.storeService.unselect_aircraft(this.aircraft.id);
    }
  }

  aircraft_profile(event: MatCheckboxChange) {
    const is_checked = typeof event === "object" ? event.checked : event;
    if (is_checked) {
      this.storeService.select_profile(this.aircraft.id);
    } else {
      this.storeService.unselect_profile(this.aircraft.id);
    }
  }

  check_track_statistics_retry(hour: number, date: string, span: number) {
    if (!this.static_retry) {
      return this.empty_obs;
    }
    this.static_retry = false;
    return this.api.aircraft_status.statistic({
      aircraft: this.aircraft.id,
      hour: hour,
      date: date,
      span: span,
    });
  }

  track_statistics(
    hour: number = null,
    date: string = null,
    span: number = 10
  ) {
    this.$statistic = timer(5, this.statisticRefreshInterval * 1000)
      .pipe(
        mergeMap((res) => this.check_track_statistics_retry(hour, date, span))
      )
      .subscribe({
        next: (res: any) => {
          this.errorService.clearError();
          this.static_retry = true;
          this.loading_track = res.hasOwnProperty("pending");
          this.chart_points = [];
          for (let p of res.statistic) {
            this.chart_points.push(p);
          }
          this.update_layer();
          const title = this.aircraft.registration;
          if (this.chart_points) {
            this.chartService.ini_chart(
              title,
              this.chart_points,
              this.aircraft.id
            );
          }
        },
        error: (error) => {
          this.errorService.setError(error);
          this.request_fail = true;
        },
      });
  }

  aircraft_last_reported_position(event) {
    event.stopPropagation();
    const latLngs = [
      [this.aircraft_status.latitude, this.aircraft_status.longitude],
    ];
    if (this.aircraft_status.latitude && this.aircraft_status.longitude) {
      this.layer_service.zoom_position(latLngs);
    }
  }

  zoom_button(event) {
    event.stopPropagation();
  }

  onChangeDate(value: any) {
    let t = moment(value.value);
    if (this.basis === "after") {
      t.add(this.hourSpan, "hours");
    }
    this.request_time = t;
  }

  requiredTrack() {
    this.storeService.set_track(this.aircraft.id, {
      span: this.hourSpan,
      direction: this.basis,
      base: this.request_time ? this.request_time.toISOString() : null,
    });
  }

  assign_payload(button: HTMLButtonElement) {
    if (!this.aircraft.id) {
      return;
    }
    button.disabled = true;
    this.dialogService
      .select_payload()
      .pipe(
        switchMap((payload: any) => {
          if (!payload) {
            return this.api.payload.null();
          }
          return this.api.payload.assign(payload.ident, this.aircraft.id);
        })
      )
      .subscribe({
        next: (data: any) => {},
        complete: () => {
          button.disabled = false;
        },
      });
  }

  ngOnDestroy() {
    try {
      if (this.$statistic) {
        this.$statistic.unsubscribe();
      }
      if (this.$status) {
        this.$status.unsubscribe();
      }
      if (this.$track) {
        this.$track.unsubscribe();
      }
      if (this.$store) {
        this.$store.unsubscribe();
      }
    } catch (e) {}
  }

  update_flightleg(button: HTMLButtonElement) {
    /*
    this is an extremely complicated sequence and the code almost needs to be
    read from bottom up.

    We start by refreshing the aircraft state to ensure that it has the
    "current" current flight (outside caching effects, this likely requires
    a cache breaker of some description), if there is no current flight
    information for this aircraft we display the `add_flight` dialog to
    allow the user to create or select an existing flight.

    Once we have the flight we retrieve the last takeoff record from the
    flight, if there are no takeoff records, or the last takeoff record has
    an associated landing record we start a new flight leg. if the last take
    off record has no landing record we close out that leg by displaying the
    `stop_flightleg` dialog
    */
    let flight: any;

    let get_last_takeoff: (Flight) => Observable<any> = (flight) => {
      if (!flight) {
        of(null);
      }
      return this.api.flight.get_last_takeoff(flight.id).pipe(
        switchMap((takeoff: any) => {
          if (!takeoff || takeoff.landing) {
            return this.dialogService.start_flight_leg(flight.id);
          } else {
            return this.dialogService.stop_flight_leg(flight.id);
          }
        })
      );
    };

    let get_flight: (Aircraft) => Observable<Flight> = (aircraft) => {
      //check that we have a single active flight
      let flight_count = aircraft.current_flights.length;

      if (flight_count == 0) {
        return this.dialogService.add_flight({
          aircraft: this.aircraft.id,
          mission_type: "Operational",
        });
      } else if (flight_count == 1) {
        return of(this.aircraft.current_flights[0]);
      } else {
        const msg = "Aircraft has " + flight_count + " active flights.";
        throw new Error(msg);
      }
    };

    button.disabled = true;
    this.api.aircraft
      .detail(this.aircraft.id)
      .pipe(
        switchMap((aircraft: Aircraft) => {
          this.aircraft = aircraft;
          return get_flight(aircraft);
        }),
        switchMap((flight: Flight) => {
          return get_last_takeoff(flight);
        })
      )
      .subscribe({
        error: (err) => console.log(err),
        complete: () => {
          button.disabled = false;
        },
      });
  }
}
