<div fxLayout="column">
  <mat-list>
    <div *ngFor="let contact of contacts">
      <mat-list-item *ngIf="contact">
        <div fxFlex fxLayout="row" *ngIf="contact">
          <div fxFlex>
            {{ contact.first_name }} {{ contact.last_name }}
            <button
              mat-icon-button
              matTooltip="Edit"
              #edit_contact_btn
              (click)="edit_contact(edit_contact_btn, contact)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <button
              mat-icon-button
              matTooltip="Call"
              #call_btn
              (click)="call(call_btn, contact)"
            >
              <mat-icon>call</mat-icon>
            </button>
            <button
              *ngIf="contact.mobile"
              mat-icon-button
              matTooltip="Message"
              #message_btn
              (click)="message(message_btn, contact)"
            >
              <mat-icon>message</mat-icon>
            </button>
            <button
              *ngIf="contact.primary_email"
              mat-icon-button
              matTooltip="Email"
              #email_btn
              (click)="email(email_btn, contact)"
            >
              <mat-icon>email</mat-icon>
            </button>
            <button
              *ngIf="contact.website"
              mat-icon-button
              matTooltip="Website"
              #browse_btn
              (click)="browse(browse_btn, contact)"
            >
              <mat-icon>public</mat-icon>
            </button>
            <button
              mat-icon-button
              matTooltip="Delete Contact"
              #delete_contact_btn
              (click)="delete_contact(delete_contact_btn, contact)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </mat-list-item>
    </div>
  </mat-list>
  <div fxFlex fxLayout="row">
    <span fxFlex fxLayoutAlign="end">
      <button
        mat-icon-button
        matTooltip="Add Contact"
        #add_contact_btn
        (click)="add_contact(add_contact_btn)"
      >
        <mat-icon>add</mat-icon>
      </button>
    </span>
  </div>
</div>
