import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "@env/environment";
import { ConfigService } from "@app/services/config.service";
import { TokenService } from "@app/services/token.service";

@Component({
  selector: "home",
  templateUrl: "./debug.html",
  styleUrls: ["./debug.css"],
})
export class DebugComponent {
  jwt: string;
  decodedJwt: string;
  expired: boolean;
  expiry: Date;
  airport: any = { id: 0, name: "test", icao_code: "test" };
  environment = null;
  api_server_edit: boolean = false;
  jwthelper: JwtHelperService = new JwtHelperService();

  get api_server(): string {
    return this.config.api_server;
  }

  set api_server(value: string) {
    this.remove_token();
    this.config.api_server = value;
  }

  get socket_server() {
    return this.config.socket_server;
  }

  set socket_server(value) {
    this.config.socket_server = value;
  }

  constructor(
    public router: Router,
    private config: ConfigService,
    private token: TokenService
  ) {
    this.environment = environment;
    this.token.token.subscribe((token) => {
      this.jwt = token;
      if (token) {
        this.decodedJwt = this.jwthelper.decodeToken(token);
        this.expired = this.jwthelper.isTokenExpired(token);
        this.expiry = this.jwthelper.getTokenExpirationDate(token);
      }
    });
  }

  other_api_server_selected(evt) {
    this.api_server_edit = evt.value === "";
  }

  remove_token(button?: HTMLButtonElement) {
    this.token.forget();
  }
  simulate_error(button: HTMLButtonElement) {
    throw new Error("Simulated Error");
  }
}
