import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { ApiService } from "@app/api/api";
import { TaskService } from "@app/task/task.service";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { CustomerRoute } from "@app/api/types";

@Pipe({
  name: "nl2br",
})
export class Nl2BrPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): any {
    let result;
    if (value) {
      result = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
    }
    return result ? result : value;
  }
}

@Component({
  selector: "route-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["../component.css"],
})
export class RouteDetailComponent implements OnInit, OnDestroy {
  customer_route: CustomerRoute = null;
  mapRequiresResize = false;
  id: number;
  filtersLoaded: Promise<boolean>;
  $customer_route: Subscription;
  customer_route_errors: any = { non_field_error: null, detail: null };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private tasks: TaskService,
    private errorHandleService: ErrorHandleService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"]; // (+) converts string 'id' to a number
      this.load_data();
    });
  }

  load_data() {
    this.customer_route_errors.detail = null;
    this.$customer_route = this.api.customer_route.detail(this.id).subscribe({
      next: (data) => {
        this.customer_route = data;
        this.filtersLoaded = Promise.resolve(true); // Setting the Promise as resolved after I have the needed data
      },
      error: (errors) => {
        this.customer_route_errors = errors.error;
      },
    });
  }

  edit_route() {
    this.router.navigate(["customer_route", this.id, "edit"]);
  }

  generate_service_area(button: HTMLButtonElement) {}
  recalculate_locations(button: HTMLButtonElement) {
    button.disabled = true;
    this.api.customer_route.recalculate_locations(this.id).subscribe({
      next: (data: any) => {
        if (data && "task_id" in data) {
          this.watch_caclulation(data["task_id"]);
        }
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  watch_caclulation(task_id: string) {
    this.tasks.add_task(task_id, {
      message: "Location calculation Scheduled",
      started: {
        message: "Location Calculation Running",
        always: true,
      },
      finished: {
        message: "Location Calculation Complete",
        callback: (_job) => {
          this.load_data();
        },
      },
    });
  }

  ngOnDestroy() {
    if (this.$customer_route) {
      this.$customer_route.unsubscribe();
    }
  }
}
