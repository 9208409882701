<div class="wrapper">
  <header>
    <mat-toolbar color="primary" fxLayout="row">
      <span fxFlex>Find Mission</span>
      <span fxFlex fxLayoutAlign="end">
        <button
          mat-icon-button
          (click)="dialogRef.close()"
          matTooltip="Close Dialog"
        >
          <mat-icon>close</mat-icon>
        </button>
      </span>
    </mat-toolbar>
  </header>
  <div class="content">
    <div fxLayout="column" id="container">
      <div fxLayout="column">
        <div fxLayout="row" fxLayout.xs="column">
          <span fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
            <mat-form-field fxFlex>
              <mat-select
                placeholder="Mission Type"
                [(ngModel)]="search_param.mission_type"
              >
                <mat-option></mat-option>
                <mat-option value="Quote">Quote</mat-option>
                <mat-option value="Operational">Operational</mat-option>
                <mat-option value="Testing">Testing</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex>
              <mat-select
                placeholder="Mission Status"
                [(ngModel)]="search_param.mission_status"
              >
                <mat-option></mat-option>
                <mat-option value="Pending">Pending</mat-option>
                <mat-option value="In Progress">In Progress</mat-option>
                <mat-option value="Completed">Completed</mat-option>
                <mat-option value="Canceled">Canceled</mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
            <mat-form-field fxFlex>
              <input
                matInput
                placeholder="from"
                [min]="minDate"
                [max]="maxDate"
                [(ngModel)]="search_param.from_date"
                type="date"
              />
            </mat-form-field>
            <mat-form-field fxFlex>
              <input
                matInput
                placeholder="until"
                [min]="minDate"
                [max]="maxDate"
                [(ngModel)]="search_param.until_date"
                type="date"
              />
            </mat-form-field>
          </span>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="0px">
          <span fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
            <span fxLayout="row" class="mat-input-wrapper">
              <mat-icon fxFlex="20%">flight_takeoff</mat-icon>
              <mat-form-field fxFlex="80">
                <mat-select
                  placeholder="Launch"
                  [(ngModel)]="search_param.launch"
                >
                  <mat-option></mat-option>
                  <mat-option *ngFor="let a of airports" [value]="a.id"
                    >{{ a.properties.name }} [{{
                      a.properties.icao_code
                    }}]</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </span>
            <span fxLayout="row" class="mat-input-wrapper">
              <mat-icon fxFlex="20%">flight_land</mat-icon>
              <mat-form-field fxFlex="80%">
                <mat-select
                  placeholder="Recover"
                  [(ngModel)]="search_param.recover"
                >
                  <mat-option></mat-option>
                  <mat-option *ngFor="let a of airports" [value]="a.id"
                    >{{ a.properties.name }} [{{
                      a.properties.icao_code
                    }}]</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </span>
          </span>
          <span fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
            <customer-select fxFlex [(ngModel)]="customer"></customer-select>
            <span fxFlex class="mat-input-wrapper">
              <mat-form-field>
                <mat-select
                  placeholder="Aircraft"
                  [(ngModel)]="search_param.aircraft"
                >
                  <mat-option></mat-option>
                  <mat-option *ngFor="let a of aircraft" [value]="a.id"
                    >{{ a.make }} {{ a.model }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </span>
          </span>
        </div>

        <div
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayout.xs="column"
          fxLayoutGap.xs="0px"
        >
          <span fxFlex fxLayoutAlign="end">
            <button
              mat-icon-button
              #find_missions_btn
              (click)="find_missions(find_missions_btn)"
              matTooltip="Search"
            >
              <mat-icon>search</mat-icon>
            </button>
          </span>
        </div>
        <span fxLayout="row">
          <mat-divider fxFlex></mat-divider>
        </span>
        <div fxFlex *ngIf="missions" class="mission_results">
          <mat-list fxLayout="column">
            <mat-list-item fxLayout="column" *ngFor="let mission of missions">
              <mat-checkbox
                fxFlex
                fxFlex.sm="10%"
                fxFlex.xs="30%"
                [(ngModel)]="mission._ui_selected"
              ></mat-checkbox>
              <span fxFlex fxFlex.sm="30%" fxFlex.xs="60%">{{
                mission.label
              }}</span>
              <span fxFlex fxHide.sm="true" fxHide.xs="true">{{
                mission.mission_type
              }}</span>
              <span fxFlex fxHide.sm="true" fxHide.xs="true">{{
                mission.mission_status
              }}</span>
              <span fxFlex fxFlex.sm="30%" fxHide.xs="true">{{
                mission.start_date | date
              }}</span>
              <span
                fxFlex
                fxFlex.sm="20%"
                fxHide.xs="true"
                *ngIf="mission.flights"
                >{{ mission.flights.length }}</span
              >
              <a class="anchor" [href]="'#/mission/' + mission.id">
                <mat-icon
                  class="mission-link"
                  fxFlex
                  fxFlex.sm="10%"
                  fxFlex.xs="30%"
                  >launch_external</mat-icon
                >
              </a>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <mat-toolbar fxLayout="row">
      <span fxFlex>
        <button type="button" mat-button (click)="dialogRef.close()">
          Cancel
        </button>
      </span>
      <span fxFlex fxLayoutAlign="end">
        <button
          type="button"
          mat-raised-button
          #submit_btn
          (click)="submit(submit_btn)"
        >
          OK
        </button>
      </span>
    </mat-toolbar>
  </footer>
</div>
