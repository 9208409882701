<div fxLayout="row" class="alert alert-danger" *ngIf="error?.message">
  {{ error?.message }}
</div>
<div fxLayout="column" fxFill fxLayoutAlign="center center" class="login">
  <span fxFlex> &nbsp;</span>
  <div
    class="jumbotron center-block"
    fxFlex="none"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <h1 class="login-title">Login</h1>
    <div class="form-group">
      <p *ngIf="error.non_field_errors" class="field-error">
        Username or Password is not correct.
      </p>
      <label for="username">Username</label>
      <p *ngIf="error.username" class="field-error">this field is required</p>
      <input
        type="text"
        #username
        class="form-control"
        [class.error-show]="error.username"
        id="username"
        placeholder="Username"
      />
    </div>
    <div *ngIf="isLoading" class="spinner">
      <mat-progress-spinner
        [color]="spinnerColor"
        [mode]="spinnerMode"
        [diameter]="50"
      ></mat-progress-spinner>
    </div>
    <div class="form-group">
      <label for="password">Password</label>
      <p *ngIf="error.password" class="field-error">this field is required</p>
      <input
        type="password"
        #password
        class="form-control"
        [class.error-show]="error.password"
        id="password"
        placeholder="Password"
      />
    </div>
    <div>
      <button
        mat-button
        type="submit"
        (click)="login(username.value, password.value)"
      >
        Login
      </button>
      <button
        mat-button
        type="button"
        #forgot_password_btn
        (click)="forgot_password(forgot_password_btn)"
      >
        Reset Password
      </button>
    </div>
    <asl-google-signin-button
      type="standard"
      size="large"
    ></asl-google-signin-button>
  </div>
  <span fxFlex> &nbsp;</span>
</div>
