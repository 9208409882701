import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";

import { TokenService } from "./token.service";

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {
  jwthelper: JwtHelperService = new JwtHelperService();
  exclude = ["api-token-refresh", "api-token-auth"];
  constructor(private tokenservice: TokenService, private router: Router) {}

  isExcluded(request: HttpRequest<any>) {
    for (let e of this.exclude) {
      if (request.url.includes(e)) {
        return true;
      }
    }
    return false;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isExcluded(request)) {
      console.log(`${request.url} is excluded from token attachment`);
      return next.handle(request);
    } else {
      return this.tokenservice.token.pipe(
        switchMap((token) => {
          if (this.tokenservice.isValid(token)) {
            request = request.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            });
          } else {
            console.log("Redirecting to login");
            this.login("Your session has expired");
          }
          return next.handle(request);
        })
      );
    }
  }

  login(message: string) {
    this.router.navigate(["/login"], {
      queryParams: {
        next: this.router.url,
        message: message,
      },
    });
  }
}
