import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ApiService } from "@app/api/api";

@Component({
  selector: "notes",
  templateUrl: "./note.html",
  styleUrls: ["./note.css"],
})
export class NoteComponent implements OnInit, OnDestroy {
  @Input("content_type")
  content_type: string = null;
  @Input("object_id")
  object_id: number = null;

  notes = [];
  note: { [id: string]: any } = {};
  loading: boolean = false;
  $note: Subscription;

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.loading = true;
    this.$note = this.api.note
      .get(this.content_type, this.object_id)
      .subscribe((data: any) => {
        this.notes = data.results;
        this.loading = false;
      });
  }

  private get_note(id: number) {
    for (let note of this.notes) {
      if (id === note.id) {
        return note;
      }
    }
  }
  save_note(button: HTMLButtonElement, id?: number) {
    let note = id ? this.get_note(id) : this.note;
    note["content_type"] = this.content_type ? this.content_type : null;
    note["object_id"] = this.object_id ? this.object_id : null;

    let call = this.api.note.create(note);
    if (note.id) {
      call = this.api.note.update(note.id, note);
    }
    this.loading = true;
    button.disabled = true;
    call.subscribe({
      next: (res: any) => {
        if (!note.id) {
          this.notes.unshift(res);
          this.note = {};
          this.loading = false;
        }
      },
      complete: () => {
        button.disabled = false;
      },
    });
    return true;
  }

  ngOnDestroy() {
    this.$note.unsubscribe();
  }
}
