<div style="padding-top: 32px" *ngIf="_filterConfig.length > 0">
  <div class="example-container mat-elevation-z4">
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [expanded]="panelOpenState"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Filters </mat-panel-title>
        <mat-panel-description>
          <div *ngIf="search_for.length > 0">
            <label class="mobile-view">Search: </label>
            <label
              class="mobile-view"
              style="margin-left: 5px"
              *ngFor="let result of search_for"
              >{{ result }}</label
            >
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <div
          fxLayout="row"
          fxLayoutGap="5px"
          fxLayout.lt-md="column"
          fxLayout.gt-sm="row"
          fxFlexFill
        >
          <ng-container *ngFor="let col of _filterConfig">
            <mat-form-field fxFlex>
              <input
                matInput
                *ngIf="col.type === 'text'"
                type="text"
                [(ngModel)]="searchParams[col.name]"
                [maxlength]="col.maxlength"
                [placeholder]="col.label"
              />
              <input
                digitOnly
                matInput
                *ngIf="col.type === 'number'"
                [placeholder]="col.label"
                type="text"
                [(ngModel)]="searchParams[col.name]"
                [maxlength]="col.maxlength"
              />
              <mat-select
                fxFlex
                *ngIf="col.type === 'select'"
                [placeholder]="col.label"
                [(ngModel)]="searchParams[col.name]"
              >
                <mat-option></mat-option>
                <mat-option *ngFor="let val of col.option" [value]="val">{{
                  val
                }}</mat-option>
              </mat-select>
              <input
                matInput
                *ngIf="col.type === 'date'"
                [placeholder]="col.label"
                [min]="col.minDate"
                [max]="col.maxDate"
                [(ngModel)]="searchParams[col.name]"
                type="date"
              />
            </mat-form-field>
          </ng-container>
        </div>
        <div fxLayout="row">
          <button
            fxLayoutAlign="end"
            fxFlex
            mat-icon-button
            matTooltip="Search"
            (click)="filterValues(searchParams)"
          >
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>
