import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector : 'mission',
  templateUrl : './component.html',
  styleUrls : [ './component.css' ]
})
export class MissionComponent implements OnInit {
  constructor(private titleService: Title) {
    const title = 'Tarnished Lamp Missions';
    this.titleService.setTitle(title);
  }

  ngOnInit() {}
}
