import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Subject, Subscription } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";
import * as moment from "moment";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

@Component({
  selector: "flight-form",
  templateUrl: "./form.component.html",
  styleUrls: ["../component.css"],
})
export class FlightFormComponent implements OnInit, OnDestroy {
  @Input() actions: boolean = true;
  @Input() scheduling: boolean = false;
  flight: { [id: string]: any } = { pilot: null, aircraft: null };
  files = [];
  pilots: any[];
  aircraft = [];
  names = { aircraft: {} };
  id: number;
  labels = {
    type: ["Operational", "Testing", "Positioning", "Planning"],
  };
  form_errors: any = {
    aircraft: null,
    launch: null,
    recover: null,
    non_field_error: null,
    detail: null,
  };
  unSubscribe = new Subject();
  $flight: Subscription;
  title = "Add New Flight";

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private api: ApiService,
    private errorHandleService: ErrorHandleService,
    private titleService: Title
  ) {
    const title = "Tarnished Lamp " + this.title;
    this.titleService.setTitle(title);
    this.api.aircraft
      .list()
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((data: any) => {
        this.aircraft = data.results.filter((e, _i, _a) => {
          return e.active;
        });
        for (let a of this.aircraft) {
          this.names.aircraft[a.id] = a.make + " " + a.model;
        }
      });
  }

  update(flight) {
    if (this.flight === null) {
      this.flight = {};
    }
    for (let k in flight) {
      this.flight[k] = flight[k];
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params["id"];
      if (this.id) {
        this.title = "Update Flight";
        this.$flight = this.api.flight.detail(this.id).subscribe(
          (flight: any) => {
            const title =
              "Tarnished Lamp " + this.title + " " + flight.aircraft_label;
            this.titleService.setTitle(title);
            this.flight = this.normalize_flight(flight);
          },
          (error) => {
            this.form_errors = error.error;
          }
        );
      }
    });
  }
  type_selected(event: { value: string }) {
    if (event.value !== "Planning" && this.flight.aircraft) {
      this.aircraft_selected({ value: this.flight.aircraft });
    } else {
      this.pilots = null;
    }
  }

  aircraft_selected(event: { value: number }) {
    let params = {};
    if (this.flight.scheduled_launch) {
      params["when"] = this.flight.scheduled_launch;
    }

    this.api.aircraft
      .pilots(event.value, params)
      .pipe(filter((data) => data != null))
      .subscribe((data: any) => {
        this.pilots = data.results;
      });
  }

  normalize_flight(flight) {
    let data = {};
    Object.assign(data, flight);
    for (let f of [
      "scheduled_launch",
      "scheduled_recovery",
      "actual_launch",
      "actual_recovery",
    ]) {
      data[f] =
        flight[f] !== null
          ? moment(flight[f]).format("yyyy-MM-DDTHH:mm")
          : null;
    }
    for (let f of ["launch", "recover"]) {
      data[f] = {
        id: flight[f],
        name: flight[f + "_airport"],
        icao_code: flight[f + "_icao"],
      };
    }
    if (flight.flight_type !== "Planning") {
      this.aircraft_selected({ value: flight.aircraft });
    }
    return data;
  }

  get valid(): boolean {
    this.form_errors = {};
    const flight_data = this.flight;
    console.debug(flight_data);
    if (!flight_data.aircraft) {
      this.form_errors["aircraft"] = "An aircraft must be selected";
    }
    if (!flight_data.flight_type) {
      this.form_errors["flight_type"] = "Flight Type must be set";
    }
    if (!flight_data.launch) {
      this.form_errors["launch"] = "Launch Airport must be set";
    }
    if (!flight_data.recover) {
      this.form_errors["recover"] = "Recover Airport must be set";
    }
    if (flight_data.flight_type != "Planning") {
      if (!flight_data.pilot) {
        this.form_errors["pilot"] =
          "A pilot must be provided for all operational flights";
      }
      if (!flight_data.scheduled_launch) {
        this.form_errors["scheduled_launch"] =
          "A Scheduled Launch time must be provided for all operational flights";
      }
      if (!flight_data.scheduled_recovery) {
        this.form_errors["scheduled_recovery"] =
          "A Scheduled Recovery time must be provided for all operational flights";
      }
      if (
        moment(this.flight.scheduled_launch).isAfter(
          this.flight.scheduled_recovery
        )
      ) {
        this.form_errors["scheduled_recovery"] =
          "The Scheduled recovery time must be after the Scheduled launch time";
      }
    }
    if (this.flight.actual_recovery && !this.flight.actual_launch) {
      this.form_errors["actual_recovery"] =
        "The actual recovery time cannot be set without an actual launch time";
      this.form_errors["actual_launch"] =
        "The actual launch time must be set to set the actual recovery time";
    }
    if (
      this.flight.actual_launch &&
      this.flight.actual_recovery &&
      moment(this.flight.actual_launch).isAfter(this.flight.actual_recovery)
    ) {
      this.form_errors["actual_recovery"] =
        "The actual recovery time must be after the actual launch time";
    }
    if (Object.values(this.form_errors).some((x) => x !== null)) {
      console.debug(this.form_errors);
      return false;
    }
    return true;
  }

  save(button: HTMLButtonElement) {
    if (this.valid) {
      this.call_api();
    }
  }

  call_api(success: (res: any) => any = null) {
    let data: { [id: string]: any } = {};
    Object.assign(data, this.flight);
    for (let f of [
      "scheduled_launch",
      "scheduled_recovery",
      "actual_launch",
      "actual_recovery",
    ]) {
      data[f] = this.flight[f] ? moment(this.flight[f]) : null;
    }

    data.launch = data.launch.id;
    data.recover = data.recover.id;
    console.debug(data);
    let call: any = this.api.flight;
    call = this.id ? call.update(this.id, data) : call.create(data);

    call.subscribe(
      (res: any) => {
        if (success) {
          success(res);
        } else {
          this.location.back();
        }
      },
      (errors) => {
        if (!errors.status) {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. If this keeps happening please contact support."
          );
        }
        this.form_errors = errors.error;
      }
    );
  }

  ngOnDestroy() {
    this.unSubscribe.next(1);
    this.unSubscribe.complete();
    if (this.$flight) {
      this.$flight.unsubscribe();
    }
  }
}
