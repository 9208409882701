import { Component, Input, OnInit } from "@angular/core";
import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { isNullOrUndefined } from "is-what";

@Component({
  selector: "app-flight-leg",
  templateUrl: "./flight-leg.component.html",
  styleUrls: ["./flight-leg.component.css"],
})
export class FlightLegComponent implements OnInit {
  @Input("flight_id") flight_id: any;
  @Input("active") flight_active: boolean = true;

  flight_leg_takeoff_list: any = [];
  flight_leg_landing_list: any = [];
  last_leg_complete: boolean = false;

  constructor(private api: ApiService, private dialogService: DialogService) {}

  ngOnInit() {
    this.flight_leg_takeoffs(this.flight_id);
  }

  flight_leg_takeoffs(flight) {
    this.api.flightleg_takeoff
      .search({ flight: flight })
      .subscribe((data: any) => {
        if (data.results.length) {
          this.flight_leg_takeoff_list = data.results;
          this.last_leg_complete = !isNullOrUndefined(
            data.results[data.results.length - 1].landing
          );
          this.flight_leg_landings(flight);
        } else {
          /*
          if there are no FlightLegService, then by definition the last
          leg is complete, this allows the `start leg` control to be displayed
          */
          this.last_leg_complete = true;
        }
      });
  }

  flight_leg_landings(flight) {
    this.api.flightleg_landing
      .search({ flight: flight })
      .subscribe((leg_landing: any) => {
        this.flight_leg_landing_list = leg_landing.results;
      });
  }

  start_flight_leg(button: HTMLButtonElement) {
    this.dialogService.start_flight_leg(this.flight_id).subscribe(() => {
      this.flight_leg_takeoffs(this.flight_id);
    });
  }

  stop_flight_leg(button: HTMLButtonElement, takeoff_id) {
    this.dialogService
      .stop_flight_leg(this.flight_id, takeoff_id)
      .subscribe(() => {
        this.flight_leg_takeoffs(this.flight_id);
      });
  }
}
