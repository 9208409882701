import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";

import { ApiService } from "@app/api/api";
import { MaterialModule } from "@app/material.module";
import { NoteModule } from "@app/note/module";
import { PipeModule } from "@app/common/pipes";
import { FlightLegComponent } from "./flight-leg.component";
import { FlightLegLandingComponent } from "./flight-leg-landing.component";
import { FlightLegTakeoffComponent } from "./flight-leg-takeoff.component";
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NoteModule,
    FormsModule,
    FlexLayoutModule,
    PipeModule,
  ],
  exports: [FlightLegComponent],
  providers: [ApiService],
  declarations: [
    FlightLegComponent,
    FlightLegLandingComponent,
    FlightLegTakeoffComponent,
  ],
})
export class FlightLegModule {}
