import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";

import { ApiService } from "@app/api/api";
import { Aircraft } from "@app/api/types";
import { SubSink } from "subsink";
@Component({
  selector: "airplane-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class AirplaneFormComponent implements OnInit, OnDestroy {
  airplane: Aircraft = { active: true, speed: 0 };
  id: number;
  title = "Add New Airplane";
  form_errors: any = {
    make: null,
    model: null,
    non_field_error: null,
    detail: null,
    active: null,
    fuel_capacity: null,
    fuel_rate: null,
    fuel_type: null,
    speed: null,
    turn_radius: null,
  };
  subsink = new SubSink();
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private titleService: Title
  ) {
    const title = "Tarnished Lamp " + this.title;
    this.titleService.setTitle(title);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      if (this.id) {
        this.form_errors.detail = null;
        this.title = "Update Airplane";
        this.subsink.sink = this.api.aircraft.detail(this.id).subscribe(
          (airplane: any) => {
            const title =
              "Tarnished Lamp " + this.title + " " + airplane.registration;
            this.titleService.setTitle(title);
            this.airplane = airplane;
          },
          (errors) => {
            this.form_errors = errors.error;
          }
        );
      }
    });
  }

  save(button) {
    button.disabled = true;
    let call = this.api.aircraft.create(this.airplane);
    if (this.id) {
      call = this.api.aircraft.update(this.id, this.airplane);
    }
    call.subscribe({
      next: () => {
        button.disabled = false;
      },
      complete: () => {
        this.location.back();
      },
      error: (errors) => {
        this.form_errors = errors.error;
      },
    });
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
