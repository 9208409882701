<mat-card>
  <mat-card-title fxLayout="row" style="margin-bottom: 10px" *ngIf="!compact">
    <span fxFlex class="header_label">Flights</span>
    <span fxFlex fxLayoutAlign="end">
      <button mat-icon-button matTooltip="Assign to Flight">
        <mat-icon>flight</mat-icon>
      </button>
      <a
        *ngIf="allow_add"
        mat-icon-button
        class="anchor"
        [href]="'#/flight/new'"
      >
        <mat-icon matTooltip="New Flight" [routerLink]="['/', 'flight', 'new']">
          add
        </mat-icon>
      </a>
    </span>
  </mat-card-title>
  <mat-divider fxLayout="column"></mat-divider>
  <mat-card-content
    class="flight-list-container"
    fxLayout="column"
    style="margin-top: 20px"
  >
    <div fxLayout="column">
      <div fxFlex *ngIf="show_filters">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Filter </mat-panel-title>
            <mat-panel-description>
              <label *ngIf="search_param.from_date">
                After: {{ search_param.from_date }}
              </label>
              <label *ngIf="search_param.until_date" style="margin-left: 1%">
                Before: {{ search_param.until_date }}
              </label>
              <label *ngIf="search_param.status" class="mat-desc">
                Status: {{ search_param.status }}
              </label>
              <label *ngIf="search_param.type" class="mat-desc">
                Type: {{ search_param.type }}
              </label>
              <label *ngIf="search_param.flown" class="mat-desc">
                Flown: {{ search_param.flown }}
              </label>
              <label *ngIf="search_param.launch" class="mat-desc">
                Launch: {{ search_param.launch }}
              </label>
              <label *ngIf="search_param.recover" class="mat-desc">
                Recover: {{ search_param.recover }}
              </label>
              <label *ngIf="search_param.aircraft" class="mat-desc">
                Aircraft: {{ search_param.aircraft }}
              </label>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutGap="20px"
            fxLayoutGap.xs="5px"
          >
            <div fxFlex>
              <mat-select
                placeholder="Mission Type"
                [(ngModel)]="search_param.type"
              >
                <mat-option></mat-option>
                <mat-option value="Quote">Quote</mat-option>
                <mat-option value="Operational">Operational</mat-option>
                <mat-option value="Canceled">Canceled</mat-option>
              </mat-select>
            </div>
            <div fxFlex>
              <mat-select
                placeholder="Mission Status"
                [(ngModel)]="search_param.status"
              >
                <mat-option></mat-option>
                <mat-option value="Pending">Pending</mat-option>
                <mat-option value="In Progress">In Progress</mat-option>
                <mat-option value="Completed">Completed</mat-option>
              </mat-select>
            </div>
            <div fxFlex>
              <mat-select
                placeholder="Aircraft"
                [(ngModel)]="search_param.aircraft"
              >
                <mat-option></mat-option>
                <mat-option *ngFor="let a of aircraft" value="{{ a.id }}">
                  {{ a.registration }} {{ a.make }} {{ a.model }}
                </mat-option>
              </mat-select>
            </div>
            <div fxFlex>
              <mat-slide-toggle
                [color]="'accent'"
                [checked]="'checked'"
                [(ngModel)]="search_param.flown"
              >
                Flown
              </mat-slide-toggle>
            </div>
            <mat-form-field fxFlex>
              <input
                matInput
                placeholder="from"
                [(ngModel)]="search_param.from_date"
                type="date"
              />
            </mat-form-field>
            <mat-form-field fxFlex>
              <input
                matInput
                placeholder="until"
                [(ngModel)]="search_param.until_date"
                type="date"
              />
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutGap="20px"
            fxLayoutGap.xs="5px"
          >
            <span fxFlex>
              <button
                mat-icon-button
                #get_flights_btn
                (click)="get_flights(get_flights_btn)"
                matTooltip="Search"
              >
                <mat-icon>search</mat-icon>
              </button>
            </span>
          </div>
        </mat-expansion-panel>
      </div>

      <div fxFlex>
        <mat-table
          [dataSource]="flights"
          style="margin-top: 1%"
          [class.isMobile]
        >
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">ID:</span>
              {{ row.id }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="scheduled_launch">
            <mat-header-cell *matHeaderCellDef>
              Scheduled Launch
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">Scheduled Launch:</span>
              {{ row.scheduled_launch | date }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="aircraft">
            <mat-header-cell *matHeaderCellDef> Aircraft </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">Aircraft:</span>
              {{ compact ? row.aircraft_registration : row.aircraft_label }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="launch_airport">
            <mat-header-cell *matHeaderCellDef>
              Launch Airport
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">Launch Airport:</span>
              {{ compact ? row.launch_icao : row.launch_airport }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="recover_airport">
            <mat-header-cell *matHeaderCellDef>
              Recover Airport
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">Recover Airport:</span>
              {{ compact ? row.recover_icao : row.recover_airport }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="pilot_name">
            <mat-header-cell *matHeaderCellDef> Pilot Name </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">Pilot Name:</span>
              {{ row.pilot_fname }}
              <span *ngIf="!compact">&nbsp;{{ row.pilot_lname }} </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Flight Status </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">Flight Status:</span>
              <span *ngIf="row.pending">Pending</span>
              <span *ngIf="row.complete">Complete</span>
              <span *ngIf="row.active">Active</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="anchor">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <a [href]="'#/flight/' + row.id"></a>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            #select_flight_btn
            (click)="select_flight(select_flight_btn, row)"
            *matRowDef="let row; columns: displayedColumns"
            [routerLink]="navigate ? ['/', 'flight', row.id] : null"
          >
          </mat-row>
        </mat-table>
        <mat-paginator
          *ngIf="totalCount > pageCount"
          [pageSize]="pageCount"
          [length]="totalCount"
          [pageSizeOptions]="pageSizeOptions"
          (page)="paginate($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
