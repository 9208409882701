import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

@Component({
  selector: "customer-form",
  templateUrl: "./form.component.html",
  styleUrls: ["../component.css"],
})
export class CustomerFormComponent implements OnInit, OnDestroy {
  customer: { [id: string]: any } = {};
  id: number;
  form_error: any = {
    name: null,
    phone: null,
    code: null,
    fax: null,
    country: null,
    email: null,
    postal_code: null,
    website: [],
    non_field_error: null,
    detail: null,
  };
  title = "Add New Customer";
  $customer: Subscription;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private errorHandleService: ErrorHandleService,
    private titleService: Title
  ) {
    const title = "Tarnished Lamp " + this.title;
    this.titleService.setTitle(title);
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params["id"];
      if (this.id) {
        this.form_error.detail = null;
        this.title = "Update Customer";
        this.$customer = this.api.customer.detail(this.id).subscribe(
          (customer: any) => {
            const title = "Tarnished Lamp " + this.title + " " + customer.name;
            this.titleService.setTitle(title);
            this.customer = customer;
          },
          (errors) => {
            this.form_error = errors.error;
          }
        );
      }
    });
  }

  save(button: HTMLButtonElement) {
    button.disabled = true;
    let call = this.api.customer.create(this.customer);
    if (this.id) {
      call = this.api.customer.update(this.id, this.customer);
    }
    call.subscribe({
      next: (res: any) => {
        this.location.back();
      },
      error: (errors: any) => {
        if (!errors.status) {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. If this keeps happening please contact support."
          );
        }
        this.form_error = errors.error;
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    if (this.$customer) {
      this.$customer.unsubscribe();
    }
  }
}
