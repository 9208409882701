import {
  BehaviorSubject,
  map,
  Observable,
  of,
  switchMap,
  single,
  tap,
} from "rxjs";

import { Injectable } from "@angular/core";
import { ApiService } from "@app/api/api";
import { Token, TokenPair } from "@app/api/user/api";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class TokenService {
  private jwt: JwtHelperService = new JwtHelperService();
  private key: string = "id_token";
  private refresh_key: string = `refresh_${this.key}`;
  private _token: BehaviorSubject<Token> = new BehaviorSubject<Token>(null);
  private _refresh: BehaviorSubject<Token> = new BehaviorSubject<Token>(null);

  set refresh(value) {
    this._refresh.next(this.save_token(this.refresh_key, value));
  }

  get refresh() {
    return this._refresh.getValue();
  }

  set_token(value: Token) {
    this._token.next(this.save_token(this.key, value));
    return value;
  }

  save_token(key: string, token: string): string {
    localStorage.setItem(key, token);
    return token;
  }

  get token(): Observable<Token> {
    // This returns a single shot observable with a currently valid token
    let token = this._token.getValue();
    return of(token).pipe(
      switchMap((v: Token) => {
        if (!this.isValid(v) && this.isValid(this.refresh)) {
          console.log("Attempting to refresh Token");
          return this.api.user.refresh_token(this.refresh).pipe(
            map((res: TokenPair) => {
              console.log("got response:", res);
              return this.set_token(res.access);
            })
          );
        }
        return of(v);
      })
    );
  }

  get valid() {
    return this.isValid(this._token.getValue());
  }

  isValid(token: Token) {
    try {
      return token == null ? false : !this.jwt.isTokenExpired(token);
    } catch {
      return false;
    }
  }

  forget() {
    console.log("Forgetting tokens");
    this.set_token(null);
    this.refresh = null;
  }

  follow() {
    return this._token.asObservable();
  }

  constructor(private api: ApiService) {
    let token = localStorage.getItem(this.key);
    this._token.next(token);
    token = localStorage.getItem(this.refresh_key);
    this._refresh.next(token);
  }
}
