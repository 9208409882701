import { Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import * as moment from "moment";

import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

@Component({
  selector: "flightleg-stop-dialog",
  templateUrl: "./dialog.component.html",

  styleUrls: ["./dialog.component.css"],
})
export class FlightLegStopDialogComponent implements OnInit {
  flight_id: any;
  takeoff_id: any;
  leg_id: any = null;
  landing_airport: any;
  complete_flight: boolean;
  flight_leg_takeoff_list = [];
  last_takeoff: any = null;
  form_errors: any = {};
  wheel_up_time: moment.Moment;
  show_note: boolean = false;

  landing = new UntypedFormGroup(
    {
      airport: new UntypedFormControl(),
      takeoff: new UntypedFormControl(),
      reason: new UntypedFormControl(),
      touchdown_time: new UntypedFormControl(),
      engine_off_time: new UntypedFormControl(),
      ttsn: new UntypedFormControl(),
      note: new UntypedFormControl(),
    },
    { validators: this.landingFormValidator }
  );

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<FlightLegStopDialogComponent>,
    private errorHandleService: ErrorHandleService
  ) {}

  ngOnInit() {
    this.get_flight_leg_takeoffs(this.flight_id);
  }

  load_leg_data(leg_id: number) {
    this.leg_id = leg_id;
    this.api.flightleg_landing.detail(leg_id).subscribe((result) => {
      this.landing.patchValue({
        takeoff: result.takeoff,
        reason: result.reason,
        touchdown_time: result.touchdown_time,
        engine_off_time: result.engine_off_time,
        ttsn: result.ttsn,
        note: result.ttsn,
      });
      if (result.note) {
        this.show_note = true;
      }
      this.last_takeoff = result.takeoff;
      this.get_airport(result.airport);
    });
  }

  landingFormValidator(g: UntypedFormGroup) {
    if (g.get("reason").value === "end of flight") {
      return null;
    }
    return g.get("airport").value ? null : { valid: true };
  }

  flight_leg_landing() {
    this.form_errors = {};

    let touchdown_time: moment.Moment = moment(
      this.landing.get("touchdown_time").value
    );
    let eng_off_time: moment.Moment = moment(
      this.landing.get("engine_off_time").value
    );
    let now: moment.Moment = moment();

    if (eng_off_time.isSameOrAfter(now)) {
      this.form_errors.engine_off_time =
        "Engine off time cannot be in the future.";
    } else if (eng_off_time.isBefore(this.wheel_up_time)) {
      this.form_errors.engine_off_time =
        "Engine off time should be after Wheel-Up Time. [" +
        this.wheel_up_time.format() +
        "]";
    } else if (eng_off_time.isBefore(touchdown_time)) {
      this.form_errors.engine_off_time =
        "Engine off time should be after TouchDown Time.";
    }

    if (touchdown_time.isSameOrAfter(now)) {
      this.form_errors.touchdown_time =
        "Touchdown time cannot be in the future";
    } else if (touchdown_time.isBefore(this.wheel_up_time)) {
      this.form_errors.touchdown_time =
        "Touchdown time should be after Wheel-Up Time. [" +
        this.wheel_up_time.format() +
        "]";
    }

    if (Object.keys(this.form_errors).length) {
      return;
    }

    this.landing.patchValue({
      engine_off_time: eng_off_time,
      touchdown_time: touchdown_time,
    });
    if (!this.leg_id) {
      this.landing.patchValue({ takeoff: this.last_takeoff.id });
    }

    let data: any = {};
    Object.assign(data, this.landing.value);
    data.airport = data.airport.id;
    if (!this.show_note) {
      delete data["note"];
    }

    let endpoint = this.leg_id
      ? this.api.flightleg_landing.update(this.leg_id, data)
      : this.api.flightleg_landing.create(data);

    endpoint.subscribe(
      (_landing) => {
        this.dialogRef.close();
      },
      (_error) => {
        console.error(_error);
        if (_error.error) {
          this.form_errors = { ..._error.error };
        } else {
          this.errorHandleService.sendError(
            "An error occurred while processing your request. " +
              "If this keeps happening please contact support."
          );
        }
      }
    );
  }

  get_airport(id: number) {
    this.api.airport.detail(id).subscribe((result) => {
      this.landing_airport = result;
      this.landing.patchValue({
        airport: {
          id: result.id,
          name: result.properties.name,
          icao_code: result.properties.icao_code,
        },
      });
    });
  }

  get_flight_leg_takeoffs(flight) {
    this.api.flightleg_takeoff
      .search({ flight: flight })
      .subscribe((data: any) => {
        this.flight_leg_takeoff_list = data.results;
        this.last_takeoff = this.flight_leg_takeoff_list[
          this.flight_leg_takeoff_list.length - 1
        ];
        this.wheel_up_time = moment(this.last_takeoff.wheel_up_time);
      });
  }
}
