import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';

import { ApiService } from '@app/api/api';


@Component({
  selector: 'flightleg-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})

export class FlightLegDialogComponent implements OnInit, OnDestroy {
  flight_id: number;
  record_available = false;
  takeoff_data_loaded = false;
  landing_data_loaded = false;

  flight_leg_takeoff_list: any = [];
  flight_leg_landing_list: any = [];

  $flight_leg_takeoff: Subscription;
  $flight_leg_landing: Subscription;

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<FlightLegDialogComponent>,
  ) { }

  ngOnInit() { }

  flight_leg(flight) {
    this.$flight_leg_takeoff = this.api.flightleg_takeoff.search({ 'flight': flight }).subscribe((data: any) => {
      if (data.results.length > 0) {
        this.flight_leg_takeoff_list = data.results;
        this.takeoff_data_loaded = true;
        this.record_available = true;
        this.leg_landings(flight)
      }
    });
  }

  leg_landings(flight) {
    this.$flight_leg_landing = this.api.flightleg_landing.search({ 'flight': flight })
        .subscribe((leg_landing: any) => {
          this.flight_leg_landing_list = leg_landing.results;
          this.landing_data_loaded = true;
        })
  }

  ngOnDestroy() {
    if (this.$flight_leg_takeoff) {
      this.$flight_leg_takeoff.unsubscribe();
    }
    if (this.$flight_leg_landing) {
      this.$flight_leg_landing.unsubscribe();
    }
  }

}
