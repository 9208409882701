import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { Title } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";

import { ApiService } from "@app/api/api";
import { MaterialModule } from "@app/material.module";
import { NoteModule } from "@app/note/module";
import { GenericListModule } from "@app/generic-list/module";
import { GenericFilterModule } from "@app/generic-filter/module";
import { QualificationComponent } from "./component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NoteModule,
    FormsModule,
    GenericListModule,
    GenericFilterModule,
    FlexLayoutModule,
  ],
  exports: [QualificationComponent],
  providers: [ApiService, Title],
  declarations: [QualificationComponent],
})
export class QualificationModule {}
