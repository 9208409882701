import { Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IConfig, NgxMaskModule } from "ngx-mask";

import { ApiService } from "@app/api/api";
import { CG_CommonModule } from "@app/common/module";
import { NoteModule } from "@app/note/module";
import { MissionComponent } from "./component";
import { MaterialModule } from "../material.module";
import { MissionDetailComponent } from "./detail/detail.component";
import { MissionDetailRoutesComponent } from "./detail/mission-routes";
import { MissionListComponent } from "./list/list.component";
import { MissionFormComponent } from "./form/form.component";
import { MissionReportComponent } from "./report/component";
import { FlightModule } from "@app/flight/module";
import { GenericListModule } from "@app/generic-list/module";
import { GenericFilterModule } from "@app/generic-filter/module";
import { CustomerJobComponents } from "@app/customerjob/customerjob.components";
import { ImageryProjectComponents } from "@app/imageryproject/imageryproject.components";
import { DialogModule } from "@app/dialog/dialog.module";
import { DialogDataService } from "@app/services/dialogData.service";
import { DialogService } from "@app/services/dialog.service";
import { MatMomentDatetimeModule } from "@mat-datetimepicker/moment";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDatetimepickerModule } from "@mat-datetimepicker/core";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    DialogModule,
    GenericListModule,
    GenericFilterModule,
    MaterialModule,
    CG_CommonModule,
    NoteModule,
    ReactiveFormsModule,
    FlightModule,
    CustomerJobComponents,
    ImageryProjectComponents,
    MatDatepickerModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    RouterModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot(options),
  ],
  exports: [
    MissionComponent,
    MissionDetailComponent,
    MissionListComponent,
    MissionFormComponent,
    MissionReportComponent,
  ],
  providers: [ApiService, DialogService, DialogDataService, Title],
  declarations: [
    MissionComponent,
    MissionDetailComponent,
    MissionListComponent,
    MissionFormComponent,
    MissionReportComponent,
    MissionDetailRoutesComponent,
  ],
})
export class MissionModule {}
