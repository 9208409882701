<mat-toolbar color="primary" fxLayout="row">
  <span fxFlex>Add Imagery Project</span>
  <span fxFlex fxLayoutAlign="end">
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      matTooltip="Close Dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </span>
</mat-toolbar>

<mat-card>
  <div fxLayout="column">
    <mat-card-content>
      <mat-tab-group
        [(selectedIndex)]="tab_index"
        (selectedTabChange)="tab_change()"
      >
        <mat-tab label="New Project">
          <imageryproject-form #project_form [actions]="false">
          </imageryproject-form>
        </mat-tab>
        <mat-tab label="Existing Project" class="select-project-tab">
          <imageryproject-list
            #project_list
            [search]="project_search"
            [show_filters]="true"
            [navigate]="false"
            [dialog]="true"
            (select)="select_project($event)"
          >
          </imageryproject-list>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row">
        <span fxFlex>
          <button type="button" mat-button (click)="dialogRef.close()">
            Cancel
          </button>
        </span>
        <span fxFlex fxLayoutAlign="end">
          <button
            type="button"
            mat-raised-button
            #submit_btn
            (click)="submit(submit_btn)"
          >
            OK
          </button>
        </span>
      </div>
    </mat-card-actions>
  </div>
</mat-card>
