import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { FlightPath } from "../types";

@Injectable()
export class FlightPathService extends BaseApiService<FlightPath> {
  component: string = "flightpath";

  make_link(object_id: number | string) {
    return this.http_client.post(
      this.function_url("make_link", object_id),
      {},
      { headers: contentHeaders() }
    );
  }
}
