import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { GridsterModule } from "angular-gridster2";
import { DashboardComponent } from "./dashboard.component";
import { LayoutService } from "./layout.service";
import { MaterialModule } from "../material.module";
import { DashboardWidgetDirective } from "./widget.directive";
import { GenericListModule } from "../generic-list/module";
import { DashboardWidget } from "./dashboard.widget";
import { DailyFlightsWidget } from "./widgets/dailyflights.widget";
import { DailyMissionsWidget } from "./widgets/dailymissions.widget";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        GridsterModule,
        MaterialModule,
        GenericListModule,
        FlexLayoutModule,
    ],
    exports: [DashboardComponent, DashboardWidgetDirective],
    providers: [LayoutService],
    declarations: [
        DashboardComponent,
        DashboardWidgetDirective,
        DailyFlightsWidget,
        DailyMissionsWidget,
        DashboardWidget,
    ]
})
export class DashboardModule {}
