import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ApiService } from '@app/api/api';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnChanges, OnDestroy {
  data = null;
  $user: Subscription;
  constructor(private api: ApiService, private titleService: Title) {
    const title = 'Tarnished Lamp User';
    this.titleService.setTitle(title);
  }

  ngOnChanges() {
  }

  ngOnInit() {
    this.$user = this.api.user.profile('self').subscribe( (data: any) => {
      this.data = data;
    })
  }

  ngOnDestroy() {
    if (this.$user) {
      this.$user.unsubscribe();
    }
  }

}
