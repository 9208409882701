import { CustomerJobDetailComponent } from "./detail/detail.component";
import { CustomerJobListComponent } from "./list/list.component";
import { CustomerJobFormComponent } from "./form/form.component";
import { CustomerJobComponent } from "./customerjob.component";
import { AuthGuard } from "@app/common/auth.guard";
import { JobResultsDetailComponent } from "./results/detail.component";
import { MeterReportDetailComponent } from "./report/meter.report";

export const CustomerJobRoutes = {
  path: "customer_job",
  component: CustomerJobComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: "",
      component: CustomerJobListComponent,
    },
    {
      path: "new",
      component: CustomerJobFormComponent,
    },
    {
      path: ":id",
      component: CustomerJobDetailComponent,
    },
    {
      path: ":id/edit",
      component: CustomerJobFormComponent,
    },
    {
      path: ":id/results",
      component: JobResultsDetailComponent,
    },
    {
      path: ":id/report",
      component: MeterReportDetailComponent,
    },
  ],
};
