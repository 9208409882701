<!--
  We explicitly load the material design icons here to get access to the
  `navigation` ligature to be used as an icon on the map.
-->
<link
  href="https://cdn.materialdesignicons.com/1.3.41/css/materialdesignicons.min.css"
  rel="stylesheet"
  type="text/css"
/>

<mat-card *ngIf="not_found">
  <div fxFlexFill class="detail-not-exist">
    Desired Meter Reading {{ not_found }}
  </div>
</mat-card>

<!--<div id=wrapper fxFlexFill fxLayout="row" *ngIf="!not_found">-->
<div id="wrapper" fxFlexFill fxLayout="row">
  <div fxLayout="column" fxFlex="70">
    <div id="metermap"></div>
  </div>
  <div id="data" fxFlex="30" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-tab-group fxFlex="grow">
      <mat-tab label="Meter Information">
        <ng-template [ngIf]="meter">
          <div>Serial: {{ meter.serial }}</div>
          <div>Market: {{ meter.market_name }}</div>
          <div>Customer: {{ meter.customer_name }}</div>
          <div>Created: {{ meter.created | date : "short" }}</div>
          <div>Updated: {{ meter.last_updated | date : "short" }}</div>
          <div *ngIf="meter.extra_data">
            <div *ngIf="meter.extra_data?.address">
              Address: {{ meter.extra_data?.address }}
            </div>
            <div *ngIf="meter.extra_data?.location">
              Location: {{ meter.extra_data?.location }}
            </div>
            <div
              style="white-space: pre-line"
              *ngIf="meter.extra_data?.comment"
            >
              Comment: {{ meter.extra_data?.comment }}
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="!meter"> No Meter Detail </ng-template>
      </mat-tab>
      <mat-tab label="Locations">
        <div fxFlex>
          <div class="mat-table">
            <div class="mat-header-row">
              <div class="mat-header-cell">Date</div>
              <div class="mat-header-cell">Latitude</div>
              <div class="mat-header-cell">Longitude</div>
              <div class="mat-header-cell">Location Type</div>
              <div class="mat-header-cell">Action</div>
            </div>
            <div
              class="mat-row"
              *ngFor="let location of locations"
              (click)="zoom_location(location.location)"
            >
              <div class="mat-cell">
                {{ location.created | date : "M/d/yy" }}
              </div>
              <div class="mat-cell">
                {{ location.location.coordinates[1] | number : "1.3-5" }}
              </div>
              <div class="mat-cell">
                {{ location.location.coordinates[0] | number : "1.3-5" }}
              </div>
              <div class="mat-cell">{{ location.location_type }}</div>
              <div class="mat-cell">
                <mat-icon
                  *ngIf="location.active"
                  class="active-checkmark"
                  [matTooltip]="'Checked Meter Location'"
                >
                  check_circle_outline
                </mat-icon>
                <mat-icon
                  *ngIf="!location.active"
                  #set_meter_btn
                  (click)="
                    set_meter(set_meter_btn, location.id);
                    $event.stopPropagation()
                  "
                  [matTooltip]="'Not Checked Meter Location'"
                >
                  check_circle_outline
                </mat-icon>
                <mat-icon
                  color="warn"
                  (click)="
                    delete_location(location.id); $event.stopPropagation()
                  "
                  [matTooltip]="'Delete Meter Location'"
                  >delete</mat-icon
                >
              </div>
            </div>
          </div>
          <button
            mat-raised-button
            #calculate_location_btn
            (click)="calculate_location(calculate_location_btn)"
          >
            Calculate Location
          </button>
        </div>
      </mat-tab>
      <mat-tab label="Readings">
        <div class="mat-table">
          <div class="mat-header-row">
            <div class="mat-header-cell">Date</div>
            <div class="mat-header-cell">Latitude</div>
            <div class="mat-header-cell">Longitude</div>
            <div class="mat-header-cell">Altitude</div>
            <div class="mat-header-cell">Strength</div>
          </div>
          <div
            class="mat-row"
            *ngFor="let reading of readings"
            #zoom_location_btn
            (click)="zoom_location(reading.location)"
          >
            <div class="mat-cell">
              {{ reading.read_at | date : "M/d/yy" }}
            </div>
            <div class="mat-cell">
              {{ reading.location.coordinates[1] | number : "1.3-5" }}
            </div>
            <div class="mat-cell">
              {{ reading.location.coordinates[0] | number : "1.3-5" }}
            </div>
            <div class="mat-cell">{{ reading.altitude }}</div>
            <div class="mat-cell">{{ reading.strength }}</div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div width="95%" fxFlexAlign="end">
      <table width="100%" class="colormap">
        <tr>
          <td
            *ngFor="let c of colormap; let i = index"
            [style.background-color]="c"
          >
            <span> {{ index_to_signal(i) }} db</span>
          </td>
          <td [style.background-color]="default_color">
            <span>unknown</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
