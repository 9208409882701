import { Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";
import * as moment from "moment";

import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { FlightLegService } from "@app/services/flight-leg.service";

@Component({
  selector: "add-flightleg-dialog",
  templateUrl: "./dialog.component.html",
  animations: [
    trigger("slider", [
      transition(
        ":increment",
        group([
          query(":enter", [
            style({ left: "100%" }),
            animate("0.5s ease-out", style("*")),
          ]),
          query(":leave", [animate("0.5s ease-out", style({ left: "-100%" }))]),
        ])
      ),
      transition(
        ":decrement",
        group([
          query(":enter", [
            style({ left: "-100%" }),
            animate("0.5s ease-out", style("*")),
          ]),
          query(":leave", [animate("0.5s ease-out", style({ left: "100%" }))]),
        ])
      ),
    ]),
  ],
  styleUrls: ["./dialog.component.css"],
})
export class AddFlightLegDialogComponent implements OnInit {
  visible = 0;
  flight_id: any;
  takeoff_airport: any;
  landing_airport: any;
  scheduled_launch: any;
  scheduled_recovery: any;
  landing_airport_select: boolean;
  complete_flight: boolean;
  airports = [];
  ttsns = [];
  flight_leg_takeoff_list = [];
  flight_leg_landing_list = [];
  form_errors: any = {};
  engine_start_time: any;
  wheel_up_time: any;

  takeoff = new UntypedFormGroup({
    flight: new UntypedFormControl(),
    airport: new UntypedFormControl(),
    engine_start_time: new UntypedFormControl(),
    wheel_up_time: new UntypedFormControl(),
    amount_of_fuel: new UntypedFormControl(),
  });

  landing = new UntypedFormGroup(
    {
      airport: new UntypedFormControl(),
      takeoff: new UntypedFormControl(),
      reason: new UntypedFormControl(),
      touchdown_time: new UntypedFormControl(),
      engine_off_time: new UntypedFormControl(),
      ttsn: new UntypedFormControl(),
    },
    { validators: this.landingFormValidator }
  );

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<AddFlightLegDialogComponent>,
    private errorHandleService: ErrorHandleService,
    private flightLegService: FlightLegService
  ) {}

  ngOnInit() {
    this.get_airports();
    this.get_ttsns();
    this.get_flight_leg_takeoffs(this.flight_id);
    this.get_flight_leg_landings(this.flight_id);
  }

  landingFormValidator(g: UntypedFormGroup) {
    if (g.get("reason").value === "end of flight") {
      return null;
    }
    return g.get("airport").value ? null : { valid: true };
  }

  get_airports() {
    this.api.airport.list().subscribe((res: any) => {
      this.airports = res.results.features;
    });
  }

  get_ttsns() {
    this.api.ttsn.list().subscribe((res: any) => {
      this.ttsns = res.results;
    });
  }

  flight_leg_take_off() {
    const last_flight_leg =
      this.flight_leg_landing_list[this.flight_leg_landing_list.length - 1];
    this.form_errors = {};

    this.engine_start_time = this.takeoff.get("engine_start_time").value;
    this.wheel_up_time = this.takeoff.get("wheel_up_time").value;

    if (
      last_flight_leg &&
      moment(last_flight_leg.engine_off_time).isSameOrAfter(
        this.engine_start_time
      )
    ) {
      this.form_errors.engine_start_time =
        "This should be after last Flight Leg Engine-off Time.";
    } else if (
      moment(this.engine_start_time).isSameOrAfter(this.wheel_up_time)
    ) {
      this.form_errors.wheel_up_time =
        "This should be after Engine Start Time.";
    } else {
      this.visible = 1;
    }
  }

  flight_leg_landing() {
    let eng_off_time;
    let touchdown_time;
    this.form_errors = {};

    touchdown_time = this.landing.get("touchdown_time").value;
    eng_off_time = this.landing.get("engine_off_time").value;

    if (moment(eng_off_time).isBefore(this.wheel_up_time)) {
      this.form_errors.engine_off_time = "This should be after Wheel-Up Time.";
    } else if (moment(eng_off_time).isBefore(touchdown_time)) {
      this.form_errors.engine_off_time = "This should be after TouchDown Time.";
    }

    if (moment(touchdown_time).isBefore(this.wheel_up_time)) {
      this.form_errors.touchdown_time = "This should be after Wheel-Up Time.";
    }

    if (Object.keys(this.form_errors).length) {
      return;
    }

    this.takeoff.patchValue({
      flight: this.flight_id,
      airport: this.takeoff_airport,
      engine_start_time: this.engine_start_time,
      wheel_up_time: this.wheel_up_time,
    });

    this.landing.patchValue({
      engine_off_time: eng_off_time,
      touchdown_time: touchdown_time,
    });

    this.api.flightleg_takeoff.create(this.takeoff.value).subscribe(
      (takeoff: any) => {
        this.landing.patchValue({ takeoff: takeoff.id });
        if (!this.landing_airport_select) {
          this.landing.patchValue({
            airport: this.landing_airport,
            reason: "end of flight",
          });
        }
        this.api.flightleg_landing.create(this.landing.value).subscribe(
          (landing: any) => {
            this.flightLegService.send_flight_data({
              id: this.flight_id,
              landing_reason: landing.reason,
            });
            this.dialogRef.close();
          },
          (error) => {
            this.errorHandleService.sendError(
              "An error occurred while processing your request. " +
                "If this keeps happening please contact support."
            );
          }
        );
      },
      (error) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. " +
            "If this keeps happening please contact support."
        );
      }
    );
  }

  goBack(button: HTMLButtonElement, e) {
    e.preventDefault();
    this.visible = 0;
  }

  get_flight_leg_takeoffs(flight) {
    this.api.flightleg_takeoff
      .search({ flight: flight })
      .subscribe((data: any) => {
        this.flight_leg_takeoff_list = data.results;
      });
  }

  get_flight_leg_landings(flight) {
    this.api.flightleg_landing
      .search({ flight: flight })
      .subscribe((leg_landing: any) => {
        if (leg_landing.count) {
          this.flight_leg_landing_list = leg_landing.results;
          const last_landing = leg_landing.results[leg_landing.count - 1];
          this.takeoff_airport = last_landing.airport;
        }
      });
  }

  landing_reason(event) {
    this.landing_airport_select = event.value !== "end of flight";
  }
}
