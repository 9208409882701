<mat-card *ngIf="form_errors.detail">
  <div class="detail-not-exist">Record Does Not Exist.</div>
</mat-card>
<mat-card *ngIf="!form_errors.detail">
  <mat-card-header>
    <div fxLayout="column" class="flight-detail-view">
      <mat-card-title>
        <div fxLayout="row">
          <span fxFlex fxLayoutAlign="end">
            <a
              mat-icon-button
              class="anchor"
              [href]="'#/flight/' + flight.id + '/report'"
              matTooltip="Flight Report"
            >
              <mat-icon>equalizer</mat-icon>
            </a>
            <a
              mat-icon-button
              class="anchor"
              [href]="'#/flight/' + flight.id + '/edit'"
              matTooltip="Edit Flight Detail"
            >
              <mat-icon>edit</mat-icon>
            </a>
            <button
              mat-icon-button
              matTooltip="Delete Flight Details"
              #delete_flight_btn
              (click)="delete_flight(delete_flight_btn, flight.id)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </span>
        </div>
        <div fxLayout="row">
          <span fxFlex fxLayoutAlign="start">
            Aircraft:
            <a
              class="anchor"
              [href]="'#/airplane/' + flight.aircraft"
              style="display: initial"
              matTooltip="Airplane Detail"
            >
              {{ flight.aircraft_label }}
              <mat-icon>open_in_new</mat-icon>
            </a>
          </span>
          <span fxFlex fxLayoutAlign="center">
            Flight Type: {{ flight.flight_type }}
          </span>
          <span fxFlex fxLayoutAlign="end">
            Flight Status: {{ flight.flight_status }}
          </span>
        </div>
      </mat-card-title>
      <mat-card-subtitle>
        <div fxLayout="row">
          <span fxFlex fxLayoutAlign="start">
            {{ flight.launch_airport }} to {{ flight.recover_airport }} on
            {{ flight.scheduled_launch | date }}
          </span>
          <span fxFlex fxLayoutAlign="end">
            {{ flight.last_updated | date }}
          </span>
        </div>
      </mat-card-subtitle>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAligh="space-between"
        fxLayoutGap="2.5em"
      >
        <div fxLayout="column" fxFlex>
          <span fxFlex>
            <b>Pilot</b>
          </span>
          <div fxLayout="row">
            <span fxFlex *ngIf="flight.flight_type !== 'Planning'">
              <a
                mat-icon-button
                class="anchor"
                [href]="'#/employee/' + flight.pilot"
                style="display: initial"
                matTooltip="Employee Detail"
              >
                <mat-icon> person </mat-icon>
                {{ flight.pilot_fname }} {{ flight.pilot_lname }}
              </a>
            </span>
          </div>
        </div>
        <!-- Crew-->
        <div fxLayout="column" fxFlex>
          <span fxFlex>
            <b>Crew</b>
          </span>
          <div fxFlex fxLayout="row">
            <span fxFlex *ngFor="let member of flight.crew">
              <a
                mat-icon-button
                matTooltip="Employee Detail"
                class="anchor"
                [href]="'#/employee/' + member.id"
                style="display: initial"
              >
                <mat-icon> person </mat-icon>
                {{ member.first_name }} {{ member.last_name }}
              </a>
              <button
                *ngIf="!flight_active"
                mat-icon-button
                #remove_crew_btn
                (click)="remove_crew(remove_crew_btn, member.id)"
                matTooltip="Unassign {{ member.first_name }} {{
                  member.last_name
                }}"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </span>
            <span>
              <button
                *ngIf="!flight.actual_recovery && !flight.complete"
                mat-icon-button
                #add_crew_btn
                (click)="add_crew(add_crew_btn)"
                matTooltip="Add Crew Assignment"
              >
                <mat-icon>add</mat-icon>
              </button>
            </span>
          </div>
        </div>
        <!-- Payloads -->
        <div fxLayout="column" fxFlex>
          <span fxFlex>
            <b>Payloads</b>
          </span>
          <div fxFlex fxLayout="row">
            <span fxFlex *ngFor="let payload of flight.payloads">
              <a
                mat-icon-button
                class="anchor"
                [href]="'#/payload/' + payload.payload"
                style="display: initial"
                matTooltip="Payload Detail"
              >
                <mat-icon> router </mat-icon>
                {{ payload.payload_name }}
              </a>
              <button
                *ngIf="flight_active"
                mat-icon-button
                #remove_payload_btn
                (click)="
                  remove_payload(remove_payload_btn, payload.payload_ident)
                "
                matTooltip="Unassign {{ payload.payload_name }}"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </span>
            <span>
              <button
                *ngIf="!flight.actual_recovery && !flight.complete"
                mat-icon-button
                #add_payload_btn
                (click)="add_payload(add_payload_btn)"
                matTooltip="Add Payload Assignment"
              >
                <mat-icon>add</mat-icon>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutGap="1em"
        fxLayoutAlign="space-around"
      >
        <mat-card fxFlex>
          <div fxLayout="column">
            <mat-card-title fxFlex>
              <mat-icon>flight_takeoff</mat-icon>
              Launch
            </mat-card-title>
            <mat-card-content fxFlex>
              <div fxLayout="column">
                <div fxLayout="row">
                  <span fxFlex class="flight-airport">Airport</span>
                  <span fxFlex fxLayoutAlign="end">
                    <a
                      class="anchor"
                      [href]="'#/airport/' + flight.launch"
                      matTooltip="Airport detail"
                    >
                      <span class="flight-airport">
                        {{ flight.launch_airport }}
                      </span>
                      <span>
                        <mat-icon> open_in_new </mat-icon>
                      </span>
                    </a>
                  </span>
                </div>
                <div fxLayout="row" *ngIf="flight.flight_type != 'Planning'">
                  <span fxFlex>Scheduled</span>
                  <span fxFlex fxLayoutAlign="end">{{
                    flight.scheduled_launch
                      | momentPipe : "MMM Do YYYY, h:mm:ss a"
                  }}</span>
                </div>
                <div fxLayout="row" *ngIf="flight.flight_type != 'Planning'">
                  <span fxFlex>Actual</span>
                  <span fxFlex fxLayoutAlign="end">
                    {{
                      flight.actual_launch
                        | momentPipe : "MMM Do YYYY, h:mm:ss a"
                    }}
                  </span>
                </div>
              </div>
            </mat-card-content>
          </div>
        </mat-card>
        <mat-card fxFlex>
          <div fxLayout="column">
            <mat-card-title fxFlex>
              <mat-icon>flight_landing</mat-icon>
              Recovery
            </mat-card-title>
            <mat-card-content fxFlex>
              <div fxLayout="column">
                <div fxLayout="row">
                  <span fxFlex class="flight-airport">Airport</span>
                  <span fxFlex fxLayoutAlign="end">
                    <a
                      class="anchor"
                      [href]="'#/airport/' + flight.recover"
                      matTooltip="Airport detail"
                    >
                      <span class="flight-airport">
                        {{ flight.recover_airport }}
                      </span>
                      <span>
                        <mat-icon> open_in_new </mat-icon>
                      </span>
                    </a>
                  </span>
                </div>
                <div fxLayout="row" *ngIf="flight.flight_type != 'Planning'">
                  <span fxFlex>Scheduled</span>
                  <span fxFlex fxLayoutAlign="end">{{
                    flight.scheduled_recovery
                      | momentPipe : "MMM Do YYYY, h:mm:ss a"
                  }}</span>
                </div>
                <div fxLayout="row" *ngIf="flight.flight_type != 'Planning'">
                  <span fxFlex>Actual</span>
                  <span fxFlex fxLayoutAlign="end">{{
                    flight.actual_recovery
                      | momentPipe : "MMM Do YYYY, h:mm:ss a"
                  }}</span>
                </div>
              </div>
            </mat-card-content>
          </div>
        </mat-card>
      </div>
      <div fxLayoutAlign="end">
        <mat-form-field>
          <mat-select
            placeholder="Location Type"
            [(ngModel)]="location_type"
            (selectionChange)="location_type_selected($event)"
          >
            <mat-option value="Assigned">Assigned</mat-option>
            <mat-option value="Reported">Reported</mat-option>
            <mat-option value="Calculated">Calculated</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayout.xs="column"
        fxLayoutGap="20px"
        fxLayoutGap.xs="10px"
        class="flight-detail-buttons"
      >
        <span fxFlex fxFlexAlign="center">
          <button
            mat-raised-button
            [matMenuTriggerFor]="download_menu"
            aria-label="Download Data"
          >
            <mat-icon>download</mat-icon> Download
          </button>
          <mat-menu #download_menu="matMenu">
            <button
              mat-menu-item
              #download_collector_files_btn
              (click)="download_collector_files(download_collector_files_btn)"
            >
              <span>Collector Files</span>
            </button>
            <button
              mat-menu-item
              #download_meter_locations_btn
              (click)="download_meter_locations(download_meter_locations_btn)"
            >
              <span>Meter Locations</span>
            </button>
            <button mat-menu-item disabled>
              <span>GIS Data</span>
            </button>
          </mat-menu>
        </span>
        <span fxFlex>
          <button
            mat-raised-button
            [matMenuTriggerFor]="upload_menu"
            aria-label="Upload Data"
          >
            <mat-icon>upload</mat-icon> Upload
          </button>
          <mat-menu #upload_menu="matMenu">
            <button
              mat-menu-item
              #upload_results_btn
              (click)="upload_results(upload_results_btn)"
            >
              <span>Upload Collector Export</span>
            </button>
            <button
              mat-menu-item
              #upload_flightplan_btn
              (click)="upload_flightplan(upload_flightplan_btn)"
            >
              <span>Upload Flightplan</span>
            </button>
          </mat-menu>
        </span>
        <span fxFlex>
          <button
            mat-raised-button
            [matMenuTriggerFor]="menu"
            aria-label="More Options"
          >
            <mat-icon>more_vert</mat-icon> More Tools
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              #calculate_route_btn
              (click)="calculate_route(calculate_route_btn)"
              [disabled]="flight_complete"
            >
              <span>Generate Flight Plan</span>
            </button>
            <button
              mat-menu-item
              #calculate_track_btn
              (click)="calculate_track(calculate_track_btn)"
              [disabled]="!flight_complete"
            >
              <span>Generate Flight Track</span>
            </button>
            <button
              mat-menu-item
              #complete_flight_btn
              (click)="complete_flight(complete_flight_btn)"
              [disabled]="!flight.has_legs"
            >
              Complete Flight
            </button>
            <button
              mat-menu-item
              #cancel_flight_btn
              (click)="cancel_flight(cancel_flight_btn)"
              [disabled]="flight.has_legs"
            >
              <span>Cancel Flight</span>
            </button>
            <button
              mat-menu-item
              #copy_flightplan_btn
              (click)="copy_flightplan(copy_flightplan_btn)"
            >
              <span>Copy Flightplan</span>
            </button>
            <button
              mat-menu-item
              #update_flightarea_btn
              (click)="update_flightarea(update_flightarea_btn)"
            >
              <span>Update Flightarea</span>
            </button>
          </mat-menu>
        </span>
      </div>
      <mat-tab-group
        [(selectedIndex)]="selected_tab"
        (selectedTabChange)="onTabSelect($event)"
      >
        <mat-tab label="Files">
          <file-list
            [object_id]="id"
            [content_type]="'flight'"
            #files
          ></file-list>
        </mat-tab>
        <mat-tab label="Notes">
          <notes [object_id]="id" [content_type]="'flight'"></notes>
        </mat-tab>
        <mat-tab label="Missions">
          <div fxLayout="row">
            <span fxFlex fxLayoutAlign="end">
              <button
                mat-icon-button
                #add_mission_btn
                (click)="add_mission(add_mission_btn)"
              >
                <mat-icon matTooltip="Add Mission"> add </mat-icon>
              </button>
            </span>
          </div>
          <app-generic-list
            #mission_list
            [displayedColumns]="['mission_id', 'delete']"
            [source]="get_flight_missions"
            [params]="{ flight: flight.id }"
            [router]="'mission'"
          >
            <ng-container matColumnDef="delete">
              <mat-header-cell *matHeaderCellDef> &nbsp; </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <button
                  mat-icon-button
                  #remove_mission_btn
                  (click)="
                    remove_mission(remove_mission_btn, row.id);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon matTooltip="Remove Mission"> delete </mat-icon>
                </button>
              </mat-cell>
            </ng-container>
          </app-generic-list>
        </mat-tab>
        <mat-tab label="Tracks" fxFlex>
          <track-map
            #trackmap
            [plan]="flight.flight_plan"
            [track]="flight.flight_path"
            [area]="flight.flight_area"
          ></track-map>
        </mat-tab>
        <mat-tab label="Flight Leg">
          <app-flight-leg
            [flight_id]="id"
            [active]="flight_active"
          ></app-flight-leg>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card-content>
</mat-card>
