import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";

@Component({
  selector: "contact-list",
  templateUrl: "./list.component.html",
  styleUrls: ["../component.css"],
})
export class ContactListComponent implements OnInit, OnDestroy {
  @Input("content_type")
  content_type: string = null;
  @Input("object_id")
  object_id: number = null;

  contacts: { [id: string]: any }[] = [];
  $contacts: Subscription;

  constructor(
    private api: ApiService,
    private dialogService: DialogService,
    private location: Location
  ) {}

  ngOnInit() {
    this.get_contacts();
  }

  get_contacts() {
    this.$contacts = this.api.contact
      .get(this.content_type, this.object_id)
      .subscribe((data: any) => {
        this.contacts = data.results;
      });
  }
  call(button: HTMLButtonElement, contact) {
    console.debug("Call " + contact.work);
  }
  message(button: HTMLButtonElement, contact) {
    console.debug("Message " + contact.mobile);
  }
  email(button: HTMLButtonElement, contact) {
    console.debug("email " + contact.primary_email);
  }
  browse(button: HTMLButtonElement, contact) {
    console.debug("browse " + contact.website);
  }
  add_contact(button: HTMLButtonElement) {
    button.disabled = true;
    this.dialogService
      .create_contact(this.content_type, this.object_id)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          this.contacts.push(res);
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }
  edit_contact(button: HTMLButtonElement, contact) {
    button.disabled = true;
    this.dialogService
      .edit_contact(this.content_type, this.object_id, contact)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          contact = res ? res : contact;
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }
  delete_contact(button: HTMLButtonElement, contact) {
    button.disabled = true;
    this.api.contact.delete(contact.id).subscribe({
      next: (res: any) => {
        this.get_contacts();
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }
  ngOnDestroy() {
    if (this.$contacts) {
      this.$contacts.unsubscribe();
    }
  }
}
