import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { mergeMap, switchMap } from "rxjs/operators";
import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";

@Component({
  selector: "customerjob-list",
  templateUrl: "./list.component.html",
  styleUrls: ["../component.css"],
})
export class CustomerJobListComponent implements OnInit, OnDestroy {
  jobs = [];
  @Input("mission")
  mission: number = null;
  displayedColumns: string[] = [
    "id",
    "customer_name",
    "name",
    "start_date",
    "commit_date",
    "due_date",
    "status",
    "anchor",
  ];
  $customer_jobs: Subscription;

  constructor(private api: ApiService, private dialogService: DialogService) {}

  ngOnInit() {
    this.load_jobs();
  }

  load_jobs() {
    this.$customer_jobs = this.api.customer_job
      .search({ mission: this.mission })
      .subscribe((data: any) => {
        this.jobs = data.results;
      });
  }

  add_customer_job(button: HTMLButtonElement) {
    if (!this.mission) {
      return;
    }
    this.dialogService
      .customerjob_detail()
      .pipe(
        switchMap((res) => {
          button.disabled = true;
          if (!res) {
            return this.api.customer_job.null();
          }
          res["mission"] = this.mission;
          return this.api.customer_job.create(res);
        })
      )
      .subscribe({
        next: (data: any) => {
          this.load_jobs();
        },
        complete: () => {
          button.disabled = false;
        },
      });
  }

  ngOnDestroy() {
    if (this.$customer_jobs) {
      this.$customer_jobs.unsubscribe();
    }
  }
}
