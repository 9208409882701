import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { FileUploadSpinnerService } from "@app/services/file-upload-spinner.service";

import { ApiService } from "@app/api/api";

@Component({
  selector: "file-upload-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class FileUploadDialogComponent implements OnInit {
  @ViewChild("fileInput", { static: true }) inputEl: ElementRef;
  file;

  constructor(
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    private api: ApiService,
    private spinner: FileUploadSpinnerService
  ) {}

  ngOnInit() {}

  submit(button: HTMLButtonElement) {
    this.spinner.upload_file();
    this.dialogRef.close(this.file);
  }

  on_file_change() {
    const inputEl: HTMLInputElement = this.inputEl.nativeElement;
    this.file = inputEl.files.item(0);
  }
}
