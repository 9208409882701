<div class="wrapper">
    <header>
        <mat-toolbar color="primary" fxLayout="row">
            <span fxFlex>Flight Leg Record</span>
            <span fxFlex fxLayoutAlign="end">
                <button mat-icon-button (click)="dialogRef.close()" matTooltip="Close Dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </span>
        </mat-toolbar>
    </header>
    <div class="content">
        <app-flight-leg [flight_id]="flight_id"></app-flight-leg>
    </div>
</div>
