import { Output, Input, EventEmitter, Component } from "@angular/core";
import { ApiService } from "@app/api/api";

@Component({ template: "<div>Base Widget</div>" })
export class BaseWidget {
  @Output() title = new EventEmitter(true);
  @Input() config: { [id: string]: any } = {};
  constructor(protected api: ApiService) {}

  setTitle(title: string): void {
    this.title.emit(title);
  }
}
