import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { ApiService } from "@app/api/api";
import { ErrorHandleService } from "@app/services/errorHandle.service";

@Component({
  selector: "payload-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css"],
})
export class PayloadDetailComponent implements OnInit, OnDestroy {
  data: { [id: string]: any } = {};
  active: string = "";
  assignments: any;
  id: number;
  deleted = null;
  $payload: Subscription;
  $assignments: Subscription;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private errorHandleService: ErrorHandleService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = params["id"];
      this.$payload = this.api.payload.detail(this.id).subscribe(
        (payload: any) => {
          const title = "Tarnished Lamp Payload " + payload.registration;
          this.titleService.setTitle(title);
          this.deleted = payload.is_delete;
          this.active = payload.active ? "Active" : "Inactive";
          this.data = payload;
        },
        (errors) => {
          this.deleted = errors.error;
        }
      );
      this.$assignments = this.api.payload.assignments(this.id).subscribe(
        (payload: any) => {
          this.assignments = payload;
        },
        (errors) => {
          console.error(errors);
        }
      );
    });
  }

  delete_payload(button: HTMLButtonElement, id) {
    button.disabled = true;
    this.api.payload.delete(id).subscribe({
      next: (data: any) => {
        this.router.navigate(["payload"]);
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    if (this.$payload) {
      this.$payload.unsubscribe();
    }
  }
}
