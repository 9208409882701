import { Action } from '@ngrx/store';

export enum ActionTypes {
    GetMissionFlights = '[Mission Flights] Get Mission Flights',
    AssignMissionFlights = '[Mission Flights] Assign Mission Flights',
}

export class GetMissionFlights implements Action {
    readonly type = ActionTypes.GetMissionFlights;
    constructor() {}
}

export class AssignMissionFlights implements Action {
    readonly type = ActionTypes.AssignMissionFlights;
    constructor(public flights: any) {}
}

export type MapMissionFlightsActions = GetMissionFlights | AssignMissionFlights;
