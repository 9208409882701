<div fxLayout="row" fxLayoutAlign="center">
  <mat-card class="client-detail">
    <div class="detail-not-exist" *ngIf="customer_job_error.detail">
      Desired Customer Job Not Found
    </div>
    <div fxFlex fxLayout="column" *ngIf="!customer_job_error.detail">
      <mat-card-header>
        <mat-card-title>
          <div fxLayout="row"><h2 fxFlex>Customer Job</h2></div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="column">
          <div fxFlex *ngIf="error">
            <mat-error>{{ error_message }}</mat-error>
          </div>

          <mat-form-field fxFlex>
            <mat-select placeholder="Customer Name" [(ngModel)]="job.customer">
              <mat-option
                *ngFor="let customer of customers"
                [value]="customer.id"
                >{{ customer.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="form_errors.customer">{{
            form_errors.customer
          }}</mat-error>

          <mat-form-field fxFlex>
            <input
              matInput
              placeholder="Job Name"
              [(ngModel)]="job.name"
              maxlength="255"
            />
          </mat-form-field>
          <mat-error *ngIf="form_errors.name">{{ form_errors.name }}</mat-error>

          <mat-form-field fxFlex>
            <input
              matInput
              [matDatepicker]="start_picker"
              placeholder="Start Date"
              [(ngModel)]="job.start_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="start_picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #start_picker></mat-datepicker>
          </mat-form-field>
          <mat-error *ngIf="form_errors.start_date">{{
            form_errors.start_date
          }}</mat-error>

          <mat-form-field fxFlex>
            <input
              matInput
              [matDatepicker]="end_picker"
              placeholder="End Date"
              [(ngModel)]="job.due_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="end_picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #end_picker></mat-datepicker>
          </mat-form-field>
          <mat-error *ngIf="form_errors.due_date">{{
            form_errors.due_date
          }}</mat-error>
          <span fxFlex>
            <mat-checkbox [(ngModel)]="job.data_logging"
              >Data Logging</mat-checkbox
            >
          </span>
        </div>
      </mat-card-content>

      <mat-card-actions fxFlex fxLayout="row">
        <span fxFlex fxLayoutAlign="end">
          <button mat-button #save_btn (click)="save(save_btn)">Save</button>
        </span>
      </mat-card-actions>
    </div>
  </mat-card>
</div>
