import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { SubSink } from "subsink";

@Component({
  selector: "imageryproject-list",
  templateUrl: "./list.component.html",
  styleUrls: ["../component.css"],
})
export class ImageryProjectListComponent implements OnInit, OnDestroy {
  @Input("search")
  search_param = {
    mission: null,
    page: null,
    page_size: null,
    from_date: null,
    until_date: null,
  };
  @Input() show_filters = true;
  @Input() allow_add = true;
  @Input() dialog = false;
  @Input() navigate = true;
  @Input() compact = false;
  @Output() select = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  totalCount: number = 0;
  pageCount: number = 0;
  selected_flight = { id: null };
  pageSizeOptions: number[] = [10, 25, 50, 100];

  projects = [];
  displayedColumns: string[] = [
    "id",
    "customer_name",
    "name",
    "start_date",
    "end_date",
    "anchor",
  ];
  subsink = new SubSink();

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.get_projects();
  }

  get_projects(button?: HTMLButtonElement) {
    if (button) button.disabled = true;
    this.subsink.sink = this.api.imagery.project
      .search(this.search_param)
      .subscribe({
        next: (data: any) => {
          this.projects = data.results;
          this.totalCount = data.count;
          this.pageCount = data.results.length;
          if (this.paginator) {
            this.paginator.pageIndex = 0;
          }
        },
        complete: () => {
          if (button) button.disabled = false;
        },
      });
  }

  paginate(event) {
    this.search_param.page_size = event.pageSize;
    this.search_param.page = event.pageIndex + 1;
    this.get_projects();
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
