<mat-toolbar color="primary">
  <span>Message</span>
  <span class="spacer"></span>
  <button mat-icon-button (click)="dialogRef.close(false)" matTooltip="Close Dialog">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-card>
  <mat-card-content>
    {{message}}
  </mat-card-content>
  <mat-card-actions class="row">
    <button type="button" *ngIf=cancel mat-button (click)="dialogRef.close(false)">
      Cancel
    </button>
    <span class="spacer"></span>
    <button type="button" *ngIf=ok mat-raised-button (click)="dialogRef.close(true)">
      OK
    </button>
  </mat-card-actions>
</mat-card>