import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { GenericListComponent } from "@app/generic-list/generic-list.component";
import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ApiService } from "@app/api/api";
import {
  FieldDefinitions,
  FieldDefinition,
  ReportNameDef,
  ReportRecords,
} from "@app/api/reports/report";
import { MatLegacySelectChange as MatSelectChange } from "@angular/material/legacy-select";
import { FileSaverDirective, FileSaverService } from "ngx-filesaver";
@Component({
  selector: "report-component",
  templateUrl: "./report.html",
  styleUrls: ["./report.css"],
})
export class ReportComponent implements OnInit {
  page = "report";
  router = "reports";
  available_reports: ReportNameDef[] = [];
  active_report: string = null;
  report_fields: FieldDefinitions = [];
  headers: string[] = [];
  generating: boolean = false;
  downloading: boolean = false;

  records: ReportRecords = [];
  @ViewChild(GenericListComponent, { static: true })
  genericlist: GenericListComponent;

  constructor(
    private api: ApiService,
    private fileSaverService: FileSaverService
  ) {}

  ngOnInit() {
    this.api.report.get_reports().subscribe((reports: ReportNameDef[]) => {
      this.available_reports = reports;
    });
  }

  get_report_filters() {
    let filters = {};
    this.report_fields
      .filter((x: FieldDefinition) => Boolean(x.value))
      .forEach((field: FieldDefinition) => {
        filters[field.target] = field.value;
      });
    return filters;
  }

  generate_report(button: HTMLButtonElement) {
    let filters = this.get_report_filters();
    console.log(filters);
    this.generating = true;
    button.disabled = true;
    this.api.report.generate_report(this.active_report, filters).subscribe({
      next: (records: ReportRecords) => {
        this.generating = false;
        if (records.length == 0) {
          this.headers = ["status"];
        } else {
          this.headers = Object.keys(records[0]);
        }
        this.records = records;
        console.log(this.headers);
        console.log(this.records);
        this.genericlist.set_headers(this.headers);
        this.genericlist.load_data();
      },
      error: (error) => {
        console.error(error);
        this.generating = false;
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }
  download_report(button: HTMLButtonElement) {
    let filters = this.get_report_filters();
    console.log(filters);
    this.downloading = true;
    button.disabled = true;
    this.api.report.download_report(this.active_report, filters).subscribe({
      next: (res: any) => {
        this.downloading = false;
        const data = new Blob([res.body], {
          type: "text/plain;charset=utf-8",
        });

        this.fileSaverService.save(data, `${this.active_report}.csv`);
      },
      error: (error) => {
        console.error(error);
        this.downloading = false;
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  set_report(report: MatSelectChange) {
    this.active_report = report.value;
    this.api.report
      .get_fields(this.active_report)
      .subscribe((data: FieldDefinitions) => {
        this.report_fields = data;
      });
  }

  report_records() {
    return ((search_params: Observable<any>) => {
      console.log(this.records);
      return search_params.pipe(
        switchMap((search_params) => {
          if (this.records) {
            return of(this.records);
          } else {
            console.log(`no records available`);
            return of([{ status: "No Records To Display" }]);
          }
        })
      );
    }).bind(this);
  }
}
