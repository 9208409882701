import { Action } from '@ngrx/store';

export enum CalenderActionTypes {
    GetChecks = '[Calender Sidenav Checks] Get Checks',
    ToggleChecks = '[Calender Sidenav Checks] Toggle Checks'
}

export class GetChecks implements Action {
    readonly type = CalenderActionTypes.GetChecks;
    constructor() {}
}

export class ToggleChecks implements Action {
    readonly type = CalenderActionTypes.ToggleChecks;
    constructor(public tree_checks: []) {}
}

export type Actions = GetChecks | ToggleChecks;
