<mat-toolbar color="primary" fxLayout="row">
  <span fxFlex>{{ title }}</span>
  <span fxFlex fxLayoutAlign="end">
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      matTooltip="Close Dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </span>
</mat-toolbar>

<mat-card>
  <div fxLayout="column">
    <mat-card-content>
      <div fxLayout="column">
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <mat-select
              matInput
              placeholder="Qualification Name"
              [(ngModel)]="qualification.qualification"
            >
              <mat-option
                *ngFor="let qual of qualifications"
                [value]="qual.id"
                >{{ qual.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <input
              matInput
              placeholder="Effective"
              [(ngModel)]="qualification.effective"
              [matDatepicker]="effective_picker"
            />
            <mat-datepicker-toggle matSuffix [for]="effective_picker">
            </mat-datepicker-toggle>
            <mat-datepicker #effective_picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field fxFlex>
            <input
              matInput
              placeholder="Expires"
              [(ngModel)]="qualification.expires"
              [matDatepicker]="expiry_picker"
            />
            <mat-datepicker-toggle matSuffix [for]="expiry_picker">
            </mat-datepicker-toggle>
            <mat-datepicker #expiry_picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row">
        <span fxFlex>
          <button type="button" mat-button (click)="dialogRef.close()">
            Cancel
          </button>
        </span>
        <span fxFlex fxLayoutAlign="end">
          <button
            type="button"
            mat-raised-button
            #submit_btn
            (click)="submit(submit_btn)"
          >
            OK
          </button>
        </span>
      </div>
    </mat-card-actions>
  </div>
</mat-card>
