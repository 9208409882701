import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { JobResult } from "../types";

@Injectable()
export class JobResultService extends BaseApiService<JobResult> {
  component: string = "jobresult";

  make_link(object_id: number | string) {
    return this.http_client.post(
      this.function_url("make_link", object_id),
      {},
      { headers: contentHeaders() }
    );
  }

  get_archive(object_id: number | string) {
    return this.get_with_retry(this.function_url("archive", object_id), {
      headers: contentHeaders(),
    });
  }
}
