import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";

import { CustomerService } from "@app/api/customer/customer.service";
import { CG_CommonModule } from "@app/common/module";
import { NoteModule } from "@app/note/module";
import { MaterialModule } from "@app/material.module";
import { TaskService } from "@app/task/task.service";
import { ImageryProjectDetailComponent } from "./detail/detail.component";
import { ImageryProjectListComponent } from "./list/list.component";
import { ImageryProjectFormComponent } from "./form/form.component";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CG_CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NoteModule,
    RouterModule,
    FlexLayoutModule,
  ],
  exports: [
    ImageryProjectDetailComponent,
    ImageryProjectListComponent,
    ImageryProjectFormComponent,
  ],
  providers: [CustomerService, TaskService],
  declarations: [
    ImageryProjectDetailComponent,
    ImageryProjectListComponent,
    ImageryProjectFormComponent,
  ],
})
export class ImageryProjectComponents {}
