import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { ImageryProject } from "../types";

@Injectable()
export class ImageryProjectService extends BaseApiService<ImageryProject> {
  component: string = "imagery/project";

  assign(project_id, mission_id) {
    return this.http_client.post(
      this.function_url("assign", project_id),
      { mission: mission_id },
      { headers: contentHeaders() }
    );
  }

  upload_file(object_id: number | string, datafile: any) {
    let formData = new FormData();
    formData.append("content_type", "imageryproject");
    formData.append("datafile", datafile);
    // the api ignores this value though the serializer requires it, feed a known invalid number
    formData.append("object_id", "-1");

    let headers = contentHeaders();
    headers = headers.delete("Content-Type");

    return this.http_client.post(
      this.function_url("load_data", object_id),
      formData,
      { headers: headers }
    );
  }

  search(params) {
    return this.list(params);
  }
}
