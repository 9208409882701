<mat-card>
  <mat-card-title>
    <div fxLayout="row">
      <span fxFlex class="header_label">Payload</span>
      <span fxFlex></span>
      <span fxFlex fxLayoutAlign="end">
        <a mat-icon-button class="anchor" [href]="'#/payload/new'">
          <mat-icon matTooltip="New Payload" [routerLink]="['new']">
            add
          </mat-icon>
        </a>
      </span>
    </div>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>
    <div fxLayout="column">
      <app-generic-list
        fxFlex
        [source]="get_payload_list"
        [router]="'payload'"
        [displayedColumns]="['active', 'ident', 'name', 'description']"
      >
        <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.active" style="color: green"
              >check_circle</mat-icon
            >
            <mat-icon *ngIf="!row.active" style="color: red">cancel</mat-icon>
          </mat-cell>
        </ng-container>
      </app-generic-list>
    </div>
  </mat-card-content>
</mat-card>
