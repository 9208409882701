import { Action } from '@ngrx/store';
import { ActionTypes } from '@app/store/map/sidebar/missions/map_mission/actions';

export default interface MissionMapChecksActionWithPayload<T> extends Action {
    type: string;
    mission_map_checks: any;
}

export interface MissionMap {
    mission_map_checks: any;
}

export const initialState: MissionMap = {
    mission_map_checks: {}
};

export function mapSidebarMissionMapChecksReducer(state = initialState, action: Action): MissionMap {
    switch (action.type) {
        case ActionTypes.GetMissionMapChecks:
            return {
                ...state,
                mission_map_checks: (action as MissionMapChecksActionWithPayload<MissionMap>).mission_map_checks
            };
        case ActionTypes.AssignMissionMapChecks:
            return {
                ...state,
                mission_map_checks: (action as MissionMapChecksActionWithPayload<MissionMap>).mission_map_checks
            };
        default:
            return state;
    }
}
