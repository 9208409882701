<div class="list">
  <div class="mat-table" width="100%">
    <div class="mat-header-row">
      <div class="mat-header-cell">Scheduled</div>
      <div class="mat-header-cell">Aircraft</div>
      <div class="mat-header-cell">Launch</div>
      <div class="mat-header-cell">Recover</div>
      <div class="mat-header-cell">Pilot</div>
      <div class="mat-header-cell">Plan</div>
      <div class="mat-header-cell">Track</div>
      <div class="mat-header-cell">Link</div>
    </div>
    <div class="mat-row" *ngFor="let flight of flights">
      <div class="mat-cell">
        <span class="mobile-label">Scheduled:</span>
        {{flight?.scheduled_launch | date:'M/d/yy'}}
      </div>
      <div class="mat-cell">
        <span class="mobile-label">Aircraft:</span>
        {{flight?.aircraft_registration}}
      </div>
      <div class="mat-cell">
        <span class="mobile-label">Launch:</span>
        {{flight?.launch_icao}}
      </div>
      <div class="mat-cell">
        <span class="mobile-label">Recover:</span>
        {{flight?.recover_icao}}
      </div>
      <div class="mat-cell">
        <span class="mobile-label">Pilot:</span>
        {{flight?.pilot_fname}}
      </div>
      <div class="mat-cell">
        <span class="mobile-label">Plan:</span>
        <mat-checkbox
          [disabled]="!flight.flight_plan"
          [(ngModel)]="flight._plan"
          (change)="update_plan(flight.id)"
        ></mat-checkbox>
      </div>
      <div class="mat-cell">
        <span class="mobile-label">Track:</span>
        <mat-checkbox
          [disabled]="!flight.flight_track"
          [(ngModel)]="flight._track"
          (change)="update_track(flight.id)"
        ></mat-checkbox>
      </div>
      <div class="mat-cell">
        <span class="mobile-label">Mission Link:</span>
        <a class="anchor-mat-icon" [href]="'#/flight/' + flight.id">
          <mat-icon [routerLink]="['/','flight',flight.id]">launch</mat-icon>
        </a>
        <mat-icon
          #flight_leg_btn
          (click)="flight_leg(flight_leg_btn, flight.id)"
        >
          local_gas_station
        </mat-icon>
      </div>
    </div>
  </div>
</div>
