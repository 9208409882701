import {
  Component,
  OnInit,
  ErrorHandler,
  Injectable,
  Inject,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { NotifierService } from "angular-notifier";

import { environment } from "@env/environment";
import { NotificationService } from "@app/services/notification.service";
import { TaskCountService } from "@app/services/task.count.service";
import { DialogDataService } from "@app/services/dialogData.service";
import { ErrorHandleService } from "@app/services/errorHandle.service";
import { SocialAuthService } from "@abacritt/angularx-social-login";

import * as Sentry from "@sentry/browser";
import { ConfigService } from "@app/services/config.service";
import { ACSService } from "@app/services/acs.service";
import { RealTimeService } from "./services/realtime.service";
import { TokenService } from "./services/token.service";
import { filter } from "rxjs/operators";
import { FlightEventService } from "./services/flight_event.service";
import * as moment from "moment-timezone";

const release =
  "tarnished_lamp-" + environment.production ? "production" : "test";
console.log("Errors being reported as release " + release);
Sentry.init({
  dsn: "https://ea43ef43ed6a44e888d2760ea8fdf044@sentry.io/1468295",
  release: release,
  /*  beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === 'ui.click') {
          const { target } = hint.event;
          if (("ariaLabel" in target) && (target.ariaLabel)) {
            breadcrumb.message = target.ariaLabel;
          }
        }
        return breadcrumb;
      },*/
});

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
  public handleError(error: any): void {
    // You can add your own logic here.
    // It is not required to delegate to the original implementation
    // TODO: handle http errors
    console.dir(error);
    const eventId = Sentry.captureException(error.originalError || error);
    Sentry.showReportDialog({ eventId });
    super.handleError(error);
  }
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  populate_toolbar: boolean = true;
  taskCount = null;
  openedDialogData: any = null;
  private notifier: NotifierService;
  socket: WebSocket = null;
  search: string = null;
  search_enabled = true;
  color = "primary";

  constructor(
    private router: Router,
    private titleService: Title,
    private snackBar: MatSnackBar,
    private taskCountService: TaskCountService,
    private dialogDataService: DialogDataService,
    public notifierService: NotifierService,
    private errorHandleService: ErrorHandleService,
    private notification: NotificationService,
    private social_auth: SocialAuthService,
    private acs: ACSService,
    private flight_events: FlightEventService,
    private rts: RealTimeService,
    private token: TokenService,
    @Inject(DOCUMENT) private document
  ) {
    this.notifier = notifierService;
    this.dialogDataService.dialogReceive().subscribe((res: any) => {
      this.openedDialogData = res.data;
    });
    this.taskCountService.getTaskCount.subscribe((count: any) => {
      this.taskCount = count;
    });
    this.errorHandleService.getError().subscribe((error: any) => {
      this.notifier.notify("error", error.message);
    });
    this.notification.get_notification().subscribe((notify: any) => {
      this.openSnackBar(notify.message, notify.action, notify.duration);
    });
    console.log(moment.tz.guess());
    console.log(moment.tz.zone(moment.tz.guess()).name);
    console.log(moment.tz.zone(moment.tz.guess()).abbr(moment().unix()));
  }

  ngOnInit() {
    this.token
      .follow()
      .pipe(filter((e) => e != null))
      .subscribe((e) => {
        console.debug("token found, starting acs service");
        this.acs.start();
        this.flight_events.start();
      });
    this.titleService.setTitle("Tarnished lamp - " + environment.env_name);
    console.log(this.document.location.hostname);
    if (this.document.location.hostname == "my.cleargrid.io") {
      this.color = "primary";
    } else {
      if (this.document.location.hostname.includes("staging")) {
        this.color = "accent";
      } else {
        this.color = "warn";
      }
    }
  }

  logout() {
    if (this.openedDialogData) {
      this.openedDialogData.close();
    }

    this.social_auth.signOut().catch((error) => console.error(error));
    this.token.forget();
    this.router.navigate(["/login"]);
  }

  route(route) {
    if (this.openedDialogData) {
      this.openedDialogData.close();
      this.openedDialogData = null;
    }
    this.router.navigate([route]);
  }

  openSnackBar(
    message: string,
    action: string = null,
    duration: number = 2500
  ) {
    this.snackBar.open(message, action, { duration: duration });
  }
}
