import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as Highcharts from "highcharts";

@Injectable()
export class HighchartsService {
  private DataSource = new Subject<any>();
  data$ = this.DataSource.asObservable();
  private CircleLayer = new Subject<any>();
  circle$ = this.CircleLayer.asObservable();
  private RemoveProfile = new Subject<any>();
  profile$ = this.RemoveProfile.asObservable();
  charControl = new Subject<any>();

  aircraft = {
    title: <string>null,
    id: <any>{ altitude: <string>null, speed: <string>null },
    data: <any>{ altitude: <any>[], speed: <any>[] },
  };
  chartopt: any;
  chart: any;

  constructor() {}

  public chartControlDataSend(data) {
    this.charControl.next({ data: data });
  }

  public chartControlDataReceived(): Observable<any> {
    return this.charControl.asObservable();
  }

  ini_chart(title, data, id) {
    this.aircraft.title = title;
    this.aircraft.id["altitude"] = "altitude-" + id;
    this.aircraft.id["speed"] = "speed-" + id;
    this.filter_data(data);
    if (this.chart) {
      if (
        this.chart.get(this.aircraft.id["speed"]) &&
        this.chart.get(this.aircraft.id["altitude"])
      ) {
        const speed_key = this.get_key_index(id, "speed");
        const altitude_key = this.get_key_index(id, "altitude");
        this.chart.series[speed_key].setData(this.aircraft.data["speed"]);
        this.chart.series[altitude_key].setData(this.aircraft.data["altitude"]);
      } else {
        this.updateSeries();
      }
    } else {
      this.set_series_option();
      this.DataSource.next({ init: true });
    }
  }

  get_key_index(id, attribute) {
    for (const item in this.chart.series) {
      if (attribute + "-" + id === this.chart.series[item].userOptions.id) {
        return item;
      }
    }
  }

  filter_data(data) {
    this.aircraft.data = { altitude: <any>[], speed: <any>[] };
    for (const item of data) {
      this.aircraft.data.altitude.push({
        y: item.altitude,
        x: Date.UTC(
          item.timestamp.year,
          item.timestamp.month,
          item.timestamp.day,
          item.timestamp.hour,
          item.timestamp.minute,
          item.timestamp.second
        ),
        attr_name: "Ground Speed",
        attr_value: item.ground_speed + " knots",
        position: [item.position.lat, item.position.lng],
        timestamp:
          item.timestamp.year +
          "/ " +
          item.timestamp.month +
          "/ " +
          item.timestamp.day +
          " " +
          item.timestamp.hour +
          " : " +
          item.timestamp.minute +
          " : " +
          item.timestamp.second,
      });

      this.aircraft.data.speed.push({
        y: item.ground_speed,
        x: Date.UTC(
          item.timestamp.year,
          item.timestamp.month,
          item.timestamp.day,
          item.timestamp.hour,
          item.timestamp.minute,
          item.timestamp.second
        ),
        attr_name: "Altitude",
        attr_value: item.altitude + " ft ASL",
        position: [item.position.lat, item.position.lng],
        timestamp:
          item.timestamp.year +
          "/ " +
          item.timestamp.month +
          "/ " +
          item.timestamp.day +
          " " +
          item.timestamp.hour +
          " : " +
          item.timestamp.minute +
          " : " +
          item.timestamp.second,
      });
    }
  }

  updateSeries() {
    this.chart.addSeries({
      name: this.aircraft.title + " [ Altitude ]",
      id: this.aircraft.id["altitude"],
      XAxis: 0,
      type: "spline",
      data: this.aircraft.data.altitude,
    });
    this.chart.addSeries({
      name: this.aircraft.title + "[ Ground Speed ]",
      id: this.aircraft.id["speed"],
      yAxis: 1,
      type: "spline",
      data: this.aircraft.data.speed,
    });
    this.chart.redraw();
  }

  createChart(container) {
    const e = document.createElement("div");
    container.appendChild(e);
    this.chart = Highcharts.chart(container, this.chartopt);
  }

  remove_aircraft(id) {
    if (this.chart && this.chart.get("speed-" + id)) {
      this.chart.get("speed-" + id).remove();
      this.chart.get("altitude-" + id).remove();
    }
    if (this.chart.series.length === 0) {
      this.RemoveProfile.next(true);
      this.chart = null;
    }
  }

  set_series_option() {
    this.chartopt = {
      chart: {
        zoomType: "xy",
      },
      title: {
        text: "Aircraft Status",
      },
      xAxis: {
        type: "datetime",
        tickInterval: 60 * 1000,
        dateTimeLabelFormats: {
          month: "%e. %b",
          year: "%b",
        },
        title: {
          text: "Timestamp",
        },
      },
      yAxis: [
        {
          title: {
            text: "GPS Altitude",
          },
          min: 0,
        },
        {
          // Secondary yAxis
          gridLineWidth: 0,
          title: {
            text: "Ground Speed",
          },
          min: 0,
          opposite: true,
        },
      ],
      tooltip: {
        formatter: function () {
          const unit = this.point.series.name.includes("Altitude")
            ? "ft ASL"
            : "knots";
          return (
            "<strong>" +
            this.point.series.name +
            ":</strong> " +
            this.point.y +
            " " +
            unit +
            "<br>" +
            this.point.attr_name +
            " : " +
            this.point.attr_value +
            "<br>" +
            "Latlng: " +
            this.point.position[0] +
            ", " +
            this.point.position[1] +
            "<br>" +
            "DateTime: " +
            this.point.timestamp
          );
        },
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
          },
        },
        series: {
          turboThreshold: 10000,
          point: {
            events: {
              mouseOver: (e) => {
                this.CircleLayer.next({
                  state: "mouseOver",
                  position: e.target.options.position,
                  color: e.target.color,
                });
              },
              mouseOut: (e) => {
                this.CircleLayer.next({
                  state: "mouseOut",
                  position: e.target.options.position,
                  color: e.target.color,
                });
              },
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: this.aircraft.title + "[ Altitude ]",
          id: this.aircraft.id["altitude"],
          XAxis: 0,
          type: "spline",
          data: this.aircraft.data.altitude,
        },
        {
          name: this.aircraft.title + "[ Ground Speed ]",
          yAxis: 1,
          type: "spline",
          id: this.aircraft.id["speed"],
          data: this.aircraft.data.speed,
        },
      ],
    };
  }
}
