import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PipeModule } from "@app/common/pipes";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IMaskModule } from "angular-imask";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMomentDatetimeModule } from "@mat-datetimepicker/moment";
import { MatDatetimepickerModule } from "@mat-datetimepicker/core";
import { ColorPickerModule } from "ngx-color-picker";

import { MeterDialogComponent } from "./meter_group/dialog.component";
import { MissionDialogComponent } from "./mission/dialog.component";
import { AddFlightDialogComponent } from "./add_flight/dialog.component";
import { FileUploadDialogComponent } from "./file_upload/dialog.component";
import { CreateContactDialogComponent } from "./contact/dialog.component";
import { ImportFileDialogComponent } from "./file_import/dialog.component";
import { SelectAircraftDialogComponent } from "./select_aircraft/dialog.component";
import { SelectPayloadDialogComponent } from "./select_payload/dialog.component";
import { MessageBoxDialog } from "./messagebox/dialog.component";
import { CustomerJobDialogComponent } from "./customerjob/dialog.component";
import { CompleteDialogComponent } from "./complete-flight/dialog.component";
import { MaterialModule } from "@app/material.module";
import { CG_CommonModule } from "@app/common/module";
import { FlightLegDialogComponent } from "@app/dialog/flight_leg/dialog.component";
import { AddFlightLegDialogComponent } from "@app/dialog/add_flight_leg/dialog.component";
import { FlightLegStartDialogComponent } from "@app/dialog/flight_leg_start/dialog.component";
import { FlightLegStopDialogComponent } from "@app/dialog/flight_leg_stop/dialog.component";
import { DialogService } from "../services/dialog.service";
import { DialogDataService } from "@app/services/dialogData.service";
import { ApiModule } from "@app/api/api.module";
import { FlightModule } from "app/flight/module";
import { ImageryProjectModule } from "@app/imageryproject/imageryproject.module";
import { FlightLegService } from "@app/services/flight-leg.service";
import { FlightLegModule } from "@app/flight-leg/flight-leg.module";
import { QualificationDialogComponent } from "./qualification/dialog.component";
import { EmployeeQualificationDialogComponent } from "./employee_qualification/dialog.component";
import { AircraftQualificationDialogComponent } from "./aircraft_qualification/dialog.component";
import { GenericSelectDialogComponent } from "./generic_select/dialog.component";
import { GenericTextDialogComponent } from "./generic_text/dialog.component";
import { NoteDialog } from "./note_dialog/dialog.component";
import { TTSNDialogComponent } from "./ttsn_dialog/dialog.component";
import { EndpointDialogComponent } from "./endpoint_style/dialog.component";
import { EndpointStyleComponent } from "./endpoint_style/endpoint_style";
import { ImageryProjectDialogComponent } from "./imagery_project/dialog.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    PipeModule,
    ApiModule,
    CG_CommonModule,
    FlightModule,
    ImageryProjectModule,
    FlexLayoutModule,
    IMaskModule,
    MatDatepickerModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    FlightLegModule,
    ColorPickerModule,
  ],
  declarations: [
    MeterDialogComponent,
    MissionDialogComponent,
    FileUploadDialogComponent,
    CreateContactDialogComponent,
    ImportFileDialogComponent,
    MessageBoxDialog,
    CustomerJobDialogComponent,
    AddFlightDialogComponent,
    CompleteDialogComponent,
    FlightLegDialogComponent,
    AddFlightLegDialogComponent,
    CustomerJobDialogComponent,
    AddFlightDialogComponent,
    SelectAircraftDialogComponent,
    SelectPayloadDialogComponent,
    FlightLegStartDialogComponent,
    FlightLegStopDialogComponent,
    QualificationDialogComponent,
    EmployeeQualificationDialogComponent,
    AircraftQualificationDialogComponent,
    GenericSelectDialogComponent,
    GenericTextDialogComponent,
    NoteDialog,
    TTSNDialogComponent,
    EndpointDialogComponent,
    EndpointStyleComponent,
    ImageryProjectDialogComponent,
  ],
  providers: [DialogService, DialogDataService, FlightLegService],
})
export class DialogModule {}
