<mat-card *ngIf="deleted">
  <div class="detail-not-exist">Record Does Not Exist.</div>
</mat-card>
<mat-card class="detail" *ngIf="!deleted">
  <div fxLayout="column">
    <mat-card-header>
      <div fxFlex>
        <mat-card-title>
          <h2>Employee Detail</h2>
        </mat-card-title>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row">
          <span
            fxFlex
            class="header_label"
            style="word-break: break-all !important"
            >{{ employee.name }}</span
          >
          <span fxFlex fxLayoutAlign="end">
            <button
              mat-icon-button
              matTooltip="Delete Employee Details"
              #delete_employee_btn
              (click)="delete_employee(delete_employee_btn, employee.id)"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <a
              mat-icon-button
              class="anchor"
              [href]="'#/employee/' + employee.id + '/edit'"
            >
              <mat-icon
                matTooltip="Edit Employee Details"
                [routerLink]="['edit']"
                >edit</mat-icon
              >
            </a>
          </span>
        </div>

        <div fxLayout="row">
          <span fxFlex>{{ employee.id }}</span>
          <span fxFlex fxLayoutAlign="end">{{
            employee.hire_date | date
          }}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-around">
          <span class="label">City</span>
          <span>{{ employee.city }} </span>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-around">
          <span class="label">Phone</span>
          <span>
            <span class="employee-contact">{{
              employee.phone | mask : "(000)-000-0000"
            }}</span>
            <button
              mat-icon-button
              matTooltip="Call"
              (click)="call()"
              *ngIf="employee.phone"
            >
              <mat-icon>call</mat-icon>
            </button>
          </span>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-around">
          <span class="label">E-mail</span>
          <span>
            <span class="employee-contact">{{ employee.email }}</span>
            <button
              mat-icon-button
              matTooltip="E-Mail"
              (click)="email()"
              *ngIf="employee.phone"
            >
              <mat-icon>email</mat-icon>
            </button>
          </span>
        </div>

        <mat-tab-group>
          <mat-tab label="Qualifications">
            <div fxLayout="column">
              <div fxLayout="row">
                <span fxFlex fxLayoutAlign="end">
                  <button mat-icon-button (click)="qualification_form()">
                    <mat-icon matTooltip="New Qualification"> add </mat-icon>
                  </button>
                </span>
              </div>
              <app-generic-list
                #quals_list
                [displayedColumns]="[
                  'employee_name',
                  'qualification_name',
                  'effective',
                  'expires',
                  'edit'
                ]"
                [source]="get_employee_qualifications"
                [router]="'qualification'"
                [params]="{ employee: employee.id }"
              >
                <ng-container matColumnDef="edit">
                  <mat-header-cell *matHeaderCellDef> &nbsp; </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <button
                      mat-icon-button
                      (click)="
                        qualification_form(employee.id, row.id);
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon matTooltip="New Qualification"> edit </mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      (click)="
                        remove_qualification(row.id); $event.stopPropagation()
                      "
                    >
                      <mat-icon matTooltip="Remove Qualification">
                        delete
                      </mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="effective">
                  <mat-header-cell *matHeaderCellDef>
                    Effective
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.effective | date : "shortDate" }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="expires">
                  <mat-header-cell *matHeaderCellDef> Expires </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.expires | date : "shortDate" }}
                  </mat-cell>
                </ng-container>
              </app-generic-list>
            </div>
          </mat-tab>
          <mat-tab label="Aircraft">
            <app-generic-list
              [displayedColumns]="['registration', 'name']"
              [source]="get_employee_aircraft"
              [router]="'airplane'"
            >
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.make }} {{ row.model }}
                </mat-cell>
              </ng-container>
            </app-generic-list>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-card-content>
  </div>
</mat-card>
