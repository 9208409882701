import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  ViewChild,
  ComponentRef,
  SimpleChanges,
  AfterViewInit,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as L from "leaflet";
import "leaflet.markercluster";
import "leaflet-editable";
import * as turf from "@turf/turf";
import { environment } from "@env/environment";
import { ApiService } from "@app/api/api";
import { MeterTooltipComponent } from "./meter-toolip";
import { TooltipInjector } from "./map_tools";
import { BaseMap } from "@app/base_map";
import { Feature } from "geojson";

@Component({
  selector: "route-map",
  template: ` <div #map id="routemap"></div> `,
  styleUrls: ["commoncss/routemap.css"],
  host: {
    class: "main-map",
    style: "display:flex;flex-flow:column;flex:1 1 auto",
  },
})
export class RouteMapComponent
  extends BaseMap
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  meterset: any = null;
  servicearea = null;
  markers: any = null;
  componentRef: ComponentRef<MeterTooltipComponent>;
  ngUnsubscribe = new Subject();
  meter_subscriptions: Array<Subscription> = [];
  @Input() editable: boolean = false;
  @Input() newpoly: boolean;
  @ViewChild("map", { static: true }) mapContainer;

  @Input("service_area")
  set service_area(value) {
    if (this.map && this.servicearea) {
      this.map.removeLayer(this.servicearea);
    }
    this.servicearea = value ? L.GeoJSON.geometryToLayer(value as any) : null;
  }

  get service_area(): turf.MultiPolygon {
    let obj = this.map.editTools ? this.map.editTools.currentPolygon : null;
    if (!obj) {
      return null;
    }
    let geom = obj.toGeoJSON().geometry;
    if (geom.type === "MultiPolygon") {
      return geom;
    } else if (geom.type === "Polygon") {
      geom = turf.multiPolygon([geom.coordinates]).geometry;
      return obj as unknown as turf.MultiPolygon;
    }
  }

  @Input("meter_set")
  set meter_set(value) {
    if (this.meterset) {
      if (this.markers) {
        this.markers.removeLayer(this.meterset);
      }
      this.meterset = null;
    }
    if (value) {
      this.add_meters(value);
    }
  }

  constructor(
    private api: ApiService,
    private tooltip_injector: TooltipInjector
  ) {
    super();
  }

  add_service_area() {
    if (!this.map || !this.servicearea) {
      return;
    }
    this.servicearea.addTo(this.map);
    if (this.editable) {
      this.servicearea.enableEdit();
      if (this.map.editTools) {
        this.map.editTools.currentPolygon = this.servicearea;
      }
    }
    this.fit_bounds();
  }

  fit_bounds() {
    let bounds;
    if (this.servicearea) {
      bounds = this.servicearea.getBounds();
    } else if (this.markers) {
      bounds = this.markers.getBounds();
    }
    if (bounds.isValid()) {
      this.map.fitBounds(bounds);
      this.invalidateSize();
    }
  }

  add_meters(metergroup, page = null) {
    this.meter_subscriptions[metergroup] = this.api.meter
      .list({
        group_id: metergroup,
        page: page,
        page_size: environment.default_meter_page_size,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (this.meterset) {
          this.meterset.addData(res);
        } else {
          this.meterset = this.create_meter_layer(res);
          page = 1;
        }

        if (this.markers) {
          this.markers.addLayer(this.meterset);
        }
        if (res.next) {
          this.add_meters(metergroup, page + 1);
        }
        this.fit_bounds();
      });
  }

  create_meter_layer(data) {
    return L.geoJson(data, {
      onEachFeature: this.tooltip_injector
        .InjectPopupComponent(MeterTooltipComponent)
        .bind(this),
    });
  }

  invalidateSize() {
    this.map.invalidateSize(true);
  }

  ngAfterViewInit() {
    this.map.invalidateSize(true);
  }

  ngOnInit() {
    this.map_build(this.mapContainer, this.newpoly);

    this.map.on("contextmenu", (e) => {});

    this.markers = L.markerClusterGroup();
    this.markers.addTo(this.map);
    if (this.servicearea) {
      this.add_service_area();
    }
    if (this.meterset) {
      this.markers.addLayer(this.meterset);
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.meter_set && change.meter_set.previousValue) {
      this.meter_subscriptions[change.meter_set.previousValue].unsubscribe();
    }
    if (change.service_area) {
      this.add_service_area();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(1);
    this.ngUnsubscribe.complete();
    this.map.remove();
  }
}
