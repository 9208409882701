<div>Meter Report</div>
<div width="100%">
  <mat-form-field class="example-chip-list">
    <mat-label>Field Order</mat-label>
    <mat-chip-list #chipList aria-label="Field selection">
      <mat-chip
        *ngFor="let field of field_order"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(field)"
      >
        {{field}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="New field..."
        #field_input
        [formControl]="field_form"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option *ngFor="let field of filtered_fields | async" [value]="field">
        {{field}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="example-tree"
  >
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <li class="mat-tree-node">
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        {{node.item}}
      </li>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li>
        <div class="mat-tree-node">
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.item"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          {{node.item}} [{{node.length}} child reports]
        </div>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
</div>
