<mat-card class="client-detail" *ngIf="filtersLoaded | async">
  <div class="detail-not-exist" *ngIf="customer_route_errors.detail">
    Record Does Not Exist.
  </div>
  <div *ngIf="!customer_route_errors.detail">
    <mat-card-header class="row">
      <mat-card-title class="row">
        <span
          [routerLink]="['/customer', customer_route.customer]"
          [queryParams]="{ tab: 'metergroups' }"
        >
          {{ customer_route.customer_name }}
        </span>
        <span class="spacer"></span>
        <span> {{ customer_route.name }}</span>
        <span class="spacer"></span>
        <a
          mat-icon-button
          class="anchor"
          [href]="'#/customer_route/' + customer_route.id + '/edit'"
        >
          <mat-icon matTooltip="Edit Customer Details" [routerLink]="['edit']">
            edit
          </mat-icon>
        </a>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-expansion-panel *ngIf="customer_route.description">
        <mat-expansion-panel-header>
          <mat-panel-title> Route Description </mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHTML]="customer_route.description | nl2br"></div>
      </mat-expansion-panel>
      <route-map
        [meter_set]="customer_route.meter_group"
        [service_area]="customer_route.service_area"
        [newpoly]="false"
      >
      </route-map>
      <button
        mat-button-raised
        #recalculate_locations_btn
        (click)="recalculate_locations(recalculate_locations_btn)"
      >
        Recalculate Meter Locations
      </button>
      <button
        mat-button-raised
        #generate_service_area_btn
        (click)="generate_service_area(generate_service_area_btn)"
        [disabled]="true"
      >
        Generate Service Area
      </button>
    </mat-card-content>
  </div>
</mat-card>
