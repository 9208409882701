import { PayloadDetailComponent } from "./detail/detail.component";
import { PayloadListComponent } from "./list/list.component";
import { PayloadFormComponent } from "./form/form.component";
import { PayloadComponent } from "./component";
import { AuthGuard } from "@app/common/auth.guard";

export const PayloadRoute = {
  path: "payload",
  component: PayloadComponent,
  canActivate: [AuthGuard],
  children: [
    { path: "", component: PayloadListComponent },
    { path: "new", component: PayloadFormComponent },
    { path: ":id", component: PayloadDetailComponent },
    { path: ":id/edit", component: PayloadFormComponent },
  ],
};
