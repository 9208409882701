import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common'
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class ConfigService {
    _api_server = new BehaviorSubject<string>(environment.api_server);
    api_server$ = this._api_server.asObservable();
    get api_server(): string { return this._api_server.value }
    set api_server(value: string) { this._api_server.next(value) }

    _socket_server = new BehaviorSubject<string>(environment.socket_server);
    socket_server$ = this._socket_server.asObservable();
    get socket_server(): string { return this._socket_server.value }
    set socket_server(value: string) { this._socket_server.next(value) }

    constructor(@Inject(DOCUMENT) private document: Document) {
        this.api_server$.subscribe(api_server => {
            if (environment.socket_server === '') {
                let server = api_server;
                if (server === '') {
                    server = this.document.location.href
                }
                const url = new URL(server);
                let protocol = 'ws';
                if (url.protocol === 'https:') {
                    protocol = 'wss';
                }
                this.socket_server = protocol + '://' + url.hostname + ':' + url.port;
                console.debug('new socket server url ' + this.socket_server);
            } else {
                console.debug('socket_server update suppressed by environment');
                console.debug(environment);
            }
        })
    }
}
