<mat-card>
  <mat-card-title>
    <div fxLayout="row">
      <span fxFlex class="header_label">Missions</span>
      <span fxFlex></span>
      <span fxFlex fxLayoutAlign="end">
        <a mat-icon-button class="anchor" [href]="'#/mission/new'">
          <mat-icon matTooltip="New Mission" [routerLink]="['new']"
            >add</mat-icon
          >
        </a>
      </span>
    </div>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-card-content>
    <div fxLayout="column">
      <div fxFlex style="padding-top: 32px">
        <app-generic-filter
          [filterList]="filters"
          [store_name]="'mission'"
          (filterData)="getFilterData($event)"
        ></app-generic-filter>
      </div>
      <app-generic-list
        [source]="get_mission_list"
        [router]="'mission'"
        [displayedColumns]="[
          'mission_id',
          'mission_type',
          'mission_status',
          'start_date',
          'end_date'
        ]"
      >
        <ng-container matColumnDef="start_date">
          <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.start_date | date: "short" }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="end_date">
          <mat-header-cell *matHeaderCellDef> End Date </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.end_date | date: "short" }}
          </mat-cell>
        </ng-container>
      </app-generic-list>
    </div>
  </mat-card-content>
</mat-card>
