import { Component, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import * as L from "leaflet";

export interface PopupRequest {
  location: L.LatLng;
  child: any;
  data: any;
  title: string;
}
@Injectable()
export class PopupService {
  // Observable string sources
  private popupSource = new Subject<PopupRequest>();

  // Observable streams
  popup_command$ = this.popupSource.asObservable();
  // Service message commands
  show(location: L.LatLng, child: any, data: any, title: string) {
    this.popupSource.next({
      location: location,
      child: child,
      data: data,
      title: title,
    });
  }

  requests() {
    return this.popup_command$;
  }
}
