import { Component, OnInit } from "@angular/core";
import { ApiService } from "@app/api/api";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, Observable, BehaviorSubject } from "rxjs";
import { switchMap, map, tap } from "rxjs/operators";

class Query {
  text: string = "";
  start: string;
  end: string;
  id: string;
}

@Component({
  selector: "qualification",
  templateUrl: "./component.html",
  styleUrls: ["./component.css"],
})
export class SearchComponent implements OnInit {
  query_string: string = "";
  _query: BehaviorSubject<Query> = new BehaviorSubject<Query>(new Query());
  get observable(): Observable<Query> {
    return this._query.asObservable();
  }
  set query(q: Query) {
    this._query.next(q);
  }

  tables = [
    {
      router: "mission",
      title: "Missions",
      source: this.default_source("mission"),
      columns: ["mission_id", "mission_type", "mission_status"],
    },
    {
      router: "airplane",
      source: this.default_source("aircraft"),
      title: "Aircraft",
      columns: ["registration", "make", "model"],
    },
    {
      router: "airport",
      title: "Airports",
      columns: ["name", "icao_code", "use_count"],
      source: (search_params: Observable<any>) => {
        return combineLatest([search_params, this.observable]).pipe(
          switchMap(([_, query]) => {
            return this.api.airport.find(query);
          }),
          map((results: any) => {
            return {
              count: results.count,
              results: results.results.features.map((element) => {
                return { id: element.id, ...element.properties };
              }),
            };
          })
        );
      },
    },
    {
      router: "customer",
      title: "Customers",
      source: this.default_source("customer"),
      columns: ["name", "address", "code"],
    },
    {
      router: "meter_reading",
      title: "Meters",
      source: (search_params: Observable<any>) => {
        return combineLatest([search_params, this.observable]).pipe(
          switchMap(([_, query]) => {
            return this.api.meter.find(query);
          }),
          map((results: any) => {
            return {
              count: results.count,
              results: results.features.map((element) => {
                return { id: element.id, ...element.properties };
              }),
            };
          })
        );
      },
      columns: ["serial", "market_name", "vendor_name", "customer_name"],
    },
    {
      router: "metergroup",
      source: this.default_source("meter_group"),
      title: "Meter Groups",
      columns: ["group_id", "group_name"],
    },
  ];

  constructor(
    private api: ApiService,
    public router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      console.debug(params);
      this.query_string = params["query_string"];
      this.parse_query_string();
    });
  }

  parse_query_string() {
    const regex = /(([a-zA-Z0-9]+):)?((\S*"[^"]+\S*")|(\S+))\s|$/gm;
    let m;
    let query = new Query();
    while ((m = regex.exec(this.query_string + " ")) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      let tag = m[2] ? m[2] : "text";
      let value = m[3] ? m[3] : "";

      console.debug(`Found query element ${tag}: ${value}`);
      if (tag == "text") {
        query.text += value ? (query.text ? " " : "" + value) : value;
      } else {
        query[tag] = value;
      }
    }
    console.table(query);
    this.query = query;
  }

  default_source(name: string) {
    console.debug(`${name} =>${this.api[name]}`);
    return (search_param: Observable<any>): Observable<any> => {
      return combineLatest([search_param, this.observable]).pipe(
        switchMap(([_, query]) => {
          return this.api[name].find(query);
        })
      );
    };
  }
}
