import { Injectable } from "@angular/core";
import { BaseApiService } from "@app/api/base_api";
import { contentHeaders } from "@app/common/headers";
import { EndpointRecordLoad } from "@cleargrid/map_layers";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Payload } from "../types";

@Injectable()
export class PayloadService extends BaseApiService<Payload> {
  component: string = "payload";

  search(params: any) {
    return this.list(params);
  }

  assign(payload_id, aircraft_id) {
    return this.post_with_retry(
      this.function_url("assign", payload_id),
      { aircraft_id: aircraft_id },
      { headers: contentHeaders() }
    );
  }

  unassign(payload_id, aircraft_id) {
    return this.post_with_retry(
      this.function_url("unassign", payload_id),
      { aircraft_id: aircraft_id },
      { headers: contentHeaders() }
    );
  }

  assignments(payload_id) {
    return this.get_with_retry(this.function_url("assignments", payload_id), {
      headers: contentHeaders(),
    });
  }

  endpoints(payload_id: number): Observable<EndpointRecordLoad> {
    return this.get_with_retry(this.function_url("endpoints", payload_id)).pipe(
      map((d) => d as EndpointRecordLoad)
    );
  }
}
