import { RouteDetailComponent } from './detail/detail.component';
import { RouteListComponent } from './list/list.component';
import { RouteFormComponent } from './form/form.component';
import { RouteComponent } from './component';
import { AuthGuard } from '@app/common/auth.guard';

export const RouteRoute = {
  path: 'customer_route',
  component: RouteComponent,
  canActivate: [AuthGuard],
  children: [
    {path: '', component: RouteListComponent},
    {path: 'new', component: RouteFormComponent},
    {path: ':id', component: RouteDetailComponent},
    {path: ':id/edit', component: RouteFormComponent},
  ]
};
