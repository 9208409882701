import { Title } from "@angular/platform-browser";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
} from "@abacritt/angularx-social-login";

import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { TokenService } from "@app/services/token.service";

import { BehaviorSubject, from } from "rxjs";
import { filter, mergeMap, map, raceWith, retry } from "rxjs/operators";
import { SubSink } from "subsink";

interface Credentials {
  username: string;
  password: string;
}

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  providers: [],
})
export class LoginComponent implements OnInit, OnDestroy {
  credentials: BehaviorSubject<Credentials> = new BehaviorSubject<Credentials>(
    null
  );
  error: any = {
    non_field_error: null,
    username: null,
    password: null,
    message: null,
  };
  spinnerColor = "primary";
  spinnerMode = "indeterminate";
  subsink = new SubSink();
  isLoading = false;
  next = "";
  jwt: JwtHelperService = new JwtHelperService();
  google_enabled: boolean = false;

  constructor(
    public router: Router,
    public api: ApiService,
    private route: ActivatedRoute,
    private titleService: Title,
    private dialog_service: DialogService,
    private token: TokenService,
    private social_auth: SocialAuthService
  ) {
    const title = "Tarnished Lamp Login";
    this.titleService.setTitle(title);
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  get logged_in(): boolean {
    try {
      return this.token.valid;
    } catch {
      return false;
    }
  }

  ngOnInit() {
    // Get the query params
    this.subsink.sink = this.route.queryParams
      .pipe(
        map((params: any) => ({
          ...params,
          next: params["next"] || "/home",
        })),
        filter((params: any) => !params.next.startsWith("/login"))
      )
      .subscribe((params: any) => {
        this.next = params.next;
        this.error.message = params.message;
        if (this.logged_in) {
          this.router.navigate([this.next]);
        }
      });

    console.log(this.social_auth);
    this.google_enabled = true;

    this.subsink.sink = this.social_auth.authState
      .pipe(
        filter((user: SocialUser) => user?.name != null),
        mergeMap((user: SocialUser) =>
          from(
            this.social_auth.getAccessToken(GoogleLoginProvider.PROVIDER_ID)
          ).pipe(mergeMap((token: string) => this.api.user.social_login(token)))
        ),
        raceWith(
          this.credentials.asObservable().pipe(
            filter((e: Credentials) => e != null),
            mergeMap((e: Credentials) =>
              this.api.user.login(e.username, e.password)
            )
          )
        ),
        retry()
      )
      .subscribe({
        next: (result: any) => {
          this.token.set_token(result.access);
          this.token.refresh = result.refresh;
          this.router.navigateByUrl(this.next);
        },
        error: (error) => {
          console.error(error);
          this.error = error.error;
        },
      });

    if (this.error?.message) {
      this.token.forget();
    }
  }

  auth_from(source, ...sources) {
    return source.pipe(raceWith(...sources), retry()).subscribe({
      next: (result: any) => {
        this.token.set_token(result.token);
        this.router.navigateByUrl(this.next);
      },
      error: (error) => {
        console.error(error);
        this.error = error.error;
      },
    });
  }

  forgot_password(button: HTMLButtonElement) {
    this.dialog_service
      .message_box(
        "Contact \"On Duty Support\" and beg, If we're feeling magnanimous we'll reset it for you"
      )
      .subscribe();
  }

  login(username: string, password: string) {
    this.credentials.next({ username: username, password: password });
  }

  google_sign_in() {
    this.social_auth.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
}
