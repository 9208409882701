<div fxLayout="column">
  <div fxFlex *ngIf="error">
    <p class="detail-not-exist">Imagery Project {{ error.error.detail }}</p>
  </div>
  <mat-card class="clientjob-detail" *ngIf="!error && project.id">
    <div fxFlex fxLayout="column">
      <mat-card-header>
        <div fxFlex fxLayout="column">
          <div fxLayout="row">
            <span fxFlex class="header_label" style="word-break: break-all">
              {{ project.customer_name }}
            </span>
            <span fxFlex fxLayoutAlign="end">
              <button
                mat-icon-button
                matTooltip="Delete Project"
                #delete_project_btn
                (click)="delete_project(delete_project_btn, project.id)"
              >
                <mat-icon>delete</mat-icon>
              </button>
              <a
                mat-icon-button
                class="anchor"
                style="width: auto !important"
                [href]="'#/imagery_project/' + project.id + '/edit'"
              >
                <mat-icon
                  matTooltip="Edit project Details"
                  [routerLink]="['edit']"
                  >edit</mat-icon
                >
              </a>
            </span>
          </div>

          <div fxLayout="row">
            <span class="header_label">{{ project.project_name }}</span>
            <span fxFlex fxLayoutAlign="end">
              <mat-icon matTooltip="Job Time">access_time</mat-icon>
            </span>
          </div>

          <div fxLayout="row" fxLayout.xs="column">
            <span fxFlex fxLayout="column">
              <div fxFlex fxLayout="row">
                <span fxFlex fxLayoutAlign="end" fxLayoutAlign.xs="none">
                  <span class="text-muted"> Start Date : </span>
                  {{ project.start_date | date }}
                </span>
              </div>
              <div fxFlex fxLayout="row">
                <span fxFlex fxLayoutAlign="end" fxLayoutAlign.xs="none">
                  <span class="text-muted"> End Date : </span>
                  {{ project.end_date | date }}
                </span>
              </div>
            </span>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div fxFlex fxLayout="column">
          <p></p>
          <div fxFlex>
            <span>
              <button
                mat-raised-button
                #add_file_btn
                (click)="add_file(add_file_btn)"
              >
                Add Files to Project
              </button>
            </span>
            <span *ngIf="uploading" class="spinner">
              <mat-progress-spinner
                [color]="spinnerColor"
                [mode]="spinnerMode"
                [diameter]="30"
              ></mat-progress-spinner>
            </span>
          </div>

          <mat-tab-group
            class="customerjob-analysis"
            [(selectedIndex)]="selected_tab"
          >
            <mat-tab label="Project" *ngIf="project.stats">
              <div
                fxLayout="row"
                fxLayoutAlign="space-around"
                fxLayout.lt-sm="column"
              >
                <div fxLayout="column" fxLayoutAlign="start">
                  <div fxFlex fxLayout="row" fxLayoutAlign="center">
                    <span width="130px">
                      {{ project.stats.bounds[3] | number : "1.5-5" }}&deg;
                    </span>
                  </div>
                  <div
                    style="line-height: 130px"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="center"
                  >
                    <span>
                      {{
                        project.stats.bounds[0] | number : "1.5-5"
                      }}&deg;&nbsp;
                    </span>
                    <span style="width: 130px; border: 1px solid black">
                      &nbsp;
                    </span>
                    <span>
                      &nbsp;{{
                        project.stats.bounds[2] | number : "1.5-5"
                      }}&deg;
                    </span>
                  </div>
                  <div fxFlex fxLayout="row" fxLayoutAlign="center">
                    <span width="130px">
                      {{ project.stats.bounds[1] | number : "1.5-5" }}&deg;
                    </span>
                  </div>
                </div>
                <div fxLayout="column">
                  <div fxFlex>
                    {{ project.stats.total_lines }} lines loaded [
                    {{ project.stats.length | number : "1.2-2" }} km ]
                  </div>
                  <div fxFlex>
                    {{ project.stats.imaged_lines }} line segments imaged
                  </div>
                  <div fxFlex>
                    {{ project.stats.complete_lines }} line segments complete
                  </div>
                  <div fxFlex>
                    {{ project.stats.error_lines }} line segments with errors
                  </div>
                  <div fxFlex>
                    {{ project.stats.split_lines }} line segments have been
                    split
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Files">
              <file-list
                [object_id]="id"
                [content_type]="'imageryproject'"
                [tagset]="valid_tags"
                [allow_upload]="false"
                #files
              ></file-list>
            </mat-tab>

            <mat-tab label="Notes">
              <notes [object_id]="id" [content_type]="'imageryproject'"></notes>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-card-content>
    </div>
  </mat-card>
</div>
