import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector : 'home',
  templateUrl : './home.component.html',
  styleUrls : [ './home.component.css' ]
})
export class HomeComponent {
  constructor(private titleService: Title) {
    const title = 'Tarnished Lamp Home';
    this.titleService.setTitle(title);
  }

  logout() {
  }
}

