<mat-card>
  <mat-card-title>
    <div fxLayout="row">
      <span fxFlex class="header_label">Customers</span>
      <span fxFlex fxLayoutAlign="end">
        <a mat-icon-button class="anchor" [href]="'#/customer/new'">
          <mat-icon matTooltip="New Customer" [routerLink]="['new']"
            >add</mat-icon
          >
        </a>
      </span>
    </div>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <app-generic-filter
      [filterList]="filters"
      [store_name]="'customer'"
      (filterData)="getFilterData($event)"
    ></app-generic-filter>
    <app-generic-list
      [source]="get_customer_list"
      [router]="'customer'"
      [displayedColumns]="[
        'name',
        'email',
        'phone',
        'city',
        'province',
        'country',
        'anchor'
      ]"
    >
    </app-generic-list>
  </mat-card-content>
</mat-card>
