import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";

import { ApiService } from "@app/api/api";

@Component({
  selector: "payload-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class PayloadFormComponent implements OnInit, OnDestroy {
  payload: { [id: string]: any } = { active: true };
  id: number;
  title = "Add New Payload";
  form_errors: any = {
    make: null,
    model: null,
    non_field_error: null,
    detail: null,
    active: null,
  };
  $payload: Subscription;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private titleService: Title
  ) {
    const title = "Tarnished Lamp " + this.title;
    this.titleService.setTitle(title);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      if (this.id) {
        this.form_errors.detail = null;
        this.title = "Update Payload";
        this.$payload = this.api.payload.detail(this.id).subscribe(
          (payload: any) => {
            const title =
              "Tarnished Lamp " + this.title + " " + payload.registration;
            this.titleService.setTitle(title);
            this.payload = payload;
          },
          (errors) => {
            this.form_errors = errors.error;
          }
        );
      }
    });
  }

  save(button: HTMLButtonElement) {
    let call = this.api.payload.create(this.payload);
    if (this.id) {
      call = this.api.payload.update(this.id, this.payload);
    }
    button.disabled = true;
    call.subscribe({
      next: () => {
        this.location.back();
      },
      error: (errors) => {
        this.form_errors = errors.error;
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  ngOnDestroy() {
    if (this.$payload) {
      this.$payload.unsubscribe();
    }
  }
}
