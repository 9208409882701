<div class="wrapper">
  <header>
    <mat-toolbar color="primary">
      <div fxLayout="row" fxFlex="100%">
        <span fxFlex fxLayoutAlign="start">Contact Form</span>
        <span fxFlex fxLayoutAlign="end">
          <button
            mat-icon-button
            (click)="dialogRef.close()"
            matTooltip="Close Dialog"
          >
            <mat-icon>close</mat-icon>
          </button>
        </span>
      </div>
    </mat-toolbar>
  </header>
  <div class="content">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
      <div fxLayout="column">
        <mat-form-field>
          <input
            matInput
            placeholder="First Name"
            [(ngModel)]="contact.first_name"
            maxlength="32"
          />
        </mat-form-field>
        <mat-error *ngIf="errors.first_name">{{ errors.first_name }}</mat-error>
        <mat-form-field>
          <input
            matInput
            placeholder="Last Name"
            [(ngModel)]="contact.last_name"
            maxlength="32"
          />
        </mat-form-field>
        <mat-error *ngIf="errors.last_name">{{ errors.last_name }}</mat-error>
        <mat-form-field>
          <input
            matInput
            placeholder="Title"
            [(ngModel)]="contact.title"
            maxlength="32"
          />
        </mat-form-field>
        <mat-error *ngIf="errors.title">{{ errors.title }}</mat-error>
        <mat-form-field>
          <input
            matInput
            [imask]="mask"
            [unmask]="true"
            placeholder="Office Number"
            [(ngModel)]="contact.office"
            maxlength="16"
          />
          <mat-hint>Phone Format:(+xxxxxxxxx)</mat-hint>
        </mat-form-field>
        <mat-error *ngIf="errors.office">{{ errors.office }}</mat-error>
        <mat-form-field>
          <input
            matInput
            [imask]="mask"
            [unmask]="true"
            placeholder="Home Number"
            [(ngModel)]="contact.home"
            maxlength="16"
          />
          <mat-hint>Phone Format:(+xxxxxxxxx)</mat-hint>
        </mat-form-field>
        <mat-error *ngIf="errors.home">{{ errors.home }}</mat-error>
        <mat-form-field>
          <input
            matInput
            [imask]="mask"
            [unmask]="true"
            placeholder="Mobile Number"
            [(ngModel)]="contact.mobile"
            maxlength="16"
          />
          <mat-hint>Phone Format:(+xxxxxxxxx)</mat-hint>
        </mat-form-field>
        <mat-error *ngIf="errors.mobile">{{ errors.mobile }}</mat-error>
        <mat-form-field>
          <input
            matInput
            [imask]="mask"
            [unmask]="true"
            placeholder="Fax Number"
            [(ngModel)]="contact.fax"
            maxlength="16"
          />
          <mat-hint>Phone Format:(+xxxxxxxxx)</mat-hint>
        </mat-form-field>
        <mat-error *ngIf="errors.fax">{{ errors.fax }}</mat-error>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <input
            matInput
            placeholder="Primary Email"
            [(ngModel)]="contact.primary_email"
            type="email"
          />
        </mat-form-field>
        <mat-error *ngIf="errors.primary_email">{{
          errors.primary_email
        }}</mat-error>
        <mat-form-field>
          <input
            matInput
            placeholder="Secondary Email"
            [(ngModel)]="contact.secondary_email"
            type="email"
          />
        </mat-form-field>
        <mat-error *ngIf="errors.secondary_email">{{
          errors.secondary_email
        }}</mat-error>
        <mat-form-field>
          <input
            matInput
            [imask]="url_mask"
            [unmask]="true"
            placeholder="Website"
            [(ngModel)]="contact.website"
            type="url"
          />
        </mat-form-field>
        <mat-error *ngIf="errors.website">{{ errors.website }}</mat-error>
        <mat-form-field>
          <input
            matInput
            [imask]="url_mask"
            [unmask]="true"
            placeholder="facebook"
            [(ngModel)]="contact.facebook"
            type="url"
          />
        </mat-form-field>
        <mat-error *ngIf="errors.facebook">{{ errors.facebook }}</mat-error>
        <mat-form-field>
          <input
            matInput
            [imask]="url_mask"
            [unmask]="true"
            placeholder="Twitter"
            [(ngModel)]="contact.twitter"
            type="url"
          />
        </mat-form-field>
        <mat-error *ngIf="errors.twitter">{{ errors.twitter }}</mat-error>
        <mat-form-field>
          <input
            matInput
            [imask]="url_mask"
            [unmask]="true"
            placeholder="Linkedin"
            [(ngModel)]="contact.linkedin"
            type="url"
          />
        </mat-form-field>
        <mat-error *ngIf="errors.linkedin">{{ errors.linkedin }}</mat-error>
      </div>
    </div>
  </div>

  <footer>
    <mat-toolbar>
      <div fxLayout="row" fxFlex="100%">
        <span fxFlex fxLayoutAlign="start">
          <button type="button" mat-button (click)="dialogRef.close()">
            Cancel
          </button>
        </span>
        <span *ngIf="show_save" fxFlex fxLayoutAlign="end">
          <button mat-raised-button #save_btn (click)="save(save_btn)">
            Save
          </button>
        </span>
      </div>
    </mat-toolbar>
  </footer>
</div>
