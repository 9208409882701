import { Action, createAction, props, createReducer, on } from "@ngrx/store";

export enum FilterActionTypes {
  AssignFilters = "[Filter] Assign Filters",
}

export interface Filter {
  [id: string]: any;
}
export interface FilterStates {
  [id: string]: Filter;
}

export const initialState: { [id: string]: FilterStates } = {
  customer: {},
  aircraft: {},
  employee: {},
  airport: {},
  mission: {},
};

export const assignFilters = createAction(
  FilterActionTypes.AssignFilters,
  props<{ component: string; filter: Filter }>()
);

export const filterReducer = createReducer(
  initialState,
  on(assignFilters, (state: FilterStates, { component, filter }) => {
    let result = { ...state };
    result[component] = filter;
    return result;
  })
);

export function FilterReducer(
  state = initialState,
  action: Action
): FilterStates {
  return filterReducer(state, action);
}
