import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import * as missionMapChecksActionTypes from "@app/store/map/sidebar/missions/map_mission/actions";
import MissionMapChecksActionWithPayload, {
  MissionMap,
} from "@app/store/map/sidebar/missions/map_mission/reducer";

@Injectable()
export class MapSidebarMissionMapChecksStoreService {
  missionMapCheckstState$: Observable<MissionMap>;

  constructor(private store: Store<MissionMap>) {}

  public get_mission_map_checks() {
    let missionMapCheckState = {};
    this.missionMapCheckstState$ = this.store.pipe(
      map((state: any) => state.map_sidebar_missions_map_check_states)
    );
    this.missionMapCheckstState$
      .pipe(
        map((checksState) => {
          if (
            checksState &&
            Object.entries(checksState.mission_map_checks).length > 0
          ) {
            missionMapCheckState = checksState.mission_map_checks;
          }
        })
      )
      .subscribe();
    return missionMapCheckState;
  }

  public assign_mission_map_checks(mission_map_checks) {
    const AssignMissionMapChecks: MissionMapChecksActionWithPayload<MissionMap> = {
      type: missionMapChecksActionTypes.ActionTypes.AssignMissionMapChecks,
      mission_map_checks: mission_map_checks,
    };
    this.store.dispatch(AssignMissionMapChecks);
  }
}
