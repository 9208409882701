<mat-card *ngIf="form_errors.detail">
  <div class="detail-not-exist">Record Does Not Exist.</div>
</mat-card>
<div fxLayout="row" fxLayoutAlign="center" *ngIf="!form_errors.detail">
  <mat-card class="client-detail" id="airplane-form">
    <div fxLayout="column">
      <mat-card-header>
        <mat-card-title>
          <h2 fxFlex>{{ title }}</h2>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="column">
          <div fxLayout="row">
            <span fxLayoutAlign="end">
              <mat-slide-toggle [(ngModel)]="airplane.active">
                Active
              </mat-slide-toggle>
            </span>
          </div>
          <div fxLayout="row">
            <mat-error *ngIf="form_errors.non_field_error">
              {{ form_errors.non_field_error }}
            </mat-error>
            <mat-form-field fxFlex>
              <input
                matInput
                placeholder="Make"
                [(ngModel)]="airplane.make"
                maxlength="30"
                required
              />
            </mat-form-field>
            <mat-error *ngIf="form_errors.make">
              {{ form_errors.make }}
            </mat-error>
            <mat-form-field fxFlex>
              <input
                matInput
                placeholder="Model"
                [(ngModel)]="airplane.model"
                maxlength="30"
                required
              />
            </mat-form-field>
            <mat-error *ngIf="form_errors.model">
              {{ form_errors.model }}
            </mat-error>
            <mat-form-field>
              <input
                matInput
                placeholder="Registration"
                [(ngModel)]="airplane.registration"
                maxlength="10"
              />
            </mat-form-field>
          </div>

          <div fxLayout="row">
            <mat-error *ngIf="form_errors.fuel_capacity">
              {{ form_errors.model }}
            </mat-error>
            <mat-form-field>
              <input
                matInput
                placeholder="Fuel Capacity (Gallons)"
                [(ngModel)]="airplane.fuel_capacity"
                maxlength="10"
              />
            </mat-form-field>
            <mat-error *ngIf="form_errors.fuel_rate">
              {{ form_errors.model }}
            </mat-error>
            <mat-form-field>
              <input
                matInput
                placeholder="Fuel Rate (Gallons/Hour)"
                [(ngModel)]="airplane.fuel_rate"
                maxlength="10"
              />
            </mat-form-field>
            <mat-error *ngIf="form_errors.fuel_type">
              {{ form_errors.model }}
            </mat-error>
            <mat-form-field>
              <mat-select
                placeholder="Fuel Type"
                [(ngModel)]="airplane.fuel_type"
              >
                <mat-option value="AVGAS">AVGAS</mat-option>
                <mat-option value="JET A-1">Jet A-1</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <mat-error *ngIf="form_errors.speed">
              {{ form_errors.model }}
            </mat-error>
            <mat-form-field>
              <input
                matInput
                placeholder="Target Ground Speed"
                [(ngModel)]="airplane.speed"
                maxlength="10"
              />
            </mat-form-field>
            <mat-error *ngIf="form_errors.turn_radius">
              {{ form_errors.model }}
            </mat-error>
            <mat-form-field>
              <input
                matInput
                placeholder="Turn Radius"
                [(ngModel)]="airplane.turn_radius"
                maxlength="10"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="column" fxLayoutGap="20px">
          <span fxFlex fxLayoutAlign="end">
            <button mat-raised-button #save_btn (click)="save(save_btn)">
              Save
            </button>
          </span>
          <span fxFlex></span>
        </div>
      </mat-card-actions>
    </div>
  </mat-card>
</div>
