import { Component, OnInit, ViewChildren, OnDestroy } from "@angular/core";
import { timer, Observable, Subscription } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { ApiService } from "@app/api/api";
import { environment } from "@env/environment";
import { ErrorService } from "./error.service";

import { AircraftEntryComponent } from "./aircraft_entry";

export const MY_MOMENT_FORMATS = {
  parseInput: "YYYY-MM-DD HH:mm",
  fullPickerInput: "YYYY-MM-DD HH:mm",
  datePickerInput: "YYYY-MM-DD",
  monthYearLabel: "MM YYYY",
  dateA11yLabel: "L",
  monthYearA11yLabel: "MM YYYY",
  timePickerInput: "HH:mm",
};

@Component({
  selector: "sidebar-aircraft",
  templateUrl: "./aircraft.html",
  styleUrls: ["./aircraft.css"],
})
export class AircraftComponent implements OnInit, OnDestroy {
  $aircraft: Subscription;
  check_status = {};
  aircraft = [];
  empty_obs = new Observable();
  new_retry = true;
  @ViewChildren(AircraftEntryComponent) entries;
  search_param: { [id: string]: any } = {
    page: null,
    page_size: environment.default_page_size,
  };
  request_fail = false;
  totalCount: null;
  pageCount: null;
  barColor = "warn";
  barMode = "indeterminate";

  constructor(private api: ApiService, private errorService: ErrorService) {
    this.errorService.getError.subscribe((data: any) => {
      this.request_fail = data;
    });
  }

  ngOnInit() {
    this.load_aircraft(this.search_param);
  }

  page_event(event) {
    this.search_param.page_size = event.pageSize;
    this.search_param.page = event.pageIndex + 1;
    this.load_aircraft(this.search_param);
  }

  load_aircraft(params) {
    this.$aircraft = this.api.aircraft.search(params).subscribe((res: any) => {
      this.aircraft = res.results.filter(
        (e: { active: any }, _i: any, _a: any) => {
          return e.active;
        }
      );
      this.aircraft.forEach(
        (aircraft) => (this.check_status[aircraft.id] = false)
      );
      this.totalCount = res.count;
      this.pageCount = res.results.length;
    });
  }

  ngOnDestroy() {
    if (this.$aircraft) {
      this.$aircraft.unsubscribe();
    }
  }
}
