import { Action } from '@ngrx/store';

export enum ActionTypes {
    GetMissionMapChecks = '[Mission Map Checks] Get Mission Map Checks',
    AssignMissionMapChecks = '[Mission Map Checks] Assign Mission Map Checks'
}

export class GetMissionMapChecks implements Action {
    readonly type = ActionTypes.GetMissionMapChecks;
    constructor() {}
}

export class AssignMissionMapChecks implements Action {
    readonly type = ActionTypes.GetMissionMapChecks;
    constructor(public routes: {}) {}
}

export type MapMissionRouteActions = GetMissionMapChecks | AssignMissionMapChecks;
