<link
  href="https://unpkg.com/leaflet@1.0.3/dist/leaflet.css"
  rel="stylesheet"
/>
<div fxFlex fxLayout="row" fxLayoutAlign="space-around">
  <button
    mat-stroked-button
    *ngIf="plan"
    #get_plan_btn
    (click)="get_link(get_plan_btn, 'plan')"
  >
    Plan Link
  </button>
  <button
    mat-stroked-button
    *ngIf="track"
    #get_track_btn
    (click)="get_link(get_track_btn, 'track')"
  >
    Track Link
  </button>
</div>
<div #map id="trackmap"></div>
