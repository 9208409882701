import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import * as L from "leaflet";
import "leaflet-rotatedmarker";
import "leaflet.icon.glyph";
// import "leaflet.markercluster";

import * as colormap from "colormap";

import { environment } from "@env/environment";
import { ApiService } from "@app/api/api";
import { TaskService } from "@app/task/task.service";
import { TooltipInjector } from "@app/common/map_tools";
import {
  MeterTooltipComponent,
  MeterReadingTooltipComponent,
} from "@app/common/meter-toolip";
import { ErrorHandleService } from "@app/services/errorHandle.service";

@Component({
  selector: "reading-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css"],
})
export class MeterReadingDetailComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  map: any;
  colormap = [];
  cluster: any;
  meter_marker: any = null;
  current_marker: any = null;
  private id: number;
  nextpage: boolean = null;
  locations = [];
  readings_loaded: boolean = false;
  readings: any[] = [];
  meter: any = null;
  $meters_location: Subscription;
  $meter: Subscription;
  $meter_reading: Subscription;
  not_found: any = null;
  public readonly default_color = "rgba(128,128,128,0.5)";

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private tooltip_injector: TooltipInjector,
    private tasks: TaskService,
    private errorHandleService: ErrorHandleService,
    private titleService: Title
  ) {
    this.colormap = colormap({
      //colormap: "cool",
      colormap: [
        { index: 0, rgb: [0, 255, 0, 1] },
        { index: 0.25, rgb: [0, 255, 0, 1] },
        { index: 0.75, rgb: [0, 0, 255, 0.25] },
        { index: 1, rgb: [255, 0, 0, 0.1] },
      ],
      nshades: 16,
      format: "rgbaString",
    });
    const last = this.colormap.length - 1;
    this.colormap[last] = this.colormap[last]?.replace(",1)", ")");
    // console.table(this.colormap);
  }

  ngOnInit() {
    this.get_meter();
  }

  get_meter() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.cluster = L.markerClusterGroup({ disableClusteringAtZoom: 8 });
      this.update_meter_marker(this.id);
      this.add_meter_reading(this.id);
      this.load_locations();
    });
  }

  set_meter(button: HTMLButtonElement, location_id) {
    button.disabled = true;
    this.api.meter.set_location(this.id, location_id).subscribe({
      next: (data) => {
        this.get_meter();
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  load_locations() {
    this.$meters_location = this.api.meter_location
      .list({ meter: this.id })
      .subscribe({
        next: (data: any) => {
          if (data.results.length > 0) {
            const locations = data.results;
            locations.forEach((x) => (x.created = new Date(x.created)));
            this.locations = locations;
            this.zoom_location(this.locations.find((e) => e.active).location);
          }
        },
        error: (errors) => {
          console.error(errors.error);
        },
      });
  }

  calculate_location(button: HTMLButtonElement) {
    button.disabled = true;
    this.api.meter.locate(this.id).subscribe({
      next: (data: any) => {
        if ("task_id" in data) {
          this.watch_caclulation(data["task_id"]);
        }
      },
      error: (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  watch_caclulation(task_id) {
    this.tasks.add_task(task_id, {
      message: "Location calculation Scheduled",
      started: {
        message: "Location Calculation Running",
        always: true,
      },
      finished: {
        message: "Location Calculation Complete",
        callback: (job) => {
          this.update_meter_marker(this.id);
          this.load_locations();
        },
      },
    });
  }

  update_meter_marker(id) {
    if (this.meter_marker !== null) {
      this.meter_marker.removeFrom(this.map);
    }
    this.$meter = this.api.meter.detail(id).subscribe({
      next: (data: any) => {
        this.meter = data.properties;
        const title = "Tarnished Lamp Meter Reading " + data.properties.serial;
        this.titleService.setTitle(title);
        const coords = data.geometry.coordinates;
        this.meter_marker = new L.CircleMarker([coords[1], coords[0]], {
          radius: 5,
        });
        const name = "meter_reading_detail";
        this.tooltip_injector.InjectPopupComponent(MeterTooltipComponent)(
          data,
          this.meter_marker,
          name
        );
        this.meter_marker.addTo(this.map);
        if (!this.readings_loaded) {
          this.map.panTo([coords[1], coords[0]]);
        }
      },
      error: (errors) => {
        this.not_found = errors.error.detail;
      },
    });
  }

  add_meter_reading(id: number, page: number = 1) {
    this.$meter_reading = this.api.meter_reading
      .list({ meter: id, location: true, page: page })
      .subscribe({
        next: (res: any) => {
          console.log(res);
          if (this.nextpage === null) {
            this.cluster.clearLayers();
          }
          this.nextpage = Boolean(res.next);
          if (res.count > 0) {
            for (const item of res.results) {
              item.read_at = new Date(item.read_at);
              let icon = this.select_arrow(item.strength);
              console.log(icon);
              const meter = L.marker(
                [
                  item.location.coordinates["1"],
                  item.location.coordinates["0"],
                ],
                {
                  rotationAngle: item.heading ? item.heading : "0",
                  icon: icon,
                }
              );
              const name = "meter_reading_detail";
              this.tooltip_injector.InjectPopupComponent(
                MeterReadingTooltipComponent
              )(item, meter, name);
              this.cluster.addLayer(meter);
              this.readings.push(item);
            }
            this.readings.sort((a, b) => {
              return b.read_at - a.read_at;
            });
          }
        },
        error: (error: any) => {
          console.error(error);
        },
        complete: () => {
          if (this.nextpage) {
            //this.add_meter_reading(id, page + 1);
          } else {
            const bounds = this.cluster.getBounds();
            if (bounds.isValid()) {
              this.map.fitBounds(bounds);
            }
          }
        },
      });
  }

  signal_to_index(value) {
    return Math.floor(
      Math.min(Math.max(0, value * -1 - 60) / 60, 1) *
        (this.colormap.length - 1)
    );
  }
  index_to_signal(index) {
    return -((60 * index) / (this.colormap.length - 1) + 60);
  }
  select_arrow(value) {
    let color = this.default_color;
    if (value) {
      let index = this.signal_to_index(value);
      color = this.colormap[index];
    }
    if (!color) {
      color = this.default_color;
    }
    return L.icon.glyph({
      prefix: "mdi",
      glyph: "navigation",
      iconUrl: "",
      //iconAnchor: [0, 0],
      glyphAnchor: [0, 20],
      glyphColor: color,
      glyphSize: "24px",
    });
  }

  ngAfterViewInit() {
    const OSM = L.tileLayer(environment.osm_server, {
      maxZoom: 18,
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    });
    const VNC = L.tileLayer(environment.vnc_server, {
      maxZoom: 11,
      attribution: "",
    });
    const Sat = L.tileLayer(environment.satellite_server, {
      maxZoom: 18,
      attribution: "",
    });
    const sectional = L.tileLayer(environment.us_sectional_server, {
      // zoom: 7,
      attribution: "",
    });

    const basemaps = {
      OSM: OSM,
      VNC: VNC,
      Satellite: Sat,
      Sectional: sectional,
    };
    const overlays = {};

    const southWest = L.latLng(-84.6308, -179.9945),
      northEast = L.latLng(85.0491, 175.7552),
      bounds = L.latLngBounds(southWest, northEast);

    this.map = L.map("metermap", {
      center: L.latLng([52.9089, -111.5552]),
      zoom: 8,
      layers: [OSM],
      maxBounds: bounds,
      editable: true,
    });
    L.control.layers(basemaps, overlays).addTo(this.map);
    this.cluster.addTo(this.map);
  }

  zoom_location(location) {
    if (this.current_marker !== null) {
      this.current_marker.removeFrom(this.map);
    }

    this.current_marker = L.geoJson(location);
    this.current_marker.addTo(this.map);
    const bounds = this.current_marker.getBounds();
    if (bounds.isValid()) {
      this.map.flyToBounds(bounds, { maxZoom: 12 });
    }
  }

  delete_location(id) {
    this.api.meter_location.delete(id).subscribe(
      (res: any) => {},
      (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      }
    );
  }

  ngOnDestroy() {
    if (this.$meter) {
      this.$meter.unsubscribe();
    }
    if (this.$meters_location) {
      this.$meters_location.unsubscribe();
    }
    if (this.$meter_reading) {
      this.$meter_reading.unsubscribe();
    }
  }
}
