<mat-card class="detail">
  <div class="detail-not-exist" *ngIf="deleted">Record Does Not Exist</div>
  <div fxLayout="column" *ngIf="!deleted">
    <mat-card-header>
      <div fxLayout="row" fxFlex="100%">
        <span fxFlex>
          <span class="header_label" style="word-break: break-all !important">{{
            customer.name
          }}</span>
          <span> [{{ customer.code }}]</span>
        </span>
        <span fxFlex fxLayoutAlign="end" fxFlexAlign="end">
          <button
            mat-icon-button
            matTooltip="Delete Customer Details"
            #delete_customer_btn
            (click)="delete_customer(delete_customer_btn, customer.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <a
            mat-icon-button
            class="anchor"
            [href]="'#/customer/' + customer.id + '/edit'"
          >
            <mat-icon matTooltip="Edit Customer Details" [routerLink]="['edit']"
              >edit</mat-icon
            >
          </a>
        </span>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" fxFlex="100%">
        <div fxLayout="column">
          <div fxLayout="row">
            <span fxFlex>{{ customer.id }}</span>
            <span fxFlex fxLayoutAlign="end">{{
              customer.last_updated | date
            }}</span>
          </div>
        </div>

        <div fxLayout="column">
          <div fxLayout="row">
            <span fxFlex fxHide.xs="true"></span>
            <span fxFlex fxLayout="column" fxLayoutGap="10px">
              <!--new row of right side-->
              <div *ngIf="customer.address2" fxLayout="row">
                <span fxFlex class="text-muted"> Address</span>
                <span fxFlex fxLayoutAlign="end">
                  {{ customer.address2 }}
                  {{ customer.city }} {{ customer.province }},
                  {{ customer.country }} {{ customer.postal_code }}
                </span>
              </div>

              <!--new row of right side-->
              <div *ngIf="customer.fax" fxLayout="row">
                <span fxFlex class="text-muted"> Fax</span>
                <span fxFlex fxLayoutAlign="end">{{ customer.fax }}</span>
              </div>

              <!--new row of right side-->
              <div *ngIf="customer.phone" fxLayout="row">
                <span fxFlex class="text-muted"> Phone</span>
                <span fxFlex fxLayoutAlign="end">
                  <span class="customer-detail">{{
                    customer.phone | mask : "(000)-000-0000"
                  }}</span>
                  <button
                    mat-icon-button
                    matTooltip="Call"
                    #call_btn
                    (click)="call(call_btn)"
                  >
                    <mat-icon>call</mat-icon>
                  </button>
                </span>
              </div>

              <!--new row of right side-->
              <div *ngIf="customer.email" fxLayout="row">
                <span fxFlex class="text-muted"> Email</span>
                <span fxFlex fxLayoutAlign="end">
                  <span class="customer-detail">{{ customer.email }}</span>
                  <button
                    mat-icon-button
                    matTooltip="E-Mail"
                    #email_btn
                    (click)="email(email_btn)"
                  >
                    <mat-icon>email</mat-icon>
                  </button>
                </span>
              </div>

              <!--new row of right side-->
              <div *ngIf="customer.website" fxLayout="row">
                <span fxFlex class="text-muted"> Website</span>
                <span fxFlex fxLayoutAlign="end">
                  <span class="customer-detail">{{ customer.website }}</span>
                  <button
                    mat-icon-button
                    matTooltip="Website"
                    #browse_btn
                    (click)="browse(browse_btn)"
                  >
                    <mat-icon>public</mat-icon>
                  </button>
                </span>
              </div>
            </span>
            <!--end of right side-->
          </div>
        </div>

        <div fxLayout="column">
          <mat-tab-group
            [(selectedIndex)]="selected_tab"
            (selectedTabChange)="tab_selected($event)"
          >
            <mat-tab label="Contacts" fxFlex>
              <contact-list
                [object_id]="id"
                [content_type]="'customer'"
              ></contact-list>
            </mat-tab>
            <mat-tab label="Files" fxFlex>
              <div
                class="detail-not-exist"
                *ngIf="fileImportError.non_field_errors"
              >
                {{ fileImportError.non_field_errors }}
              </div>
              <file-list
                [object_id]="id"
                [content_type]="'customer'"
                [process_file]="process_file"
              ></file-list>
            </mat-tab>
            <mat-tab label="Users" fxFlex>
              <div>
                <mat-list>
                  <mat-list-item *ngFor="let user of users">
                    <mat-icon mat-list-avatar>person</mat-icon>
                    <h4 mat-line>{{ user.name }}</h4>
                    <p mat-line>{{ user.updated | date }}</p>
                  </mat-list-item>
                </mat-list>
              </div>
            </mat-tab>
            <mat-tab label="Meter Groups" fxFlex>
              <metergroups #meterGroups [customer]="id"></metergroups>
            </mat-tab>
            <mat-tab label="Service Area" fxFlex>
              <route-map
                #map
                [meter_set]="meterset"
                [service_area]="customer.service_area"
                [editable]="true"
                [newpoly]="true"
              ></route-map>
              <div fxLayout="row">
                <div fxFlex fxlayout="column" class="here">
                  <mat-form-field>
                    <mat-select
                      placeholder="Meter Group"
                      [(ngModel)]="meterset"
                    >
                      <mat-option
                        *ngFor="let mg of meter_groups"
                        [value]="mg.id"
                      >
                        {{ mg.group_name }}
                        <span *ngIf="mg.group_id">[{{ mg.group_id }}]</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    fxFlexAlign="end"
                    mat-raised-button
                    class="service-area-button"
                    #update_btn
                    (click)="update(update_btn)"
                  >
                    Update Service Area
                  </button>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Customer Options" fxFlex>
              <div>
                <div *ngFor="let option of options">
                  <mat-checkbox
                    *ngIf="option.datatype == 'boolean'"
                    [(ngModel)]="option.value"
                    >{{ option.label }}</mat-checkbox
                  >
                </div>
              </div>
              <button
                mat-raised-button
                #update_options_btn
                (click)="update(update_options_btn)"
              >
                Update
              </button>
            </mat-tab>
            <mat-tab label="Notes" fxFlex>
              <notes [object_id]="id" [content_type]="'customer'"></notes>
            </mat-tab>
            <mat-tab label="raw" fxFlex>
              <pre>{{ customer | json }}</pre>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </mat-card-content>
  </div>
</mat-card>
