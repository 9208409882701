<!-- <pre> {{displayed_columns|json}}</pre>
<pre> {{table_data | json}}</pre>
<pre> {{analysis | json}}</pre> -->

<mat-table [dataSource]="table_data" width="100%">
  <ng-container matColumnDef="route_name">
    <mat-header-cell *matHeaderCellDef>Route Name</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.route_name}}</mat-cell>
    <mat-footer-cell *matFooterCellDef> Totals </mat-footer-cell>
  </ng-container>

  <ng-container *ngFor="let column of columns" [matColumnDef]="column">
    <mat-header-cell *matHeaderCellDef>
      {{column_label(column)}}
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{row[column]}}</mat-cell>
    <mat-footer-cell *matFooterCellDef> {{totals[column]}} </mat-footer-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayed_columns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayed_columns;"></mat-row>
  <mat-footer-row *matFooterRowDef="displayed_columns"></mat-footer-row>
</mat-table>
