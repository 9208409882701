<div fxLayout="column" fxFlex="100%">
  <mat-card class="airport-form" id="airport-form-id">
    <div *ngIf="form_errors.detail" class="detail-not-exist">
      Record Does Not Exist.
    </div>
    <div fxLayout="column" *ngIf="!form_errors.detail">
      <mat-card-header>
        <mat-card-title>
          <h2 fxFlex>{{ title }}</h2>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="column">
          <mat-form-field>
            <input
              matInput
              placeholder="Name"
              [(ngModel)]="airport.properties.name"
              maxlength="255"
            />
          </mat-form-field>
          <mat-error *ngIf="form_errors.name">{{ form_errors.name }}</mat-error>
          <mat-form-field>
            <input
              matInput
              placeholder="ICAO Identifier"
              [(ngModel)]="airport.properties.icao_code"
              maxlength="4"
            />
          </mat-form-field>
          <mat-error *ngIf="form_errors.icao_code">
            {{ form_errors.icao_code }}
          </mat-error>
          <span fxLayout="row">
            <mat-form-field>
              Latitude
              <input matInput placeholder="Latitude" [(ngModel)]="coord.lat" />
            </mat-form-field>
            <mat-form-field>
              Longitude
              <input matInput placeholder="Longitude" [(ngModel)]="coord.lng" />
            </mat-form-field>
          </span>
          <span>
            <mat-form-field
              >Field Altitude
              <input
                matInput
                placeholder="Field Altitude"
                [(ngModel)]="airport.properties.field_altitude"
            /></mat-form-field>
            <mat-form-field style="width: 5em">
              <mat-select [(ngModel)]="altitude_conversion_factor">
                <mat-option value="1">Feet</mat-option>
                <mat-option value="3.3">Meters</mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <mat-error class="mt-3" *ngIf="form_errors.footprint">
            Polygon: Must contain atleast 4 points.
          </mat-error>
          <app-airport-map
            #map
            [airport_footprint]="airport.properties.footprint"
            [(point)]="coord"
            [editable]="true"
            [newPoly]="true"
          ></app-airport-map>
          <!--
            <span>
                <pre>{{airport | json}}</pre>
                <pre>{{coord | json}}</pre>
            </span>
        --></div>
      </mat-card-content>
      <mat-card-actions>
        <span fxFlex fxLayoutAlign="end">
          <button
            mat-raised-button
            [disabled]="!isPolygon"
            #save_btn
            (click)="save(save_btn)"
          >
            Save
          </button>
        </span>
      </mat-card-actions>
    </div>
  </mat-card>
</div>
