import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { first, mergeMap, takeUntil } from "rxjs/operators";
import { FileSaverService } from "ngx-filesaver";

import { copyTextToClipboard } from "@app/common/clipboard";
import { ApiService } from "@app/api/api";
import { DialogService } from "@app/services/dialog.service";
import { TaskService } from "@app/task/task.service";
import { ErrorHandleService } from "@app/services/errorHandle.service";

interface JobResult {
  id: any;
  analysis: any;
  link?: string;
  [id: string]: any;
}

@Component({
  selector: "customerjob-results",
  templateUrl: "./detail.component.html",
  styleUrls: ["../component.css"],
})
export class JobResultsDetailComponent implements OnInit, OnDestroy {
  customer_job = new BehaviorSubject({ id: null, analysis: null, results: [] });

  get job() {
    return this.customer_job.value;
  }

  set job(data) {
    this.customer_job.next(data);
  }

  job_result = new BehaviorSubject<JobResult>({ id: null, analysis: null });

  get result(): JobResult {
    return this.job_result.value;
  }

  set result(data) {
    this.job_result.next(data);
  }

  get link() {
    return this.result.link;
  }

  error = null;
  id: string;
  valid_tags = ["meter locations", "data logging request", "real time request"];
  validated = false;
  selected_tab: number = 0;
  UnSubscribe = new Subject();
  $job_result: Subscription;
  $customer_job: Subscription;
  $download_file: Subscription;
  @ViewChild("files") files;
  @ViewChild("routeanalysis") route_analysis;

  constructor(
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute,
    private tasks: TaskService,
    private fileSaverService: FileSaverService,
    private errorHandleService: ErrorHandleService,
    private api: ApiService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        mergeMap((params) => {
          this.id = params["id"];
          return this.api.customer_job.detail(this.id);
        })
      )
      .pipe(takeUntil(this.UnSubscribe))
      .subscribe(
        (data: any) => {
          this.job = data;
          const title = "Tarnished Lamp CustomerJob Result" + this.id;
          this.titleService.setTitle(title);
        },
        (error: any) => {
          this.id = null;
          this.error = error;
        }
      );
    this.customer_job.asObservable().subscribe((job: any) => {
      if (this.job.results.length) {
        this.$job_result = this.api.job_result
          .detail(job.results[0])
          .subscribe((res: any) => {
            this.result = res;
          });
      }
    });
  }

  reload(tab: number = 0) {
    this.$customer_job = this.api.customer_job
      .detail(this.id)
      .subscribe((data: any) => {
        this.job = data;
        this.selected_tab = tab;
      });
  }

  makelink() {
    if (this.link) {
      copyTextToClipboard(this.link);
      return;
    }
    this.api.customer_job.make_link(this.id).subscribe((res: any) => {
      copyTextToClipboard(this.link);
      this.reload();
    });
  }

  create_results_archive(id) {
    this.api.job_result.get_archive(id).subscribe(
      (task: any) => {
        this.tasks.add_task(task.task_id, {
          message: "Scheduled Archive Generation",
          started: {
            message: "Generating Archive",
          },
          finished: {
            message: "Archive Complete",
            callback: (job) => {
              this.download_results_archive(job.result);
            },
          },
        });
      },
      (errors) => {
        this.errorHandleService.sendError(
          "An error occurred while processing your request. If this keeps happening please contact support."
        );
      }
    );
  }

  download_results_archive(file) {
    this.$download_file = this.api.file
      .download(file.id)
      .pipe(first())
      .subscribe(
        (res: any) => {
          let data = new Blob([res.body], { type: "text/plain;charset=utf-8" });
          this.fileSaverService.save(data, file.filename);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  ngOnDestroy() {
    if (this.$job_result) {
      this.$job_result.unsubscribe();
    }
    if (this.$customer_job) {
      this.$customer_job.unsubscribe();
    }
    this.UnSubscribe.next(1);
    this.UnSubscribe.complete();
  }
}
