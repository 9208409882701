import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {

  // Observable string sources
  private notification = new Subject<any>();

  constructor() { }
  // Observable streams
  get_notification(): Observable<any> {
    return this.notification.asObservable();
  }
  // Service message commands

  add_notification(message: string, action: string = null, duration: number = 2500) {
    const d = { message: message, action: action, duration: duration };
    this.notification.next(d);
  }
}
