

<div #map class="map-wrapper" fxFlexFill>
  <mat-sidenav-container fxFlexFill>
    <mat-sidenav
      #sidenavMap
      [disableClose]="isSidenavCloseDisabled"
      [mode]="sidenavPosition"
      [opened]="isSidenavOpen"
      (opened)="(!previous_btn)"
      (closed)="(!next_btn)"
      id="map-sidenav"
    >
      <map-sidebar [search_meter_layer]="search_meter_layer"></map-sidebar>
    </mat-sidenav>
    <mat-sidenav-content class="map-area">
      <div id="map" fxFlexFill></div>
      <div [hidden]="!profile_chart" id="graphchart">
        Profile
        <mat-icon id="graphicon" matTooltip="Aircraft Profile"
          >timeline</mat-icon
        >
        <div #charts></div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <button mat-mini-fab (click)="get_link()" matTooltip="Get Map Link">
    <mat-icon>link</mat-icon>
  </button>
  <button mat-mini-fab *ngIf="next_btn" (click)="sidenav('sidebar')">
    <mat-icon>skip_next</mat-icon>
  </button>
  <button mat-mini-fab *ngIf="this.previous_btn" (click)="sidenav('map')">
    <mat-icon>skip_previous</mat-icon>
  </button>
</div>
