import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import * as turf from "@turf/turf";
import { MatLegacyTable as MatTable } from "@angular/material/legacy-table";

class RouteData {
  selected: boolean = false;
  get name(): string {
    return this._route.name;
  }

  get id(): number {
    return this._route.id;
  }

  get scm_read(): number {
    return this.get_value("scm", "reads");
  }

  get scm_request(): number {
    return this.get_value("scm", "request");
  }

  get scm_pct(): number {
    return this.get_value("scm", "pct");
  }

  get idm_read(): number {
    return this.get_value("idm", "reads");
  }

  get idm_request(): number {
    return this.get_value("idm", "request");
  }

  get idm_pct(): number {
    return this.get_value("idm", "pct");
  }

  _route;
  constructor(route) {
    this._route = route;
  }

  get completion() {
    let analysis = this._route.analysis;
    if (!analysis) {
      return 0;
    }
    analysis = analysis.idm[1] ? analysis.idm : analysis.scm;
    analysis = analysis[1] ? analysis[0] / analysis[1] : 0;
    return analysis;
  }

  get area() {
    if (!this._route.service_area) {
      return 0;
    }
    return turf.area(this._route.service_area) / 10000;
  }

  get_value(type: string, value: string) {
    if (this._route === undefined || this._route.analysis === undefined) {
      return 0;
    }
    let analysis = this._route.analysis[type];
    switch (value) {
      case "reads":
        return analysis[0];
      case "request":
        return analysis[1];
      case "pct":
        return analysis[1] ? analysis[0] / analysis[1] : 0;
    }
  }
}

@Component({
  selector: "mission-routes",
  templateUrl: "./mission-routes.html",
  styleUrls: ["../component.css"],
})
export class MissionDetailRoutesComponent implements OnInit {
  @ViewChild("table", { static: true }) table: MatTable<RouteData>;
  @Input("routes") set routes(routes: {}[]) {
    if (routes.length == 0) {
      return;
    }
    this.dataset = routes.map((element) => new RouteData(element));
    this.sorted_dataset = this.dataset.slice();
    this.table.renderRows();
  }

  dataset: RouteData[] = [];
  sorted_dataset: RouteData[] = [];

  @Input("selected_routes") selected_routes;
  @Output("selected_routesChange") selected_routes_change = new EventEmitter<
    number[]
  >();
  displayed_columns = [
    "selected",
    "name",
    "scm_read",
    "scm_request",
    "scm_pct",
    "idm_read",
    "idm_request",
    "idm_pct",
    "completion",
    "area",
  ];
  group_headers = ["blank_header", "scm_header", "idm_header"];

  ngOnInit() {}

  get_total_area() {
    let sum = 0;
    for (let route of this.dataset) {
      sum += route.area;
    }
    return sum;
  }

  get_total(type: string, value: string) {
    let sum = 0;
    for (let route of this.dataset) {
      sum += route.get_value(type, value);
    }
    return sum;
  }

  get_total_pct(type) {
    let total = this.get_total(type, "request");
    return total ? this.get_total(type, "reads") / total : 0;
  }

  get_total_completion() {
    let complete = 0;
    let total = 0;

    for (let route of this.dataset) {
      if (route.idm_request > 0) {
        complete += route.idm_read;
        total += route.idm_request;
      } else {
        complete += route.scm_read;
        total += route.scm_request;
      }
    }

    return total ? complete / total : 0;
  }

  sort_change(sort_event) {
    const data = this.dataset.slice();
    if (!sort_event.active || sort_event.direction === "") {
      this.sorted_dataset = data;
      return;
    }

    this.sorted_dataset = data.sort((a, b) => {
      return compare(
        a[sort_event.active],
        b[sort_event.active],
        sort_event.direction === "asc"
      );
    });

    this.table.renderRows();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
