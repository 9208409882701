import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { filter, map, takeUntil } from "rxjs/operators";
import { ApiService } from "@app/api/api";
import { LayerService } from "@app/services/layer.service";
import {
  Payload,
  PayloadUpdateMessage,
  RealTimeService,
} from "@app/services/realtime.service";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { EndpointRecord, PayloadLayer } from "@cleargrid/map_layers";
import { DialogService } from "@app/services/dialog.service";
import { PayloadEndpointListComponent } from "./endpoint_detail";
import { PopupService } from "@app/map/popup.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { SubSink } from "subsink";
import { LatLng } from "leaflet";

@Component({
  selector: "payload-status",
  templateUrl: "./payload_status.html",
  styleUrls: ["./aircraft.css"],
  providers: [],
})
export class PayloadStatusComponent implements OnInit, OnDestroy {
  @Input("payload")
  public payload: Payload = null;
  layer: PayloadLayer = undefined;
  _percent: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  percent: Observable<number> = this._percent.asObservable();
  completion;
  $completion;
  enabled: boolean = false;
  subsink = new SubSink();

  constructor(
    private api: ApiService,
    private layer_service: LayerService,
    private rts: RealTimeService,
    private dialog_service: DialogService,
    private popup: PopupService
  ) {}

  ngOnInit() {}
  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  _create_payload_layer(payload: Payload): PayloadLayer {
    let map_layer = new PayloadLayer();

    map_layer.setMouseRange(5);
    this.subsink.sink = this.api.payload
      .endpoints(payload.payload)
      .subscribe((d) => map_layer.addEndpoints(d));

    this.subsink.sink = map_layer.selectedRecords
      .pipe(
        map((records: EndpointRecord[]) =>
          records.filter((record) => record.state != 1)
        ),
        filter((records: EndpointRecord[]) => records && records.length > 0)
      )
      .subscribe((records) => {
        let location: LatLng = new LatLng(
          records[0].latitude,
          records[0].longitude
        );
        this.popup.show(
          location,
          PayloadEndpointListComponent,
          records,
          "Endpoints"
        );
      });

    map_layer.setEndpointSource(() => {
      console.log(
        `getting endpoint data from ${payload.payload_ident} [${payload.payload}]`
      );
      return this.api.payload.endpoints(payload.payload);
    });

    map_layer.linkUpdates(
      this.rts.payload_subscribe(payload).pipe(
        map((e: PayloadUpdateMessage) => {
          if (e.refresh) {
            map_layer.updateEndpoints();
            return [];
          }
          return e.updates;
        })
      )
    );

    return map_layer;
  }

  toggle_payload(event: MatCheckboxChange) {
    if (event.checked) {
      if (!this.layer) {
        this.layer = this._create_payload_layer(this.payload);
        this.$completion = this.layer.completion.subscribe((c) => {
          this.completion = c;
          this._percent.next((c.complete / c.total) * 100);
          console.log("completion", c);
        });
        this.subsink.sink = this.$completion;
      }
      this.layer_service.add_layer("layer", this.layer);
    } else {
      this.layer_service.delete_layer("layer", this.layer);
      if (this.$completion) {
        this.$completion.unsubscribe();
        this.$completion = null;
      }
    }
  }

  payload_layer_exists() {
    return this.layer !== undefined;
  }

  configure_layer() {
    this.subsink.sink = this.dialog_service
      .endpoint_style_form(this.layer.getIcons())
      .subscribe((d) => {
        if (d && d.length == 4) {
          this.layer.setIcons(d);
        }
      });
  }
}
